import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import moment from "moment";
import ClipPlayer from "../../components/ClipPlayer";
import ReactPlayer from "../../components/ReactPlayer";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../RootNavigation";

const RenderReact = ({ item }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <View>
      <View style={[styles.clipContainer, { width: isMobile ? "100%" : 410 }]}>
        <ClipPlayer
          _id={`streamerProfileClip-0`}
          feed={false}
          playbackId={item.clipId}
        />
      </View>
      <View style={styles.reactOuterContainer}>
        <View style={[styles.reactDetails, { width: isMobile ? 135 : 160 }]}>
          <Text style={styles.reactName}>{item.reactName}</Text>
          <View style={styles.detailContainer}>
            <Text style={styles.label}>TOTAL</Text>
            <Text style={styles.value}>{item.total}</Text>
          </View>
          <View style={styles.detailContainer}>
            <Text style={styles.label}>OG PRICE</Text>
            <Text style={styles.value}>${item.reserve.toFixed(2)}</Text>
          </View>
          <View style={styles.detailContainer}>
            <Text style={styles.label}>DROP</Text>
            <Text style={styles.value}>
              {moment(item.dropDate).format("l")}
            </Text>
          </View>
        </View>
        <View style={styles.reactContainer}>
          <ReactPlayer
            _id={`streamerProfileReact-0`}
            playbackId={item.reactId}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  clipContainer: {
    backgroundColor: colors.backOverlay,
    width: 410,
    alignSelf: "flex-start",
    marginBottom: 25,
  },
  reactOuterContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  reactName: {
    fontFamily: fonts.semiBold,
    color: "white",
    fontSize: 20,
    marginBottom: 20,
  },
  reactContainer: {
    backgroundColor: "red",
    width: 150,
    height: 150,
  },
  detailContainer: {
    alignSelf: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    flexWrap: "wrap",
    width: "100%",
  },
  label: {
    fontFamily: fonts.semiBold,
    color: "grey",
    fontSize: 12,
  },
  value: {
    fontFamily: fonts.bold,
    color: "white",
    fontSize: 18,
  },
});

export default RenderReact;
