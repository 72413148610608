import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  FlatList,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { Input } from "react-native-elements";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { Context as UserContext } from "../../context/UserContext";

const ReactRequest = () => {
  const {
    fetchReactRequests,
    submitReactRequest,
    state: { isUserInfoLoaded, streamerProfile, reactRequests },
  } = useContext(UserContext);
  const [clipLinkOne, setClipLinkOne] = useState("");
  const [clipLinkTwo, setClipLinkTwo] = useState("");
  const [clipLinkThree, setClipLinkThree] = useState("");
  const [clipLinkFour, setClipLinkFour] = useState("");
  const [clipLinkFive, setClipLinkFive] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const windowSize = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    const loadReactRequests = async () => {
      if (!reactRequests) {
        await fetchReactRequests();
      }
    };
    loadReactRequests();
  }, []);

  const onSave = async () => {
    if (!isLoading) {
      if (
        clipLinkOne == "" ||
        clipLinkTwo == "" ||
        clipLinkThree == "" ||
        clipLinkFour == "" ||
        clipLinkFive == ""
      )
        return toast.error("Please submit five valid clip links");
      setIsLoading(true);
      await submitReactRequest({
        streamerId: streamerProfile._id,
        clipLinks: [
          clipLinkOne,
          clipLinkTwo,
          clipLinkThree,
          clipLinkFour,
          clipLinkFive,
        ],
      });
      await fetchReactRequests();
      setIsLoading(false);
    }
  };

  if (!reactRequests) {
    return (
      <View style={styles.container}>
        <View style={{ flexShrink: 1 }}>
          <Text style={styles.header}>Buffd React Clip</Text>
        </View>
        <View style={styles.divider} />
        <ActivityIndicator style={{ alignSelf: "center" }} />
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        <View style={{ flexShrink: 1 }}>
          <Text style={styles.header}>Buffd React Clip</Text>
          {reactRequests.isEligible ? (
            <Text style={styles.subHeader}>
              Submit the clips you want to turn into your first collectible
              launch.{" "}
              <TouchableOpacity>
                <Text style={{ color: colors.green }}>
                  While in beta, Buffd handles prices and economies to maintain
                  a stable market and streamers are limited to one drop per
                  month.
                </Text>
              </TouchableOpacity>
              {"\n \n"}(1) We are only able to use clips{" "}
              <Text
                style={{
                  fontFamily: fonts.bold,
                  textDecorationLine: "underline",
                }}
              >
                with facecam
              </Text>
              {"\n"}(2) Please try and use high quality videos for best results
              {"\n"}(3) This is usually a link to a Twitch clip, but we can
              accept links to videos of all kinds{"\n \n"}Note: The actual Buffd
              React will be your reaction cropped from the video. The more
              exciting, funny, or crazy the clip, the better! If you have the
              file saved locally, please first upload it to YouTube as an
              unlisted video or use a service like Streamable.
            </Text>
          ) : null}
        </View>
        <View style={styles.divider} />

        {reactRequests.isEligible ? (
          <>
            <Input
              label="Clip Link"
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholder={"https://twitch.tv/your_name/clip/exampleclip"}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={clipLinkOne}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newLink) => setClipLinkOne(newLink)}
            />
            <Input
              label="Clip Link"
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholder={"https://twitch.tv/your_name/clip/exampleclip"}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={clipLinkTwo}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newLink) => setClipLinkTwo(newLink)}
            />
            <Input
              label="Clip Link"
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholder={"https://twitch.tv/your_name/clip/exampleclip"}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={clipLinkThree}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newLink) => setClipLinkThree(newLink)}
            />
            <Input
              label="Clip Link"
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholder={"https://twitch.tv/your_name/clip/exampleclip"}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={clipLinkFour}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newLink) => setClipLinkFour(newLink)}
            />
            <Input
              label="Clip Link"
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholder={"https://twitch.tv/your_name/clip/exampleclip"}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={clipLinkFive}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newLink) => setClipLinkFive(newLink)}
            />

            <TouchableOpacity
              style={styles.submitButton}
              onPress={() => onSave()}
            >
              {isLoading ? (
                <ActivityIndicator />
              ) : (
                <Text style={styles.submitButtonText}>
                  Submit React Link (Minimum 5)
                </Text>
              )}
            </TouchableOpacity>
          </>
        ) : (
          <Text
            style={{ fontFamily: fonts.text, fontSize: 18, color: "white" }}
          >
            Clips Submitted Successfully. Currently, streamers can launch once
            every month, so you can submit your next set of clips on{" "}
            <Text style={{ color: colors.green }}>
              {reactRequests.nextAllowed}
            </Text>
            .{"\n\n"}Contact support@buffd.gg for help
          </Text>
        )}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    backgroundColor: colors.backOverlay,
    padding: 25,
    flexShrink: 1,
  },
  header: {
    fontFamily: fonts.text,
    fontSize: 25,
    color: "white",
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: fonts.text,
    fontSize: 18,
    color: "#A9A9A9",
    marginBottom: 15,
    lineHeight: 25,
  },
  divider: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: "#181818",
    marginBottom: 30,
  },
  labelStyle: {
    fontFamily: fonts.text,
    fontSize: 14,
    marginBottom: 10,
    color: "grey",
  },
  inputContainerStyle: {
    paddingHorizontal: 20,
    marginVertical: 5,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 25,
    paddingVertical: 5,
  },
  inputTextStyle: {
    fontFamily: fonts.text,
    color: "white",
    fontSize: 14,
    outlineWidth: 0,
  },
  submitButton: {
    marginTop: 40,
    backgroundColor: colors.green,
    width: "50%",
    paddingVertical: 12,
    borderRadius: 5,
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "center",
  },
  submitButtonText: {
    fontFamily: fonts.semiBold,
    fontSize: 16,
  },
});

export default ReactRequest;
