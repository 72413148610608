import * as React from "react";
import Svg, { Path, Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={712.046}
      height={380.322}
      viewBox="0 0 712.046 380.322"
      {...props}
    >
      <Path
        d="M712.046 225.224c0-31.583-25.62-106.527-57.224-106.527s-57.224 74.944-57.224 106.527a57.224 57.224 0 00114.448 0z"
        fill="#f2f2f2"
      />
      <Path
        d="M654.822 351.941a.654.654 0 01-.654-.654V217.702a.654.654 0 111.309 0v133.585a.654.654 0 01-.655.654z"
        fill="#ccc"
      />
      <Path
        d="M654.823 242.66a.653.653 0 01-.495-.225l-32.911-37.954a.654.654 0 01.989-.858l32.91 37.954a.655.655 0 01-.493 1.083zM654.822 264.564a.654.654 0 01-.462-1.117l18.7-18.701a.654.654 0 01.926.925l-18.701 18.701a.652.652 0 01-.463.192z"
        fill="#ccc"
      />
      <Path
        d="M81.046 280.584c0-17.785-14.427-59.988-32.224-59.988S16.598 262.8 16.598 280.584a32.224 32.224 0 0064.448 0z"
        fill="#f2f2f2"
      />
      <Path
        d="M48.822 351.941a.369.369 0 01-.368-.368v-75.225a.368.368 0 11.737 0v75.225a.369.369 0 01-.369.368z"
        fill="#ccc"
      />
      <Path
        d="M48.822 290.403a.368.368 0 01-.278-.127L30.01 268.903a.369.369 0 01.557-.483L49.1 289.793a.369.369 0 01-.279.61zM48.822 302.737a.368.368 0 01-.26-.629l10.53-10.53a.368.368 0 01.521.52l-10.53 10.531a.367.367 0 01-.26.108z"
        fill="#ccc"
      />
      <Path d="M13.92 351.453a13.453 13.453 0 1126.906 0" fill="#f2f2f2" />
      <Path
        d="M27.373 351.453a.364.364 0 01-.363-.364V344a.364.364 0 01.727 0v7.09a.364.364 0 01-.364.364z"
        fill="#ccc"
      />
      <Path
        d="M27.373 349.016a.362.362 0 01-.237-.087l-4.363-3.745a.364.364 0 11.474-.552l4.363 3.745a.364.364 0 01-.237.64z"
        fill="#ccc"
      />
      <Path d="M55.37 351.453a13.453 13.453 0 0126.905 0" fill="#f2f2f2" />
      <Path
        d="M68.822 351.453a.364.364 0 01-.363-.364V344a.364.364 0 11.727 0v7.09a.364.364 0 01-.364.364z"
        fill="#ccc"
      />
      <Path
        d="M68.822 349.016a.362.362 0 01-.237-.087l-4.363-3.745a.364.364 0 01.474-.552l4.363 3.745a.364.364 0 01-.237.64zM304.838 298c-82.16 0-149-66.841-149-149s66.84-149 149-149 149 66.841 149 149-66.841 149-149 149zm0-296c-81.056 0-147 65.944-147 147s65.944 147 147 147 147-65.944 147-147-65.944-147-147-147z"
        fill="#ccc"
      />
      <Path
        d="M304.838 138a1 1 0 01-1-1V1a1 1 0 012 0v136a1 1 0 01-1 1zM452.838 150h-136a1 1 0 010-2h136a1 1 0 010 2zM292.838 150H156.902a1 1 0 010-2h135.936a1 1 0 010 2zM304.838 298a1 1 0 01-1-1V161a1 1 0 012 0v136a1 1 0 01-1 1zM313.655 141.874a1 1 0 01-.707-1.707l95.743-95.744a1 1 0 111.414 1.414l-95.743 95.744a.996.996 0 01-.707.293zM296.021 141.874a.996.996 0 01-.707-.293L199.57 45.837a1 1 0 111.414-1.414l95.744 95.744a1 1 0 01-.707 1.707zM409.398 253.87a.996.996 0 01-.707-.293l-95.743-95.744a1 1 0 011.414-1.414l95.743 95.744a1 1 0 01-.707 1.707zM200.277 253.87a1 1 0 01-.707-1.707l95.744-95.744a1 1 0 011.414 1.414l-95.744 95.744a.996.996 0 01-.707.293z"
        fill="#ccc"
      />
      <Path
        d="M304.838 251.5a102.5 102.5 0 11102.5-102.5 102.616 102.616 0 01-102.5 102.5zm0-203a100.5 100.5 0 10100.5 100.5 100.614 100.614 0 00-100.5-100.5z"
        fill="#ccc"
      />
      <Circle cx={304.838} cy={149} r={12} fill="#3f3d56" />
      <Path
        fill="#3f3d56"
        d="M258.381 351.695l-10.543.305 51.457-207.695 9.565 2.918-50.479 204.472z"
      />
      <Path
        fill="#3f3d56"
        d="M351.295 351.695l10.543.305-51.457-207.695-9.565 2.918 50.479 204.472z"
      />
      <Path fill="#ccc" d="M287.838 31h33v18h-33z" />
      <Path fill="#fff" d="M289.838 35h13v14h-13zM305.838 35h13v14h-13z" />
      <Path
        d="M304.338 68.5a19.022 19.022 0 01-19-19V49a4.505 4.505 0 014.5-4.5h29a4.505 4.505 0 014.5 4.5v.5a19.022 19.022 0 01-19 19z"
        fill="#ccc"
      />
      <Path
        d="M319.602 35.5h-30.528a2.5 2.5 0 01-2.236-3.618l6-12a2.486 2.486 0 012.236-1.382h18.528a2.486 2.486 0 012.236 1.382l6 12a2.5 2.5 0 01-2.236 3.618z"
        fill="#7dfcb5"
      />
      <Path fill="#ccc" d="M376.838 71h33v18h-33z" />
      <Path fill="#fff" d="M378.838 75h13v14h-13zM394.838 75h13v14h-13z" />
      <Path
        d="M393.338 108.5a19.022 19.022 0 01-19-19V89a4.505 4.505 0 014.5-4.5h29a4.505 4.505 0 014.5 4.5v.5a19.022 19.022 0 01-19 19z"
        fill="#ccc"
      />
      <Path
        d="M384.074 59.5a1.492 1.492 0 00-1.342.83l-6 12a1.5 1.5 0 001.342 2.17h30.528a1.5 1.5 0 001.341-2.17l-6-12a1.492 1.492 0 00-1.341-.83z"
        fill="#7dfcb5"
      />
      <Path fill="#ccc" d="M411.838 161h33v18h-33z" />
      <Path fill="#fff" d="M413.838 165h13v14h-13zM429.838 165h13v14h-13z" />
      <Path
        d="M428.338 198.5a19.022 19.022 0 01-19-19v-.5a4.505 4.505 0 014.5-4.5h29a4.505 4.505 0 014.5 4.5v.5a19.022 19.022 0 01-19 19z"
        fill="#ccc"
      />
      <Path
        d="M443.602 165.5h-30.528a2.5 2.5 0 01-2.236-3.618l6-12a2.486 2.486 0 012.236-1.382h18.528a2.486 2.486 0 012.236 1.382l6 12a2.5 2.5 0 01-2.236 3.618z"
        fill="#7dfcb5"
      />
      <Path fill="#ccc" d="M374.838 245h33v18h-33z" />
      <Path fill="#fff" d="M376.838 249h13v14h-13zM392.838 249h13v14h-13z" />
      <Path
        d="M391.338 282.5a19.022 19.022 0 01-19-19v-.5a4.505 4.505 0 014.5-4.5h29a4.505 4.505 0 014.5 4.5v.5a19.022 19.022 0 01-19 19z"
        fill="#ccc"
      />
      <Path
        d="M406.602 249.5h-30.528a2.5 2.5 0 01-2.236-3.618l6-12a2.486 2.486 0 012.236-1.382h18.528a2.486 2.486 0 012.236 1.382l6 12a2.5 2.5 0 01-2.236 3.618z"
        fill="#7dfcb5"
      />
      <Path fill="#ccc" d="M232.837 89h-33V71h33z" />
      <Path fill="#fff" d="M230.837 89h-13V75h13zM214.837 89h-13V75h13z" />
      <Path
        d="M216.338 108.5a19.022 19.022 0 01-19-19V89a4.505 4.505 0 014.5-4.5h29a4.505 4.505 0 014.5 4.5v.5a19.022 19.022 0 01-19 19z"
        fill="#ccc"
      />
      <Path
        d="M231.602 75.5h-30.528a2.5 2.5 0 01-2.236-3.618l6-12a2.486 2.486 0 012.236-1.382h18.528a2.486 2.486 0 012.236 1.382l6 12a2.5 2.5 0 01-2.236 3.618z"
        fill="#7dfcb5"
      />
      <Path fill="#ccc" d="M197.837 179h-33v-18h33z" />
      <Path fill="#fff" d="M195.837 179h-13v-14h13zM179.837 179h-13v-14h13z" />
      <Path
        d="M181.338 198.5a19.022 19.022 0 01-19-19v-.5a4.505 4.505 0 014.5-4.5h29a4.505 4.505 0 014.5 4.5v.5a19.022 19.022 0 01-19 19z"
        fill="#ccc"
      />
      <Path
        d="M196.602 165.5h-30.528a2.5 2.5 0 01-2.236-3.618l6-12a2.486 2.486 0 012.236-1.382h18.528a2.486 2.486 0 012.236 1.382l6 12a2.5 2.5 0 01-2.236 3.618z"
        fill="#7dfcb5"
      />
      <Path fill="#ccc" d="M234.837 263h-33v-18h33z" />
      <Path fill="#fff" d="M232.837 263h-13v-14h13zM216.837 263h-13v-14h13z" />
      <Path
        d="M218.338 282.5a19.022 19.022 0 01-19-19v-.5a4.505 4.505 0 014.5-4.5h29a4.505 4.505 0 014.5 4.5v.5a19.022 19.022 0 01-19 19z"
        fill="#ccc"
      />
      <Path
        d="M233.602 249.5h-30.528a2.5 2.5 0 01-2.236-3.618l6-12a2.486 2.486 0 012.236-1.382h18.528a2.486 2.486 0 012.236 1.382l6 12a2.5 2.5 0 01-2.236 3.618z"
        fill="#7dfcb5"
      />
      <Path fill="#ccc" d="M287.838 283h33v18h-33z" />
      <Path fill="#fff" d="M289.838 287h13v14h-13zM305.838 287h13v14h-13z" />
      <Path
        d="M289.838 297.5a3.504 3.504 0 00-3.5 3.5v.5a18 18 0 0036 0v-.5a3.504 3.504 0 00-3.5-3.5z"
        fill="#ccc"
      />
      <Path
        d="M295.074 271.5a1.492 1.492 0 00-1.342.83l-6 12a1.5 1.5 0 001.342 2.17h30.528a1.5 1.5 0 001.341-2.17l-6-12a1.492 1.492 0 00-1.341-.83z"
        fill="#7dfcb5"
      />
      <Path
        d="M529.046 322a1 1 0 000-2h-9.5v-4h9.5a1 1 0 000-2h-81a1 1 0 100 2h9.5v4h-9.5a1 1 0 100 2h9.5v4h-9.5a1 1 0 100 2h9.5v4h-8.5a2 2 0 100 4h8.092a1.49 1.49 0 00-.092.5v14a1.5 1.5 0 003 0v-14a1.49 1.49 0 00-.091-.5h57.183a1.49 1.49 0 00-.092.5v14a1.5 1.5 0 003 0v-14a1.49 1.49 0 00-.091-.5h8.091a2 2 0 000-4h-8.5v-4h9.5a1 1 0 000-2h-9.5v-4zm-69.5-6h58v4h-58zm58 16h-58v-4h58zm0-6h-58v-4h58zM189.046 322a1 1 0 000-2h-9.5v-4h9.5a1 1 0 000-2h-81a1 1 0 000 2h9.5v4h-9.5a1 1 0 000 2h9.5v4h-9.5a1 1 0 000 2h9.5v4h-8.5a2 2 0 000 4h8.092a1.49 1.49 0 00-.092.5v14a1.5 1.5 0 003 0v-14a1.49 1.49 0 00-.091-.5h57.183a1.49 1.49 0 00-.092.5v14a1.5 1.5 0 003 0v-14a1.49 1.49 0 00-.091-.5h8.091a2 2 0 000-4h-8.5v-4h9.5a1 1 0 000-2h-9.5v-4zm-69.5-6h58v4h-58zm58 16h-58v-4h58zm0-6h-58v-4h58z"
        fill="#3f3d56"
      />
      <Path
        d="M504.046 316.5h-3a1.502 1.502 0 01-1.5-1.5v-3a1.502 1.502 0 011.5-1.5h3a1.502 1.502 0 011.5 1.5v3a1.502 1.502 0 01-1.5 1.5z"
        fill="#ccc"
      />
      <Path
        d="M502.603 313.194a.966.966 0 01-.17-.014 1 1 0 01-.817-1.155l9.89-57.546a1 1 0 011.97.338l-9.889 57.547a1 1 0 01-.984.83z"
        fill="#ccc"
      />
      <Path
        d="M502.6 313.194a1 1 0 01-.984-.83l-9.89-57.547a1 1 0 011.972-.338l9.89 57.546a1 1 0 01-.817 1.155.968.968 0 01-.17.014z"
        fill="#ccc"
      />
      <Path
        d="M523.892 244.628c.964-6.83-2.683-12.99-8.147-13.762s-10.674 4.14-11.638 10.97c-.813 5.759 1.66 11.03 5.731 13.007l-.232 1.641 4.947.699.231-1.642c4.459-.773 8.295-5.153 9.108-10.913z"
        fill="#ff6584"
      />
      <Path
        d="M500.925 241.52c-1.183-6.794-6.55-11.535-11.985-10.588s-8.883 7.222-7.7 14.017c.999 5.73 4.974 9.985 9.455 10.614l.285 1.633 4.92-.857-.284-1.633c4.005-2.107 6.307-7.455 5.31-13.186zM502.546 312a1 1 0 01-1-1v-59.158a1 1 0 112 0V311a1 1 0 01-1 1z"
        fill="#ccc"
      />
      <Path
        d="M511.917 240.48c0-6.898-4.472-12.489-9.99-12.489s-9.99 5.591-9.99 12.488c0 5.817 3.185 10.69 7.492 12.079v1.658h4.995v-1.658c4.307-1.389 7.493-6.262 7.493-12.079z"
        fill="#7dfcb5"
      />
      <Path
        d="M710.485 353.537H1.19a1.19 1.19 0 110-2.381h709.294a1.19 1.19 0 110 2.381z"
        fill="#ccc"
      />
      <Path
        fill="#a0616a"
        d="M553.475 351.407l-2.275-.001-1.082-8.773h3.357v8.774z"
      />
      <Path
        d="M549.251 350.757h4.711v2.762h-7.148v-.324a2.438 2.438 0 012.437-2.438z"
        fill="#2f2e41"
      />
      <Path
        fill="#a0616a"
        d="M569.978 348.83l-2.069.945-4.629-7.53 3.053-1.395 3.645 7.98z"
      />
      <Path
        d="M565.867 349.994l4.285-1.958 1.147 2.513-6.502 2.97-.135-.295a2.438 2.438 0 011.205-3.23zM553.33 349.093h-2.173a.838.838 0 01-.833-.774l-1.185-31.643a.835.835 0 01.914-.892l12.567 1.335a.835.835 0 01.753.815l.264 13.958a.648.648 0 00.043.222l5.155 13.367a.835.835 0 01-.49 1.084l-2.202.811a.835.835 0 01-1.031-.401l-6.333-12.313a.83.83 0 01-.075-.212l-1.082-5.213a.65.65 0 00-1.28.058l-2.183 19.058a.835.835 0 01-.83.74z"
        fill="#2f2e41"
      />
      <Circle cx={554.412} cy={287.377} r={4.557} fill="#a0616a" />
      <Path
        d="M557.553 319.563a16.478 16.478 0 01-8.33-2.38.83.83 0 01-.392-.768l1.108-15.839a6.867 6.867 0 013.132-5.316 6.61 6.61 0 015.899-.703 4.655 4.655 0 011.2.622 7.921 7.921 0 013.246 7.553c-.813 6.163.107 14.477.215 15.404l.008.067-.061.028a14.293 14.293 0 01-6.025 1.332z"
        fill="#7dfcb5"
      />
      <Path
        d="M563.354 321.826a1.866 1.866 0 00.15-2.857l2.673-6.067-3.397.576-2.018 5.673a1.876 1.876 0 002.592 2.675z"
        fill="#a0616a"
      />
      <Path
        d="M563.61 318.078a.832.832 0 01-.614-.27l-.997-1.088a.84.84 0 01-.185-.8l2.283-7.728-3.474-7.294a1.546 1.546 0 01-.105-1.029 1.542 1.542 0 012.854-.364l4.696 7.619a2.124 2.124 0 01.128 1.99l-3.824 8.473a.833.833 0 01-.629.48.847.847 0 01-.133.011z"
        fill="#7dfcb5"
      />
      <Path
        d="M558.166 291.395c-.472.08-.861-.577-.92-1.04a8.139 8.139 0 01.128-1.365 1.12 1.12 0 00-.676-1.154c-.5-.143-.964.258-1.476.21-.714-.067-1.073-.903-1.16-1.579s-.154-1.476-.772-1.861c-.616-.384-1.379-.105-2.087-.093a2.85 2.85 0 01-2.695-2.019.679.679 0 00.775-.31.687.687 0 00-.207-.812l1.357.664a.729.729 0 01.48-.95 2.563 2.563 0 011.259-.013c2.124.33 4.585.168 5.994 1.787a7.507 7.507 0 011.67 4.638 12.874 12.874 0 01-1.67 3.897z"
        fill="#2f2e41"
      />
      <Path
        d="M534.004 299.163a1.866 1.866 0 012.682.996l6.63-.047-1.877 2.89-6.008-.398a1.876 1.876 0 01-1.427-3.441z"
        fill="#a0616a"
      />
      <Path
        d="M537.454 302.312a.832.832 0 01.14-.657l.861-1.197a.839.839 0 01.746-.345l7.755-.325 6.708-3.903a1.546 1.546 0 01.986-.313 1.542 1.542 0 01.939 2.72l-6.502 6.15a2.124 2.124 0 01-1.923.532l-9.073-2.016a.833.833 0 01-.6-.518.849.849 0 01-.037-.128z"
        fill="#7dfcb5"
      />
      <Path
        d="M423.453 347.67a1.92 1.92 0 00-1.496-2.537l-1.152-6.728-2.589 2.424 1.49 6.018a1.931 1.931 0 003.747.823z"
        fill="#ffb8b8"
      />
      <Path
        d="M421.733 344.451l-2.543.667a.48.48 0 01-.58-.332l-4.569-16.185-1.38-4.129a2.487 2.487 0 012.657-3.257 2.485 2.485 0 012.087 1.763l3.65 12.339 1.03 8.616a.48.48 0 01-.25.479.47.47 0 01-.102.04z"
        fill="#7dfcb5"
      />
      <Circle cx={412.442} cy={313.793} r={4.692} fill="#ffb8b8" />
      <Path
        fill="#ffb8b8"
        d="M398.628 377.976l-2.245-.668 1.508-8.976 3.313.986-2.576 8.658z"
      />
      <Path
        d="M398.553 380.322l-7.237-2.154.027-.091a2.94 2.94 0 013.655-1.98l4.42 1.316z"
        fill="#2f2e41"
      />
      <Path
        fill="#ffb8b8"
        d="M423.22 377.861l-2.342-.001-1.114-9.033 2.31.001 1.146 9.033z"
      />
      <Path
        d="M423.817 380.13h-7.551v-.095a2.94 2.94 0 012.939-2.94h4.612zM399.492 375.32h-2.352a.86.86 0 01-.845-1.015c-1.5-.667 6.574-39.386 8.481-38.635l.063-.09 9.606.783c4.303 8.611 7.026 22.86 9.575 37.735a.86.86 0 01-.792 1.07l-2.642.128a.856.856 0 01-.84-.539l-10.122-25.304a.274.274 0 00-.272-.18.28.28 0 00-.264.192l-8.784 25.278a.86.86 0 01-.812.577z"
        fill="#2f2e41"
      />
      <Path
        d="M414.646 337.12l-.073-.005-10.227-.772 1.794-11.41a5.974 5.974 0 015.104-5.007l.237-.032a5.903 5.903 0 015.365 2.147 5.977 5.977 0 011.023 5.763z"
        fill="#7dfcb5"
      />
      <Path
        d="M421.552 319.16c-3.226 1.475-7.603 1.211-12.607 0 .692-1.897 1.232-3.73.62-5.074a4.096 4.096 0 01-.134-3.279 6.306 6.306 0 015.818-3.873 6.304 6.304 0 016.303 6.304z"
        fill="#2f2e41"
      />
      <Path
        d="M411.204 307.363h3.472v4.933h-8.405a4.933 4.933 0 014.933-4.933z"
        fill="#2f2e41"
      />
      <Path
        d="M404.124 348.731a1.92 1.92 0 00-.132-2.942l2.136-6.483-3.424.928-1.503 6.014a1.931 1.931 0 002.923 2.484z"
        fill="#ffb8b8"
      />
      <Path
        d="M404.113 345.082l-2.558-.602a.48.48 0 01-.358-.566l3.55-16.439.716-4.294a2.487 2.487 0 013.873-1.633 2.485 2.485 0 011.017 2.536l-2.558 12.61-3.127 8.095a.48.48 0 01-.446.305.47.47 0 01-.109-.012z"
        fill="#7dfcb5"
      />
      <Path
        d="M483.179 309.354a1.941 1.941 0 00-.29.099l-7.686-4.959.106-2.22-3.329-.597-.57 3.91a1.476 1.476 0 00.848 1.556l9.467 4.321a1.936 1.936 0 101.454-2.11z"
        fill="#ffb8b8"
      />
      <Path
        d="M474.89 304.358l-4.123-.761a.83.83 0 01-.634-1.085l1.353-3.954a2.305 2.305 0 014.53.85l-.146 4.163a.83.83 0 01-.98.786z"
        fill="#3f3d56"
      />
      <Path
        d="M489.224 293.632a1.941 1.941 0 00-.044.303l-7.923 4.57-1.926-1.108-2.053 2.688 3.218 2.294a1.476 1.476 0 001.77-.043l8.17-6.446a1.936 1.936 0 10-1.212-2.257z"
        fill="#ffb8b8"
      />
      <Path
        d="M480.992 298.722l-2.561 3.319a.83.83 0 01-1.255.069l-2.899-3.01a2.305 2.305 0 012.827-3.642l3.636 2.033a.83.83 0 01.252 1.231z"
        fill="#ccc"
      />
      <Circle cx={470.505} cy={286.268} r={4.53} fill="#ffb8b8" />
      <Path
        fill="#ffb8b8"
        d="M474.782 351.13h2.261l1.075-8.722h-3.337l.001 8.722z"
      />
      <Path
        d="M474.205 350.392h4.453a2.838 2.838 0 012.838 2.837v.092h-7.291z"
        fill="#2f2e41"
      />
      <Path
        fill="#ffb8b8"
        d="M467.035 351.13h2.261l1.076-8.722h-3.337v8.722z"
      />
      <Path
        d="M466.458 350.392h4.453a2.838 2.838 0 012.838 2.837v.092h-7.29zM474.103 347.82l-2.306-17.845a.09.09 0 00-.094-.087.088.088 0 00-.09.09l-.663 17.585a.827.827 0 01-.83.8h-2.739a.825.825 0 01-.83-.81l-.71-29.302 12.257.123.306 29.395a.83.83 0 01-.291.612.819.819 0 01-.638.192l-2.656.007a.83.83 0 01-.716-.76z"
        fill="#2f2e41"
      />
      <Path
        d="M465.659 319.053a.83.83 0 01-.524-.652l-.122-16.103c-.542-3.585-1.183-7.099 1.952-8.915l.277-.829a.463.463 0 01.424-.315l5.947-.17a.457.457 0 01.437.28l.442 1.031.157.09a8.745 8.745 0 014.352 8.633l-.34 2.871-.241 13.416a.837.837 0 01-.436.717 11.284 11.284 0 01-5.452 1.27 20.427 20.427 0 01-6.873-1.324z"
        fill="#ccc"
      />
      <Path
        d="M470.358 290.966a4.833 4.833 0 11.906-9.559 5.556 5.556 0 013.315-.908 3.25 3.25 0 012.743 1.874 2.32 2.32 0 01-1.112 2.947 4.192 4.192 0 01-2.212.031 3.298 3.298 0 00-2.187.194c-.642.38-.931 1.402-.356 1.879a1.98 1.98 0 01.57 2.18 2.167 2.167 0 01-1.667 1.362z"
        fill="#2f2e41"
      />
      <Path
        fill="#ffb8b8"
        d="M207.665 377.775h2.204l1.048-8.5h-3.253l.001 8.5z"
      />
      <Path
        d="M206.693 380.322v-3.676h4.75a3.18 3.18 0 013.176 3.175v.5z"
        fill="#2f2e41"
      />
      <Path
        fill="#ffb8b8"
        d="M195.787 377.775h2.203l1.048-8.5h-3.252l.001 8.5z"
      />
      <Path
        d="M194.815 380.322v-3.676h4.75a3.18 3.18 0 013.175 3.175v.5z"
        fill="#2f2e41"
      />
      <Circle cx={202.241} cy={312.615} r={4.734} fill="#ffb8b8" />
      <Path
        d="M218.43 343.491a1.808 1.808 0 00-2.332-1.5l-2.646-3.171-2.32 1.138 3.8 4.426a1.817 1.817 0 003.497-.893zM195.658 346.699a1.808 1.808 0 00-1.163-2.516l-.531-4.096-2.568-.285.831 5.775a1.817 1.817 0 003.43 1.122z"
        fill="#ffb8b8"
      />
      <Path
        d="M198.284 321.557a2.33 2.33 0 00-4 .565l-3.88 9.684a6.016 6.016 0 00-.367 3.122l1.264 8.512a.771.771 0 00.99.624l1.664-.512a.771.771 0 00.54-.826 28.01 28.01 0 012.248-14.695l1.83-4.092a2.33 2.33 0 00-.29-2.382z"
        fill="#ccc"
      />
      <Path
        d="M201.088 317.35a5.051 5.051 0 11.946-9.99 5.806 5.806 0 013.465-.95 3.396 3.396 0 012.867 1.959 2.425 2.425 0 01-1.162 3.08 4.381 4.381 0 01-2.312.032 3.447 3.447 0 00-2.285.202c-.672.399-.974 1.466-.372 1.964a2.07 2.07 0 01.594 2.279 2.264 2.264 0 01-1.741 1.423zM195.214 343.304l-.84 31.871a.771.771 0 00.81.79l2.767-.14a.771.771 0 00.722-.65l3.29-20.7a.386.386 0 01.757-.024l4.455 19.931a.771.771 0 001.01.558l2.746-.167a.771.771 0 00.51-.782l-2.396-30.989z"
        fill="#2f2e41"
      />
      <Path
        d="M193.866 328.166a8.688 8.688 0 017.928-9.542c2.085-.148 5.322-.2 6.39 2.533 3.214 8.233-2.414 8.033-.475 15.167s3.652 7.542.991 8.057-14.624 3.556-14.133-1.465c.302-3.086-.239-9.932-.701-14.75z"
        fill="#ccc"
      />
      <Path
        d="M207.614 320.319a2.33 2.33 0 00-3.14 2.54l1.51 9.444a9.229 9.229 0 001.98 4.396l4.907 5.98a.771.771 0 001.169.027l1.165-1.294a.771.771 0 00.038-.987l-.343-.445a25.966 25.966 0 01-5.213-12.757l-.601-5.01a2.33 2.33 0 00-1.472-1.894z"
        fill="#ccc"
      />
      <Path
        d="M218.655 345.686a1.857 1.857 0 00.664-2.769l1.388-13.574h-3l-1.104 13.254a1.867 1.867 0 002.052 3.089z"
        fill="#a0616a"
      />
      <Circle cx={221.41} cy={317.607} r={4.245} fill="#a0616a" />
      <Path
        d="M222.209 337.248a.776.776 0 01-.748-.57 42.927 42.927 0 00-2.63-6.21 5.144 5.144 0 012.92-7.101 5.141 5.141 0 016.608 3.275 25.443 25.443 0 01.963 8.485.78.78 0 01-.611.72l-6.335 1.383a.774.774 0 01-.167.018z"
        fill="#7dfcb5"
      />
      <Path
        fill="#a0616a"
        d="M228.344 377.557h2.119l1.008-8.173h-3.128l.001 8.173z"
      />
      <Path
        d="M235.05 380.024h-7.66v-3.572h4.587a3.072 3.072 0 013.072 3.072z"
        fill="#2f2e41"
      />
      <Path
        fill="#a0616a"
        d="M207.156 374.811l1.857 1.021 4.821-6.676-2.74-1.507-3.938 7.162z"
      />
      <Path
        d="M211.844 380.204l-6.712-3.69 1.721-3.132 4.019 2.21a3.073 3.073 0 011.212 4.173zM228.245 373.02a.778.778 0 01-.77-.677l-2.65-20.42a.432.432 0 00-.807-.153l-11.044 20.037a.782.782 0 01-.958.351l-2.407-.917a.778.778 0 01-.444-1.02l7.65-18.793a.6.6 0 00.04-.149c1.036-8.02 3.672-12.098 4.456-13.157a.602.602 0 00.085-.548l-.107-.323a.777.777 0 01.15-.757c2.822-3.215 7.741-1.46 7.79-1.442l.025.009.016.02c5.516 7.566 3.476 32.138 3.012 36.986a.776.776 0 01-.715.7l-3.262.251a.834.834 0 01-.06.003z"
        fill="#2f2e41"
      />
      <Path
        d="M237.912 322.538a1.818 1.818 0 00-.041.283l-7.425 4.283-1.804-1.039-1.924 2.519 3.016 2.15a1.383 1.383 0 001.659-.04l7.656-6.04a1.814 1.814 0 10-1.137-2.116z"
        fill="#a0616a"
      />
      <Path
        d="M230.198 327.307l-2.4 3.11a.778.778 0 01-1.175.065l-2.717-2.821a2.16 2.16 0 012.65-3.413l3.406 1.905a.778.778 0 01.236 1.154zM220.898 332.71l-3.865-.698a.778.778 0 01-.599-1.014l1.253-3.71a2.16 2.16 0 014.249.78l-.122 3.901a.778.778 0 01-.916.741z"
        fill="#7dfcb5"
      />
      <Path
        d="M222.082 316.63c1.09.747 2.523 1.515 3.637.72a2.028 2.028 0 00.666-2.338c-.534-1.526-2-2.197-3.401-2.712a9.768 9.768 0 00-5.688-.723c-1.882.48-3.544 2.301-3.205 4.213.273 1.537 1.723 2.816 1.517 4.363-.207 1.558-1.958 2.378-3.505 2.656s-3.277.368-4.388 1.479c-1.418 1.417-1.064 3.95.27 5.446a9.938 9.938 0 005.298 2.635c2.585.61 5.38.932 7.835-.082s4.365-3.743 3.654-6.301a8.791 8.791 0 00-1.692-2.884 8.036 8.036 0 01-1.637-2.91 3.025 3.025 0 01.546-2.647.767.767 0 00.103-.896z"
        fill="#2f2e41"
      />
    </Svg>
  );
}

export default SvgComponent;
