import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import moment from "moment";
import RenderClip from "./RenderClip";
import { Context as ReactContext } from "../../../context/ReactContext";
import { useMediaQuery } from "react-responsive";
import { InView, useInView } from "react-intersection-observer";
import loading_messages from "../../../../assets/loading_messages";
import {
  desktop as ClipDesktopStyle,
  tablet as ClipTabletStyle,
  mobile as ClipMobileStyle,
} from "../Stylesheets/RenderClipStyle";

const SoldOutFeed = () => {
  const {
    fetchReactFeed,
    state: { feedList, currentPage, totalPages, isFeedLoaded },
  } = useContext(ReactContext);
  const [isLoading, setIsLoading] = useState(false);
  const [playLock, setPlayLock] = useState(-1);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const soldOut = feedList.filter((item) => item.claimed.length >= item.total);

  const handleViewChange = async (inView, entry, index) => {
    //if (!inView || !index % 2 == 0 || currentPage > totalPages) return;
    if (inView && !soldOut[index + 1]) {
      setIsLoading(true);
      await fetchReactFeed({ page: currentPage + 1 });
      setIsLoading(false);
    }
  };

  return (
    <View>
      {soldOut.map((item, index) => {
        return (
          <InView
            threshold={0.4}
            root={document.body}
            onChange={(inView, entry) => handleViewChange(inView, entry, index)}
            triggerOnce={true}
            key={item._id}
          >
            <RenderClip
              item={item}
              index={index}
              playLock={playLock}
              setPlayLock={setPlayLock}
              styles={
                isMobile
                  ? ClipMobileStyle(window)
                  : isTabletOrMobile
                  ? ClipTabletStyle(window)
                  : ClipDesktopStyle(window)
              }
            />
          </InView>
        );
      })}

      {isLoading ? (
        <>
          <ActivityIndicator color="white" style={{ alignSelf: "center" }} />
          <Text
            style={{
              marginTop: 20,
              color: "grey",
              fontFamily: fonts.text,
              fontSize: 16,
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            {
              loading_messages[
                Math.floor(Math.random() * loading_messages.length)
              ]
            }
          </Text>
        </>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "black",
  },
  labelHeader: {
    fontFamily: fonts.bold,
    fontSize: 30,
    color: "white",
    marginBottom: 40,
  },
});

export default React.memo(SoldOutFeed);
