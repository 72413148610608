import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { Context as AuthContext } from "../../../context/AuthContext";
import { Context as UserContext } from "../../../context/UserContext";
import { Context as ReactContext } from "../../../context/ReactContext";
import { Context as StreamerContext } from "../../../context/StreamerContext";
import CurrencyInput from "react-native-currency-input";
import Popup from "reactjs-popup";
import "./PullReactStyle.css";
import { MaterialIcons } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import { useIsFocused } from "@react-navigation/native";
import { Mixpanel } from "../../../api/mixPanel";
import moment from "moment";
import { range } from "lodash";
import * as RootNavigation from "../../../RootNavigation";

const PullReactModal = ({ data, isModalVisible, setIsModalVisible }) => {
  const {
    state: { token },
  } = useContext(AuthContext);
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const { pullStreamerReact } = useContext(ReactContext);
  const { navigateStreamer } = useContext(StreamerContext);
  const [price, setPrice] = useState(data.reserve);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () => setIsModalVisible(false);

  const isFocused = useIsFocused();
  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;

  const purchaseClip = async () => {
    if (!isLoading) {
      setIsLoading(true);
      if (price && price > 0.0 && price >= data.reserve) {
        Mixpanel.track("Initiate React Pull Purchase", {
          streamerId: data._id,
        });
        await pullStreamerReact({ streamerId: data._id });
      } else {
        setError(
          `Price must be greater than the reserve ($${data.reserve.toFixed(
            2
          )}).`
        );
      }
      if (isFocused) {
        setIsLoading(false);
      }
    }
  };

  const onAuth = async (type) => {
    await navigateStreamer(data.alias);
    if (type == 0) {
      setIsModalVisible(false);
      Mixpanel.track("Page View", { pageName: "SignIn" });
      RootNavigation.navigate("SignIn");
    } else {
      setIsModalVisible(false);
      Mixpanel.track("Page View", { pageName: "SignUp" });
      RootNavigation.navigate("SignUp");
    }
  };

  const innerContent = () => {
    return (
      <>
        <Text style={styles.inputLabel}>STREAMER NAME</Text>
        <Text style={styles.clipName}>{data.alias}</Text>
        <Text style={{ marginBottom: 25, fontFamily: fonts.text }}>
          You will receive one of the available {data.alias} Reacts left.
        </Text>
        <Text style={styles.inputLabel}>PURCHASE AMOUNT $</Text>
        <CurrencyInput
          value={price}
          onChangeValue={setPrice}
          unit="$"
          delimiter=","
          separator="."
          precision={2}
          ignoreNegative={true}
          onChangeText={(formattedValue) => {
            console.log(formattedValue); // $2,310.46
          }}
          style={styles.inputStyle}
          keyboardType={"numeric"}
          placeholder={"$0.00"}
        />
        <View style={styles.commonAmountRow}>
          <TouchableOpacity
            style={styles.amountButton}
            onPress={() => setPrice(price + 5.0)}
          >
            <Text style={styles.amountText}>+$5.00</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.amountButton}
            onPress={() => setPrice(price + 25.0)}
          >
            <Text style={styles.amountText}>+$25.00</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.amountButton}
            onPress={() => setPrice(price + 50.0)}
          >
            <Text style={styles.amountText}>+$50.00</Text>
          </TouchableOpacity>
        </View>
        <Text
          style={[
            styles.noteText,
            {
              color: "black",
              fontSize: 14,
              fontFamily: fonts.medium,
              lineHeight: 24,
            },
          ]}
        >
          {`This clip costs $${data.reserve.toFixed(
            2
          )}, but all net proceeds and any additional amount will go directly to ${
            data.alias
          }`}
        </Text>

        {error && error != "" ? (
          <Text style={styles.errorText}>{error}</Text>
        ) : null}
        <TouchableOpacity
          style={styles.purchaseButton}
          onPress={() => purchaseClip()}
        >
          {isLoading ? (
            <ActivityIndicator />
          ) : (
            <Text style={styles.purchaseText}>
              Purchase ({price ? `$${price.toFixed(2)}` : null})
            </Text>
          )}
        </TouchableOpacity>
      </>
    );
  };

  return (
    <Popup
      open={isModalVisible}
      closeOnDocumentClick
      onClose={closeModal}
      position="center center"
      contentStyle={{
        borderTopColor: colors.green,
        borderTopWidth: 5,
        borderRadius: 10,
        width: modalWidth,
      }}
    >
      <View style={{ padding: isMobile ? 10 : 30 }}>
        <View style={styles.topRow}>
          <Text style={styles.header}>
            {!token ? "Log In" : "Purchase React"}
          </Text>
          <TouchableOpacity
            onPress={() => {
              Mixpanel.track("Claim Clip Close", {
                streamerAlias: data.alias,
              });
              setIsModalVisible(!isModalVisible);
            }}
          >
            <MaterialIcons name="cancel" size={24} color="grey" />
          </TouchableOpacity>
        </View>

        {!token ? (
          <>
            <Text style={styles.explainerText}>
              You must be signed in to make a purchase. Choose an option below.
            </Text>
            <TouchableOpacity
              style={[styles.authButton, { backgroundColor: colors.green }]}
              onPress={() => onAuth(0)}
            >
              <Text style={[styles.authText, { color: "black" }]}>LOG IN</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.authButton}
              onPress={() => onAuth(1)}
            >
              <Text style={styles.authText}>SIGN UP</Text>
            </TouchableOpacity>
          </>
        ) : (
          <>{innerContent()}</>
        )}
      </View>
    </Popup>
  );
};

const styles = StyleSheet.create({
  container: {},
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
  },
  inputLabel: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
    marginBottom: 10,
  },
  clipName: {
    fontFamily: fonts.semiBold,
    fontSize: 22,
    marginBottom: 10,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    fontFamily: fonts.expandedRegular,
    color: "black",
    fontSize: 25,
    outlineWidth: 0,
  },
  commonAmountRow: {
    flexDirection: "row",
    marginVertical: 20,
    justifyContent: "space-between",
  },
  amountButton: {
    width: "30%",
    backgroundColor: colors.green,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  amountText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
  },
  purchaseButton: {
    backgroundColor: "black",
    marginTop: 25,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  purchaseText: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 14,
  },
  explainerText: {
    fontFamily: fonts.text,
    color: colors.greyText,
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
  },
  authButton: {
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    paddingVertical: 14,
    marginVertical: 15,
  },
  authText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
    color: "white",
  },
  noteText: {
    fontFamily: fonts.text,
    color: "grey",
    marginVertical: 10,
    fontSize: 14,
  },
  errorText: {
    fontFamily: fonts.text,
    color: "red",
    marginBottom: 5,
    fontSize: 14,
  },
  buyButton: {
    backgroundColor: "black",
    paddingHorizontal: 30,
    paddingVertical: 8,
    borderRadius: 4,
  },
  buyText: {
    fontFamily: fonts.bold,
    color: "white",
  },
});

export default PullReactModal;
