import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  useWindowDimensions,
  Modal,
} from "react-native";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { useIsFocused } from "@react-navigation/native";
import { MaterialIcons } from "@expo/vector-icons";
import CurrencyInput from "react-native-currency-input";
import { Context as UserContext } from "../../../context/UserContext";
import * as RootNavigation from "../../../RootNavigation";
import { Mixpanel } from "../../../api/mixPanel";

const ListCardModal = ({ data, isModalVisible, setIsModalVisible }) => {
  const {
    submitCardListing,
    fetchCardDetails,
    state: { cardLoaded, cardPortfolio },
  } = useContext(UserContext);
  const [reservePrice, setReservePrice] = useState(0);
  const [buyNowPrice, setBuyNowPrice] = useState(0);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isFocused = useIsFocused();
  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;

  useEffect(() => {
    const loadDetails = async () => {
      if (isModalVisible == true) {
        console.log("Load card details");
        setIsCardLoading(true);
        await fetchCardDetails(data._id);
        setIsCardLoading(false);
      }
    };
    loadDetails();
  }, [isModalVisible]);

  const listCard = async () => {
    setIsLoading(true);
    if (!reservePrice || reservePrice <= 0.0) {
      toast.warn("The reserve price must be greater than $0.00");
      setIsLoading(false);
    } else if (!buyNowPrice || buyNowPrice <= 0.0) {
      toast.warn("The buy now price must be greater than $0.00");
      setIsLoading(false);
    } else if (buyNowPrice <= reservePrice) {
      toast.warn("The buy now price must be greater than the reserve price");
      setIsLoading(false);
    } else {
      Mixpanel.track("Market List Card", {
        streamerAlias: data.streamerId.alias,
      });
      await submitCardListing(data._id, reservePrice, buyNowPrice);
      setIsLoading(false);
      setIsModalVisible(false);
    }
  };

  if (
    false &&
    (cardPortfolio.stripeConnectId === undefined ||
      !cardPortfolio.stripeVerified)
  ) {
    return (
      <Modal animationType="fade" transparent={true} visible={isModalVisible}>
        <TouchableWithoutFeedback
          onPress={() => {
            Mixpanel.track("List Modal Close", { card: data._id });
            setIsModalVisible(!isModalVisible);
          }}
        >
          <View
            style={{
              position: "absolute",
              height: window.height,
              width: window.width,
              zIndex: 100000000,
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
          />
        </TouchableWithoutFeedback>

        <View
          style={{
            position: "absolute",
            zIndex: 100000002,
            paddingTop: "5%",
            alignSelf: "center",
          }}
        >
          <View style={[styles.container, { width: modalWidth }]}>
            <View style={styles.topRow}>
              <Text style={styles.header}>Seller Information</Text>
              <TouchableOpacity
                onPress={() => {
                  Mixpanel.track("List Modal Close", {
                    card: data._id,
                  });
                  setIsModalVisible(!isModalVisible);
                }}
              >
                <MaterialIcons name="cancel" size={24} color="grey" />
              </TouchableOpacity>
            </View>

            <View style={styles.spacer} />

            <Text style={styles.explainerText}>
              Before you can list an item for auction, you need to complete your
              seller and payout information.
            </Text>

            <View style={styles.spacer} />

            <TouchableOpacity
              style={styles.purchaseButton}
              onPress={() => {
                setIsModalVisible(!isModalVisible);
                RootNavigation.navigate("Transaction");
              }}
            >
              <Text style={styles.purchaseText}>Complete Information</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    );
  } else {
    return (
      <Modal animationType="fade" transparent={true} visible={isModalVisible}>
        <TouchableWithoutFeedback
          onPress={() => {
            Mixpanel.track("List Modal Close", { card: data._id });
            setIsModalVisible(!isModalVisible);
          }}
        >
          <View
            style={{
              position: "absolute",
              height: window.height,
              width: window.width,
              zIndex: 100000000,
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
          />
        </TouchableWithoutFeedback>

        <View
          style={{
            position: "absolute",
            zIndex: 100000002,
            paddingTop: "5%",
            alignSelf: "center",
          }}
        >
          <View style={[styles.container, { width: modalWidth }]}>
            <View style={styles.topRow}>
              <Text style={styles.header}>List Card</Text>
              <TouchableOpacity
                onPress={() => {
                  Mixpanel.track("List Modal Close", {
                    card: data._id,
                  });
                  setIsModalVisible(!isModalVisible);
                }}
              >
                <MaterialIcons name="cancel" size={24} color="grey" />
              </TouchableOpacity>
            </View>

            <View style={styles.spacer} />

            {isCardLoading || cardLoaded == null || !cardLoaded ? (
              <ActivityIndicator />
            ) : (
              <>
                <Text style={styles.inputLabel}>CARD DETAILS</Text>
                <Text style={styles.explainerText}>
                  {cardLoaded.streamerId.alias}{" "}
                  {cardLoaded.type == 0 ||
                  cardLoaded.type == 1 ||
                  cardLoaded.type == 4
                    ? "First Edition"
                    : "Founder's Edition"}{" "}
                  - Serial No. {cardLoaded.mintNumber}
                </Text>

                <View style={styles.spacer} />

                <Text style={styles.inputLabel}>AUCTION RESERVE $</Text>
                <Text style={styles.explainerText}>
                  This is the price you set as the start of the auction. (You
                  will not sell below this price.)
                </Text>
                <CurrencyInput
                  value={reservePrice}
                  onChangeValue={setReservePrice}
                  unit="$"
                  delimiter=","
                  separator="."
                  precision={2}
                  ignoreNegative={true}
                  onChangeText={(formattedValue) => {
                    console.log(formattedValue); // $2,310.46
                  }}
                  style={styles.inputStyle}
                  keyboardType={"numeric"}
                  placeholder={"$0.00"}
                />

                <View style={styles.spacer} />
                <View style={styles.spacer} />

                <Text style={styles.inputLabel}>BUY IT NOW PRICE $</Text>
                <Text style={styles.explainerText}>
                  This is the price you allow anybody to skip the auction and
                  buy it instantly.
                </Text>
                <CurrencyInput
                  value={buyNowPrice}
                  onChangeValue={setBuyNowPrice}
                  unit="$"
                  delimiter=","
                  separator="."
                  precision={2}
                  ignoreNegative={true}
                  onChangeText={(formattedValue) => {
                    console.log(formattedValue); // $2,310.46
                  }}
                  style={styles.inputStyle}
                  keyboardType={"numeric"}
                  placeholder={"$0.00"}
                />

                <Text style={styles.noteText}>
                  {
                    "NOTE: If nobody purchases your listing after the allotted time, it will return to the listing queue to be featured once again. Please contact support@buffd.gg for help."
                  }
                </Text>
                <TouchableOpacity
                  style={styles.purchaseButton}
                  onPress={() => {
                    if (!isLoading) listCard();
                  }}
                >
                  {isLoading ? (
                    <ActivityIndicator />
                  ) : (
                    <Text style={styles.purchaseText}>Submit to Queue</Text>
                  )}
                </TouchableOpacity>
              </>
            )}
          </View>
        </View>
      </Modal>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    alignContent: "center",
    //justifyContent: "center",
    padding: 30,
    alignSelf: "center",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  spacer: {
    marginVertical: 10,
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
  },
  inputLabel: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
    marginBottom: 10,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    fontFamily: fonts.expandedRegular,
    color: "black",
    fontSize: 25,
    outlineWidth: 0,
  },
  commonAmountRow: {
    flexDirection: "row",
    marginVertical: 20,
    justifyContent: "space-between",
  },
  amountButton: {
    width: "30%",
    backgroundColor: colors.green,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  amountText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
  },
  purchaseButton: {
    backgroundColor: "black",
    marginTop: 25,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  purchaseText: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 14,
  },
  explainerText: {
    fontFamily: fonts.text,
    color: colors.greyText,
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
  },
  ruleText: {
    fontFamily: fonts.expandedBold,
    color: "black",
    fontSize: 14,
    lineHeight: 24,
    marginVertical: 10,
  },
  authButton: {
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    paddingVertical: 14,
    marginVertical: 15,
  },
  authText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
    color: "white",
  },
  noteText: {
    fontFamily: fonts.medium,
    color: "black",
    marginVertical: 20,
    fontSize: 16,
  },
});

export default ListCardModal;
