import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import * as RootNavigation from "../../RootNavigation";
import { useMediaQuery } from "react-responsive";

const StreamerExplainer = ({ styles }) => {
  return (
    <View>
      <View style={styles.outerContainer}>
        <View style={styles.heroContainer}>
          <Text style={[styles.header, { color: "#939393" }]}>Meet Buffd</Text>
          <Text style={styles.header}>
            The best way for fans to support streamers.
          </Text>
          <View style={styles.columnContainer}>
            <View style={styles.cardContainer}>
              <Text style={styles.cardHeader}>
                Your new online storefront 🏦
              </Text>
              <Text style={styles.cardBody}>
                Buffd handles all of the complexity from website development,
                collectible creation, and more. We provide a seamless connection
                with payment providers and a beautiful UI for fans to engage
                with.
              </Text>
            </View>
            <View style={styles.cardContainer}>
              <Text style={styles.cardHeader}>Twitch Memorabilia 🃏</Text>
              <Text style={styles.cardBody}>
                Buffd gives your fans a way to collect your on-stream moments.
                Every collectible is minted on the blockchain for true
                ownership. Finally, a win-win for you and your supporters.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.middleSection}>
        <View style={styles.heroContainer}>
          <Text style={[styles.header, { color: "#939393" }]}>
            Passive Income
          </Text>
          <Text style={[styles.header, { color: "white" }]}>
            Earn more for every dollar donated.
          </Text>
          <View style={styles.columnContainer}>
            <View
              style={[styles.cardContainer, { backgroundColor: "#141414" }]}
            >
              <Text style={[styles.cardHeader, { color: "white" }]}>
                For Streamers
              </Text>
              <Text style={[styles.cardBody, { color: colors.text }]}>
                Streamers continue to earn money off of the digital collectibles
                from all marketplace activity. Every time a collectible is
                resold, the streamer will earn a percentage.
              </Text>
            </View>
            <View
              style={[styles.cardContainer, { backgroundColor: "#141414" }]}
            >
              <Text style={[styles.cardHeader, { color: "white" }]}>
                For Fans
              </Text>
              <Text style={[styles.cardBody, { color: colors.text }]}>
                For the first time, fans can reap the benefits as the streamers
                grow, too. Curate your very own collection to memorialize your
                Twitch fandom. Every purchase directly supports the streamer.
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.outerContainer}>
        <View style={styles.signUpContainer}>
          <Text style={[styles.header, { color: "#939393" }]}>Ready?</Text>
          <Text style={styles.header}>Signing up is easy.</Text>
          <TouchableOpacity
            style={styles.signUpButton}
            onPress={() => RootNavigation.navigate("StreamerEnroll")}
          >
            <Text style={styles.signUpText}>ENROLL AS CREATOR</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default StreamerExplainer;
