import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { useMediaQuery } from "react-responsive";
import { Collapse } from "react-collapse";
import { Entypo } from "@expo/vector-icons";
import { Mixpanel } from "../../../api/mixPanel";

const FAQSection = () => {
  const [isOpened, setIsOpened] = useState(-1);
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });

  const pressQ = (number) => {
    Mixpanel.track("FAQ Question Select", { questionNumber: number });
    if (isOpened == number) {
      setIsOpened(-1);
    } else {
      setIsOpened(number);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.header}>F.A.Q.</Text>
      <TouchableOpacity onPress={() => pressQ(0)} style={styles.qContainer}>
        <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Text style={styles.qHeader}>What is Buffd?</Text>
          {isOpened == 0 ? (
            <Entypo name="chevron-up" size={24} color={colors.green} />
          ) : (
            <Entypo name="chevron-down" size={24} color={colors.green} />
          )}
        </View>
        <Collapse isOpened={isOpened == 0}>
          <View style={{ marginTop: 20 }} />
          <Text
            style={[
              styles.descText,
              {
                fontSize: 18,
                lineHeight: 26,
                fontFamily: fonts.text,
              },
            ]}
          >
            The live streamers we love spend their entire career making digital
            content. For the first time, viewers can own their favorite
            on-stream moments. Buffd is the best way to support the creators we
            love. With these collectibles, you've got skin in the game. Hold
            your assets for eternity, trade them, or sell them on the
            marketplace. Buffd is our love letter to the gaming world. Enjoy :)
          </Text>
        </Collapse>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => pressQ(1)} style={styles.qContainer}>
        <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Text style={styles.qHeader}>
            Where does the money from my collectible purchases go?
          </Text>
          {isOpened == 1 ? (
            <Entypo name="chevron-up" size={24} color={colors.green} />
          ) : (
            <Entypo name="chevron-down" size={24} color={colors.green} />
          )}
        </View>
        <Collapse isOpened={isOpened == 1}>
          <View style={{ marginTop: 20 }} />
          <Text
            style={[
              styles.descText,
              {
                fontSize: 18,
                lineHeight: 26,
                fontFamily: fonts.text,
              },
            ]}
          >
            Every collectible purchased directly supports the streamer you buy
            from. This includes all primary and secondary sales.
          </Text>
        </Collapse>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => pressQ(2)} style={styles.qContainer}>
        <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Text style={styles.qHeader}>
            Can I donate more than the minimum when claiming a streamer's card?
          </Text>
          {isOpened == 2 ? (
            <Entypo name="chevron-up" size={24} color={colors.green} />
          ) : (
            <Entypo name="chevron-down" size={24} color={colors.green} />
          )}
        </View>
        <Collapse isOpened={isOpened == 2}>
          <View style={{ marginTop: 20 }} />
          <Text
            style={[
              styles.descText,
              {
                fontSize: 18,
                lineHeight: 26,
                fontFamily: fonts.text,
              },
            ]}
          >
            Of course! Every dollar supports your favorite creators and helps
            them keep doing what they love: streaming!
          </Text>
        </Collapse>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => pressQ(3)} style={styles.qContainer}>
        <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Text style={styles.qHeader}>
            Are my cards minted on the blockchain?
          </Text>
          {isOpened == 3 ? (
            <Entypo name="chevron-up" size={24} color={colors.green} />
          ) : (
            <Entypo name="chevron-down" size={24} color={colors.green} />
          )}
        </View>
        <Collapse isOpened={isOpened == 3}>
          <View style={{ marginTop: 20 }} />
          <Text
            style={[
              styles.descText,
              {
                fontSize: 18,
                lineHeight: 26,
                fontFamily: fonts.text,
              },
            ]}
          >
            Yes! Blockchain technology allows for true digital ownership. Every
            Buffd collectible is minted on the Flow blockchain.
          </Text>
        </Collapse>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => pressQ(4)} style={styles.qContainer}>
        <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
          <Text style={styles.qHeader}>
            Do Buffd NFTs hurt the environment?
          </Text>
          {isOpened == 4 ? (
            <Entypo name="chevron-up" size={24} color={colors.green} />
          ) : (
            <Entypo name="chevron-down" size={24} color={colors.green} />
          )}
        </View>
        <Collapse isOpened={isOpened == 4}>
          <View style={{ marginTop: 20 }} />
          <Text
            style={[
              styles.descText,
              {
                fontSize: 18,
                lineHeight: 26,
                fontFamily: fonts.text,
              },
            ]}
          >
            Unlike NFT projects running on Ethereum, Buffd has 99% less
            environmental impact 🌳 by operating on the Flow blockchain (the
            same blockchain that powers NBA TopShot). {"\n\n"} This is because
            the Flow blockchain uses the Proof of Stake (PoS) model rather than
            the Proof of Work (PoW) model, meaning it does not require mining
            and gets rid of emissions. Many have compared this new model to
            driving electric cars rather than gas cars.
          </Text>
        </Collapse>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: "4%",
    marginHorizontal: "2%",
  },
  header: {
    fontFamily: fonts.expandedBlack,
    color: "black",
    marginTop: 25,
    fontSize: 80,
  },
  qContainer: {
    paddingVertical: 15,
    paddingHorizontal: 25,
    backgroundColor: colors.backOverlay,
    borderRadius: 8,
    justifyContent: "center",
    marginTop: 30,
  },
  qHeader: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 20,
  },
  descText: {
    fontFamily: fonts.text,
    color: "white",
    marginTop: 50,
    textAlign: "center",
    fontSize: 25,
  },
});

export default FAQSection;
