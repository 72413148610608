import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import TopBar from "../../components/TopBar";
import { useMediaQuery } from "react-responsive";
import fonts from "../../res/fonts";

const BetaScreen = ({ navigation }) => {
  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;

  return (
    <>
      <TopBar />
      <ScrollView contentContainerStyle={styles.container}>
        <View style={[styles.modalContainer, { width: modalWidth }]}>
          <View style={{ alignSelf: "center" }}>
            <Text style={styles.header}>BUFFD BETA</Text>
            <Text style={styles.subDesc}>$ buffd --version 2.2.3</Text>
          </View>
          <View style={styles.divider} />
          <Text style={styles.streamerDesc}>
            Hey, thanks for checking out Buffd. We love watching streams and
            wanted to build our vision for a better way to support streamers.
            Buffd is currently in Beta and working hard on the next stable
            version to release with new features. Keep reading to learn more
            about our Beta, also known as...
          </Text>
          <Text style={styles.phaseHeader}>THE MINING PHASE</Text>
          <Text style={styles.streamerDesc}>
            During the mining phase, early collectors are tasked with building
            their dream portfolio of streamer collectibles. As an early user,
            this is your chance to make your everlasting impact on the Buffd
            community. During the mining phase, the marketplace is being
            developed in addition to a secure blockchain experience for all of
            our users. In the meantime, each collectible comes with a unique
            Buffd certificate of ownership certifying your asset.
          </Text>
          <Text style={styles.streamerDesc}>Happy hunting!</Text>
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#f9f8f9",
    paddingHorizontal: "5%",
    paddingTop: 50,
    paddingBottom: 200,
  },
  header: {
    fontFamily: fonts.expandedBlack,
    fontSize: 30,
    color: "black",
  },
  modalContainer: {
    backgroundColor: "white",
    alignContent: "center",
    //justifyContent: "center",
    padding: 50,
    alignSelf: "center",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  subDesc: {
    fontFamily: fonts.semiBold,
    color: "grey",
    fontSize: 16,
    marginTop: 15,
    textAlign: "center",
  },
  streamerDesc: {
    fontFamily: fonts.text,
    color: colors.greyText,
    fontSize: 16,
    marginVertical: 15,
    lineHeight: 25,
  },
  divider: {
    borderTopWidth: 0.5,
    borderColor: "grey",
    marginTop: 20,
    opacity: 0.2,
  },
  phaseHeader: {
    fontFamily: fonts.expandedBold,
    marginVertical: 15,
    fontSize: 18,
  },
});

export default BetaScreen;
