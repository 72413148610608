import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import colors from "../../../../res/colors";
import fonts from "../../../../res/fonts";
import Popup from "reactjs-popup";
import RenderReactBid from "./RenderReactBid";
import "../../../HomeScreen/FeedView/ClipStyle.css";
import { Context as UserContext } from "../../../../context/UserContext";
import { Context as ReactContext } from "../../../../context/ReactContext";
import { MaterialIcons } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import { useIsFocused } from "@react-navigation/native";
import { Mixpanel } from "../../../../api/mixPanel";
import { range } from "lodash";

const ReactIncomingBidsModal = ({ isModalVisible, setIsModalVisible }) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const {
    fetchIncomingBids,
    state: { incomingBids },
  } = useContext(ReactContext);
  const closeModal = () => setIsModalVisible(false);

  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;

  useEffect(() => {
    async function loadBids() {
      if (!incomingBids) {
        await fetchIncomingBids();
      }
    }
    loadBids();
  }, [isModalVisible, incomingBids]);

  const innerContent = () => {
    if (!incomingBids) return <ActivityIndicator />;
    const availableBids = incomingBids.filter(
      (item) => `${item.bidder._id}` != `${userInfo._id}`
    );

    return (
      <>
        {availableBids.length == 0 ? (
          <Text style={{ fontFamily: fonts.semiBold }}>
            No incoming bids available
          </Text>
        ) : (
          <>
            <Text style={styles.inputLabel}>
              ALL BIDS ({availableBids.length})
            </Text>
            <ScrollView
              style={{ borderRadius: 10, maxHeight: 400 }}
              contentContainerStyle={styles.listingsContainer}
            >
              {availableBids.map((item, index) => {
                return (
                  <RenderReactBid
                    item={item}
                    setIsModalVisible={setIsModalVisible}
                    key={`${item._id}${index}`}
                  />
                );
              })}
            </ScrollView>
            <Text style={styles.noteText}>All bids expire after 5 days</Text>
          </>
        )}
      </>
    );
  };

  return (
    <Popup
      open={isModalVisible}
      closeOnDocumentClick
      onClose={closeModal}
      position="center center"
      contentStyle={{
        borderTopColor: colors.green,
        borderTopWidth: 5,
        borderRadius: 10,
        width: modalWidth,
      }}
    >
      <View style={{ padding: isMobile ? 10 : 30 }}>
        <View style={styles.topRow}>
          <Text style={styles.header}>Incoming Bids</Text>
          <TouchableOpacity
            onPress={() => {
              Mixpanel.track("Incoming Bids Modal Close");
              setIsModalVisible(!isModalVisible);
            }}
          >
            <MaterialIcons name="cancel" size={24} color="grey" />
          </TouchableOpacity>
        </View>

        {innerContent()}
      </View>
    </Popup>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 30,
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
  },
  inputLabel: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
    marginBottom: 10,
  },
  clipName: {
    fontFamily: fonts.semiBold,
    fontSize: 22,
    marginBottom: 30,
  },

  amountButton: {
    width: "30%",
    backgroundColor: colors.green,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  amountText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
  },
  purchaseButton: {
    backgroundColor: "black",
    marginTop: 25,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  purchaseText: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 14,
  },
  explainerText: {
    fontFamily: fonts.text,
    color: colors.greyText,
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
  },
  noteText: {
    fontFamily: fonts.text,
    color: "grey",
    marginVertical: 10,
    fontSize: 14,
  },
  errorText: {
    fontFamily: fonts.text,
    color: "red",
    marginBottom: 5,
    fontSize: 14,
  },
  listingsContainer: {
    backgroundColor: "#E8E8E8",
    padding: 15,
  },
});

export default ReactIncomingBidsModal;
