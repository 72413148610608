import React from "react";
import { View, Text } from "react-native";

import * as Sentry from "sentry-expo";
import AppNavigator from "./src/AppNavigator";
import { Provider as AuthProvider } from "./src/context/AuthContext";
import { Provider as UserProvider } from "./src/context/UserContext";
import { Provider as StreamerProvider } from "./src/context/StreamerContext";
import { Provider as ReactProvider } from "./src/context/ReactContext";
import { Provider as MarketplaceProvider } from "./src/context/MarketplaceContext";
import { Provider as PackProvider } from "./src/context/PackContext";
import { Provider as StripeProvider } from "./src/context/StripeContext";
import { Provider as EmailProvider } from "./src/context/EmailContext";
import { Provider as TwitchProvider } from "./src/context/TwitchContext";
import LoadingView from "./src/components/LoadingView";
import Logo from "./assets/images/Logo";
import * as Font from "expo-font";
import { useFonts } from "@use-expo/font";
import "@expo/match-media";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  Entypo,
  FontAwesome,
  FontAwesome5,
  AntDesign,
  Ionicons,
} from "@expo/vector-icons";

/****
 * FONT IMPORTS
 */
import Archivo_Expanded_Black from "./assets/fonts/Archivo_Expanded-Black.ttf";
import Archivo_Expanded_Bold from "./assets/fonts/Archivo_Expanded-Bold.ttf";
import Archivo_Expanded_Regular from "./assets/fonts/Archivo_Expanded-Regular.ttf";
import Archivo_Black from "./assets/fonts/Archivo-Black.ttf";
import Archivo_Bold from "./assets/fonts/Archivo-Bold.ttf";
import Archivo_Medium from "./assets/fonts/Archivo-Bold.ttf";
import Archivo_Regular from "./assets/fonts/Archivo-Regular.ttf";
import Archivo_SemiBold from "./assets/fonts/Archivo-SemiBold.ttf";

Sentry.init({
  dsn: "https://383cf82a8c314563816392fa5931938b@o373137.ingest.sentry.io/5382005",
  enableInExpoDevelopment: true,
  debug: true,
});

function App() {
  let [loaded, error] = useFonts({
    "Archivo-Expanded-Black": {
      uri: Archivo_Expanded_Black,
      fontDisplay: Font.FontDisplay.SWAP,
    },
    "Archivo-Expanded-Bold": {
      uri: Archivo_Expanded_Bold,
      fontDisplay: Font.FontDisplay.SWAP,
    },
    "Archivo-Expanded-Regular": {
      uri: Archivo_Expanded_Regular,
      fontDisplay: Font.FontDisplay.SWAP,
    },
    "Archivo-Black": {
      uri: Archivo_Black,
      fontDisplay: Font.FontDisplay.SWAP,
    },
    "Archivo-Bold": {
      uri: Archivo_Bold,
      fontDisplay: Font.FontDisplay.SWAP,
    },
    "Archivo-Medium": {
      uri: Archivo_Medium,
      fontDisplay: Font.FontDisplay.SWAP,
    },
    "Archivo-Regular": {
      uri: Archivo_Regular,
      fontDisplay: Font.FontDisplay.SWAP,
    },
    "Archivo-SemiBold": {
      uri: Archivo_SemiBold,
      fontDisplay: Font.FontDisplay.SWAP,
    },
    ...MaterialIcons.font,
    ...MaterialCommunityIcons.font,
    ...Entypo.font,
    ...FontAwesome.font,
    ...FontAwesome5.font,
    ...AntDesign.font,
    ...Ionicons.font,
  });

  React.useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  // return (
  //   <View
  //     style={{
  //       backgroundColor: "black",
  //       height: "100%",
  //       width: "100%",
  //       justifyContent: "center",
  //       alignItems: "center",
  //     }}
  //   >
  //     <Logo
  //       width={200}
  //       height={50}
  //       style={{
  //         marginBottom: 50,
  //         alignSelf: "center",
  //       }}
  //     />
  //     <Text style={{ color: "white" }}>
  //       We want to thank everyone who sees this for an awesome day. We're fixing
  //       everything and will have the site back up in the morning.
  //     </Text>
  //   </View>
  // );

  if (loaded) {
    return (
      <AuthProvider>
        <StreamerProvider>
          <ReactProvider>
            <PackProvider>
              <MarketplaceProvider>
                <StripeProvider>
                  <EmailProvider>
                    <TwitchProvider>
                      <UserProvider>
                        <ToastContainer />
                        <AppNavigator />
                      </UserProvider>
                    </TwitchProvider>
                  </EmailProvider>
                </StripeProvider>
              </MarketplaceProvider>
            </PackProvider>
          </ReactProvider>
        </StreamerProvider>
      </AuthProvider>
    );
  } else {
    return <LoadingView />;
  }
}

export default App;
