import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Dimensions,
  Image,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import ContractModal from "../../components/ContractModal";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../RootNavigation";
import { Context as UserContext } from "../../context/UserContext";
import { Context as StripeContext } from "../../context/StripeContext";
import {
  desktop as ContractDesktop,
  tablet as ContractTablet,
  mobile as ContractMobile,
} from "../../components/ContractModalStyle";

const StreamerPending = () => {
  const {
    state: { userInfo, streamerProfile, cardPortfolio },
  } = useContext(UserContext);
  const [isContractOpen, setIsContractOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });
  const windowSize = useWindowDimensions();

  const onJoinClick = () => {
    window.open("https://discord.gg/2MT3D2SHmq");
  };

  if (isLoading) {
    return (
      <View
        style={[
          styles.container,
          {
            width: isMobile || isTabletOrMobile ? null : 1024,
            justifyContent: "center",
            alignItems: "center",
          },
        ]}
      >
        <ActivityIndicator />
      </View>
    );
  } else {
    return (
      <View
        style={[
          styles.container,
          {
            width: isMobile || isTabletOrMobile ? null : 1024,
          },
        ]}
      >
        <ContractModal
          isModalVisible={isContractOpen}
          setIsModalVisible={setIsContractOpen}
          styles={
            isMobile
              ? ContractMobile(windowSize)
              : isTabletOrMobile
              ? ContractTablet(windowSize)
              : ContractDesktop(windowSize)
          }
        />
        <Text style={styles.header}>Streamer Status Pending</Text>
        {streamerProfile.legalStatus == undefined ||
        streamerProfile.legalStatus == 0 ? (
          <Text style={styles.desc}>
            We are so thankful for the support and interest of all the streamers
            that have submitted their info to Buffd. This is a note confirming
            that we have your info on file successfully. To keep moving forward,
            there is{" "}
            <Text style={{ fontFamily: fonts.bold }}>
              one thing you need to do:
            </Text>{" "}
            {"\n\n"} (1) Agree to the Buffd terms of service{"\n\n"}
            {/* {"\n\n"} (2) Complete your payout information {"\n\n"}  */}
            Don't hesitate to reach out with any questions at support@buffd.gg.
            While you wait, you can start supporting other streamers and
            creating your portfolio of collectibles, too. Happy Streaming 🎮
          </Text>
        ) : (
          <Text style={styles.desc}>
            We have everything we need from you on file! Thank you so much for
            your interest and our team will get to work on your profile.{" "}
            {"\n\n"} Don't hesitate to reach out with any questions at
            support@buffd.gg. While you wait, you can start supporting other
            streamers and creating your portfolio of collectibles, too. Happy
            Streaming 🎮
          </Text>
        )}
        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {streamerProfile.legalStatus == undefined ||
          streamerProfile.legalStatus == 0 ? (
            <TouchableOpacity
              style={[styles.paymentButton, { backgroundColor: "red" }]}
              onPress={() => setIsContractOpen(true)}
            >
              <Text style={styles.paymentText}>AGREE TO TERMS</Text>
            </TouchableOpacity>
          ) : null}
          {/* {cardPortfolio.stripeConnectId == undefined ||
          !cardPortfolio.stripeVerified ? (
            <TouchableOpacity
              style={[styles.paymentButton, { backgroundColor: "red" }]}
              onPress={() => RootNavigation.navigate("Transaction")}
            >
              <Text style={styles.paymentText}>COMPLETE PAYMENT INFO</Text>
            </TouchableOpacity>
          ) : null} */}
          <TouchableOpacity
            style={styles.paymentButton}
            onPress={() => onJoinClick()}
          >
            <Text style={styles.paymentText}>JOIN OUR DISCORD</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  } //else {
  //   return (
  //     <View
  //       style={[
  //         styles.container,
  //         {
  //           width: isMobile || isTabletOrMobile ? null : 1024,
  //         },
  //       ]}
  //     >
  //       <Text style={styles.header}>Automated Payouts</Text>
  //       <Text style={styles.desc}>
  //         We've finally finished our system to automate all streamer payouts.
  //         Buffd payouts are powered by Stripe, and we'll now need you to
  //         complete your payout information just this one time to get everything
  //         set up.
  //       </Text>
  //       <TouchableOpacity
  //         style={[styles.paymentButton, { backgroundColor: "red" }]}
  //         onPress={() => RootNavigation.navigate("Transaction")}
  //       >
  //         <Text style={[styles.paymentText, { color: "white" }]}>
  //           COMPLETE PAYMENT INFO
  //         </Text>
  //       </TouchableOpacity>
  //       <Text style={styles.infoText}>
  //         Note: This button appears if you have not started or have not
  //         completed your payout registration. If you already started, it will
  //         save your progress.
  //       </Text>
  //     </View>
  //   );
  // }
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    padding: 25,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
    marginBottom: 25,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 18,
  },
  desc: {
    fontFamily: fonts.text,
    marginTop: 15,
    color: "#303030",
    fontSize: 15,
    lineHeight: 20,
  },
  paymentButton: {
    backgroundColor: colors.green,
    alignSelf: "flex-start",
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginRight: 20,
  },
  paymentText: {
    fontFamily: fonts.expandedBold,
  },
  infoText: {
    fontFamily: fonts.text,
    color: "grey",
    marginTop: 20,
  },
});

export default StreamerPending;
