import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  useWindowDimensions,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { MaterialIcons, Entypo } from "@expo/vector-icons";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";
import { Context as TwitchContext } from "../../context/TwitchContext";
import { Context as StreamerContext } from "../../context/StreamerContext";
import { useIsFocused } from "@react-navigation/native";
import * as RootNavigation from "../../RootNavigation";
import { Mixpanel } from "../../api/mixPanel";

const onNavigate = (pageName) => {
  Mixpanel.track("Page View", { pageName: pageName });
  RootNavigation.navigate(pageName);
};

const MobileMenu = ({ selected }) => {
  const {
    state: { token },
    signout,
  } = useContext(AuthContext);
  const {
    resetUserInfo,
    state: { streamerProfile },
  } = useContext(UserContext);
  const { clearState } = useContext(TwitchContext);
  const { reset } = useContext(StreamerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const isFocused = useIsFocused();
  const window = useWindowDimensions();

  useEffect(() => {
    return () => {
      if (!isFocused) setIsMenuVisible(false);
    };
  }, [isFocused]);

  const onMenuPress = () => {
    if (isMenuVisible) {
      setIsMenuVisible(false);
    } else {
      setIsMenuVisible(true);
    }
  };

  const onLogOut = async () => {
    setIsLoading(true);
    await resetUserInfo();
    await clearState();
    await reset();
    await signout();
  };

  return (
    <View style={styles.navSection}>
      <View style={styles.outerMenu}>
        <TouchableOpacity
          style={isMenuVisible ? styles.menuButtonSelected : styles.menuButton}
          onPress={() => onMenuPress()}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MaterialIcons
              name={isMenuVisible ? "keyboard-arrow-up" : "keyboard-arrow-down"}
              size={24}
              color={isMenuVisible ? "black" : "white"}
            />
          </View>
        </TouchableOpacity>
        {isMenuVisible ? (
          <View style={[styles.menuContainer, { width: 0.7 * window.width }]}>
            {token != null ? (
              <>
                <TouchableOpacity
                  style={styles.labelContainer}
                  onPress={() => onNavigate("Home")}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Entypo
                      name="home"
                      size={20}
                      color={selected == 0 ? "black" : "grey"}
                      style={{ marginRight: 10 }}
                    />
                    <Text style={styles.menuLabel}>Home</Text>
                  </View>
                  <MaterialIcons
                    name={"keyboard-arrow-right"}
                    size={24}
                    color={"black"}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.labelContainer}
                  onPress={() => onNavigate("Portfolio")}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Entypo
                      name="archive"
                      size={20}
                      color={selected == 1 ? "black" : "grey"}
                      style={{ marginRight: 10 }}
                    />
                    <Text style={styles.menuLabel}>Portfolio</Text>
                  </View>
                  <MaterialIcons
                    name={"keyboard-arrow-right"}
                    size={24}
                    color={"black"}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.labelContainer}
                  onPress={() => onNavigate("About")}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Entypo
                      name="book"
                      size={20}
                      color={selected == 2 ? "black" : "grey"}
                      style={{ marginRight: 10 }}
                    />
                    <Text style={styles.menuLabel}>Manifesto</Text>
                  </View>
                  <MaterialIcons
                    name={"keyboard-arrow-right"}
                    size={24}
                    color={"black"}
                  />
                </TouchableOpacity>
                {streamerProfile != null && streamerProfile._id ? (
                  <TouchableOpacity
                    style={styles.labelContainer}
                    onPress={() => RootNavigation.navigate("Dashboard")}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Entypo
                        name="v-card"
                        size={20}
                        color={selected == 3 ? "black" : "grey"}
                        style={{ marginRight: 10 }}
                      />
                      <Text style={styles.menuLabel}>Profile</Text>
                    </View>
                    <MaterialIcons
                      name={"keyboard-arrow-right"}
                      size={24}
                      color={"black"}
                    />
                  </TouchableOpacity>
                ) : null}
                <View style={styles.divider} />
                <TouchableOpacity
                  style={styles.subLabelContainer}
                  onPress={() => onLogOut()}
                >
                  {isLoading ? (
                    <ActivityIndicator />
                  ) : (
                    <Text style={styles.subLabel}>Log Out</Text>
                  )}
                </TouchableOpacity>
              </>
            ) : (
              <>
                <TouchableOpacity
                  style={styles.labelContainer}
                  onPress={() => onNavigate("Home")}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Entypo
                      name="home"
                      size={20}
                      color={selected == 0 ? "black" : "grey"}
                      style={{ marginRight: 10 }}
                    />
                    <Text style={styles.menuLabel}>Home</Text>
                  </View>
                  <MaterialIcons
                    name={"keyboard-arrow-right"}
                    size={24}
                    color={"black"}
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.labelContainer}
                  onPress={() => onNavigate("SignIn")}
                >
                  <Text style={styles.menuLabel}>Log In</Text>
                  <MaterialIcons
                    name={"keyboard-arrow-right"}
                    size={24}
                    color={"black"}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.labelContainer}
                  onPress={() => onNavigate("SignUp")}
                >
                  <Text style={styles.menuLabel}>Sign Up</Text>
                  <MaterialIcons
                    name={"keyboard-arrow-right"}
                    size={24}
                    color={"black"}
                  />
                </TouchableOpacity>
                <View style={styles.divider} />
              </>
            )}
            <TouchableOpacity
              style={styles.subLabelContainer}
              onPress={() => onNavigate("Contact")}
            >
              <Text style={styles.subLabel}>Contact Us</Text>
            </TouchableOpacity>
            {!streamerProfile && streamerProfile == null ? (
              <TouchableOpacity
                style={styles.subLabelContainer}
                onPress={() => onNavigate("StreamerInfo")}
              >
                <Text style={styles.subLabel}>Enroll as Creator</Text>
              </TouchableOpacity>
            ) : null}
          </View>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  navSection: {
    flexDirection: "row",
    alignItems: "center",
  },
  menuButtonText: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
  },
  menuButton: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingVertical: 4,
    backgroundColor: "#1C1C1C",
    borderRadius: 2,
    borderWidth: 2,
    borderColor: "#303030",
    marginLeft: 20,
  },
  menuButtonSelected: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingVertical: 4,
    backgroundColor: colors.green,
    borderRadius: 2,
    borderWidth: 2,
    borderColor: colors.darkerGreen,
    marginLeft: 20,
  },
  menuContainer: {
    backgroundColor: "white",
    paddingVertical: 10,
    paddingHorizontal: 15,
    position: "absolute",
    borderRadius: 10,
    top: 45,
    right: 0,
    //marginLeft: 20,
    zIndex: 100000,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.25,
    shadowRadius: 14.78,
    elevation: 22,
  },
  labelContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginVertical: 5,
    borderRadius: 10,
    padding: 5,
  },
  menuLabel: {
    fontFamily: fonts.expandedBold,
  },
  divider: {
    borderBottomColor: "grey",
    borderBottomWidth: 1,
    opacity: 0.2,
    marginVertical: 15,
  },
  subLabelContainer: {
    width: "100%",
    marginVertical: 5,
    borderRadius: 10,
    padding: 5,
  },
  subLabel: {
    fontFamily: fonts.text,
    fontSize: 14,
    color: colors.subBackgroundDark,
  },
});

export default MobileMenu;
