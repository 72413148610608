import React, { useState, useEffect, PureComponent } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { useMediaQuery } from "react-responsive";
import BPSExplainer from "../../../../assets/images/BPSExplainer.png";

const StrategyContainer = () => {
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });
  const window = useWindowDimensions();

  const contentWidth = isTabletOrMobile ? 0.9 * window.width : 1024;

  return (
    <View
      style={[
        styles.container,
        {
          flexDirection: isTabletOrMobile ? "column-reverse" : "row",
          width: contentWidth,
        },
      ]}
    >
      <View style={{ width: isTabletOrMobile ? contentWidth : 600 }}>
        <Text style={styles.topText}>STREAMER GROWTH</Text>
        <Text style={styles.header}>Collector Strategy</Text>
        <Text style={styles.descText}>
          Buffd is not luck of the draw. Strategic collectors rise to the top by
          scouting and collecting streamers at the right time. Every collectible
          is tied to a single point in a streamer's career. Timing is everything
          on Buffd. Invest early, buy the dip, or HODL for the long game.
        </Text>
      </View>
      <Image
        style={{
          width: isTabletOrMobile ? contentWidth : 400,
          height: isTabletOrMobile ? 350 : 300,
          resizeMode: "contain",
          marginBottom: isTabletOrMobile ? 50 : 0,
        }}
        source={BPSExplainer}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 200,
    alignSelf: "center",
    borderRadius: 10,
    justifyContent: "space-between",
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    color: "grey",
    letterSpacing: 14,
    fontSize: 14,
  },
  header: {
    fontFamily: fonts.heavy,
    color: "white",
    marginTop: 25,
    fontSize: 60,
  },
  descText: {
    fontFamily: fonts.text,
    color: "grey",
    marginTop: 50,
    fontSize: 20,
  },
});

export default StrategyContainer;
