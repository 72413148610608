import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import ReactView from "./ReactView/ReactView";
import CardView from "./CardView/CardView";
import { Context as StreamerContext } from "../../context/StreamerContext";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../RootNavigation";
import { TabView, TabBar, SceneMap } from "react-native-tab-view";
import { Entypo, MaterialCommunityIcons } from "@expo/vector-icons";

const PortfolioTabView = () => {
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "first", title: "Reacts" },
    { key: "second", title: "Cards" },
  ]);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const renderScene = ({ route }) => {
    switch (route.key) {
      case "first":
        return <ReactView />;
      case "second":
        return <CardView />;
      default:
        return null;
    }
  };

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      inactiveColor="grey"
      tabStyle={
        !isTabletOrMobile
          ? {
              width: 200,
            }
          : {}
      }
      indicatorStyle={{
        width: !isTabletOrMobile ? 200 : null,
        backgroundColor: "#F8F8F8",
      }}
      style={{
        backgroundColor: "black",
        borderBottomColor: "#505050",
        borderBottomWidth: StyleSheet.hairlineWidth,
        marginBottom: 25,
      }}
      renderLabel={({ route, focused, color }) => (
        <Text
          style={{
            color,
            margin: 8,
            fontFamily: fonts.semiBold,
            fontSize: 14,
            letterSpacing: 2,
          }}
        >
          {route.title == "Reacts" ? (
            <Entypo
              name="folder-video"
              size={18}
              color={color}
              style={{ marginRight: 10 }}
            />
          ) : (
            <MaterialCommunityIcons
              name="cards"
              size={18}
              color={color}
              style={{ marginRight: 10 }}
            />
          )}
          {route.title.toUpperCase()}
        </Text>
      )}
    />
  );

  return (
    <TabView
      renderTabBar={renderTabBar}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "black",
  },
  innerContent: {
    alignSelf: "center",
  },
  sectionHeader: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
    marginBottom: 40,
  },
});

export default PortfolioTabView;
