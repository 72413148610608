import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import Logo from "../../assets/images/Logo";
import fonts from "../res/fonts";

const IBOCard = ({
  backgroundUri,
  alias,
  width,
  height,
  mintNumber,
  claimDate,
  totalCards,
}) => {
  // width:height is 360:504

  const styles = {
    laminate: {
      position: "absolute",
      zIndex: 2000005,
      width: width,
      height: height,
    },
    mintContainer: {
      position: "absolute",
      zIndex: 50000,
      right: 0.05 * width,
      top: 0.024 * height,
    },
    mintNumber: {
      fontFamily: fonts.expandedBlack,
      fontSize: 0.09 * width,
      color: "white",
    },
    totalNumber: {
      fontFamily: fonts.expandedBold,
      fontSize: 0.035 * width,
      color: "white",
      marginTop: -2,
    },
    date: {
      position: "absolute",
      color: "#e1e1e1",
      zIndex: 50000,
      left: 0.512 * width,
      bottom:
        (0.9 * width) / alias.length > (0.7 * width) / 8
          ? 0.19 * height
          : 0.205 * height,
      fontFamily: fonts.expandedBlack,
      fontSize: 0.028 * width,
    },
  };

  return (
    <View>
      <View style={styles.laminate} />
      <Image
        style={{
          width: width,
          height: height,
          resizeMode: "contain",
          borderRadius: 10,
        }}
        source={{
          uri: backgroundUri,
        }}
      />

      <View style={styles.mintContainer}>
        {/* <Text style={styles.backgroundMint}>42</Text> */}
        <View style={{ alignItems: "flex-end" }}>
          <Text style={styles.mintNumber}>{mintNumber}</Text>
          <Text style={styles.totalNumber}>
            /{totalCards ? totalCards : 100}
          </Text>
        </View>
      </View>
      <Text style={styles.date}>{claimDate}</Text>
    </View>
  );
};

export default IBOCard;
