import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import colors from "../res/colors";
import fonts from "../res/fonts";

const DiscordHero = () => {
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });

  const headerFontSize = isMobile ? 40 : isTabletOrMobile ? 45 : 60;
  const subHeaderFontSize = isMobile ? 15 : isTabletOrMobile ? 20 : 30;

  const onJoinClick = () => {
    window.open("https://discord.gg/2MT3D2SHmq");
  };

  return (
    <View style={styles.container}>
      <FontAwesome5
        name="discord"
        size={250}
        color="black"
        style={styles.backgroundIcon}
      />
      <Text style={[styles.header, { fontSize: headerFontSize }]}>
        Join the Discord
      </Text>
      <Text style={[styles.subHeader, { fontSize: subHeaderFontSize }]}>
        Get in on the action with our new Discord server.
      </Text>
      <TouchableOpacity
        onPress={() => onJoinClick()}
        style={styles.discordButton}
      >
        <FontAwesome5 name="discord" size={25} color="white" />
        <Text style={styles.discordButtonText}>JOIN DISCORD</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: "15%",
    paddingVertical: 50,
    backgroundColor: colors.green,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  backgroundIcon: {
    position: "absolute",
    top: -5,
    right: -5,
    opacity: 0.1,
  },
  header: {
    fontFamily: fonts.expandedBold,
    color: "black",
    textAlign: "center",
  },
  subHeader: {
    fontFamily: fonts.text,
    color: "#36454F",
    textAlign: "center",
    marginTop: 20,
  },
  discordButton: {
    flexDirection: "row",
    marginTop: 35,
    alignSelf: "center",
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
    paddingHorizontal: 30,
  },
  discordButtonText: {
    marginLeft: 15,
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 16,
  },
});

export default DiscordHero;
