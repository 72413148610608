import * as React from "react";
import { View, Text } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import AboutScreen from "./screens/AboutScreen/AboutScreen";
import SignInScreen from "./screens/AuthFlow/SignIn/SignInScreen";
import SignUpScreen from "./screens/AuthFlow/SignUp/SignUpScreen";
import PortfolioScreen from "./screens/Portfolio/PortfolioScreen";
import StreamerProfileScreen from "./screens/StreamerProfile/StreamerProfileScreen";
import StreamerInfoScreen from "./screens/StreamerInfo/StreamerInfoScreen";
import StreamerEnrollScreen from "./screens/StreamerInfo/StreamerEnrollScreen";
import TerminalScreen from "./screens/Manifesto/TerminalScreen";
import ManifestoScreen from "./screens/Manifesto/ManifestoScreen";
import ActivationScreen from "./screens/Manifesto/ActivationScreen";
import LogInScreen from "./screens/Manifesto/LogInScreen";
import StreamerDashboardScreen from "./screens/StreamerDashboard/StreamerDashboardScreen";
//import PackScreen from "./screens/Pack/PackScreen";
//import MarketplaceScreen from "./screens/Marketplace/MarketplaceScreen";
import MiningScreen from "./screens/Mining/MiningScreen";
import BetaScreen from "./screens/Beta/BetaScreen";
import ContactScreen from "./screens/Contact/ContactScreen";
import TransactionScreen from "./screens/Transaction/TransactionScreen";
import { navigationRef } from "./RootNavigation";
import * as Linking from "expo-linking";
import AppLoading from "expo-app-loading";
import { Context as AuthContext } from "./context/AuthContext";
import { Context as UserContext } from "./context/UserContext";
import { Context as StreamerContext } from "./context/StreamerContext";
import { Context as PackContext } from "./context/PackContext";
import { Context as ReactContext } from "./context/ReactContext";
import { socket } from "./api/Socket";
import AsyncStorage from "@react-native-async-storage/async-storage";
import LoadingView from "./components/LoadingView";
import { enableScreens } from "react-native-screens";
const queryString = require("query-string");
import { Mixpanel } from "./api/mixPanel";
import moment from "moment";
import { toast } from "react-toastify";
import ReactPixel from "react-facebook-pixel";
import "intersection-observer";

enableScreens();

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const prefix = Linking.createURL("/");

export default function AppNavigator() {
  const {
    checkSignIn,
    signout,
    state: { token },
  } = React.useContext(AuthContext);
  const {
    makePortfolioConnection,
    fetchUserInfo,
    resetUserInfo,
    state: { isUserInfoLoaded, userInfo, cardPortfolio, streamerProfile },
  } = React.useContext(UserContext);
  const {
    makeStreamerConnection,
    fetchStreamerList,
    state: { isStreamerListLoaded },
  } = React.useContext(StreamerContext);
  const { makeReactConnection } = React.useContext(ReactContext);
  const { makePackConnection } = React.useContext(PackContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [toDirect, setToDirect] = React.useState(null);
  const [notifyParam, setNotifyParam] = React.useState(null);

  const linking = {
    prefixes: [prefix],
    config: {
      Home: "",
      Activate: "activate",
      SignIn: "login",
      SignUp: "signup",
      Portfolio: "portfolio",
      Transaction: "transactions",
      StreamerInfo: "streamers",
      StreamerEnroll: "streamerenroll",
      //Pack: "pack",
      //Marketplace: "marketplace",
      //Explore: "explore",
      About: "about",
      Beta: "beta",
      Contact: "contact",
      Dashboard: "dashboard",
      StreamerProfile: ":tag",
    },
  };

  /****
   * HANDLE DEEP LINKS
   */
  React.useEffect(() => {
    const params = queryString.parse(document.location.search);
    const redirect = params.redirect;
    const success = params.success;
    const canceled = params.canceled;

    if ((success || canceled) && notifyParam == null) {
      if (success && success != null) {
        setNotifyParam("success");
        let url = document.location.href;
        window.history.pushState({}, "", url.split("?")[0]);
      } else if (canceled && canceled != null) {
        setNotifyParam("canceled");
        let url = document.location.href;
        window.history.pushState({}, "", url.split("?")[0]);
      }
    }

    if (document.location.pathname === "/" && redirect) {
      const routes = [
        "activate",
        "login",
        "signup",
        "portfolio",
        "transactions",
        "streamers",
        "streamerenroll",
        //"pack",
        //"marketplace",
        //"explore",
        "about",
        "beta",
        "contact",
        "dashboard",
      ];
      const routeDict = {
        Activate: "activate",
        SignIn: "login",
        SignUp: "signup",
        Portfolio: "portfolio",
        Transaction: "transactions",
        StreamerInfo: "streamers",
        StreamerEnroll: "streamerenroll",
        //Pack: "pack",
        //Marketplace: "marketplace",
        //Explore: "explore",
        About: "about",
        Beta: "beta",
        Contact: "contact",
        Dashboard: "dashboard",
      };
      if (routes.includes(`${redirect.substring(1)}`)) {
        const directRoute = {
          route: Object.keys(routeDict).find(
            (key) => routeDict[key] == `${redirect.substring(1)}`
          ),
        };
        if (!toDirect) {
          setToDirect(directRoute);
        }
      } else {
        const directRoute = {
          route: "StreamerProfile",
          tag: redirect.substring(1),
        };
        if (!toDirect) {
          setToDirect(directRoute);
        }
      }
    }
  }, []);

  const redirectNav = () => {
    if (navigationRef.current && toDirect != null) {
      console.log("******REDIRECTING");
      if (`${toDirect.route}` == "StreamerProfile") {
        navigationRef.current?.navigate(toDirect.route, { tag: toDirect.tag });
      } else {
        navigationRef.current?.navigate(toDirect.route);
      }
      setToDirect(null);
    }
    if (navigationRef.current && notifyParam) {
      console.log("****RECEIVED STRIPE PARAM");
      if (notifyParam == "success") {
        toast.success(
          "Card claimed successfully! If you do not see your card in your portfolio, this is due to high volume and you will receive it on a delay."
        );
        ReactPixel.track("Purchase", { currency: "USD" });
      } else if (notifyParam == "canceled") {
        toast.error("Order canceled -- checkout again when you're ready.");
      } else if (notifyParam == "bidsuccess") {
        toast.success(
          "Please check the marketplace to see if you're bid was successful!"
        );
      } else if (notifyParam == "bidcanceled") {
        toast.error("Bid canceled -- try again when you're ready.");
      } else if (notifyParam == "reauth") {
        toast("Click the button below to complete your payout registration.");
      }
    }
  };

  const loadHome = async () => {
    console.log("----RUNNING LOADHOME");
    console.log("SOCKET CONNECTED: " + socket.connected);

    if (!socket.connected) {
      console.log("CONNECTING SOCKET");
      const email = await AsyncStorage.getItem("email");
      console.log("EMAIL" + email);
      await socket.connect();
      await socket.emit("connection", "I'm connected");
      await socket.emit("login", email);
    }
    if (!isUserInfoLoaded) {
      console.log("NOT LOADED");
      await fetchUserInfo();
      //await fetchStreamerList();

      makePortfolioConnection();
      makeStreamerConnection();
      makePackConnection();
      makeReactConnection();

      socket.on("disconnect", async () => {
        console.log("socket disconnected");
        const email = await AsyncStorage.getItem("email");
        await socket.connect();
        await socket.emit("connection", "I'm connected");
        await socket.emit("login", email);

        await fetchUserInfo();
        //await fetchStreamerList();

        makePortfolioConnection();
        makeStreamerConnection();
        makePackConnection();
        makeReactConnection();
      });
    }
  };

  React.useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        setIsLoading(true);
        console.log("CHECKING TOKEN");
        await checkSignIn();
        setIsLoading(false);
      } catch (e) {
        console.log("error", e);
      }
    };
    bootstrapAsync();
  }, [token]);

  React.useEffect(() => {
    const loadUp = async () => {
      if (!isLoading) {
        setIsDataLoaded(false);
        if (token != null) {
          console.log("----LOADING DATA");
          await loadHome();
        } else {
          console.log("RESETTING USER INFO");
          //await fetchStreamerList();
          resetUserInfo();
          Mixpanel.track("Opened App");
          ReactPixel.init(
            "1149216408927576",
            {},
            { autoConfig: true, debug: false }
          );
          ReactPixel.pageView();
        }
        setIsDataLoaded(true);
      }
    };
    loadUp();
  }, [isLoading]);

  React.useEffect(() => {
    if (isUserInfoLoaded && userInfo) {
      Mixpanel.identify(userInfo._id);
      Mixpanel.track("Opened App");
      const peopleSetObject = {
        $name: `${userInfo.alias}`,
        $email: userInfo.email,
        USER_ID: userInfo._id,
        "Last Opened": moment().format(),
        USER_TYPE: userInfo.type == 0 ? "Collector" : "Streamer",
        "Cards Acquired": cardPortfolio.cardsAcquired.length,
        "Total Donation Amount": cardPortfolio.totalDonationAmount,
        "Streamers Supported": cardPortfolio.streamersSupported,
      };
      if (userInfo.type == 1 && streamerProfile) {
        peopleSetObject["Total Received"] = streamerProfile.totalDonationAmount;
        peopleSetObject["Total Cards Issued"] =
          streamerProfile.cardDetails.cardsIssued.length;
      }
      Mixpanel.people.set(peopleSetObject);
      ReactPixel.init(
        "1149216408927576",
        { em: userInfo.email },
        { autoConfig: true, debug: false }
      );
      ReactPixel.pageView();
    }
  }, [isUserInfoLoaded]);

  if (
    isLoading ||
    !isDataLoaded ||
    // !isStreamerListLoaded ||
    (token && !isUserInfoLoaded)
  ) {
    return <LoadingView />;
  } else {
    return (
      <NavigationContainer
        ref={navigationRef}
        onReady={redirectNav}
        linking={linking}
        fallback={<Text>Loading...</Text>}
      >
        <Stack.Navigator headerMode="none">
          {token == null || !isUserInfoLoaded ? ( //!isLoading && token == null
            // No token found, user isn't signed in
            <>
              <Stack.Screen
                name="Home"
                component={AboutScreen}
                options={{
                  headerShown: false,
                  cardStyle: { backgroundColor: "white" },
                  title: "Buffd",
                }}
              />
              <Stack.Screen
                name="About"
                component={ManifestoScreen}
                options={{
                  headerShown: false,
                  cardStyle: { backgroundColor: "white" },
                  title: "Buffd",
                }}
              />
              <Stack.Screen
                name="StreamerProfile"
                component={StreamerProfileScreen}
                options={({ route, navigation }) => ({
                  headerShown: false,
                  cardStyle: { backgroundColor: "white" },
                  title: `${route.params.tag} | Buffd`,
                })}
              />
              <Stack.Screen
                name="SignIn"
                component={LogInScreen}
                options={{ headerShown: false, title: "Buffd | Log In" }}
              />
              <Stack.Screen
                name="SignUp"
                component={SignUpScreen}
                options={{ headerShown: false, title: "Buffd | Sign Up" }}
              />
              <Stack.Screen
                name="StreamerInfo"
                component={StreamerInfoScreen}
                options={{ headerShown: false, title: "Buffd | For Streamers" }}
              />
              <Stack.Screen
                name="StreamerEnroll"
                component={StreamerEnrollScreen}
                options={{
                  headerShown: false,
                  title: "Buffd | Streamer Enroll",
                }}
              />
              <Stack.Screen
                name="Beta"
                component={BetaScreen}
                options={{ headerShown: false, title: "Buffd | Beta" }}
              />
              <Stack.Screen
                name="Contact"
                component={ContactScreen}
                options={{ headerShown: false, title: "Buffd | Contact" }}
              />
            </>
          ) : (
            <>
              <Stack.Screen
                name="Home"
                component={HomeScreen}
                options={{
                  headerShown: false,
                  cardStyle: { backgroundColor: "white" },
                  title: "Buffd",
                }}
              />
              <Stack.Screen
                name="About"
                component={ManifestoScreen}
                options={{
                  headerShown: false,
                  cardStyle: { backgroundColor: "white" },
                  title: "Buffd | About",
                }}
              />
              <Stack.Screen
                name="StreamerProfile"
                component={StreamerProfileScreen}
                options={({ route, navigation }) => ({
                  headerShown: false,
                  cardStyle: { backgroundColor: "white" },
                  title: `${route.params.tag} | Buffd`,
                })}
              />
              {streamerProfile != null && streamerProfile._id ? (
                <Stack.Screen
                  name="Dashboard"
                  component={StreamerDashboardScreen}
                  options={({ route, navigation }) => ({
                    headerShown: false,
                    cardStyle: { backgroundColor: "white" },
                    title: "Dashboard | Buffd",
                  })}
                />
              ) : null}
              <Stack.Screen
                name="Portfolio"
                component={PortfolioScreen}
                options={{
                  headerShown: false,
                  cardStyle: { backgroundColor: "white" },
                  title: "Portfolio | Buffd",
                }}
              />
              <Stack.Screen
                name="Transaction"
                component={TransactionScreen}
                options={{
                  headerShown: false,
                  cardStyle: { backgroundColor: "white" },
                  title: "Transactions | Buffd",
                }}
              />
              <Stack.Screen
                name="StreamerInfo"
                component={StreamerInfoScreen}
                options={{ headerShown: false, title: "Buffd | For Streamers" }}
              />
              <Stack.Screen
                name="StreamerEnroll"
                component={StreamerEnrollScreen}
                options={{
                  headerShown: false,
                  title: "Buffd | Streamer Enroll",
                }}
              />

              <Stack.Screen
                name="Beta"
                component={BetaScreen}
                options={{ headerShown: false, title: "Buffd | Beta" }}
              />
              <Stack.Screen
                name="Contact"
                component={ContactScreen}
                options={{ headerShown: false, title: "Buffd | Contact" }}
              />
            </>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
}
