import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import fonts from "../res/fonts";

const StreamerCard = ({
  uri,
  width,
  height,
  mintNumber,
  totalCards,
  claimDate,
}) => {
  // width:height is 360:504

  const styles = {
    laminate: {
      position: "absolute",
      zIndex: 100000,
      width: width,
      height: height,
    },
    mintContainer: {
      position: "absolute",
      zIndex: 50000,
      right: 0.05 * width,
      top: 0.035 * height,
    },
    mintNumber: {
      fontFamily: fonts.expandedBlack,
      fontSize: 0.1 * width,
      color: "white",
    },
    totalNumber: {
      fontFamily: fonts.expandedBold,
      fontSize: 0.04 * width,
      color: "white",
      marginTop: 0,
    },
    date: {
      position: "absolute",
      zIndex: 50000,
      right: 0.05 * width,
      bottom: 0.032 * height,
      color: "white",
      fontFamily: fonts.medium,
      fontSize: 0.04 * width,
    },
  };

  return (
    <View>
      <View style={styles.laminate} />
      <Image
        style={{
          width: width,
          height: height,
          resizeMode: "contain",
          borderRadius: 10,
        }}
        source={{ uri: uri }}
      />
      <View style={styles.mintContainer}>
        {/* <Text style={styles.backgroundMint}>42</Text> */}
        <View style={{ alignItems: "flex-end" }}>
          <Text style={styles.mintNumber}>{mintNumber}</Text>
          <Text style={styles.totalNumber}>/{totalCards}</Text>
        </View>
      </View>
      <Text style={styles.date}>{claimDate}</Text>
    </View>
  );
};

export default StreamerCard;
