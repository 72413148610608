import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { useMediaQuery } from "react-responsive";
import CollectibleContainer from "./ExplainerComps/CollectibleContainer";
import StrategyContainer from "./ExplainerComps/StrategyContainer";
import FeaturedSection from "./FeaturedSection";
import ColumnContainer from "./ExplainerComps/ColumnContainer";
import FAQSection from "./ExplainerComps/FAQSection";
import DiscordHero from "../../components/DiscordHero";
import {
  desktop as ColumnDesktop,
  tablet as ColumnTablet,
  mobile as ColumnMobile,
} from "./Stylesheets/ExplainerColumnStyle";

const Explainer = () => {
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });
  const window = useWindowDimensions();

  return (
    <View style={[styles.container, { width: 0.9 * window.width }]}>
      <CollectibleContainer />
      <StrategyContainer />
      {/* <FeaturedSection /> */}
      <ColumnContainer
        styles={
          isMobile
            ? ColumnMobile
            : isTabletOrMobile
            ? ColumnTablet
            : ColumnDesktop
        }
      />
      <FAQSection />
      <DiscordHero />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: "4%",
    alignSelf: "center",
    borderRadius: 10,
  },
});

export default Explainer;
