import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import _ from "lodash";
import colors from "../../../res/colors";
import { useMediaQuery } from "react-responsive";
import fonts from "../../../res/fonts";
import RenderCard from "./RenderCard";
import { Context as UserContext } from "../../../context/UserContext";
import {
  desktop as CardDesktop,
  tablet as CardTablet,
  mobile as CardMobile,
} from "../Stylesheets/RenderCardStyles";

const CardGrid = ({ navigation }) => {
  const {
    state: { cardPortfolio },
  } = useContext(UserContext);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const window = useWindowDimensions();

  const varColumns = isMobile ? 1 : isTabletOrMobile ? 3 : 4;

  const unopenedPacks = cardPortfolio.packsAcquired.filter(
    (pack) => pack.type == 1
  );
  const dataArr =
    unopenedPacks.length > 0
      ? [...unopenedPacks, ...cardPortfolio.cardsAcquired]
      : cardPortfolio.cardsAcquired;

  const chunk = _.chunk(dataArr, varColumns);

  const chunkItems = chunk.map((item, index) => (
    <View
      key={`${index}`}
      style={{
        flexDirection: "row",
        justifyContent: isMobile ? "center" : "flex-start",
      }}
    >
      {item.map((item, index) => {
        let isQueued = false;
        if (
          cardPortfolio.marketListings &&
          cardPortfolio.marketListings != undefined
        ) {
          isQueued = cardPortfolio.marketListings
            .map((listing) => `${listing.cardId}`)
            .includes(`${item._id}`);
        }

        if (item != null && item._id)
          return (
            <RenderCard
              key={`${item._id}`}
              item={item}
              index={index}
              numColumns={varColumns}
              queued={isQueued}
              styles={
                isMobile
                  ? CardMobile(window, varColumns)
                  : isTabletOrMobile
                  ? CardTablet(window, varColumns)
                  : CardDesktop(window, varColumns)
              }
            />
          );
      })}
    </View>
  ));

  return <>{chunkItems}</>;
};

const styles = StyleSheet.create({
  heroContainer: {},
  header: {
    fontFamily: fonts.expandedBlack,
    fontSize: 20,
    color: "white",
    marginVertical: 50,
  },
});

export default CardGrid;
