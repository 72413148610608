import { StyleSheet } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";

const desktop = (window, numColumns) =>
  StyleSheet.create({
    firstStreamerContainer: {
      marginRight:
        (0.9 * window.width -
          numColumns * ((0.9 * window.width) / (numColumns + 1))) /
        numColumns,
      marginBottom: 60,
    },
    streamerContainer: {
      marginRight:
        (0.9 * window.width -
          numColumns * ((0.9 * window.width) / (numColumns + 1))) /
        numColumns,
      marginBottom: 60,
    },
    streamerImage: {
      borderRadius: 6,
      borderWidth: 2,
      borderColor: "#202020",
      width: (0.9 * window.width) / (numColumns + 1),
      height: (0.9 * window.width) / (numColumns + 1) / (360 / 504),
      resizeMode: "contain",
    },
    streamerName: {
      fontFamily: fonts.bold,
      fontSize: 20,
      color: "white",
      marginTop: 15,
    },
    underCardContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    listButton: {
      backgroundColor: colors.green,
      paddingHorizontal: 20,
      paddingVertical: 10,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 6,
      alignSelf: "flex-end",
    },
    listText: {
      fontFamily: fonts.expandedBold,
      fontSize: 10,
    },
    descText: {
      fontFamily: fonts.semiBold,
      fontSize: 14,
      color: "grey",
      marginTop: 10,
    },
  });

const tablet = (window, numColumns) =>
  StyleSheet.create({
    firstStreamerContainer: {
      marginRight:
        (0.9 * window.width -
          numColumns * ((0.9 * window.width) / (numColumns + 1))) /
        numColumns,
      marginBottom: 60,
    },
    streamerContainer: {
      marginRight:
        (0.9 * window.width -
          numColumns * ((0.9 * window.width) / (numColumns + 1))) /
        numColumns,
      marginBottom: 60,
    },
    streamerImage: {
      borderRadius: 6,
      borderWidth: 2,
      borderColor: "#202020",
      width: (0.9 * window.width) / (numColumns + 1),
      height: (0.9 * window.width) / (numColumns + 1) / (360 / 504),
      resizeMode: "contain",
    },
    streamerName: {
      fontFamily: fonts.bold,
      fontSize: 20,
      color: "white",
      marginTop: 15,
    },
    listButton: {
      backgroundColor: colors.green,
      paddingHorizontal: 20,
      paddingVertical: 10,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 6,
      alignSelf: "flex-end",
    },
    listText: {
      fontFamily: fonts.expandedBold,
      fontSize: 10,
    },
    descText: {
      fontFamily: fonts.semiBold,
      fontSize: 14,
      color: "grey",
      marginTop: 10,
    },
  });

const mobile = (window, numColumns) =>
  StyleSheet.create({
    firstStreamerContainer: {
      //marginRight: (0.9 * window.width) / (numColumns + 1) / numColumns / 2,
      marginBottom: 60,
    },
    streamerContainer: {
      //marginRight: (0.9 * window.width) / (numColumns + 1) / numColumns / 2,
      marginBottom: 60,
    },
    streamerImage: {
      borderRadius: 6,
      borderWidth: 2,
      borderColor: "#202020",
      width: 0.65 * window.width,
      height: (0.65 * window.width) / (360 / 504),
      resizeMode: "contain",
    },
    streamerName: {
      fontFamily: fonts.bold,
      fontSize: 20,
      color: "white",
      marginTop: 15,
    },
    listButton: {
      backgroundColor: colors.green,
      paddingHorizontal: 20,
      paddingVertical: 10,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 6,
      alignSelf: "flex-end",
    },
    listText: {
      fontFamily: fonts.expandedBold,
      fontSize: 10,
    },
    descText: {
      fontFamily: fonts.semiBold,
      fontSize: 14,
      color: "grey",
      marginTop: 10,
    },
  });

export { desktop, tablet, mobile };
