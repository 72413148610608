import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  FlatList,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { Input } from "react-native-elements";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { Context as UserContext } from "../../context/UserContext";

const StreamerGuide = () => {
  const {
    state: { isUserInfoLoaded, streamerProfile },
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const windowSize = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  if (streamerProfile.status == 0) {
    return (
      <View style={styles.container}>
        <View style={{ flexShrink: 1 }}>
          <Text style={styles.header}>Streamer Resources</Text>
          <Text style={styles.subHeader}>
            We're so excited to have you join Buffd alongside hundreds of other
            amazing streamers. Please make sure to complete the to do list above
            and then you will find helpful information here.
          </Text>
        </View>
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        <View style={{ flexShrink: 1 }}>
          <Text style={styles.header}>Streamer Resources</Text>
          <Text style={styles.subHeader}>
            We're so excited to have you join Buffd alongside hundreds of other
            amazing streamers. Here is the info you need to get started be
            successful as a Buffd streamer!
          </Text>
        </View>
        <View style={styles.divider} />
        <View style={styles.itemContainer}>
          <Text style={styles.header}>
            ℹ️ How does Buffd work? How do I explain it to my viewers?
          </Text>
          <Text style={styles.subHeader}>
            Buffd is your online store of on-stream moments. For you, it allows
            you to monetize the content you already work so hard to create every
            day on stream. For viewers and fans, it's the best way to support
            you: you receive more money than a sub, and the supporters can
            collect and own your on-stream moments. All ownership is guaranteed
            by blockchain technology, and as you continue to grow, they can even
            sell the collectible to profit off of their early support.
          </Text>
        </View>
        <View style={styles.itemContainer}>
          <Text style={styles.header}>
            🔗 Your Unique Buffd Link:{" "}
            <TouchableOpacity
              onPress={() =>
                window.open(`https://buffd.gg/${streamerProfile.alias}`)
              }
            >
              <Text style={{ textDecorationLine: "underline" }}>
                https://buffd.gg/{streamerProfile.alias}
              </Text>
            </TouchableOpacity>{" "}
          </Text>
          <Text style={styles.subHeader}>
            This works just like a Streamlabs link, where your supporters can
            purchase a clip right from this page. This should go in your social
            media bios and/or link aggregators, like LinkTree.
          </Text>
        </View>
        <View style={styles.itemContainer}>
          <Text style={styles.header}>📺 Monthly Buffd Streams</Text>
          <Text style={styles.subHeader}>
            Currently, all Buffd streamers can drop new digital collectibles
            once every month. This is a great opportunity for you to host a
            designated stream to announce your Buffd launch to drum up
            excitement with your supporters. We've seen our creators have the
            most success with this when they actually pull up the Buffd website
            and talk about it on stream to explain what Buffd is.{"\n\n"}This
            should become a monthly event for your stream, where supporters rush
            to collect your best on-stream moments, all while supporting you and
            your stream.
          </Text>
        </View>
        {/* <View style={styles.itemContainer}>
          <Text style={styles.header}>🖼️ Graphics for Twitch Panels</Text>
          <Text style={styles.subHeader}>
            <TouchableOpacity>
              <Text
                style={{ textDecorationLine: "underline", color: colors.green }}
              >
                Click here
              </Text>
            </TouchableOpacity>{" "}
            to download images that you can put in your Twitch Bio panels
          </Text>
        </View> */}
        <View style={styles.itemContainer}>
          <Text style={styles.header}>
            💬 Chatbot Commands with Personal Link and Description
          </Text>
          <Text style={styles.subHeader}>
            Add the Twitch Chatbot commands{" "}
            <Text style={{ color: colors.green }}>!buffd</Text> and{" "}
            <Text style={{ color: colors.green }}>!nft</Text> to include a
            description of your NFT card and a link to your unique Buffd page.
            Here are some options you can copy and paste:{"\n\n"}You can now
            collect my best on-stream moments as NFT memorabilia.
            https://www.buffd.gg/{streamerProfile.alias} (This helps me more
            than a sub!)
            {"\n\n"}Did you ever collect baseball cards of your favorite
            players? Buffd is brand new and allows you to collect my on-stream
            moments! Every collectible purchased supports me.
            https://www.buffd.gg/SnekyTurt1e
          </Text>
        </View>
        <View style={styles.itemContainer}>
          <Text style={styles.header}>🐦 Social Media Post</Text>
          <Text style={styles.subHeader}>
            You can copy and paste the text below (it is already customized for
            you!):{"\n\n"}Can't believe I have my own NFT now! You can now
            support and collect my best on-stream moments. There are super
            limited quantities so don't miss out and cop yours at
            https://buffd.gg/{streamerProfile.alias} {"\n"}#
            {streamerProfile.alias.toUpperCase()}TOTHEMOON 🚀🚀🚀🚀
          </Text>
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    flexShrink: 1,
  },
  header: {
    fontFamily: fonts.text,
    fontSize: 25,
    color: "white",
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: fonts.text,
    fontSize: 18,
    color: "#A9A9A9",
    marginBottom: 15,
    lineHeight: 30,
  },
  divider: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: "#181818",
    marginBottom: 30,
  },
  itemContainer: {
    backgroundColor: colors.backOverlay,
    padding: 40,
    marginBottom: 40,
  },
});

export default StreamerGuide;
