import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import RenderReact from "./RenderReact";
import ReactPurchaseButton from "./ReactPurchaseButton";
import StreamerCard from "../../components/StreamerCard";
import StreamerGifCard from "../../components/StreamerGifCard";
import IBOCard from "../../components/IBOCard";
import DropGrid from "./DropGrid";
import ClaimCardModal from "./ClaimCardModal";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import { range } from "lodash";
import { Collapse } from "react-collapse";

const CollectibleTab = ({
  tag,
  fetchedStreamer,
  selectedCollectible,
  setSelectedCollectible,
}) => {
  const [showBio, setShowBio] = useState(false);
  const [showHistory, setShowHistory] = useState(true);
  const [isCardPurchaseModalVisible, setIsCardPurchaseModalVisible] =
    useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const cardComp = () =>
    fetchedStreamer.cardDetails.cardType === undefined ||
    fetchedStreamer.cardDetails.cardType == 0 ? (
      <StreamerCard
        uri={fetchedStreamer.cardDetails.cardDesign}
        width={260}
        height={260 / (360 / 504)}
        mintNumber={
          fetchedStreamer.cardDetails.cardsIssued.length >= 100
            ? 100
            : fetchedStreamer.cardDetails.cardsIssued.length + 1
        }
        claimDate={moment().format("MM/DD/YYYY")}
      />
    ) : (
      <IBOCard
        backgroundUri={fetchedStreamer.cardDetails.cardDesign}
        alias={fetchedStreamer.alias}
        width={260}
        height={260 / (360 / 504)}
        mintNumber={
          fetchedStreamer.cardDetails.cardsIssued.length >= 100
            ? 100
            : fetchedStreamer.cardDetails.cardsIssued.length + 1
        }
        claimDate={moment().format("MM/DD/YYYY")}
      />
    );

  if (!selectedCollectible) return null;

  const listData =
    selectedCollectible.status < 3
      ? {
          date: moment(selectedCollectible.dropDate)
            .format("MMM D, YY")
            .toUpperCase(),
          edition: "ROOKIE SERIES",
          acquired: selectedCollectible.claimed.length,
          total: selectedCollectible.total,
          reserve: selectedCollectible.reserve,
          claimData: selectedCollectible.claimed,
        }
      : {
          date: moment(fetchedStreamer.cardDetails.dropDate)
            .format("MMM D, YY")
            .toUpperCase(),
          edition: "FIRST EDITION",
          acquired: fetchedStreamer.cardDetails.cardsIssued.length,
          total: fetchedStreamer.cardDetails.totalCards,
          reserve: fetchedStreamer.cardDetails.reserve,
          topSalePrice:
            fetchedStreamer.cardDetails.topDonation.price.toFixed(2),
          topSaleName: fetchedStreamer.cardDetails.topDonation.ownerId.alias,
          claimData: fetchedStreamer.cardDetails.cardsIssued,
        };

  const onPurchase = () => {
    if (selectedCollectible.status < 3) {
      console.log("React collectible onPurchase called");
    } else {
      if (listData.acquired < listData.total) {
        setIsCardPurchaseModalVisible(true);
      }
    }
  };

  return (
    <View style={{ marginTop: 15 }}>
      <View style={styles.container}>
        <View
          style={[
            styles.splitContainer,
            { width: isMobile ? 0.95 * window.width : 460 },
          ]}
        >
          <View style={styles.cardContainer}>
            {selectedCollectible.status < 3 ? (
              <RenderReact item={selectedCollectible} />
            ) : (
              cardComp()
            )}
          </View>
        </View>
        <View
          style={[
            styles.splitContainer,
            {
              marginTop: isMobile ? 20 : 0,
              width: isMobile ? 0.95 * window.width : 460,
              paddingLeft: isMobile ? 0 : 25,
            },
          ]}
        >
          <View style={styles.headerContainer}>
            <View style={styles.dateTextContainer}>
              <Text style={styles.dateText}>{listData.date}</Text>
            </View>
            <View style={styles.dateDivider} />
            <View style={styles.editionContainer}>
              <Text style={styles.dropHeader}>{listData.edition}</Text>
              <Text style={styles.dropDesc}>
                {listData.acquired} ACQ. | {listData.total} TOTAL
              </Text>
            </View>
          </View>
          <View style={styles.pricingContainer}>
            <View style={styles.priceBox}>
              <Text style={styles.priceVal}>
                ${listData.reserve.toFixed(2)}
              </Text>
              <Text style={styles.priceLabel}>Reserve Price</Text>
            </View>
            <View style={styles.priceBox}>
              <Text style={styles.priceVal}>
                ${listData.topSalePrice ? listData.topSalePrice : "N/A"}
              </Text>
              <Text style={styles.priceLabel}>
                Top Sale{" "}
                {listData.topSaleName ? `@${listData.topSaleName}` : null}
              </Text>
            </View>
          </View>
          {selectedCollectible.status < 3 &&
          listData.acquired >= listData.total ? (
            <ReactPurchaseButton item={selectedCollectible} />
          ) : null}
          {selectedCollectible.status >= 3 ? (
            <TouchableOpacity
              style={styles.claimButton}
              onPress={() => onPurchase()}
            >
              <Text style={styles.claimText}>
                {listData.acquired < listData.total ? "PURCHASE" : "SOLD OUT"}
              </Text>
            </TouchableOpacity>
          ) : null}
        </View>
      </View>
      <View style={styles.historyContainer}>
        <TouchableOpacity
          style={styles.historyHeaderContainer}
          onPress={() => setShowBio(!showBio)}
        >
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.historyHeader}>
              ABOUT {fetchedStreamer.alias.toUpperCase()}
            </Text>
            <AntDesign
              name={!showBio ? "arrowdown" : "arrowup"}
              size={24}
              color="white"
            />
          </View>
          <Collapse isOpened={showBio}>
            <View style={{ marginTop: 50 }}>
              <Text
                style={{ fontFamily: fonts.text, color: "white", fontSize: 20 }}
              >
                {fetchedStreamer.info.bio}
              </Text>
            </View>
          </Collapse>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.historyHeaderContainer}
          onPress={() => setShowHistory(!showHistory)}
        >
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Text style={styles.historyHeader}>CLAIM HISTORY</Text>
            <AntDesign
              name={!showHistory ? "arrowdown" : "arrowup"}
              size={24}
              color="white"
            />
          </View>
          <Collapse isOpened={showHistory}>
            <ScrollView
              style={{ marginTop: 50, maxHeight: 400, paddingRight: 15 }}
            >
              {listData.claimData.map((item, index) => (
                <View style={styles.claimContainer} key={index}>
                  <View style={styles.claimContent}>
                    <View
                      style={{
                        width: isMobile ? null : 100,
                        marginRight: isMobile ? 5 : null,
                        marginBottom: isMobile ? 5 : null,
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <Text style={styles.serial}>{item.mintNumber}</Text>
                      <Text style={styles.totalSerial}>/{listData.total}</Text>
                    </View>
                    <View
                      style={{
                        width: isMobile ? null : 100,
                        marginRight: isMobile ? 5 : null,
                        marginBottom: isMobile ? 5 : null,
                        alignItems: "center",
                      }}
                    >
                      <Text style={styles.price}>
                        {item.price ? `$${item.price.toFixed(2)}` : "N/A"}
                      </Text>
                    </View>

                    <View
                      style={{
                        width: isMobile ? null : 200,
                        marginRight: isMobile ? 5 : null,
                        marginBottom: isMobile ? 5 : null,
                        alignItems: "center",
                      }}
                    >
                      <Text style={styles.buyerAlias}>
                        {item.ownerId ? `@${item.ownerId.alias}` : "N/A"}
                      </Text>
                    </View>

                    <View
                      style={{
                        width: isMobile ? null : 200,
                        marginRight: isMobile ? 5 : null,
                        marginBottom: isMobile ? 5 : null,
                        alignItems: "flex-end",
                      }}
                    >
                      <Text style={styles.date}>
                        {moment(item.updatedAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.divider} />
                </View>
              ))}
            </ScrollView>
          </Collapse>
        </TouchableOpacity>
      </View>
      {selectedCollectible.status >= 3 ? (
        <ClaimCardModal
          data={fetchedStreamer}
          isModalVisible={isCardPurchaseModalVisible}
          setIsModalVisible={setIsCardPurchaseModalVisible}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  dropTitleContainer: {
    alignSelf: "flex-start",
    backgroundColor: colors.green,
    paddingHorizontal: 35,
    paddingVertical: 12,
    marginBottom: 25,
    borderRadius: 5,
  },
  dropTitle: {
    fontFamily: fonts.expandedBold,
    color: "black",
    fontSize: 12,
    letterSpacing: 2,
  },
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    // borderWidth: 1,
    // borderColor: "yellow",
    justifyContent: "space-between",
  },
  splitContainer: {
    //width: 460,
    // borderWidth: 1,
    // borderColor: "red",
    flexShrink: 1,
  },
  cardContainer: {
    alignSelf: "flex-start",
    alignItems: "flex-start",
    backgroundColor: colors.backOverlay,
    padding: 25,
    borderRadius: 5,
  },
  headerContainer: {
    alignItems: "flex-start",
    flexDirection: "row",
  },
  editionContainer: {
    flexShrink: 1,
    marginLeft: 10,
  },
  dropHeader: {
    fontFamily: fonts.expandedBlack,
    color: "white",
    fontSize: 35,
    flexWrap: "wrap",
    marginBottom: 10,
  },
  dropDesc: {
    fontFamily: fonts.semiBold,
    color: "grey",
    fontSize: 14,
  },
  dateTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: 150,
    width: 30,
  },
  dateText: {
    fontFamily: fonts.expandedBold,
    color: "grey",
    transform: [{ rotate: "90deg" }, { translateX: 28 }],
    width: 200,
  },
  dateDivider: {
    height: 95,
    width: 1,
    backgroundColor: "grey",
  },
  priceBox: {
    marginBottom: 10,
  },
  priceVal: {
    fontFamily: fonts.expandedBlack,
    color: "white",
    fontSize: 25,
  },
  priceLabel: {
    color: "grey",
    fontSize: 12,
    fontFamily: fonts.semiBold,
  },
  claimButton: {
    marginTop: 20,
    backgroundColor: "white",
    paddingVertical: 14,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 2,
  },
  claimText: {
    fontFamily: fonts.bold,
    letterSpacing: 3,
    fontSize: 11,
  },
  noteText: {
    fontFamily: fonts.semiBold,
    color: "#E0E0E0",
    marginTop: 15,
    fontSize: 12,
  },
  historyContainer: {
    marginTop: 40,
    paddingVertical: 25,
  },
  historyHeaderContainer: {
    backgroundColor: colors.backOverlay,
    paddingVertical: 25,
    justifyContent: "center",
    paddingHorizontal: 25,
    borderRadius: 2.5,
    marginBottom: 20,
  },
  historyHeader: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 25,
  },
  claimContainer: {
    marginBottom: 25,
  },
  claimContent: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  divider: {
    borderBottomColor: "#303030",
    borderBottomWidth: 0.5,
    marginTop: 15,
  },
  serial: {
    fontFamily: fonts.expandedBlack,
    color: "white",
    fontSize: 14,
    marginRight: 5,
  },
  totalSerial: {
    fontFamily: fonts.expandedBold,
    color: "grey",
    fontSize: 12,
  },
  price: {
    fontFamily: fonts.semiBold,
    color: "white",
  },
  buyerAlias: {
    fontFamily: fonts.semiBold,
    color: "white",
  },
  date: {
    fontFamily: fonts.semiBold,
    color: "grey",
  },
});

export default CollectibleTab;
