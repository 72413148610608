import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import TopBar from "../../components/TopBar";
import FeedView from "./FeedView/FeedView";
import Footer from "../../components/Footer";
import StreamerPending from "./StreamerPending";
import ContractModal from "../../components/ContractModal";
import { Context as UserContext } from "../../context/UserContext";
import { Context as StreamerContext } from "../../context/StreamerContext";
import {
  desktop as ContractDesktop,
  tablet as ContractTablet,
  mobile as ContractMobile,
} from "../../components/ContractModalStyle";
import { useMediaQuery } from "react-responsive";
import { AntDesign } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";

const HomeScreen = ({ navigation }) => {
  //const [sentToMix, setSentToMix] = useState(false);
  const [isContractOpen, setIsContractOpen] = useState(false);
  const {
    state: { streamerProfile, cardPortfolio },
  } = useContext(UserContext);
  const {
    state: { navigatedStreamer },
  } = useContext(StreamerContext);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  //const isDesktop = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    if (
      navigatedStreamer &&
      navigatedStreamer != null &&
      navigatedStreamer != ""
    ) {
      navigation.navigate("StreamerProfile", { tag: navigatedStreamer });
    }
  }, []);

  useEffect(() => {
    console.log("RELOADED PAGE ********");
  }, []);

  const streamerItems = () => (
    <>
      {streamerProfile != null && streamerProfile.status < 1 ? (
        <TouchableOpacity
          style={styles.streamerButton}
          onPress={() => navigation.navigate("Dashboard")}
        >
          <Text
            style={{
              fontFamily: fonts.semiBold,
              fontSize: 16,
              color: "white",
            }}
          >
            <Text style={{ color: colors.green }}>Hey, talented creator!</Text>{" "}
            Check out your new streamer dashboard{" "}
          </Text>
          <Ionicons
            name="ios-arrow-forward"
            size={24}
            color="white"
            style={{ marginLeft: 10 }}
          />
        </TouchableOpacity>
      ) : null}
      {streamerProfile != null &&
      streamerProfile.status == 1 &&
      (streamerProfile.legalStatus == undefined ||
        streamerProfile.legalStatus == 0) ? (
        <ContractModal
          isModalVisible={true}
          setIsModalVisible={setIsContractOpen}
          styles={
            isMobile
              ? ContractMobile(window)
              : isTabletOrMobile
              ? ContractTablet(window)
              : ContractDesktop(window)
          }
        />
      ) : null}
    </>
  );

  return (
    <>
      <TopBar selected={0} />
      <ScrollView
        contentContainerStyle={[styles.container, { width: window.width }]}
      >
        <View
          style={[
            styles.innerContent,
            {
              width: isMobile || isTabletOrMobile ? 0.95 * window.width : 1024,
              paddingVertical: isMobile ? 20 : 50,
            },
          ]}
        >
          {streamerItems()}

          <FeedView />
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "black",
    borderWidth: 0,
  },
  innerContent: {
    alignSelf: "center",
    borderWidth: 0,
  },
  sectionHeader: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
    marginBottom: 40,
  },
  aboutButton: {
    alignSelf: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.backOverlay,
    borderRadius: 50,
    paddingHorizontal: 10,
    paddingVertical: 8,
  },
  newContainer: {
    backgroundColor: colors.green,
    borderRadius: 50,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginRight: 15,
  },
  newText: {
    fontFamily: fonts.expandedBold,
    color: "black",
  },
  aboutText: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 16,
  },
  streamerButton: {
    alignSelf: "flex-start",
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: "#303030",
    borderRadius: 50,
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 25,
  },
});

export default HomeScreen;
