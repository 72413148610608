import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { Context as AuthContext } from "../../context/AuthContext";
import { useMediaQuery } from "react-responsive";
import Terminal, { ColorMode, LineType } from "react-terminal-ui";
import { ActivityIndicator } from "react-native";
import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider";
import { range } from "lodash";

import "./terminalStyle.css";

const spacerComp = {
  type: LineType.Output,
  value: (
    <View style={{ marginTop: -25, marginBottom: 10 }}>
      <Text style={{ color: "white" }}>. . . . .</Text>
    </View>
  ),
};

const TerminalScreen = ({ navigation }) => {
  const {
    state: { token },
  } = useContext(AuthContext);
  const [loadingText, setLoadingText] = useState(0);
  const [terminalLineData, setTerminalLineData] = useState([
    {
      type: LineType.Output,
      value:
        "Welcome to Buffd, we're glad you found us. Join us as we bring NFTs to the billions of gamers worldwide. Let's disrupt some shit, together.",
    },
    spacerComp,
    {
      type: LineType.Output,
      value: "We're currently in closed beta.",
    },
    {
      type: LineType.Output,
      value: "Type !commands at any time to see your options. Have fun.",
    },
  ]);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const marqueeSpeed = isMobile ? 1 : isTabletOrMobile ? 2.5 : 5;

  const addToData = (input) => {
    switch (`${input.toLowerCase()}`) {
      case "!commands":
        return setTerminalLineData([
          ...terminalLineData,
          {
            type: LineType.Input,
            value: input,
          },
          spacerComp,
          {
            type: LineType.Output,
            value: "!manifesto - See why we do what we do",
          },
          {
            type: LineType.Output,
            value: "!access - Use your access code",
          },
          {
            type: LineType.Output,
            value: "!waitlist - Get your email on file",
          },
          {
            type: LineType.Output,
            value: "!login - Already have an account?",
          },
          {
            type: LineType.Output,
            value: "!help - I guess there's always that one person",
          },
          spacerComp,
        ]);
      case "!manifesto":
        return navigation.navigate("About");
      case "!login":
        return navigation.navigate("SignIn");
      case "!access":
        return navigation.navigate("Activate");
      case "!waitlist":
        return navigation.navigate("Activate");
      case "!help":
        return setTerminalLineData([
          ...terminalLineData,
          {
            type: LineType.Input,
            value: input,
          },
          {
            type: LineType.Output,
            value:
              "Couldn't figure this out, huh? Email support@buffd.gg. We'll help you out :)",
          },
        ]);
      case "clear":
        return setTerminalLineData([]);
      default:
        return setTerminalLineData([
          ...terminalLineData,
          {
            type: LineType.Input,
            value: input,
          },
          {
            type: LineType.Output,
            value: "bash: command not found",
          },
        ]);
    }
  };

  if (isMobile) {
    return (
      <View style={styles.container}>
        <View style={{ marginBottom: isMobile ? 15 : 50 }}>
          <Marquee
            velocity={marqueeSpeed}
            style={{ alignItems: "space-between" }}
          >
            {range(30).map((item, index) => {
              return (
                <View
                  style={{
                    width: 250,
                    marginHorizontal: 40,
                    paddingVertical: 20,
                  }}
                  key={`${index}`}
                >
                  <Text style={styles.tickerText}># GRANTED ACCESS: 2,756</Text>
                </View>
              );
            })}
          </Marquee>
        </View>
        <View
          style={[
            styles.innerContent,
            {
              width: 0.95 * window.width,
            },
          ]}
        >
          <div className="container">
            <Terminal
              name="Activation"
              colorMode={ColorMode.Dark}
              lineData={terminalLineData}
              onInput={(terminalInput) => {
                console.log(`New terminal input received: '${terminalInput}'`);
                addToData(terminalInput);
              }}
            />
          </div>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View
        style={{
          marginBottom: isMobile ? 15 : 30,
          height: 50,
        }}
      >
        <Marquee
          velocity={marqueeSpeed}
          style={{ alignItems: "space-between" }}
        >
          {range(30).map((item, index) => {
            return (
              <View
                style={{
                  width: 250,
                  marginHorizontal: 40,
                  paddingVertical: 20,
                }}
                key={`${index}`}
              >
                <Text style={styles.tickerText}># GRANTED ACCESS: 2,756</Text>
              </View>
            );
          })}
        </Marquee>
      </View>
      <View
        style={[
          styles.innerContent,
          {
            width: isTabletOrMobile ? 0.95 * window.width : 1024,
          },
        ]}
      >
        <div className="container">
          <Terminal
            name="Activation"
            colorMode={ColorMode.Dark}
            lineData={terminalLineData}
            onInput={(terminalInput) => {
              console.log(`New terminal input received: '${terminalInput}'`);
              addToData(terminalInput);
            }}
          />
        </div>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "black",
    flexGrow: 1,
  },
  innerContent: {
    marginHorizontal: 15,
    alignSelf: "center",
  },
  heroContent: {
    width: 424,
  },
  terminalContent: {
    width: "100%",
  },
  title: {
    fontFamily: fonts.semiBold,
    fontSize: 80,
    marginBottom: 25,
    color: "white",
  },
  subHeader: {
    fontFamily: fonts.text,
    fontSize: 24,
    lineHeight: 35,
    color: "#D8D8D8",
    marginBottom: 40,
  },
  body: {
    fontFamily: fonts.text,
    fontSize: 18,
    lineHeight: 28,
    color: "#D8D8D8",
    marginVertical: 20,
  },
  button: {
    alignSelf: "flex-start",
    backgroundColor: colors.backOverlay,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 35,
    paddingVertical: 12,
    borderRadius: 5,
    marginTop: 25,
  },
  buttonText: {
    fontFamily: fonts.text,
    color: "black",
    fontSize: 18,
  },
  logInText: {
    fontFamily: fonts.text,
    color: colors.green,
  },
  tickerText: {
    color: "grey",
    fontFamily: fonts.expandedBold,
  },
});

export default TerminalScreen;
