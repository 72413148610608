import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import moment from "moment";
import ClipPlayer from "../../components/ClipPlayer";
import ReactPlayer from "../../components/ReactPlayer";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../RootNavigation";

const RenderReactGrid = ({ item }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <View>
      <View style={styles.clipContainer}>
        <ClipPlayer
          _id={`streamerProfileClip-0`}
          feed={false}
          playbackId={item.clipId}
        />
      </View>
      <Text style={styles.reactName}>{item.reactName}</Text>
      <View style={styles.reactOuterContainer}>
        <View style={styles.reactContainer}>
          <ReactPlayer
            _id={`streamerProfileReact-0`}
            playbackId={item.reactId}
          />
        </View>
        <View style={styles.reactDetails}>
          <View style={styles.detailContainer}>
            <Text
              style={[
                styles.label,
                {
                  color:
                    item.claimed.length == item.total ? "red" : colors.green,
                },
              ]}
            >
              {item.claimed.length == item.total
                ? "SOLD OUT"
                : `${item.unclaimed.length} LEFT`}
            </Text>
            <View
              style={{
                backgroundColor:
                  item.claimed.length == item.total ? "red" : colors.green,
                height: 10,
                width: 10,
                borderRadius: 25,
              }}
            />
          </View>
          <View style={styles.detailContainer}>
            <Text style={styles.label}>QUANTITY</Text>
            <Text style={styles.value}>{item.total}</Text>
          </View>
          <View style={styles.detailContainer}>
            <Text style={styles.label}>OG PRICE</Text>
            <Text style={styles.value}>${item.reserve.toFixed(2)}</Text>
          </View>
          <View style={styles.detailContainer}>
            <Text style={styles.label}>DROP</Text>
            <Text style={styles.value}>
              {moment(item.dropDate).format("l")}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  clipContainer: {
    alignSelf: "flex-start",
    width: "100%",
    marginBottom: 15,
  },
  reactOuterContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexWrap: "wrap",
  },
  reactName: {
    fontFamily: fonts.semiBold,
    color: "white",
    fontSize: 22,
    marginHorizontal: 10,
    marginBottom: 30,
  },
  reactContainer: {
    backgroundColor: "red",
    width: 115,
    height: 115,
  },
  reactDetails: {
    width: 130,
    marginHorizontal: 10,
  },
  detailContainer: {
    alignSelf: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    flexWrap: "wrap",
    width: "100%",
  },
  label: {
    fontFamily: fonts.semiBold,
    color: "grey",
    fontSize: 12,
  },
  value: {
    fontFamily: fonts.bold,
    color: "white",
    fontSize: 18,
  },
});

export default RenderReactGrid;
