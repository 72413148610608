import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import fonts from "../res/fonts";

const StreamerGifCard = ({
  cardDesign,
  gifLink,
  width,
  height,
  mintNumber,
}) => {
  // width:height is 360:504

  const styles = {
    laminate: {
      position: "absolute",
      zIndex: 100000,
      width: width,
      height: height,
    },
    mintContainer: {
      position: "absolute",
      zIndex: 5000000,
      left: 0.03 * width,
      bottom: 0.31 * height,
      transform: [{ rotate: "-8deg" }],
    },
    mintNumber: {
      fontFamily: fonts.expandedBlack,
      fontSize: 0.03 * width,
      color: "white",
    },
    totalNumber: {
      fontFamily: fonts.expandedBold,
      fontSize: 0.04 * width,
      color: "white",
      marginTop: 0,
    },
    date: {
      position: "absolute",
      zIndex: 50000,
      right: 0.05 * width,
      bottom: 0.032 * height,
      color: "white",
      fontFamily: fonts.medium,
      fontSize: 0.04 * width,
    },
  };

  return (
    <View>
      <View style={styles.laminate} />
      <View style={{ width: width, height: height }}>
        <Image
          style={{
            width: width,
            height: 0.75 * height,
            resizeMode: "cover",
            borderRadius: 10,
          }}
          source={{
            uri: gifLink,
          }}
        />
      </View>
      <Image
        style={{
          width: width,
          height: height,
          resizeMode: "cover",
          borderRadius: 10,
          position: "absolute",
          zIndex: 100000,
          width: width,
          height: height,
        }}
        source={{
          uri: cardDesign,
        }}
      />
      <View style={styles.mintContainer}>
        <Text style={styles.mintNumber}>{mintNumber}/10</Text>
      </View>
    </View>
  );
};

export default StreamerGifCard;
