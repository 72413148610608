import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import _ from "lodash";

const emailReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const sendFeedback = (dispatch) => async ({ name, email, text, id, alias }) => {
  try {
    console.log("SENDING FEEDBACK");
    const response = await serverApi.post("/email", {
      name,
      email,
      text,
      id,
      alias,
    });
  } catch (err) {
    console.log("sendFeedback Error");
  }
};

export const { Provider, Context } = createDataContext(
  emailReducer,
  {
    sendFeedback,
  },
  {}
);
