import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import TopBar from "../../components/TopBar";
import VideoPlayer from "./VideoPlayer";
import SideVideo from "./SideVideo";
import { useMediaQuery } from "react-responsive";
import { Context as TwitchContext } from "../../context/TwitchContext";
import { Mixpanel } from "../../api/mixPanel";
import fonts from "../../res/fonts";
import EmptyMiningImage from "../../../assets/images/EmptyMiningImage";

const MiningScreen = ({ navigation }) => {
  const {
    fetchStreams,
    clearState,
    state: { streams, isStreamsLoaded, currentStream },
  } = useContext(TwitchContext);
  const [isLoaded, setIsLoaded] = useState(false);
  //const [sentToMix, setSentToMix] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const window = useWindowDimensions();

  useEffect(() => {
    async function loadHome() {
      if (!isStreamsLoaded) {
        console.log("STREAMS NOT LOADED");
        await fetchStreams();
        setIsLoaded(true);
      }
    }
    loadHome();
  }, []);

  return (
    <>
      <TopBar selected={1} />
      <ScrollView
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
      >
        {!streams || !isStreamsLoaded ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator color="grey" size={50} />
            <Text style={styles.loadingText}>finding streams for you</Text>
          </View>
        ) : streams.length == 0 ? (
          <View style={{ marginTop: 50 }}>
            <EmptyMiningImage
              width={window.width}
              height={0.35 * window.height}
              style={{
                alignSelf: "center",
                marginBottom: 50,
              }}
            />
            <Text style={[styles.loadingText, { alignSelf: "center" }]}>
              Unfortunately, no Buffd streamers are currently live. Check back
              soon!
            </Text>
          </View>
        ) : currentStream ? (
          <>
            <View
              style={{
                marginTop: 20,
                marginBottom: 50,
                width: isTabletOrMobile ? 0.92 * window.width + 15 : "100%",
              }}
            >
              <Text
                style={[
                  styles.header,
                  { fontSize: Math.max(0.035 * window.width, 25) },
                ]}
              >
                BUFFD STREAMERS LIVE
              </Text>
              <Text
                style={[
                  styles.subHeader,
                  { fontSize: Math.max(0.015 * window.width, 16) },
                ]}
              >
                Explore streamers on Buffd that are live right now. If you like
                what you see, consider adding them to your portfolio.
              </Text>
            </View>
            <View
              style={{ flexDirection: isTabletOrMobile ? "column" : "row" }}
            >
              <VideoPlayer />
              <SideVideo />
            </View>
          </>
        ) : null}
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "black",
  },
  contentContainer: {
    height: "100%",
    paddingTop: "2.5%",
    borderWidth: 2,
    marginBottom: 200,
    paddingHorizontal: "5%",
    alignItems: "center",
  },
  header: {
    fontFamily: fonts.expandedBlack,
    color: "white",
    fontSize: 50,
    alignSelf: "flex-start",
  },
  subHeader: {
    marginTop: 20,
    fontFamily: fonts.semiBold,
    color: "grey",
    fontSize: 18,
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 50,
  },
  loadingText: {
    fontFamily: fonts.semiBold,
    color: "white",
    fontSize: 20,
    marginTop: 25,
  },
  backButton: {
    width: 200,
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 5,
    backgroundColor: colors.subBackground,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  backText: {
    fontFamily: fonts.bold,
    color: "white",
    fontSize: 16,
  },
});

export default MiningScreen;
