import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { LinearGradient } from "expo-linear-gradient";
import { MaterialIcons } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import { Context as TwitchContext } from "../../context/TwitchContext";
import { Mixpanel } from "../../api/mixPanel";
import * as RootNavigation from "../../RootNavigation";
import { toast } from "react-toastify";

const SideVideo = () => {
  const {
    fetchNextStream,
    state: { currentStream, remainingStreams },
  } = useContext(TwitchContext);
  const [rating, setRating] = useState(-1);
  const [fetchingNextStream, setFetchingNextStream] = useState(false);
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });
  const window = useWindowDimensions();

  const claimStreamer = () => {
    Mixpanel.track("Page View", {
      pageName: "StreamerProfile",
      streamerAlias: currentStream.alias,
    });
    RootNavigation.navigate("StreamerProfile", { tag: currentStream.alias });
  };

  const pressNextStream = async () => {
    setFetchingNextStream(true);
    setRating(-1);
    if (remainingStreams.length > 0) {
      await fetchNextStream(remainingStreams);
    } else {
      console.log("No more streams to watch");
      toast.error("We ran out of streams! Check back soon.");
    }
    setFetchingNextStream(false);
    Mixpanel.track("Mine Next Stream");
  };

  if (isMobile) {
    return (
      <View
        style={{
          width: 0.8 * window.width + 50,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 200,
        }}
      >
        <View style={{ width: "100%" }}>
          <Text style={styles.Header}>@{currentStream.user_name}</Text>
          <Text style={styles.descText}>{currentStream.title}</Text>
          <Text style={styles.descText}>
            {currentStream.viewer_count} Viewers
          </Text>
        </View>

        <View style={{ width: "100%", marginTop: 30 }}>
          <Text style={styles.rateText}>
            What do you think of {currentStream.user_name}?
          </Text>
          <Text style={styles.rateSub}>We'll tailor your experience</Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <TouchableOpacity
              style={
                rating == 0 ? styles.rateButtonSelected : styles.rateButton
              }
              onPress={() => {
                if (rating == 0) {
                  setRating(-1);
                  Mixpanel.track("Undisliked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                } else {
                  setRating(0);
                  toast.warn("Understood. We'll learn from that.");
                  Mixpanel.track("Disliked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                }
              }}
            >
              <Text style={{ fontSize: 35 }}>💩</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={
                rating == 1 ? styles.rateButtonSelected : styles.rateButton
              }
              onPress={() => {
                if (rating == 1) {
                  setRating(-1);
                  Mixpanel.track("Unliked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                } else {
                  setRating(1);
                  toast.success("We'll show you more just like this.");
                  Mixpanel.track("Liked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                }
              }}
            >
              <Text style={{ fontSize: 35 }}>🔥</Text>
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity
              style={styles.claimButtonMobile}
              onPress={() => {
                claimStreamer();
              }}
            >
              {fetchingNextStream ? (
                <ActivityIndicator />
              ) : (
                <>
                  <Text style={styles.claimText}>Claim Streamer Card</Text>
                  <MaterialIcons name="navigate-next" size={24} color="black" />
                </>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.nextButtonMobile}
              onPress={() => {
                pressNextStream();
              }}
            >
              {fetchingNextStream ? (
                <ActivityIndicator />
              ) : (
                <>
                  <Text style={styles.nextText}>Next Stream</Text>
                  <MaterialIcons name="navigate-next" size={24} color="white" />
                </>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={{ marginTop: 20 }}
              onPress={() => {
                Mixpanel.track("Page View", { pageName: "Contact" });
                RootNavigation.navigate("Contact");
              }}
            >
              <Text style={styles.feedbackText}>
                What do you think of Buffd?
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  } else if (isTabletOrMobile) {
    return (
      <View
        style={{
          width: 0.8 * window.width + 50,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 200,
        }}
      >
        <View style={{ width: "45%" }}>
          <Text style={styles.Header}>@{currentStream.user_name}</Text>
          <Text style={styles.descText}>{currentStream.title}</Text>
          <Text style={styles.descText}>
            {currentStream.viewer_count} Viewers
          </Text>
        </View>

        <View style={{ width: "45%" }}>
          <Text style={styles.rateText}>
            What do you think of {currentStream.user_name}?
          </Text>
          <Text style={styles.rateSub}>We'll tailor your experience</Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <TouchableOpacity
              style={
                rating == 0 ? styles.rateButtonSelected : styles.rateButton
              }
              onPress={() => {
                if (rating == 0) {
                  setRating(-1);
                  Mixpanel.track("Undisliked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                } else {
                  setRating(0);
                  toast.warn("Understood. We'll learn from that.");
                  Mixpanel.track("Disliked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                }
              }}
            >
              <Text style={{ fontSize: 35 }}>💩</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={
                rating == 1 ? styles.rateButtonSelected : styles.rateButton
              }
              onPress={() => {
                if (rating == 1) {
                  setRating(-1);
                  Mixpanel.track("Unliked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                } else {
                  setRating(1);
                  toast.success("We'll show you more just like this.");
                  Mixpanel.track("Liked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                }
              }}
            >
              <Text style={{ fontSize: 35 }}>🔥</Text>
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity
              style={styles.claimButtonMobile}
              onPress={() => {
                claimStreamer();
              }}
            >
              {fetchingNextStream ? (
                <ActivityIndicator />
              ) : (
                <>
                  <Text style={styles.claimText}>Claim Streamer Card</Text>
                  <MaterialIcons name="navigate-next" size={24} color="black" />
                </>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.nextButtonMobile}
              onPress={() => {
                pressNextStream();
              }}
            >
              {fetchingNextStream ? (
                <ActivityIndicator />
              ) : (
                <>
                  <Text style={styles.nextText}>Next Stream</Text>
                  <MaterialIcons name="navigate-next" size={24} color="white" />
                </>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={{ marginTop: 20 }}
              onPress={() => RootNavigation.navigate("Contact")}
            >
              <Text style={styles.feedbackText}>
                What do you think of Buffd?
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <View
        style={{
          width: 0.3 * window.width - 50,
          height: (1 / (16 / 9)) * (0.3 * window.width) - 50,
          paddingHorizontal: 50,
        }}
      >
        <View style={{ marginBottom: 40 }}>
          <Text style={styles.Header}>@{currentStream.user_name}</Text>
          <Text style={styles.descText}>{currentStream.title}</Text>
          <Text style={styles.descText}>
            {currentStream.viewer_count} Viewers
          </Text>
        </View>
        <View>
          <Text style={styles.rateText}>
            What do you think of {currentStream.user_name}?
          </Text>
          <Text style={styles.rateSub}>
            We'll customize your experience based on your answer
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <TouchableOpacity
              style={
                rating == 0 ? styles.rateButtonSelected : styles.rateButton
              }
              onPress={() => {
                if (rating == 0) {
                  setRating(-1);
                  Mixpanel.track("Undisliked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                } else {
                  setRating(0);
                  toast.warn("Understood. We'll learn from that.");
                  Mixpanel.track("Disliked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                }
              }}
            >
              <Text style={{ fontSize: 35 }}>💩</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={
                rating == 1 ? styles.rateButtonSelected : styles.rateButton
              }
              onPress={() => {
                if (rating == 1) {
                  setRating(-1);
                  Mixpanel.track("Unliked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                } else {
                  setRating(1);
                  toast.success("We'll show you more just like this.");
                  Mixpanel.track("Liked Stream", {
                    Streamer_Name: currentStream.alias,
                  });
                }
              }}
            >
              <Text style={{ fontSize: 35 }}>🔥</Text>
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity
              style={styles.claimButton}
              onPress={() => {
                claimStreamer();
              }}
            >
              {fetchingNextStream ? (
                <ActivityIndicator />
              ) : (
                <>
                  <Text style={styles.claimText}>Claim Streamer Card</Text>
                  <MaterialIcons name="navigate-next" size={24} color="black" />
                </>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.nextButton}
              onPress={() => {
                pressNextStream();
              }}
            >
              {fetchingNextStream ? (
                <ActivityIndicator />
              ) : (
                <>
                  <Text style={styles.nextText}>Next Stream</Text>
                  <MaterialIcons name="navigate-next" size={24} color="white" />
                </>
              )}
            </TouchableOpacity>
            <TouchableOpacity
              style={{ marginTop: 20 }}
              onPress={() => {
                Mixpanel.track("Page View", { pageName: "Contact" });
                RootNavigation.navigate("Contact");
              }}
            >
              <Text style={styles.feedbackText}>
                What do you think of Buffd?
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  Header: {
    fontFamily: fonts.heavy,
    color: "white",
    fontSize: 30,
    marginBottom: 20,
  },
  descText: {
    fontFamily: fonts.semiBold,
    color: colors.greyText,
    fontSize: 20,
  },
  rateText: {
    fontFamily: fonts.semiBold,
    color: "white",
    fontSize: 20,
  },
  rateSub: {
    fontFamily: fonts.greyText,
    color: "white",
    fontSize: 12,
    paddingTop: 10,
  },
  rateButton: {
    width: "45%",
    height: 60,
    borderRadius: 10,
    backgroundColor: colors.subBackgroundDark,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 3,
    borderColor: colors.subBackgroundDark,
  },
  rateButtonSelected: {
    width: "45%",
    height: 60,
    borderRadius: 10,
    backgroundColor: colors.subBackgroundDark,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 3,
    borderColor: colors.green,
  },
  claimButton: {
    width: "100%",
    paddingVertical: 10,
    backgroundColor: colors.subBackground,
    borderRadius: 10,
    marginTop: 50,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  nextButton: {
    width: "100%",
    paddingVertical: 10,
    backgroundColor: colors.subBackgroundDark,
    borderRadius: 10,
    marginTop: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  nextButtonMobile: {
    width: "100%",
    paddingVertical: 10,
    backgroundColor: colors.subBackgroundDark,
    borderRadius: 10,
    marginTop: 15,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  claimButtonMobile: {
    width: "100%",
    paddingVertical: 10,
    backgroundColor: colors.subBackground,
    borderRadius: 10,
    marginTop: 25,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  nextText: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 14,
  },
  claimText: {
    fontFamily: fonts.expandedBold,
    color: "black",
    fontSize: 14,
  },
  feedbackText: {
    fontFamily: fonts.regular,
    color: colors.green,
    fontSize: 12,
  },
});

export default SideVideo;
