import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  FlatList,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { Input } from "react-native-elements";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { Context as UserContext } from "../../context/UserContext";

const StreamerDetails = () => {
  const {
    updateStreamerProfile,
    state: { isUserInfoLoaded, streamerProfile },
  } = useContext(UserContext);
  const [bio, setBio] = useState(streamerProfile.info.bio);
  const [bioHeight, setBioHeight] = useState("");
  const [twitchLink, setTwitchLink] = useState(streamerProfile.twitchLink);
  const [instagramLink, setInstagramLink] = useState(
    streamerProfile.instagramLink
  );
  const [youTubeLink, setYouTubeLink] = useState(streamerProfile.youTubeLink);
  const [tikTokLink, setTikTokLink] = useState(streamerProfile.tikTokLink);
  const [twitterLink, setTwitterLink] = useState(streamerProfile.twitterLink);
  const [isLoading, setIsLoading] = useState(false);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const onSave = async () => {
    if (!isLoading) {
      setIsLoading(true);
      await updateStreamerProfile({
        bio,
        twitchLink,
        instagramLink,
        youTubeLink,
        tikTokLink,
        twitterLink,
        streamerId: streamerProfile._id,
      });
      toast.success("Profile updated successfully");
      setIsLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <View style={{ flexShrink: 1 }}>
        <Text style={styles.header}>Streamer Bio Details</Text>
        <Text style={styles.subHeader}>
          This information will be public on your Buffd streamer page
        </Text>
      </View>
      <View style={styles.divider} />
      <View
        style={{
          flexDirection: isTabletOrMobile ? "column" : "row",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            width: isTabletOrMobile ? "100%" : 450,
          }}
        >
          <Input
            label="Short Bio"
            style={{ height: Math.max(35, bioHeight) }}
            onContentSizeChange={(event) => {
              setBioHeight(event.nativeEvent.contentSize.height);
            }}
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainerStyle}
            placeholderTextColor="grey"
            inputContainerStyle={[styles.inputStyle, {}]}
            value={bio}
            multiline={true}
            inputStyle={styles.inputTextStyle}
            onChangeText={(newBio) => setBio(newBio)}
          />
          <Input
            label="Twitch Link"
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainerStyle}
            placeholderTextColor="grey"
            inputContainerStyle={styles.inputStyle}
            value={twitchLink}
            inputStyle={styles.inputTextStyle}
            onChangeText={(newLink) => setTwitchLink(newLink)}
          />
          <Input
            label="Instagram Link"
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainerStyle}
            placeholderTextColor="grey"
            inputContainerStyle={styles.inputStyle}
            value={instagramLink}
            inputStyle={styles.inputTextStyle}
            onChangeText={(newLink) => setInstagramLink(newLink)}
          />
        </View>
        <View style={{ width: isTabletOrMobile ? "100%" : 450 }}>
          <Input
            label="YouTube Link"
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainerStyle}
            placeholderTextColor="grey"
            inputContainerStyle={styles.inputStyle}
            value={youTubeLink}
            inputStyle={styles.inputTextStyle}
            onChangeText={(newLink) => setYouTubeLink(newLink)}
          />
          <Input
            label="TikTok Link"
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainerStyle}
            placeholderTextColor="grey"
            inputContainerStyle={styles.inputStyle}
            value={tikTokLink}
            inputStyle={styles.inputTextStyle}
            onChangeText={(newLink) => setTikTokLink(newLink)}
          />
          <Input
            label="Twitter Link"
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainerStyle}
            placeholderTextColor="grey"
            inputContainerStyle={styles.inputStyle}
            value={twitterLink}
            inputStyle={styles.inputTextStyle}
            onChangeText={(newLink) => setTwitterLink(newLink)}
          />
        </View>
      </View>
      <TouchableOpacity style={styles.submitButton} onPress={() => onSave()}>
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <Text style={styles.submitButtonText}>Save All Bio Details</Text>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    backgroundColor: colors.backOverlay,
    padding: 25,
    flexShrink: 1,
  },
  header: {
    fontFamily: fonts.text,
    fontSize: 25,
    color: "white",
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: fonts.text,
    fontSize: 18,
    color: "#A9A9A9",
    marginBottom: 15,
  },
  divider: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: "#181818",
    marginBottom: 30,
  },
  labelStyle: {
    fontFamily: fonts.text,
    fontSize: 14,
    marginBottom: 10,
    color: "grey",
  },
  inputContainerStyle: {
    paddingHorizontal: 20,
    marginVertical: 5,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 25,
    paddingVertical: 5,
  },
  inputTextStyle: {
    fontFamily: fonts.text,
    color: "white",
    fontSize: 14,
    outlineWidth: 0,
  },
  submitButton: {
    marginTop: 40,
    backgroundColor: colors.green,
    width: "50%",
    paddingVertical: 12,
    borderRadius: 5,
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "center",
  },
  submitButtonText: {
    fontFamily: fonts.semiBold,
    fontSize: 16,
  },
});

export default StreamerDetails;
