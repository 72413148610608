import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Dimensions,
  Image,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { AntDesign } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../../RootNavigation";

const CardChart = ({ item, index }) => {
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.iContainer}>
        <AntDesign
          name="Trophy"
          size={100}
          color={colors.green}
          style={{ bottom: 0, right: 10, position: "absolute", opacity: 0.1 }}
        />
        <Text style={styles.header}>Marketplace</Text>
        <View style={styles.comingSoonCont}>
          <Text style={styles.comingSoonText}>COMING SOON</Text>
        </View>
      </TouchableOpacity>
      <View style={styles.iContainer}>
        <AntDesign
          name="notification"
          size={100}
          color={colors.green}
          style={{ bottom: 0, right: 10, position: "absolute", opacity: 0.1 }}
        />
        <Text style={styles.header}>List Cards for Auction</Text>
        <Text
          style={{
            color: "white",
            fontFamily: fonts.text,
            fontSize: 14,
          }}
        >
          Select cards of streamers who have sold out of their first edition
          below to enter the marketplace queue.
        </Text>
      </View>
      {/* <TouchableOpacity
        onPress={() => RootNavigation.navigate("Pack")}
        style={styles.iContainer}
      >
        <AntDesign
          name="tagso"
          size={100}
          color={colors.green}
          style={{ bottom: 0, right: 10, position: "absolute", opacity: 0.1 }}
        />
        <Text style={styles.header}>Purchase Packs</Text>
        <View style={styles.comingSoonCont}>
          <Text style={styles.comingSoonText}>FIRST EDITION</Text>
        </View>
      </TouchableOpacity> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iContainer: {
    width: "30%",
    backgroundColor: "rgb(16, 16, 16)",
    padding: 20,
    borderRadius: 10,
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 20,
    color: "white",
    marginBottom: 30,
  },
  comingSoonCont: {
    backgroundColor: colors.green,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
    paddingVertical: 8,
    paddingHorizontal: 18,
  },
  comingSoonText: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
  },
});

export default CardChart;
