import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Image,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import ClipPlayer from "../../../components/ClipPlayer";
import ReactPlayer from "../../../components/ReactPlayer";
import ClipModal from "./ClipModal";
import { Context as ReactContext } from "../../../context/ReactContext";
import moment from "moment";
import Rating from "react-rating";
import { FontAwesome } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../../RootNavigation";

const RenderClip = ({ item, index, playLock, setPlayLock, styles }) => {
  const { rateReact } = useContext(ReactContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isScouting, setIsScouting] = useState(
    item.dropDate ? moment().isBefore(moment(item.dropDate)) : true
  );
  const [timeRemaining, setTimeRemaining] = useState(" -- : -- : -- ");
  const [rating, setRating] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (isScouting == false) clearInterval(timeInterval);
      const now = moment();
      const duration = moment.duration(moment(item.dropDate).diff(now));
      if (duration <= 0) {
        clearInterval(timeInterval);
        setTimeRemaining("00:00:00");
        setIsScouting(false);
      } else {
        setTimeRemaining(
          moment.utc(duration.asMilliseconds()).format("HH:mm:ss")
        );
      }
    }, 1000);
    return () => clearInterval(timeInterval);
  }, []);

  const ratingChanged = async (newRating) => {
    if (!isLoading) {
      setIsLoading(true);
      await rateReact({ poolId: item._id, score: parseInt(newRating) });
      setIsLoading(false);
    }
  };

  const truncateText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return text.substr(0, length) + "\u2026";
  };

  const availableListings = item.listings.filter((item) => item.status == 0);
  const type =
    item.claimed.length < item.total ? 0 : availableListings.length > 0 ? 1 : 2;

  const clipWidth = isMobile ? 0.95 * window.width : 600;

  return (
    <>
      {isScouting ? (
        <View style={styles.scoutingNotification}>
          <Text style={styles.scoutingText}>SCOUTING: {timeRemaining}</Text>
        </View>
      ) : null}
      <View style={styles.container}>
        <View style={[styles.clipContainer, { width: clipWidth }]}>
          <ClipPlayer
            _id={item._id}
            playLock={playLock}
            setPlayLock={setPlayLock}
            feed={true}
            playbackId={item.clipId}
            width={clipWidth}
          />
          <TouchableOpacity
            style={styles.streamerBio}
            onPress={() =>
              RootNavigation.navigate("StreamerProfile", {
                tag: item.streamerId.alias,
              })
            }
          >
            <Image
              style={styles.streamerImage}
              source={{ uri: item.streamerId.profilePic }}
            />
            <View style={{ flexShrink: 1 }}>
              <Text style={styles.header}>{item.streamerId.alias}</Text>
              <Text style={styles.bioText}>
                {truncateText(item.streamerId.info.bio, 100)}{" "}
                <Text style={styles.learnMoreText}>Learn more</Text>
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={styles.streamerSide}>
          <Text style={styles.clipName}>{item.reactName}</Text>
          <View style={styles.cardContainer}>
            <View style={styles.reactContainer}>
              <ReactPlayer _id={index} feed={true} playbackId={item.reactId} />
            </View>

            <View style={{ marginLeft: 10 }}>
              <View style={styles.detailContainer}>
                <Text style={styles.label}>DROP DATE</Text>
                <Text style={styles.value}>
                  {moment(item.dropDate).format("l")}
                </Text>
              </View>

              <View style={styles.detailContainer}>
                <Text style={styles.label}>SERIAL</Text>
                <Text style={styles.value}>#/{item.total}</Text>
              </View>

              <View style={styles.detailContainer}>
                <Text style={styles.label}>OG PRICE</Text>
                <Text style={styles.value}>${item.reserve.toFixed(2)}</Text>
              </View>
            </View>
          </View>

          <View style={styles.ratingBox}>
            <View>
              <Text style={styles.ratingLabel}>REACT RATING</Text>

              {isLoading ? (
                <ActivityIndicator color="white" />
              ) : !item.myRating || !item.myRating.score ? (
                <Rating
                  emptySymbol={
                    <FontAwesome
                      name="star-o"
                      size={24}
                      color="white"
                      style={{ marginHorizontal: 2.5 }}
                    />
                  }
                  fullSymbol={
                    <FontAwesome
                      name="star"
                      size={24}
                      color="white"
                      style={{ marginHorizontal: 2.5 }}
                    />
                  }
                  onChange={ratingChanged}
                />
              ) : (
                <Rating
                  readonly={true}
                  initialRating={
                    item.myRating && item.myRating.score
                      ? item.myRating.score
                      : 0
                  }
                  emptySymbol={
                    <FontAwesome
                      name="star-o"
                      size={24}
                      color="white"
                      style={{ marginHorizontal: 2.5 }}
                    />
                  }
                  fullSymbol={
                    <FontAwesome
                      name="star"
                      size={24}
                      color="white"
                      style={{ marginHorizontal: 2.5 }}
                    />
                  }
                  onChange={ratingChanged}
                />
              )}
            </View>
            {item.myRating && item.myRating.score ? (
              <View>
                <Text style={styles.ratingLabel}>AVG</Text>
                <View style={styles.avgContainer}>
                  <Text style={styles.avgValue}>{item.rating}</Text>
                </View>
              </View>
            ) : null}
          </View>

          <Text style={styles.soldOutText}>
            {isScouting
              ? "SCOUTING"
              : type == 0
              ? `${item.total - item.claimed.length} REMAINING`
              : "SOLD OUT"}
          </Text>
          <TouchableOpacity
            style={styles.claimButton}
            onPress={() => {
              if (!isScouting) setIsModalVisible(true);
            }}
          >
            <Text style={styles.claimText}>
              {isScouting
                ? `SCOUTING: ${timeRemaining}`
                : type == 0
                ? "BUY REACT"
                : type == 1
                ? "MARKETPLACE LISTINGS"
                : "PLACE BID"}
            </Text>
          </TouchableOpacity>
          <Text style={styles.descText}>
            Every purchase supports {item.streamerId.alias} directly
          </Text>
        </View>
        {!isScouting ? (
          <ClipModal
            data={item}
            type={type}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        ) : null}
      </View>
    </>
  );
};

export default RenderClip;
