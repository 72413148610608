import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  useWindowDimensions,
  Modal,
} from "react-native";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { useIsFocused } from "@react-navigation/native";
import { MaterialIcons } from "@expo/vector-icons";
import CurrencyInput from "react-native-currency-input";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as StripeContext } from "../../context/StripeContext";
import { Context as StreamerContext } from "../../context/StreamerContext";
import * as RootNavigation from "../../RootNavigation";
import { Mixpanel } from "../../api/mixPanel";

const ClaimCardModal = ({ data, isModalVisible, setIsModalVisible }) => {
  const {
    state: { token },
  } = useContext(AuthContext);
  const { purchaseStreamerCard } = useContext(StripeContext);
  const {
    navigateStreamer,
    state: { isClaimEligible },
  } = useContext(StreamerContext);
  const [price, setPrice] = useState(data.cardDetails.reserve);
  const [isLoading, setIsLoading] = useState(false);
  const isFocused = useIsFocused();
  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;

  const purchaseCard = async () => {
    setIsLoading(true);
    if (price && price > 0.0 && price >= data.cardDetails.reserve) {
      Mixpanel.track("Initiate Purchase", { streamerAlias: data.alias });
      await purchaseStreamerCard(data._id, data.alias, price);
    } else {
      toast.warn("Donations must be greater than the reserve.");
    }
    if (isFocused) {
      setIsLoading(false);
    }
  };

  const onAuth = async (type) => {
    await navigateStreamer(data.alias);
    if (type == 0) {
      setIsModalVisible(false);
      Mixpanel.track("Page View", { pageName: "SignIn" });
      RootNavigation.navigate("SignIn");
    } else {
      setIsModalVisible(false);
      Mixpanel.track("Page View", { pageName: "SignUp" });
      RootNavigation.navigate("SignUp");
    }
  };

  return (
    <Modal animationType="fade" transparent={true} visible={isModalVisible}>
      <TouchableWithoutFeedback
        onPress={() => {
          Mixpanel.track("Claim Card Close", { streamerAlias: data.alias });
          setIsModalVisible(!isModalVisible);
        }}
      >
        <View
          style={{
            position: "absolute",
            height: window.height,
            width: window.width,
            zIndex: 100000000,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        />
      </TouchableWithoutFeedback>

      <View
        style={{
          position: "absolute",
          zIndex: 100000002,
          paddingTop: "10%",
          alignSelf: "center",
        }}
      >
        <View style={[styles.container, { width: modalWidth }]}>
          <View style={styles.topRow}>
            <Text style={styles.header}>
              {!token ? "Log In" : "Claim Card"}
            </Text>
            <TouchableOpacity
              onPress={() => {
                Mixpanel.track("Claim Card Close", {
                  streamerAlias: data.alias,
                });
                setIsModalVisible(!isModalVisible);
              }}
            >
              <MaterialIcons name="cancel" size={24} color="grey" />
            </TouchableOpacity>
          </View>
          {true ? (
            <>
              <Text style={styles.inputLabel}>DONATION AMOUNT $</Text>
              <CurrencyInput
                value={price}
                onChangeValue={setPrice}
                unit="$"
                delimiter=","
                separator="."
                precision={2}
                ignoreNegative={true}
                onChangeText={(formattedValue) => {
                  console.log(formattedValue); // $2,310.46
                }}
                style={styles.inputStyle}
                keyboardType={"numeric"}
                placeholder={"$0.00"}
              />
              <View style={styles.commonAmountRow}>
                <TouchableOpacity
                  style={styles.amountButton}
                  onPress={() => setPrice(5.0)}
                >
                  <Text style={styles.amountText}>$5.00</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.amountButton}
                  onPress={() => setPrice(25.0)}
                >
                  <Text style={styles.amountText}>$25.00</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.amountButton}
                  onPress={() => setPrice(50.0)}
                >
                  <Text style={styles.amountText}>$50.00</Text>
                </TouchableOpacity>
              </View>
              <Text style={styles.noteText}>
                {`NOTE: If you are donating more than the card reserve ($${data.cardDetails.reserve.toFixed(
                  2
                )}), split up your card purchases to acquire your maximum of 3 first edition cards.`}
              </Text>
              <TouchableOpacity
                style={styles.purchaseButton}
                onPress={() => purchaseCard()}
              >
                {isLoading ? (
                  <ActivityIndicator />
                ) : (
                  <Text style={styles.purchaseText}>
                    Donate {price ? `${price.toFixed(2)}` : null} to Claim Card
                  </Text>
                )}
              </TouchableOpacity>
            </>
          ) : !token ? (
            <>
              <Text style={styles.explainerText}>
                You must be signed in to claim this streamer's card. Choose an
                option below.
              </Text>
              <TouchableOpacity
                style={[styles.authButton, { backgroundColor: colors.green }]}
                onPress={() => onAuth(0)}
              >
                <Text style={[styles.authText, { color: "black" }]}>
                  LOG IN
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.authButton}
                onPress={() => onAuth(1)}
              >
                <Text style={styles.authText}>SIGN UP</Text>
              </TouchableOpacity>
            </>
          ) : (
            <>
              <Text style={styles.explainerText}>
                You cannot claim this streamer's card according to the rules
                below. Please context support@buffd.gg with questions or for any
                help.
              </Text>
              <Text style={styles.ruleText}>
                1. You cannot claim more than 5 total free cards per day
              </Text>
              <Text style={styles.ruleText}>
                2. Supporters cannot claim more than 3 first edition cards for
                any one streamer
              </Text>
              <Text style={styles.ruleText}>
                3. Streamers cannot claim their own cards (for now)
              </Text>
            </>
          )}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    alignContent: "center",
    //justifyContent: "center",
    padding: 30,
    alignSelf: "center",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
  },
  inputLabel: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
    marginBottom: 10,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    fontFamily: fonts.expandedRegular,
    color: "black",
    fontSize: 25,
    outlineWidth: 0,
  },
  commonAmountRow: {
    flexDirection: "row",
    marginVertical: 20,
    justifyContent: "space-between",
  },
  amountButton: {
    width: "30%",
    backgroundColor: colors.green,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  amountText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
  },
  purchaseButton: {
    backgroundColor: "black",
    marginTop: 25,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  purchaseText: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 14,
  },
  explainerText: {
    fontFamily: fonts.text,
    color: colors.greyText,
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
  },
  ruleText: {
    fontFamily: fonts.expandedBold,
    color: "black",
    fontSize: 14,
    lineHeight: 24,
    marginVertical: 10,
  },
  authButton: {
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    paddingVertical: 14,
    marginVertical: 15,
  },
  authText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
    color: "white",
  },
  noteText: {
    fontFamily: fonts.medium,
    color: "black",
    marginVertical: 20,
    fontSize: 16,
  },
});

export default ClaimCardModal;
