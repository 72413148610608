import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import colors from "../../../../res/colors";
import fonts from "../../../../res/fonts";
import { Context as ReactContext } from "../../../../context/ReactContext";
import { Mixpanel } from "../../../../api/mixPanel";
import { useMediaQuery } from "react-responsive";

const ReactBidsModal = ({ item, setIsModalVisible }) => {
  const { acceptReactBid } = useContext(ReactContext);
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const acceptBid = async () => {
    if (!isLoading) {
      setIsLoading(true);
      Mixpanel.track("Accept React Bid", {
        poolId: item._id,
        // streamerAlias: data.streamerId.alias,
      });
      await acceptReactBid({ reactId: item.reactId._id, bidId: item._id });

      setIsLoading(false);
      setIsModalVisible(false);
    }
  };

  return (
    <>
      <Text style={styles.clipName}>{item.pool.reactName}</Text>
      <Text style={styles.streamerClipName}>
        #{item.reactId.mintNumber}/{item.pool.total} |{" "}
        {item.pool.streamerId.alias}
      </Text>
      <View style={styles.listItemContainer}>
        <Text
          style={[
            styles.listLabel,
            { marginLeft: isMobile ? 10 : 25, fontSize: 16 },
          ]}
        >
          ${item.price.toFixed(2)}
        </Text>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text
            style={[
              styles.listLabel,
              {
                width: 125,
                marginRight: 10,
                color: "grey",
                fontSize: 12,
              },
            ]}
          >
            @{item.bidder.alias}
          </Text>
          <TouchableOpacity
            style={[
              styles.buyButton,
              { backgroundColor: isSelected ? colors.green : "black" },
            ]}
            onPress={() => {
              if (!isSelected) {
                setIsSelected(true);
              } else {
                acceptBid();
              }
            }}
          >
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              <Text
                style={[
                  styles.buyText,
                  { color: isSelected ? "black" : "white" },
                ]}
              >
                {isSelected ? "Confirm" : "Accept"}
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.listDivider} />
    </>
  );
};

const styles = StyleSheet.create({
  clipName: {
    fontFamily: fonts.semiBold,
    fontSize: 16,
    marginBottom: 5,
  },
  streamerClipName: {
    fontFamily: fonts.semiBold,
    fontSize: 12,
    color: colors.backOverlay,
    marginBottom: 15,
  },
  listItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listLabel: {
    fontFamily: fonts.semiBold,
    color: "black",
  },
  listDivider: {
    borderBottomColor: "#D0D0D0",
    borderBottomWidth: 0.5,
    marginTop: 8,
    marginBottom: 25,
  },
  buyButton: {
    backgroundColor: "black",
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 4,
  },
  buyText: {
    fontFamily: fonts.bold,
    color: "white",
  },
});

export default ReactBidsModal;
