import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import colors from "../../../../res/colors";
import fonts from "../../../../res/fonts";
import Popup from "reactjs-popup";
import "../../../HomeScreen/FeedView/ClipStyle.css";
import { Context as ReactContext } from "../../../../context/ReactContext";
import { MaterialIcons } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import { useIsFocused } from "@react-navigation/native";
import { Mixpanel } from "../../../../api/mixPanel";
import { range } from "lodash";

const ReactListingsModal = ({ isModalVisible, setIsModalVisible }) => {
  const {
    fetchMarketListings,
    state: { marketListings },
  } = useContext(ReactContext);
  const closeModal = () => setIsModalVisible(false);

  const isFocused = useIsFocused();
  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;

  useEffect(() => {
    async function loadBids() {
      if (isModalVisible && !marketListings) {
        await fetchMarketListings();
      }
    }
    loadBids();
  }, [isModalVisible]);

  const innerContent = () => {
    return (
      <>
        {!marketListings ? (
          <ActivityIndicator />
        ) : marketListings.length == 0 ? (
          <Text style={{ fontFamily: fonts.semiBold }}>
            You have no active marketplace listings
          </Text>
        ) : (
          <>
            <Text style={styles.inputLabel}>
              ALL LISTINGS ({marketListings.length})
            </Text>
            <ScrollView
              style={{ borderRadius: 10, maxHeight: 400 }}
              contentContainerStyle={styles.listingsContainer}
            >
              {marketListings.map((item) => {
                return (
                  <View key={item._id}>
                    <Text style={styles.clipName}>
                      {item.reactId.pool.reactName}
                    </Text>
                    <Text style={styles.streamerClipName}>
                      ${item.price.toFixed(2)} | #{item.reactId.mintNumber}/
                      {item.reactId.pool.total} |{" "}
                      {item.reactId.pool.streamerId.alias}
                    </Text>
                    <View style={styles.listDivider} />
                  </View>
                );
              })}
            </ScrollView>
          </>
        )}
      </>
    );
  };

  return (
    <Popup
      open={isModalVisible}
      closeOnDocumentClick
      onClose={closeModal}
      position="center center"
      contentStyle={{
        borderTopColor: colors.green,
        borderTopWidth: 5,
        borderRadius: 10,
        width: modalWidth,
      }}
    >
      <View style={{ padding: isMobile ? 10 : 30 }}>
        <View style={styles.topRow}>
          <Text style={styles.header}>Market Listings</Text>
          <TouchableOpacity
            onPress={() => {
              Mixpanel.track("Listings Modal Close");
              setIsModalVisible(!isModalVisible);
            }}
          >
            <MaterialIcons name="cancel" size={24} color="grey" />
          </TouchableOpacity>
        </View>

        {innerContent()}
      </View>
    </Popup>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 30,
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
  },
  inputLabel: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
    marginBottom: 10,
  },
  clipName: {
    fontFamily: fonts.semiBold,
    fontSize: 22,
    marginBottom: 30,
  },

  amountButton: {
    width: "30%",
    backgroundColor: colors.green,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  amountText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
  },
  purchaseButton: {
    backgroundColor: "black",
    marginTop: 25,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  purchaseText: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 14,
  },
  explainerText: {
    fontFamily: fonts.text,
    color: colors.greyText,
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
  },
  noteText: {
    fontFamily: fonts.text,
    color: "grey",
    marginVertical: 10,
    fontSize: 14,
  },
  errorText: {
    fontFamily: fonts.text,
    color: "red",
    marginBottom: 5,
    fontSize: 14,
  },
  listingsContainer: {
    backgroundColor: "#E8E8E8",
    padding: 15,
  },
  clipName: {
    fontFamily: fonts.semiBold,
    fontSize: 16,
    marginBottom: 5,
  },
  streamerClipName: {
    fontFamily: fonts.semiBold,
    fontSize: 12,
    color: colors.backOverlay,
    marginBottom: 15,
  },
  listItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listLabel: {
    fontFamily: fonts.semiBold,
    color: "black",
  },
  listDivider: {
    borderBottomColor: "#D0D0D0",
    borderBottomWidth: 0.5,
    marginTop: 8,
    marginBottom: 25,
  },
  buyButton: {
    backgroundColor: "black",
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 4,
  },
  buyText: {
    fontFamily: fonts.bold,
    color: "white",
  },
});

export default ReactListingsModal;
