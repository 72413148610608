import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import SoldOutGrid from "./SoldOutGrid";
import SoldOutFeed from "./SoldOutFeed";
import { useMediaQuery } from "react-responsive";
import { TabView, TabBar, SceneMap } from "react-native-tab-view";
import { Entypo } from "@expo/vector-icons";

const SoldOutTab = () => {
  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "first", title: "Feed" },
    { key: "second", title: "Grid" },
  ]);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const renderScene = ({ route }) => {
    switch (route.key) {
      case "first":
        return <SoldOutFeed />;
      case "second":
        return <SoldOutGrid />;
      default:
        return null;
    }
  };

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      inactiveColor="grey"
      tabStyle={
        {
          //width: 200,
        }
      }
      indicatorStyle={{ backgroundColor: "#F8F8F8" }}
      style={{
        backgroundColor: "black",
        marginBottom: isMobile ? 25 : 35,
        width: "100%",
        borderBottomColor: "#505050",
        borderBottomWidth: StyleSheet.hairlineWidth,
      }}
      renderLabel={({ route, focused, color }) => (
        <Text
          style={{
            color,
            margin: 8,
            fontFamily: fonts.semiBold,
            fontSize: 13,
            letterSpacing: 2,
          }}
        >
          {route.title == "Grid" ? (
            <Entypo
              name="grid"
              size={16}
              color={color}
              style={{ marginRight: 10 }}
            />
          ) : (
            <Entypo
              name="folder-video"
              size={16}
              color={color}
              style={{ marginRight: 10 }}
            />
          )}
          {route.title.toUpperCase()}
        </Text>
      )}
    />
  );

  return (
    <TabView
      renderTabBar={renderTabBar}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "black",
  },
  innerContent: {
    alignSelf: "center",
  },
  sectionHeader: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
    marginBottom: 40,
  },
});

export default SoldOutTab;
