const fonts = {
  expandedBlack: "Archivo-Expanded-Black",
  expandedBold: "Archivo-Expanded-Bold",
  expandedRegular: "Archivo-Expanded-Regular",
  heavy: "Archivo-Black",
  bold: "Archivo-Bold",
  semiBold: "Archivo-SemiBold",
  medium: "Archivo-Medium",
  text: "Archivo-Regular",
};

export default fonts;
