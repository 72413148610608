import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../RootNavigation";

const FeaturedSection = () => {
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });
  const window = useWindowDimensions();

  const onNavigate = (alias) => {
    RootNavigation.navigate("StreamerProfile", { tag: alias });
  };

  return (
    <View style={styles.container}>
      <Text style={styles.header}>BUFFD BLUE CHIPS</Text>
      <View style={styles.featuredStreamers}>
        <TouchableOpacity
          style={styles.featureContainer}
          onPress={() => onNavigate("LosPollosTV")}
        >
          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.streamerImage} />
            <Text style={styles.streamerName}>LosPollosTV</Text>
          </View>
          <Text style={styles.streamerBio}>
            NBA 2K15 KOK Invitational National Champion
          </Text>
          <View style={styles.marketStats}>
            <View>
              <Text style={styles.label}>MKT CAP</Text>
              <Text style={styles.value}>$6.9K</Text>
            </View>
            <View>
              <Text style={styles.label}>QTY</Text>
              <Text style={styles.value}>100</Text>
            </View>
            <View>
              <Text style={styles.label}>GRWTH</Text>
              <Text style={[styles.value, { color: "green" }]}>+45.6%</Text>
            </View>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.featureContainer}
          onPress={() => onNavigate("PROBLEM")}
        >
          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.streamerImage} />
            <Text style={styles.streamerName}>PROBLEM</Text>
          </View>
          <Text style={styles.streamerBio}>
            GOAT in Madden Football. Professional Streamer.
          </Text>
          <View style={styles.marketStats}>
            <View>
              <Text style={styles.label}>MKT CAP</Text>
              <Text style={styles.value}>$2K</Text>
            </View>
            <View>
              <Text style={styles.label}>QTY</Text>
              <Text style={styles.value}>34</Text>
            </View>
            <View>
              <Text style={styles.label}>GRWTH</Text>
              <Text style={[styles.value, { color: "green" }]}>+82.4%</Text>
            </View>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.featureContainer}
          onPress={() => onNavigate("Aznboi")}
        >
          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={styles.streamerImage} />
            <Text style={styles.streamerName}>AznBoi</Text>
          </View>
          <Text style={styles.streamerBio}>
            70k+ on Twitch and 330k+ on Tiktok. Warzone Content Creator!
          </Text>
          <View style={styles.marketStats}>
            <View>
              <Text style={styles.label}>MKT CAP</Text>
              <Text style={styles.value}>$500</Text>
            </View>
            <View>
              <Text style={styles.label}>QTY</Text>
              <Text style={styles.value}>57</Text>
            </View>
            <View>
              <Text style={styles.label}>GRWTH</Text>
              <Text style={[styles.value, { color: "green" }]}>+122.8%</Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    backgroundColor: colors.backOverlay,
    marginBottom: 150,
    paddingVertical: 25,
    width: 1024,
    alignSelf: "center",
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 18,
    marginLeft: 25,
    marginBottom: 25,
    color: "white",
  },
  featuredStreamers: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  featureContainer: {
    backgroundColor: "black",
    borderRadius: 10,
    width: 285,
    height: 220,
    padding: 25,
  },
  streamerImage: {
    backgroundColor: colors.backOverlay,
    borderRadius: 6,
    width: 50,
    height: 50,
  },
  streamerName: {
    fontFamily: fonts.expandedBold,
    marginLeft: 10,
    alignSelf: "flex-end",
    color: "white",
  },
  streamerBio: {
    fontFamily: fonts.text,
    color: "grey",
  },
  marketStats: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
  },
  label: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
    color: "grey",
    marginBottom: 5,
  },
  value: {
    fontFamily: fonts.bold,
    fontSize: 14,
    color: "white",
  },
});

export default FeaturedSection;
