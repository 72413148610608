import React, { useEffect, useRef } from "react";
//import { unstable_createElement } from "react-native-web";
import { InView, useInView } from "react-intersection-observer";
import Hls from "hls.js";
//const Video = (props) => unstable_createElement("video", props);

export default function VideoPlayer({
  _id,
  playLock,
  setPlayLock,
  feed,
  playbackId,
  width,
}) {
  const videoRef = useRef(null);
  const src = `https://stream.mux.com/${playbackId}.m3u8`;

  useEffect(() => {
    let hls;
    if (videoRef.current) {
      const video = videoRef.current;

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }

      //   const videoElem = document.querySelector(`#clip-video-${_id}`);
      //   videoElem
      //     .play()
      //     .then(function () {
      //       console.log("PLAYING");
      //       videoElem.muted = false;
      //     })
      //     .catch(function (error) {
      //       console.log("ERROR PLAYING");
      //     });
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef]);

  const handleViewChange = (inView, entry) => {
    if (videoRef.current) {
      const videoElem = document.querySelector(`#clip-video-${_id}`);

      if (inView) {
        if (playLock == -1) {
          setPlayLock(_id);
          videoElem
            .play()
            .then(function () {
              console.log("PLAYING VIDEO");
              //videoElem.muted = false;
            })
            .catch(function (error) {
              console.log("ERROR PLAYING");
              console.log(error);
            });
        } else {
          console.log("Cannot get playLock");
        }
      } else {
        if (playLock == _id) setPlayLock(-1);
        videoElem.pause();
      }
    }
  };

  return (
    <>
      {feed ? (
        <InView
          threshold={0.9}
          root={document.body}
          onChange={(inView, entry) => handleViewChange(inView, entry)}
        >
          <video
            id={`clip-video-${_id}`}
            controls
            ref={videoRef}
            muted={true}
            disablePictureInPicture={true}
            loop={true}
            style={{ width: width, height: "100%" }}
          />
        </InView>
      ) : (
        <video
          id={`clip-video-${_id}`}
          controls
          ref={videoRef}
          muted={true}
          disablePictureInPicture={true}
          loop={true}
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </>
  );
}
