import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Image,
  Button,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import TopBar from "../../components/TopBar";
import { useMediaQuery } from "react-responsive";
import fonts from "../../res/fonts";
import LogoBlack from "../../../assets/images/LogoBlack";
import { Input } from "react-native-elements";
import { toast } from "react-toastify";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";
import * as EmailValidator from "email-validator";
import * as RootNavigation from "../../RootNavigation";
import { useIsFocused } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";

const StreamerEnroll = ({ navigation }) => {
  const {
    streamerEnroll,
    clearErrorMessage,
    state: { token, errorMessage },
  } = useContext(AuthContext);
  const {
    fetchUserInfo,
    streamerConvert,
    state: { userInfo, streamerProfile },
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [reserve, setReserve] = useState("");
  const [bio, setBio] = useState("");
  const [bioHeight, setBioHeight] = useState("");
  const [paymentInfo, setPaymentInfo] = useState("");
  const [image, setImage] = useState(null);
  const [clipUrl, setClipUrl] = useState("");
  const [twitchLink, setTwitchLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [youTubeLink, setYouTubeLink] = useState("");
  const [tikTokLink, setTikTokLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const window = useWindowDimensions();
  const isFocused = useIsFocused();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;
  const modalHorizontalPadding = isMobile ? 15 : 50;

  const regex = /[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]/g;

  const validateEntries = () => {
    if (alias == "" || email == "" || password == "" || confirmPassword == "") {
      toast.error("Please complete all fields.");
      return false;
    } else if (regex.test(alias)) {
      toast.error("The alias cannot contain special characters.");
      return false;
    } else if (alias.indexOf(" ") >= 0) {
      toast.error("The alias cannot contain spaces.");
      return false;
    } else if (password != confirmPassword) {
      toast.error("Passwords do not match.");
      return false;
    } else if (EmailValidator.validate(email) == false) {
      toast.error("Invalid email.");
      return false;
    } else {
      return true;
    }
  };

  const onSignUp = async () => {
    setIsLoading(true);
    if (validateEntries()) {
      await streamerEnroll({
        alias,
        email,
        password,
        bio,
        paymentInfo,
        clipUrl,
        twitchLink,
        instagramLink,
        youTubeLink,
        tikTokLink,
        twitterLink,
      });
      //navigation.navigate("Dashboard");
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (streamerProfile != null && streamerProfile._id) {
      navigation.navigate("Dashboard");
    }
  }, []);

  useEffect(() => {
    console.log("Ending Loading");
    setIsLoading(false);
    clearErrorMessage();
    return () => {
      setIsLoading(false);
      clearErrorMessage();
    };
  }, [errorMessage]);

  const onConvert = async () => {
    setIsLoading(true);
    try {
      await streamerConvert({
        bio,
        paymentInfo,
        clipUrl,
        twitchLink,
        instagramLink,
        youTubeLink,
        tikTokLink,
        twitterLink,
      });
      await fetchUserInfo();
      setIsLoading(false);
      //navigation.navigate("Dashboard");
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onSubmitPress = () => {
    if (!token) {
      onSignUp();
    } else {
      onConvert();
    }
  };

  if (
    streamerProfile &&
    (streamerProfile.status == 0 || streamerProfile.status == 1)
  ) {
    return (
      <>
        <ScrollView contentContainerStyle={styles.container}>
          <TopBar />
          <View style={styles.contentContainer}>
            <View
              style={[
                styles.modalContainer,
                {
                  width: modalWidth,
                  paddingHorizontal: modalHorizontalPadding,
                },
              ]}
            >
              <TouchableOpacity
                style={styles.backButton}
                onPress={() => navigation.navigate("StreamerInfo")}
              >
                <Text style={styles.backText}>GO BACK</Text>
              </TouchableOpacity>
              <View style={{ alignSelf: "center" }}>
                <Text style={styles.descText}>
                  You've enrolled to be a streamer on Buffd!
                </Text>
                <Text style={styles.subDesc}>
                  <TouchableOpacity
                    onPress={() => RootNavigation.navigate("Home")}
                  >
                    <Text style={{ textDecorationLine: "underline" }}>
                      Go Home
                    </Text>
                  </TouchableOpacity>
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </>
    );
  } else {
    return (
      <>
        <ScrollView contentContainerStyle={styles.container}>
          <TopBar />
          <View style={styles.contentContainer}>
            <View
              style={[
                styles.modalContainer,
                {
                  width: modalWidth,
                  paddingHorizontal: modalHorizontalPadding,
                },
              ]}
            >
              <TouchableOpacity
                style={styles.backButton}
                onPress={() => navigation.navigate("StreamerInfo")}
              >
                <Text style={styles.backText}>GO BACK</Text>
              </TouchableOpacity>
              <View style={{ alignSelf: "center" }}>
                <Text style={styles.descText}>Enroll as a Creator</Text>
              </View>
              <View style={styles.divider} />
              <View style={styles.inputContainer}>
                {/*ASK FOR ALIAS, EMAIL, PASS, CONFIRM IF NOT SIGNED IN*/}
                {!token ? (
                  <>
                    <Text style={styles.dividerText}>ALIAS</Text>
                    <Text style={styles.dividerDescText}>
                      Your Buffd alias will be used as the name for your
                      streamer profile page. This can be anything you choose
                      that is still available on Buffd.{" "}
                    </Text>
                    <Input
                      label="Alias *"
                      labelStyle={styles.labelStyle}
                      placeholder={"This is what you'll go by"}
                      containerStyle={styles.inputContainerStyle}
                      placeholderTextColor="grey"
                      inputContainerStyle={styles.inputStyle}
                      value={alias}
                      inputStyle={styles.inputTextStyle}
                      onChangeText={(newAlias) => setAlias(newAlias)}
                    />
                    <Text style={styles.dividerText}>EMAIL</Text>
                    <Text style={styles.dividerDescText}>
                      Our team will get in touch with you through this email
                      entered.{" "}
                    </Text>
                    <Input
                      label="Email *"
                      labelStyle={styles.labelStyle}
                      containerStyle={styles.inputContainerStyle}
                      placeholderTextColor="grey"
                      inputContainerStyle={styles.inputStyle}
                      value={email}
                      inputStyle={styles.inputTextStyle}
                      onChangeText={(newEmail) => setEmail(newEmail)}
                    />
                    <Input
                      label="Password *"
                      secureTextEntry={true}
                      labelStyle={styles.labelStyle}
                      containerStyle={styles.inputContainerStyle}
                      placeholderTextColor="grey"
                      inputContainerStyle={styles.inputStyle}
                      value={password}
                      inputStyle={styles.inputTextStyle}
                      onChangeText={(newPassword) => setPassword(newPassword)}
                    />
                    <Input
                      label="Confirm password *"
                      secureTextEntry={true}
                      labelStyle={styles.labelStyle}
                      containerStyle={styles.inputContainerStyle}
                      placeholderTextColor="grey"
                      inputContainerStyle={styles.inputStyle}
                      value={confirmPassword}
                      inputStyle={styles.inputTextStyle}
                      onChangeText={(newConfirmPassword) =>
                        setConfirmPassword(newConfirmPassword)
                      }
                    />
                  </>
                ) : (
                  <>
                    <Text style={styles.dividerText}>CHANGE ACCOUNT TYPE</Text>
                    <Text style={styles.dividerDescText}>
                      Your current Buffd alias (
                      {userInfo ? userInfo.alias : null}) will be used as the
                      name for your streamer profile page. Please contact
                      support@buffd.gg after submitting this form if you would
                      like that name to be changed.
                    </Text>
                  </>
                )}

                <TouchableOpacity
                  style={styles.submitButton}
                  onPress={() => onSubmitPress()}
                >
                  {isLoading ? (
                    <ActivityIndicator />
                  ) : (
                    <Text style={styles.submitButtonText}>
                      Enroll as Creator
                    </Text>
                  )}
                </TouchableOpacity>
                <Text style={styles.confirmText}>
                  By signing up for Buffd, you acknowledge that you have read
                  and agree to all applicable Terms of Service and our Privacy
                  Policy
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#f9f8f9",
  },
  contentContainer: {
    paddingHorizontal: "5%",
    paddingTop: 50,
    paddingBottom: 200,
  },
  backButton: {
    backgroundColor: colors.subBackground,
    borderRadius: 6,
    paddingVertical: 8,
    paddingHorizontal: 12,
    alignSelf: "flex-start",
    marginBottom: 15,
  },
  backText: {
    fontFamily: fonts.expandedBold,
    color: "black",
    fontSize: 10,
  },
  header: {
    fontFamily: fonts.bold,
    fontSize: 45,
    color: "white",
  },
  modalContainer: {
    backgroundColor: "white",
    alignContent: "center",
    //justifyContent: "center",
    paddingVertical: 50,
    alignSelf: "center",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  logoContainer: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
    marginBottom: 30,
  },
  descText: {
    fontFamily: fonts.bold,
    color: colors.secondaryGreen,
    fontSize: 20,
    textAlign: "center",
  },
  subDesc: {
    fontFamily: fonts.semiBold,
    color: "grey",
    fontSize: 16,
    marginTop: 15,
    textAlign: "center",
  },
  streamerDesc: {
    fontFamily: fonts.semiBold,
    color: "black",
    fontSize: 16,
    textAlign: "center",
    marginBottom: 15,
  },
  divider: {
    borderTopWidth: 0.5,
    borderColor: "grey",
    marginTop: 20,
    opacity: 0.2,
  },
  dividerText: {
    fontFamily: fonts.expandedBold,
    fontSize: 12,
    marginVertical: 10,
  },
  dividerDescText: {
    fontFamily: fonts.text,
    color: colors.greyText,
    fontSize: 12,
    marginBottom: 30,
  },
  inputContainer: {
    marginTop: 30,
    marginHorizontal: 0,
  },
  labelStyle: {
    fontFamily: fonts.text,
    fontSize: 14,
    marginBottom: 10,
    color: "grey",
  },
  inputContainerStyle: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    marginVertical: 5,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 25,
    paddingVertical: 5,
  },
  inputTextStyle: {
    fontFamily: fonts.text,
    color: "black",
    fontSize: 14,
    outlineWidth: 0,
  },
  submitButton: {
    backgroundColor: colors.green,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
    borderRadius: 8,
    width: "75%",
    alignSelf: "center",
    marginTop: 20,
  },
  submitButtonText: {
    fontFamily: fonts.bold,
    color: colors.secondaryGreen,
    fontSize: 14,
  },
  confirmText: {
    fontFamily: fonts.regular,
    color: "grey",
    fontSize: 10,
    marginTop: 20,
    textAlign: "center",
  },
  imageButton: {
    backgroundColor: colors.green,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
  },
  imageText: {
    fontFamily: fonts.expandedBold,
    color: "black",
    fontSize: 12,
  },
});

export default StreamerEnroll;
