import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import TopBar from "../../components/TopBar";
import PayoutInfo from "./PayoutInfo";
import Footer from "../../components/Footer";
import { Context as UserContext } from "../../context/UserContext";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../RootNavigation";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css"; // or 'rsuite-table/dist/css/rsuite-table.css'

const dataList = [
  { id: 1, name: "a", email: "a@email.com" },
  { id: 2, name: "b", email: "b@email.com" },
  { id: 3, name: "c", email: "c@email.com" },
];

const PaymentScreen = ({ navigation }) => {
  //const [sentToMix, setSentToMix] = useState(false);
  const {
    state: { streamerProfile },
  } = useContext(UserContext);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  //const isDesktop = useMediaQuery({ maxWidth: 1024 });

  const viewWidth = isMobile || isTabletOrMobile ? "95%" : 1025;

  const changeHandler = (value) => {
    setValue(value);
    console.log(value);
  };

  return (
    <>
      <TopBar />
      <ScrollView contentContainerStyle={styles.container}>
        <View style={[styles.innerContainer, { width: viewWidth }]}>
          <PayoutInfo />
          <View style={styles.headerContainer}>
            <Text style={styles.header}>
              Transaction Overview (Coming Soon)
            </Text>
          </View>
          {/* <Table data={dataList}>
            <Column width={100} sortable fixed resizable>
              <HeaderCell style={{ fontFamily: fonts.expandedBlack }}>
                ID
              </HeaderCell>
              <Cell dataKey="id" />
            </Column>

            <Column width={100} sortable resizable>
              <HeaderCell style={{ fontFamily: fonts.expandedBlack }}>
                Name
              </HeaderCell>
              <Cell dataKey="name" />
            </Column>

            <Column width={100} sortable resizable>
              <HeaderCell style={{ fontFamily: fonts.expandedBlack }}>
                Email
              </HeaderCell>
              <Cell>
                {(rowData, rowIndex) => {
                  return (
                    <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
                  );
                }}
              </Cell>
            </Column>
          </Table> */}
        </View>
        <Footer />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    //flexGrow: 1,
    backgroundColor: "white",
  },
  innerContainer: {
    width: 1025,
    alignSelf: "center",
    minHeight: 600,
  },
  headerContainer: {
    marginVertical: 50,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
  },
  desc: {
    fontFamily: fonts.text,
    fontSize: 14,
    marginTop: 20,
  },
  button: {
    backgroundColor: colors.green,
    paddingHorizontal: 30,
    paddingVertical: 8,
    borderRadius: 8,
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  buttonText: {
    fontFamily: fonts.semiBold,
    fontSize: 16,
  },
});

export default PaymentScreen;
