import { StyleSheet } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";

const desktop = (window) =>
  StyleSheet.create({
    container: {
      marginBottom: 80,
      flexDirection: "row",
      justifyContent: "space-between",
      borderWidth: StyleSheet.hairlineWidth,
      borderColor: "#202020",
      padding: 30,
      borderRadius: 30,
    },
    clipContainer: {
      //backgroundColor: colors.backOverlay,
      //width: 700,
      //padding: 10,
      alignSelf: "flex-start",
    },
    streamerSide: {
      //backgroundColor: "blue",
      width: 300,
    },
    streamerBio: {
      width: "80%",
      flexDirection: "row",
      //backgroundColor: colors.backOverlay,
      //padding: 15,
      //borderRadius: 10,
      marginBottom: 20,
      flexShrink: 1,
      marginTop: 20,
    },
    streamerImage: {
      width: 50,
      height: 50,
      backgroundColor: "black",
      borderRadius: 50,
      marginRight: 15,
    },
    header: {
      fontFamily: fonts.expandedBold,
      color: "white",
      fontSize: 14,
      marginRight: 10,
      marginBottom: 5,
    },
    learnMoreText: {
      fontFamily: fonts.text,
      color: colors.green,
      fontSize: 10,
    },
    bioText: {
      fontFamily: fonts.text,
      color: "grey",
      flexWrap: "wrap",
      fontSize: 11,
    },
    cardContainer: {
      flexDirection: "row",
    },
    clipName: {
      fontFamily: fonts.semiBold,
      color: "white",
      fontSize: 20,
      marginBottom: 15,
    },
    detailContainer: {
      marginBottom: 10,
    },
    label: {
      fontFamily: fonts.expandedBold,
      color: "grey",
      fontSize: 10,
    },
    value: {
      fontFamily: fonts.expandedBold,
      color: "white",
      fontSize: 11,
    },
    reactContainer: {
      backgroundColor: colors.backOverlay,
      // width: 150,
      // height: 150,
      marginBottom: 20,
    },
    ratingBox: {
      paddingVertical: 15,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    ratingLabel: {
      fontFamily: fonts.expandedBold,
      fontSize: 10,
      color: "grey",
      marginBottom: 10,
    },
    avgContainer: {
      backgroundColor: colors.green,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
      paddingVertical: 6,
      paddingHorizontal: 12,
    },
    avgValue: {
      fontFamily: fonts.expandedBold,
      color: "black",
    },
    soldOutText: {
      marginTop: 20,
      marginBottom: 10,
      fontFamily: fonts.expandedBold,
      color: "red",
      fontSize: 12,
    },
    claimButton: {
      backgroundColor: "white",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 2.5,
      paddingVertical: 14,
    },
    claimText: {
      fontFamily: fonts.expandedBold,
      fontSize: 11,
    },
    descText: {
      marginTop: 15,
      fontFamily: fonts.medium,
      color: "grey",
      fontSize: 12,
    },
    scoutingNotification: {
      backgroundColor: colors.green,
      width: 250,
      paddingVertical: 10,
      borderRadius: 2,
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 25,
    },
    scoutingText: {
      fontFamily: fonts.expandedBold,
      fontSize: 14,
    },
  });

const tablet = (window) =>
  StyleSheet.create({
    container: {
      marginBottom: 80,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    clipContainer: {
      // backgroundColor: colors.backOverlay,
      // width: 700,
      // padding: 10,
      // borderColor: colors.backOverlay,
      alignSelf: "flex-start",
    },
    streamerSide: {
      //backgroundColor: "blue",
      width: 300,
      // height: 500,
    },
    streamerBio: {
      flexDirection: "row",
      backgroundColor: colors.backOverlay,
      padding: 15,
      borderRadius: 10,
      marginBottom: 20,
      flexShrink: 1,
    },
    streamerImage: {
      width: 50,
      height: 50,
      backgroundColor: "black",
      borderRadius: 5,
      marginRight: 15,
    },
    header: {
      fontFamily: fonts.expandedBold,
      color: "white",
      fontSize: 14,
      marginRight: 10,
      marginBottom: 5,
    },
    learnMoreText: {
      fontFamily: fonts.text,
      color: colors.green,
      fontSize: 10,
    },
    bioText: {
      fontFamily: fonts.text,
      color: "grey",
      flexWrap: "wrap",
      fontSize: 11,
    },
    cardContainer: {
      flexDirection: "row",
    },
    clipName: {
      fontFamily: fonts.semiBold,
      color: "white",
      fontSize: 20,
      marginBottom: 15,
    },
    detailContainer: {
      marginBottom: 10,
    },
    label: {
      fontFamily: fonts.expandedBold,
      color: "grey",
      fontSize: 10,
    },
    value: {
      fontFamily: fonts.expandedBold,
      color: "white",
      fontSize: 11,
    },
    reactContainer: {
      backgroundColor: colors.backOverlay,
      // width: 150,
      // height: 150,
      marginBottom: 20,
    },
    ratingBox: {
      paddingVertical: 15,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    ratingLabel: {
      fontFamily: fonts.expandedBold,
      fontSize: 10,
      color: "grey",
      marginBottom: 10,
    },
    avgContainer: {
      backgroundColor: colors.green,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
      paddingVertical: 6,
      paddingHorizontal: 12,
    },
    avgValue: {
      fontFamily: fonts.expandedBold,
      color: "black",
    },
    soldOutText: {
      marginTop: 20,
      marginBottom: 10,
      fontFamily: fonts.expandedBold,
      color: "red",
      fontSize: 12,
    },
    claimButton: {
      backgroundColor: "white",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 2.5,
      paddingVertical: 14,
      marginTop: 25,
    },
    claimText: {
      fontFamily: fonts.expandedBold,
      fontSize: 11,
    },
    descText: {
      marginTop: 15,
      fontFamily: fonts.medium,
      color: "grey",
      fontSize: 12,
    },
    scoutingNotification: {
      backgroundColor: colors.green,
      width: 250,
      paddingVertical: 10,
      borderRadius: 2,
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 25,
    },
    scoutingText: {
      fontFamily: fonts.expandedBold,
      fontSize: 14,
    },
  });

const mobile = (window) =>
  StyleSheet.create({
    container: {
      marginBottom: 80,
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    clipContainer: {
      //backgroundColor: colors.backOverlay,
      //width: 700,
      //padding: 10,
      //borderColor: colors.backOverlay,
      alignSelf: "flex-start",
      //marginBottom: 20,
    },
    streamerSide: {
      //backgroundColor: "blue",
      width: 300,
      //height: 500,
    },
    streamerBio: {
      flexDirection: "row",
      backgroundColor: colors.backOverlay,
      padding: 15,
      borderRadius: 10,
      marginBottom: 20,
      flexShrink: 1,
    },
    streamerImage: {
      width: 50,
      height: 50,
      backgroundColor: "black",
      borderRadius: 5,
      marginRight: 15,
    },
    header: {
      fontFamily: fonts.expandedBold,
      color: "white",
      fontSize: 14,
      marginRight: 10,
      marginBottom: 5,
    },
    learnMoreText: {
      fontFamily: fonts.text,
      color: colors.green,
      fontSize: 10,
    },
    bioText: {
      fontFamily: fonts.text,
      color: "grey",
      flexWrap: "wrap",
      fontSize: 11,
    },
    cardContainer: {
      flexDirection: "row",
    },
    clipName: {
      fontFamily: fonts.semiBold,
      color: "white",
      fontSize: 20,
      marginBottom: 15,
    },
    detailContainer: {
      marginBottom: 10,
    },
    label: {
      fontFamily: fonts.expandedBold,
      color: "grey",
      fontSize: 10,
    },
    value: {
      fontFamily: fonts.expandedBold,
      color: "white",
      fontSize: 11,
    },
    reactContainer: {
      backgroundColor: colors.backOverlay,
      // width: 150,
      // height: 150,
      marginBottom: 20,
    },
    ratingBox: {
      paddingVertical: 15,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    ratingLabel: {
      fontFamily: fonts.expandedBold,
      fontSize: 10,
      color: "grey",
      marginBottom: 10,
    },
    avgContainer: {
      backgroundColor: colors.green,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 8,
      paddingVertical: 6,
      paddingHorizontal: 12,
    },
    avgValue: {
      fontFamily: fonts.expandedBold,
      color: "black",
    },
    soldOutText: {
      marginTop: 20,
      marginBottom: 10,
      fontFamily: fonts.expandedBold,
      color: "red",
      fontSize: 12,
    },
    claimButton: {
      backgroundColor: "white",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 2.5,
      paddingVertical: 14,
      marginTop: 5,
    },
    claimText: {
      fontFamily: fonts.expandedBold,
      fontSize: 11,
    },
    descText: {
      marginTop: 15,
      fontFamily: fonts.medium,
      color: "grey",
      fontSize: 12,
    },
    scoutingNotification: {
      backgroundColor: colors.green,
      width: 250,
      paddingVertical: 10,
      borderRadius: 2,
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 25,
    },
    scoutingText: {
      fontFamily: fonts.expandedBold,
      fontSize: 14,
    },
  });

export { desktop, tablet, mobile };
