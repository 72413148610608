import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";

const instance = axios.create({
  baseURL: "https://cryptic-lake-17949.herokuapp.com",
  //baseURL: "http://localhost:3000",
  //baseURL:
  //  Platform.OS == "ios" ? "http://localhost:3000" : "http://10.0.2.2:3000",
  //baseURL: "http://e5ff7823e123.ngrok.io",
});

instance.interceptors.request.use(
  //Called before making request
  async (config) => {
    const token = await AsyncStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  //Called if there is an error
  (err) => {
    return Promise.reject(err);
  }
);

export default instance;
