import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../src/res/colors";
import fonts from "../../src/res/fonts";
import { useMediaQuery } from "react-responsive";
import Logo from "../../assets/images/Logo";
import * as RootNavigation from "../RootNavigation";
import {
  desktop as FooterDesktop,
  tablet as FooterTablet,
  mobile as FooterMobile,
} from "./FooterStyle";
import { Mixpanel } from "../api/mixPanel";

const Footer = () => {
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });

  const styles = isMobile
    ? FooterMobile
    : isTabletOrMobile
    ? FooterTablet
    : FooterDesktop;

  return (
    <View style={styles.container}>
      <View style={styles.column}>
        <Logo
          width={100}
          height={20}
          style={{
            marginBottom: 20,
          }}
        />
        <Text style={styles.licenseText}>Copyright © 2021 Qade, LLC.</Text>
        <Text style={styles.licenseText}>All rights reserved.</Text>

        <TouchableOpacity
          onPress={() =>
            window.open(
              "https://drive.google.com/file/d/15dKe3PsTjKCoPBjseOcyL0eSawntRmiD/view?usp=sharing"
            )
          }
        >
          <Text
            style={[styles.licenseText, { textDecorationLine: "underline" }]}
          >
            Terms of Service{" "}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>
            window.open(
              "https://drive.google.com/file/d/1liHSp8aPxgeh0ToycygEvwn22Aeoewa4/view?usp=sharing"
            )
          }
        >
          <Text
            style={[styles.licenseText, { textDecorationLine: "underline" }]}
          >
            Privacy Policy{" "}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.column}>
        <Text style={styles.header}>Contact Us</Text>
        <TouchableOpacity
          onPress={() => {
            Mixpanel.track("Page View", { pageName: "Contact" });
            RootNavigation.navigate("Contact");
          }}
        >
          <Text
            style={[styles.licenseText, { textDecorationLine: "underline" }]}
          >
            Contact Us Page
          </Text>
        </TouchableOpacity>
        <TouchableOpacity>
          <Text style={styles.licenseText}>support@buffd.gg</Text>
        </TouchableOpacity>
        <TouchableOpacity>
          <Text
            style={[styles.licenseText, { textDecorationLine: "underline" }]}
          >
            System Status
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.column}>
        <Text style={styles.header}>Ready to become a streamer on Buffd?</Text>
        <TouchableOpacity
          style={styles.talentButton}
          onPress={() => {
            Mixpanel.track("Page View", { pageName: "StreamerInfo" });
            RootNavigation.navigate("StreamerInfo");
          }}
        >
          <Text style={styles.talentText}>ENROLL AS CREATOR</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default Footer;
