import { StyleSheet } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";

const desktop = StyleSheet.create({
  outerContainer: {
    paddingTop: 80,
    height: "100vh",
    alignSelf: "center",
    justifyContent: "center",
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    letterSpacing: 16,
    fontSize: 14,
    color: colors.darkerGreen,
    marginBottom: 20,
  },
  header: {
    marginVertical: 25,
    fontFamily: fonts.bold,
    fontSize: 65,
    color: "black",
  },
  body: {
    marginTop: 20,
    fontFamily: fonts.text,
    color: "grey",
    fontSize: 24,
    lineHeight: 30,
  },
  heroButton: {
    marginTop: 40,
    alignSelf: "flex-start",
    backgroundColor: colors.green,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 35,
    paddingVertical: 20,
  },
  heroButtonText: {
    fontFamily: fonts.semiBold,
    fontSize: 18,
    color: "black",
  },
});

const tablet = StyleSheet.create({
  outerContainer: {
    paddingTop: 80,
    height: "100vh",
    alignSelf: "center",
    justifyContent: "center",
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    letterSpacing: 16,
    fontSize: 14,
    color: colors.darkerGreen,
    marginBottom: 20,
  },
  header: {
    marginVertical: 25,
    fontFamily: fonts.bold,
    fontSize: 65,
    color: "black",
  },
  body: {
    marginTop: 20,
    fontFamily: fonts.text,
    color: "grey",
    fontSize: 24,
    lineHeight: 30,
  },
  heroButton: {
    marginTop: 40,
    alignSelf: "flex-start",
    backgroundColor: colors.green,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 35,
    paddingVertical: 20,
  },
  heroButtonText: {
    fontFamily: fonts.semiBold,
    fontSize: 18,
    color: "black",
  },
});

const mobile = StyleSheet.create({
  outerContainer: {
    paddingTop: 40,
    alignSelf: "center",
    justifyContent: "center",
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    letterSpacing: 16,
    fontSize: 12,
    color: colors.darkerGreen,
    marginTop: 30,
  },
  header: {
    marginVertical: 15,
    fontFamily: fonts.bold,
    fontSize: 40,
    color: "black",
  },
  body: {
    marginTop: 20,
    fontFamily: fonts.text,
    color: "grey",
    fontSize: 18,
    lineHeight: 25,
  },
  heroButton: {
    marginVertical: 40,
    alignSelf: "flex-start",
    backgroundColor: colors.green,
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 35,
    paddingVertical: 20,
  },
  heroButtonText: {
    fontFamily: fonts.semiBold,
    fontSize: 18,
    color: "black",
  },
});

export { desktop, tablet, mobile };
