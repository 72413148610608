import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Image,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import ReactGridItem from "./ReactGridItem";
import { Context as UserContext } from "../../../context/UserContext";
import { useMediaQuery } from "react-responsive";
import { range } from "lodash";

const ReactGrid = ({ setIsIncomingBidsVisible }) => {
  const {
    state: { cardPortfolio },
  } = useContext(UserContext);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <View style={styles.gridContainer}>
      {cardPortfolio.reactsAcquired.map((item, index) => {
        return (
          <ReactGridItem
            item={item}
            index={index}
            setIsIncomingBidsVisible={setIsIncomingBidsVisible}
            key={item._id}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  gridContainer: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    //justifyContent: "space-between",
  },
});

export default ReactGrid;
