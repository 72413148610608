import React, { useState, useEffect, Suspense } from "react";
import { StyleSheet, View, Text, ActivityIndicator, Image } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../res/colors";
import TopBarIconless from "./TopBarIconless";
import { useMediaQuery } from "react-responsive";
import fonts from "../res/fonts";
import loadinggif2 from "../../assets/images/loadinggif2.gif";

const LoadingView = ({ navigation }) => {
  const [isMounted, setIsMounted] = useState(true);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <View style={styles.container}>
      <TopBarIconless />
      {/* <View style={styles.loadingContainer}>
        <Text style={styles.loadingText}>PLUGGIN' IN THE CRONUS</Text>
        <ActivityIndicator />
      </View> */}

      <Image
        source={{
          uri: isMounted ? loadinggif2 : "",
        }}
        style={{
          width: 350,
          height: 400,
          alignSelf: "center",
          marginTop: "10%",
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#030303",
  },
  loadingContainer: {
    padding: 50,
    borderRadius: 10,
    justifyContent: "center",
    alignSelf: "center",
    marginTop: "10%",
    backgroundColor: colors.subBackground,
  },
  loadingText: {
    fontFamily: fonts.expandedBold,
    fontSize: 20,
    marginBottom: 40,
  },
});

export default LoadingView;
