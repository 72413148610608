import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { socket } from "../api/Socket";
import * as Linking from "expo-linking";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";

const marketplaceReducer = (state, action) => {
  switch (action.type) {
    case "fetch_market":
      return {
        liveListings: action.payload.liveListings,
        soldListings: action.payload.soldListings,
        isMarketDataLoaded: true,
      };
    case "update_listing":
      return {
        ...state,
        liveListings: _.map(state.liveListings, function (listing) {
          if (`${listing._id}` == `${action.payload.listingId}`) {
            return {
              ...action.payload.listing,
            };
          } else {
            return listing;
          }
        }),
      };
    default:
      return state;
  }
};

const makeMarketConnection = (dispatch) => async () => {
  try {
    socket.on("buyNowMarket", (data) => {
      console.log("--GLOBAL SOCKET AUCTION BUY NOW");
      dispatch({ type: "update_listing", payload: data.body });
    });
    socket.on("bidPlaced", (data) => {
      console.log("--GLOBAL SOCKET AUCTION BID PLACED");
      dispatch({ type: "update_listing", payload: data.body });
    });
    socket.on("endAuction", (data) => {
      console.log("--GLOBAL END AUCTION");
      dispatch({ type: "update_listing", payload: data.body });
    });
  } catch (err) {
    console.log("makeMarketConnection error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "makeMarketConnection");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const fetchMarketData = (dispatch) => async () => {
  try {
    console.log("FETCHING MARKET DATA");
    const response = await serverApi.get("/market-data");
    dispatch({ type: "fetch_market", payload: response.data });
  } catch (err) {
    console.log("fetchMarketData error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchMarketData");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const bidListing = (dispatch) => async (listingId, cardId, price) => {
  try {
    toast.error("Purchases not available at this time");
    // const stripePromise = loadStripe(
    //   "pk_live_51IY33tEHZWPb26FdB3C1CJiEun2i35keEX6t5Lo9R6PLKXCGCXk9FeRlB0XsbZ7SA8LKBb5ZbX7vPEegK1vVcAqZ00pMNioPYZ"
    // );
    // const stripe = await stripePromise;
    // const response = await serverApi.post("/marketplace-checkout-session", {
    //   listingId: listingId,
    //   cardId: cardId,
    //   price: price,
    //   type: 2,
    // });
    // const result = await stripe.redirectToCheckout({
    //   sessionId: response.data.id,
    // });
    // if (result.error) {
    //   console.log(result.error);
    //   toast.error("Something went wrong. Refresh the page and try again.");
    // }
  } catch (err) {
    console.log(err);
    console.log("bidListing error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "triggerStreamerAuth");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const buyNowListing = (dispatch) => async (listingId, cardId, price) => {
  try {
    toast.error("Purchases not available at this time");
    // const stripePromise = loadStripe(
    //   "pk_live_51IY33tEHZWPb26FdB3C1CJiEun2i35keEX6t5Lo9R6PLKXCGCXk9FeRlB0XsbZ7SA8LKBb5ZbX7vPEegK1vVcAqZ00pMNioPYZ"
    // );
    // const stripe = await stripePromise;
    // const response = await serverApi.post("/marketplace-checkout-session", {
    //   listingId: listingId,
    //   cardId: cardId,
    //   price: price,
    //   type: 3,
    // });
    // const result = await stripe.redirectToCheckout({
    //   sessionId: response.data.id,
    // });
    // if (result.error) {
    //   console.log(result.error);
    //   toast.error("Something went wrong. Refresh the page and try again.");
    // }
  } catch (err) {
    console.log(err);
    console.log("bidListing error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "triggerStreamerAuth");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

export const { Provider, Context } = createDataContext(
  marketplaceReducer,
  {
    makeMarketConnection,
    fetchMarketData,
    bidListing,
    buyNowListing,
  },
  {
    liveListings: [],
    soldListings: [],
    isMarketDataLoaded: null,
  }
);
