import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  FlatList,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import TopBar from "../../components/TopBar";
import StreamerToDo from "./StreamerToDo";
import StreamerStats from "./StreamerStats";
import DashboardTabView from "./DashboardTabView";
import { useMediaQuery } from "react-responsive";
import fonts from "../../res/fonts";
import { Context as UserContext } from "../../context/UserContext";

const StreamerDashboardScreen = ({ route, navigation }) => {
  const {
    state: { isUserInfoLoaded, streamerProfile },
  } = useContext(UserContext);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <>
      <TopBar selected={3} />
      <ScrollView
        style={styles.container}
        style={{
          backgroundColor: "black",
          paddingTop: 50,
          paddingHorizontal: 0.05 * window.width,
          paddingBottom: 200,
          flexGrow: 1,
        }}
        contentContainerStyle={{
          width: isTabletOrMobile ? 0.95 * window.width : 1024,
          alignSelf: "center",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Text style={styles.header}>
            Welcome back, {streamerProfile.alias}!
          </Text>
          <Text style={{ fontFamily: fonts.expandedBold, color: colors.green }}>
            FOR HELP CALL (914) 505-7941
          </Text>
        </View>
        {streamerProfile.status == 0 ? <StreamerToDo /> : <StreamerStats />}
        <DashboardTabView />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {},
  header: {
    fontFamily: fonts.semiBold,
    fontSize: 35,
    color: "white",
    marginBottom: 25,
  },
});

export default StreamerDashboardScreen;
