import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import colors from "../../../../res/colors";
import fonts from "../../../../res/fonts";
import { Context as AuthContext } from "../../../../context/AuthContext";
import { Context as ReactContext } from "../../../../context/ReactContext";
import CurrencyInput from "react-native-currency-input";
import Popup from "reactjs-popup";
import "../../../HomeScreen/FeedView/ClipStyle.css";
import { MaterialIcons } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import { useIsFocused } from "@react-navigation/native";
import { Mixpanel } from "../../../../api/mixPanel";
import { range } from "lodash";

const ReactListModal = ({ item, isModalVisible, setIsModalVisible }) => {
  const {
    state: { token },
  } = useContext(AuthContext);
  const { createReactListing } = useContext(ReactContext);
  const [price, setPrice] = useState(item.pool.reserve);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () => setIsModalVisible(false);

  const isFocused = useIsFocused();
  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;

  const createListing = async () => {
    if (!isLoading) {
      setIsLoading(true);
      if (price && price > 5.0) {
        Mixpanel.track("Initiate React Listing", {
          reactId: item._id,
          streamerAlias: item.pool.streamerId.alias,
        });
        await createReactListing({ reactId: item._id, price: price });
        if (isFocused) {
          setIsLoading(false);
          setIsModalVisible(false);
        }
      } else {
        setError("Price must be greater than $5.00");
        setIsLoading(false);
      }
    }
  };

  const innerContent = () => {
    return (
      <>
        <Text style={styles.inputLabel}>REACT TITLE</Text>
        <Text style={styles.clipName}>{item.pool.reactName}</Text>
        <Text style={styles.inputLabel}>SALE PRICE $</Text>
        <CurrencyInput
          value={price}
          onChangeValue={setPrice}
          unit="$"
          delimiter=","
          separator="."
          precision={2}
          ignoreNegative={true}
          onChangeText={(formattedValue) => {
            console.log(formattedValue); // $2,310.46
          }}
          style={styles.inputStyle}
          keyboardType={"numeric"}
          placeholder={"$0.00"}
        />
        <View style={styles.commonAmountRow}>
          <TouchableOpacity
            style={styles.amountButton}
            onPress={() => setPrice(price + 5.0)}
          >
            <Text style={styles.amountText}>+$5.00</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.amountButton}
            onPress={() => setPrice(price + 25.0)}
          >
            <Text style={styles.amountText}>+$25.00</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.amountButton}
            onPress={() => setPrice(price + 50.0)}
          >
            <Text style={styles.amountText}>+$50.00</Text>
          </TouchableOpacity>
        </View>
        {error && error != "" ? (
          <Text style={styles.errorText}>{error}</Text>
        ) : null}
        <TouchableOpacity
          style={styles.purchaseButton}
          onPress={() => createListing()}
        >
          {isLoading ? (
            <ActivityIndicator />
          ) : (
            <Text style={styles.purchaseText}>
              List React on Market ({price ? `$${price.toFixed(2)}` : null})
            </Text>
          )}
        </TouchableOpacity>
      </>
    );
  };

  return (
    <Popup
      open={isModalVisible}
      closeOnDocumentClick
      onClose={closeModal}
      position="center center"
      contentStyle={{
        borderTopColor: colors.green,
        borderTopWidth: 5,
        borderRadius: 10,
        width: modalWidth,
      }}
    >
      <View style={styles.container}>
        <View style={styles.topRow}>
          <Text style={styles.header}>Sell React</Text>
          <TouchableOpacity
            onPress={() => {
              Mixpanel.track("List Clip Close");
              setIsModalVisible(!isModalVisible);
            }}
          >
            <MaterialIcons name="cancel" size={24} color="grey" />
          </TouchableOpacity>
        </View>

        {innerContent()}
      </View>
    </Popup>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 30,
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
  },
  inputLabel: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
    marginBottom: 10,
  },
  clipName: {
    fontFamily: fonts.semiBold,
    fontSize: 22,
    marginBottom: 30,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    fontFamily: fonts.expandedRegular,
    color: "black",
    fontSize: 25,
    outlineWidth: 0,
  },
  commonAmountRow: {
    flexDirection: "row",
    marginVertical: 20,
    justifyContent: "space-between",
  },
  amountButton: {
    width: "30%",
    backgroundColor: colors.green,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  amountText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
  },
  purchaseButton: {
    backgroundColor: "black",
    marginTop: 25,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  purchaseText: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 14,
  },
  explainerText: {
    fontFamily: fonts.text,
    color: colors.greyText,
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
  },
  noteText: {
    fontFamily: fonts.text,
    color: "grey",
    marginVertical: 10,
    fontSize: 14,
  },
  errorText: {
    fontFamily: fonts.text,
    color: "red",
    marginBottom: 5,
    fontSize: 14,
  },
});

export default ReactListModal;
