import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { socket } from "../api/Socket";
import { toast } from "react-toastify";

const reactReducer = (state, action) => {
  switch (action.type) {
    case "fetch_feed":
      return {
        ...state,
        feedList: action.payload.reactRecords,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        isFeedLoaded: true,
      };
    case "load_more":
      return {
        ...state,
        feedList: state.feedList.concat(action.payload.reactRecords),
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    case "rated_react":
      return {
        ...state,
        feedList: _.map(state.feedList, function (feedItem) {
          if (`${feedItem._id}` == `${action.payload.poolId}`) {
            return {
              ...feedItem,
              rating: action.payload.rating,
              myRating: action.payload.myRating
                ? {
                    score: action.payload.myRating,
                  }
                : feedItem.myRating,
            };
          } else {
            return feedItem;
          }
        }),
      };
    case "update_pool":
      return {
        ...state,
        feedList: _.map(state.feedList, function (feedItem) {
          if (`${feedItem._id}` == `${action.payload.poolId}`) {
            return {
              ...action.payload.pool,
            };
          } else {
            return feedItem;
          }
        }),
        incomingBids: null,
      };
    case "update_portfolio":
      return {
        ...state,
        marketListings: null,
      };
    case "fetch_incoming_bids":
      return {
        ...state,
        incomingBids: action.payload,
      };
    case "fetch_outgoing_bids":
      return {
        ...state,
        outgoingBids: action.payload,
      };
    case "fetch_market_listings":
      return {
        ...state,
        marketListings: action.payload,
      };
    case "reset_feed":
      return {
        ...state,
        isFeedLoaded: false,
        feedList: [],
        currentPage: 1,
        totalPages: 1,
      };
    case "placed_bid":
      return {
        ...state,
        outgoingBids: null,
      };
    case "fetch_balance":
      return {
        ...state,
        balance: action.payload,
      };
    default:
      return state;
  }
};

const makeReactConnection = (dispatch) => async () => {
  try {
    socket.on("updatePool", (data) => {
      console.log("--GLOBAL SOCKET POOL UPDATE " + data.body.poolId);
      dispatch({ type: "update_pool", payload: data.body });
    });
    socket.on("updateRating", (data) => {
      console.log("--GLOBAL SOCKET RATING UPDATE " + data.body.poolId);
      dispatch({ type: "rated_react", payload: data.body });
    });
    socket.on("updatePortfolio", (data) => {
      console.log("SOCKET: Resetting market listings");
      dispatch({ type: "update_portfolio" });
    });
    socket.on("ownReactBidPlaced", (data) => {
      console.log("I PLACED A BID");
      dispatch({ type: "placed_bid" });
    });
  } catch (err) {
    console.log("makeReactConnection error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "makeReactConnection");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const fetchReactFeed =
  (dispatch) =>
  async ({ page }) => {
    try {
      console.log("FETCHING REACT FEED");
      const response = await serverApi.post("/feed", { page });
      if (page > 1) {
        dispatch({
          type: "load_more",
          payload: response.data,
        });
      } else {
        dispatch({ type: "fetch_feed", payload: response.data });
      }
    } catch (err) {
      console.log("fetchReactFeed error");
      console.log(err);
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "fetchReactFeed");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const rateReact =
  (dispatch) =>
  async ({ poolId, score }) => {
    try {
      const response = await serverApi.post("/rate-react", { poolId, score });
      dispatch({ type: "rated_react", payload: response.data });
    } catch (err) {
      console.log("rateReact error");
      console.log(err);
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "rateReact");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const acceptReactBid =
  (dispatch) =>
  async ({ reactId, bidId }) => {
    try {
      console.log("ACCEPTING REACT BID");
      const response = await serverApi.post("/accept-react-bid", {
        reactId,
        bidId,
      });
    } catch (err) {
      console.log("acceptReactBid error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "acceptReactBid");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const purchaseStreamerReact =
  (dispatch) =>
  async ({ poolId, price }) => {
    try {
      toast.error("Purchases not available at this time");
      // const stripePromise = loadStripe(
      //   "pk_live_51IY33tEHZWPb26FdB3C1CJiEun2i35keEX6t5Lo9R6PLKXCGCXk9FeRlB0XsbZ7SA8LKBb5ZbX7vPEegK1vVcAqZ00pMNioPYZ"
      // );
      // const stripe = await stripePromise;
      // const response = await serverApi.post("/react-checkout-session", {
      //   poolId,
      //   price,
      // });
      // const result = await stripe.redirectToCheckout({
      //   sessionId: response.data.id,
      // });
      // if (result.error) {
      //   console.log(result.error);
      //   toast.error("Something went wrong. Refresh the page and try again.");
      // }
    } catch (err) {
      console.log(err);
      console.log("purchaseStreamerReact error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "purchaseStreamerReact");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const pullStreamerReact =
  (dispatch) =>
  async ({ streamerId }) => {
    try {
      toast.error("Purchases not available at this time");
      // const stripePromise = loadStripe(
      //   "pk_live_51IY33tEHZWPb26FdB3C1CJiEun2i35keEX6t5Lo9R6PLKXCGCXk9FeRlB0XsbZ7SA8LKBb5ZbX7vPEegK1vVcAqZ00pMNioPYZ"
      // );
      // const stripe = await stripePromise;
      // const response = await serverApi.post("/pull-react-checkout", {
      //   streamerId,
      // });
      // const result = await stripe.redirectToCheckout({
      //   sessionId: response.data.id,
      // });
      // if (result.error) {
      //   console.log(result.error);
      //   toast.error("Something went wrong. Refresh the page and try again.");
      // }
    } catch (err) {
      console.log(err);
      console.log("pullStreamerReact error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "pullStreamerReact");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const placeReactBid =
  (dispatch) =>
  async ({ poolId, price }) => {
    try {
      toast.error("Purchases not available at this time");
      // const stripePromise = loadStripe(
      //   "pk_live_51IY33tEHZWPb26FdB3C1CJiEun2i35keEX6t5Lo9R6PLKXCGCXk9FeRlB0XsbZ7SA8LKBb5ZbX7vPEegK1vVcAqZ00pMNioPYZ"
      // );
      // const stripe = await stripePromise;
      // const response = await serverApi.post("/react-bid-pool", {
      //   poolId,
      //   price,
      // });
      // const result = await stripe.redirectToCheckout({
      //   sessionId: response.data.id,
      // });
      // if (result.error) {
      //   console.log(result.error);
      //   toast.error("Something went wrong. Refresh the page and try again.");
      // }
    } catch (err) {
      console.log(err);
      console.log("placeReactBid error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "placeReactBid");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const purchaseReactListing =
  (dispatch) =>
  async ({ listingId, poolId, price }) => {
    try {
      toast.error("Purchases not available at this time");
      // const stripePromise = loadStripe(
      //   "pk_live_51IY33tEHZWPb26FdB3C1CJiEun2i35keEX6t5Lo9R6PLKXCGCXk9FeRlB0XsbZ7SA8LKBb5ZbX7vPEegK1vVcAqZ00pMNioPYZ"
      // );
      // const stripe = await stripePromise;
      // const response = await serverApi.post("/react-purchase-listing", {
      //   listingId,
      //   poolId,
      //   price,
      // });
      // const result = await stripe.redirectToCheckout({
      //   sessionId: response.data.id,
      // });
      // if (result.error) {
      //   console.log(result.error);
      //   toast.error("Something went wrong. Refresh the page and try again.");
      // }
    } catch (err) {
      console.log(err);
      console.log("purchaseReactListing error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "purchaseReactListing");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const createReactListing =
  (dispatch) =>
  async ({ reactId, price }) => {
    try {
      const response = await serverApi.post("/create-listing", {
        reactId,
        price,
      });
      toast.success("React listed successfully");
    } catch (err) {
      console.log(err);
      console.log("createReactListing error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "createReactListing");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const fetchIncomingBids = (dispatch) => async () => {
  try {
    console.log("FETCHING INCOMING BIDS");
    const response = await serverApi.get("/react-bids-available");
    dispatch({
      type: "fetch_incoming_bids",
      payload: response.data,
    });
  } catch (err) {
    console.log("fetchIncomingBids error");
    console.log(err);
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchIncomingBids");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const fetchOutgoingBids = (dispatch) => async () => {
  try {
    console.log("FETCHING OUTGOING BIDS");
    const response = await serverApi.get("/react-outgoing-bids");
    dispatch({
      type: "fetch_outgoing_bids",
      payload: response.data,
    });
  } catch (err) {
    console.log("fetchOutgoingBids error");
    console.log(err);
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchOutgoingBids");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const fetchMarketListings = (dispatch) => async () => {
  try {
    console.log("FETCHING MARKET LISTINGS");
    const response = await serverApi.get("/react-market-listings");
    dispatch({
      type: "fetch_market_listings",
      payload: response.data,
    });
  } catch (err) {
    console.log("fetchMarketListings error");
    console.log(err);
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchMarketListings");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const fetchBalance = (dispatch) => async () => {
  try {
    console.log("FETCHING BALANCE");
    const response = await serverApi.get("/outstanding-balance");
    dispatch({
      type: "fetch_balance",
      payload: response.data,
    });
  } catch (err) {
    console.log("fetchBalance error");
    console.log(err);
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchBalance");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

export const { Provider, Context } = createDataContext(
  reactReducer,
  {
    makeReactConnection,
    fetchReactFeed,
    rateReact,
    acceptReactBid,
    purchaseStreamerReact,
    pullStreamerReact,
    placeReactBid,
    purchaseReactListing,
    createReactListing,
    fetchIncomingBids,
    fetchOutgoingBids,
    fetchMarketListings,
    fetchBalance,
  },
  {
    isFeedLoaded: false,
    feedList: [],
    currentPage: 1,
    totalPages: 1,
    incomingBids: null,
    outgoingBids: null,
    marketListings: null,
    balance: null,
  }
);
