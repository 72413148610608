import React, { useReducer } from "react";

export default (reducer, actions, defaultValue) => {
  const Context = React.createContext();

  //Create helper provider component
  // Going to take some children as props
  const Provider = ({ children }) => {
    //Set up useReducer call and pass in reducer function (switch statement)
    const [state, dispatch] = useReducer(reducer, defaultValue);

    //Loop over all different actions inside our actions object
    //Need to call each of those with our dispatch (which will automatically send our action to the reducer)
    const boundActions = {};
    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    return (
      //Underlying react component that make this available to all
      //other component rendered underneath it
      //Value prop is the actual info that gets shared with child components
      //boundActions is what we use to change our state
      //Show whichever child component was passed in as a prop
      //Lecture 130 for more info on boundActions
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };

  return { Context: Context, Provider: Provider };
};
