import { StyleSheet } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";

const desktop = StyleSheet.create({
  background: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    opacity: 0.8,
    zIndex: -1,
  },
  heroContainer: {
    justifyContent: "center",
    flexShrink: 1,
    maxWidth: 800,
  },
  heroViewContainer: {
    justifyContent: "center",
    paddingTop: "8%",
    paddingBottom: 200,
    flexShrink: 1,
    width: "40%",
    alignItems: "center",
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    letterSpacing: 16,
    fontSize: 14,
    color: colors.darkerGreen,
    marginBottom: 20,
  },
  header: {
    marginBottom: 25,
    fontFamily: fonts.bold,
    fontSize: 75,
    color: "white",
    lineHeight: 90,
  },
  body: {
    width: 550,
    fontFamily: fonts.expandedRegular,
    color: colors.text,
    fontSize: 20,
    lineHeight: 30,
  },
  heroButton: {
    marginTop: 40,
    borderRadius: 50,
    borderWidth: 2,
    borderColor: colors.green,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 45,
    paddingVertical: 18,
    alignSelf: "flex-start",
  },
  heroButtonText: {
    fontFamily: fonts.expandedBold,
    fontSize: 11,
    color: "white",
    letterSpacing: 3,
  },
  imageContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: -50,
  },
  videoContainer: {
    backgroundColor: "grey",
    borderRadius: 10,
    borderWidth: 5,
    borderColor: colors.green,
    width: 600,
    height: 350,
    position: "absolute",
    alignSelf: "center",
  },
  backgroundImage: {
    width: "80%",
    height: hp("80%"),
    resizeMode: "contain",
    alignSelf: "center",
  },
});

const tablet = StyleSheet.create({
  background: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    opacity: 0.8,
    zIndex: -1,
  },
  heroContainer: {
    justifyContent: "center",
    flexShrink: 1,
    maxWidth: 800,
  },
  heroViewContainer: {
    justifyContent: "center",
    paddingTop: "8%",
    paddingBottom: 200,
    flexShrink: 1,
    width: "40%",
    alignItems: "center",
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    letterSpacing: 16,
    fontSize: 14,
    color: colors.darkerGreen,
    marginBottom: 20,
  },
  header: {
    marginBottom: 25,
    fontFamily: fonts.bold,
    fontSize: 75,
    color: "white",
    lineHeight: 90,
  },
  body: {
    width: 550,
    fontFamily: fonts.expandedRegular,
    color: colors.text,
    fontSize: 20,
    lineHeight: 30,
  },
  heroButton: {
    marginTop: 40,
    borderRadius: 50,
    borderWidth: 2,
    borderColor: colors.green,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 45,
    paddingVertical: 18,
    alignSelf: "flex-start",
  },
  heroButtonText: {
    fontFamily: fonts.expandedBold,
    fontSize: 11,
    color: "white",
    letterSpacing: 3,
  },
  imageContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: -50,
  },
  videoContainer: {
    backgroundColor: "grey",
    borderRadius: 10,
    borderWidth: 5,
    borderColor: colors.green,
    width: 600,
    height: 350,
    position: "absolute",
    alignSelf: "center",
  },
  backgroundImage: {
    width: "80%",
    height: hp("80%"),
    resizeMode: "contain",
    alignSelf: "center",
  },
});

const mobile = StyleSheet.create({
  background: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    opacity: 0.8,
    zIndex: -1,
  },
  heroContainer: {
    justifyContent: "center",
    flexShrink: 1,
    maxWidth: "90%",
  },
  heroViewContainer: {
    justifyContent: "center",
    paddingTop: "8%",
    paddingBottom: 200,
    flexShrink: 1,
    width: "40%",
    alignItems: "center",
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    letterSpacing: 16,
    fontSize: 14,
    color: colors.darkerGreen,
    marginBottom: 20,
  },
  header: {
    marginBottom: 25,
    fontFamily: fonts.bold,
    fontSize: 50,
    color: "white",
    lineHeight: 60,
  },
  body: {
    width: "70%",
    fontFamily: fonts.expandedRegular,
    color: colors.text,
    fontSize: 18,
    lineHeight: 30,
  },
  heroButton: {
    marginTop: 40,
    borderRadius: 50,
    borderWidth: 2,
    borderColor: colors.green,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 45,
    paddingVertical: 18,
    alignSelf: "flex-start",
  },
  heroButtonText: {
    fontFamily: fonts.expandedBold,
    fontSize: 11,
    color: "white",
    letterSpacing: 3,
  },
  imageContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: -50,
  },
  videoContainer: {
    backgroundColor: "grey",
    borderRadius: 10,
    borderWidth: 5,
    borderColor: colors.green,
    width: 600,
    height: 350,
    position: "absolute",
    alignSelf: "center",
  },
  backgroundImage: {
    width: "80%",
    height: hp("80%"),
    resizeMode: "contain",
    alignSelf: "center",
  },
});

export { desktop, tablet, mobile };
