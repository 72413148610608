import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  FlatList,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import CardChart from "./CardChart";
import RenderCard from "./RenderCard";
import { Context as UserContext } from "../../../context/UserContext";
import CardGrid from "./CardGrid";
import EmptyPortfolio from "../../../../assets/images/EmptyPortfolio";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

const CardView = () => {
  //const [sentToMix, setSentToMix] = useState(false);
  const {
    state: { isUserInfoLoaded, cardPortfolio },
  } = useContext(UserContext);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <View
      style={[
        styles.outerContainer,
        { padding: isMobile ? 0 : 25, borderWidth: isMobile ? 0 : 1 },
      ]}
    >
      <View style={styles.heroContainer}>
        <Text style={styles.header}>
          {cardPortfolio.cardsAcquired.length} Card(s) in Total
        </Text>
      </View>
      {cardPortfolio.cardsAcquired.length > 0 ||
      cardPortfolio.packsAcquired.filter((pack) => pack.type == 1).length >
        0 ? (
        <CardGrid />
      ) : (
        <View>
          <EmptyPortfolio
            width={400}
            height={400}
            style={{
              alignSelf: "center",
              marginVertical: 20,
            }}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    backgroundColor: "black",
    // marginHorizontal: 10,
  },
  heroContainer: {},
  header: {
    fontFamily: fonts.expandedBlack,
    fontSize: 20,
    color: "white",
    marginBottom: 50,
  },
});

export default CardView;
