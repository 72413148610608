import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  FlatList,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import TopBar from "../../components/TopBar";
import { useMediaQuery } from "react-responsive";
import fonts from "../../res/fonts";
import { Context as UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import PortfolioTabView from "./PortfolioTabView";

const PortfolioScreen = ({ route, navigation }) => {
  //const [sentToMix, setSentToMix] = useState(false);
  const {
    state: { isUserInfoLoaded, cardPortfolio },
  } = useContext(UserContext);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <>
      <TopBar selected={1} />

      {!isUserInfoLoaded ? (
        <View style={{ flex: 1, backgroundColor: "black", paddingTop: "5%" }}>
          <ActivityIndicator />
        </View>
      ) : (
        <ScrollView
          style={{
            backgroundColor: "black",
            paddingTop: 50,
            paddingHorizontal: 0.05 * window.width,
            paddingBottom: 200,
            flexGrow: 1,
          }}
          contentContainerStyle={{
            width: isMobile ? 0.98 * window.width : 0.9 * window.width,
            alignSelf: "center",
          }}
        >
          <PortfolioTabView />
        </ScrollView>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  heroContainer: {},
  header: {
    fontFamily: fonts.expandedBlack,
    fontSize: 20,
    color: "white",
    marginVertical: 50,
  },
});

export default PortfolioScreen;
