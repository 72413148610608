import React, { useContext } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
} from "react-native";
import Logo from "../../../assets/images/Logo";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../RootNavigation";
import { Mixpanel } from "../../api/mixPanel";
import { FontAwesome5 } from "@expo/vector-icons";

const LogoSection = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });

  return (
    <View
      style={[
        styles.navSection,
        { width: !isTabletOrMobile ? "33.33%" : null },
      ]}
    >
      <TouchableOpacity
        onPress={() => {
          Mixpanel.track("Page View", { pageName: "Home" });
          RootNavigation.navigate("Home");
        }}
      >
        <Logo
          width={80}
          height={16}
          style={{
            alignSelf: "center",
            marginVertical: 20,
          }}
        />
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.betaButton}
        onPress={() => {
          Mixpanel.track("Page View", { pageName: "Beta" });
          RootNavigation.navigate("Beta");
        }}
      >
        <Text style={styles.betaText}>BETA</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.socialButton}
        onPress={() => window.open("https://discord.gg/2MT3D2SHmq")}
      >
        <FontAwesome5 name="discord" size={24} color={colors.green} />
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.socialButton}
        onPress={() => window.open("https://twitter.com/BUFFDgg")}
      >
        <FontAwesome5 name="twitter" size={24} color={colors.green} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  navSection: {
    flexDirection: "row",
    alignItems: "center",
  },

  betaText: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
    color: colors.green,
  },
  betaButton: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 2,
    marginHorizontal: 15,
    backgroundColor: "#1C1C1C",
  },
  socialButton: {
    marginRight: 20,
  },
});

export default LogoSection;
