import { StyleSheet } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../res/colors";
import fonts from "../res/fonts";

const desktop = (window) =>
  StyleSheet.create({
    container: {
      paddingVertical: "5%",
      paddingHorizontal: "15%",
      flexGrow: 1,
    },
    topRow: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 25,
    },
    header: {
      fontFamily: fonts.semiBold,
      fontSize: 40,
    },
    descText: {
      fontFamily: fonts.text,
      fontSize: 20,
      color: "grey",
      marginBottom: 20,
    },
    pageSelector: {
      position: "relative",
      bottom: 20,
      alignSelf: "center",
      flexDirection: "row",
      backgroundColor: "white",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 11,
      },
      shadowOpacity: 0.25,
      shadowRadius: 14.78,
      elevation: 22,
      padding: 20,
      borderRadius: 10,
      justifyContent: "space-between",
    },
    pageButtonText: {
      fontFamily: fonts.bold,
      fontSize: 20,
      marginHorizontal: 20,
    },
    signaturePad: {
      backgroundColor: "grey",
      padding: 20,
      borderRadius: 10,
    },
    acceptButton: {
      backgroundColor: "green",
      paddingVertical: 10,
      paddingHorizontal: 40,
      borderRadius: 5,
      marginTop: 40,
      alignSelf: "flex-start",
    },
    acceptButtonText: {
      fontFamily: fonts.bold,
      color: "white",
      fontSize: 20,
    },
    errorText: {
      color: "red",
      fontFamily: fonts.text,
      marginTop: 10,
      fontSize: 14,
    },
  });

const tablet = (window) =>
  StyleSheet.create({
    container: {
      paddingVertical: "5%",
      paddingHorizontal: "5%",
      flexGrow: 1,
    },
    topRow: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 25,
    },
    header: {
      fontFamily: fonts.semiBold,
      fontSize: 30,
    },
    descText: {
      fontFamily: fonts.text,
      fontSize: 20,
      color: "grey",
      marginBottom: 20,
    },
    pageSelector: {
      position: "relative",
      bottom: 20,
      alignSelf: "center",
      flexDirection: "row",
      backgroundColor: "white",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 11,
      },
      shadowOpacity: 0.25,
      shadowRadius: 14.78,
      elevation: 22,
      padding: 20,
      borderRadius: 10,
      justifyContent: "space-between",
    },
    pageButtonText: {
      fontFamily: fonts.bold,
      fontSize: 20,
      marginHorizontal: 20,
    },
    signaturePad: {
      backgroundColor: "grey",
      padding: 20,
      borderRadius: 10,
    },
    acceptButton: {
      backgroundColor: "green",
      paddingVertical: 10,
      paddingHorizontal: 40,
      borderRadius: 5,
      marginTop: 40,
      alignSelf: "flex-start",
    },
    acceptButtonText: {
      fontFamily: fonts.bold,
      color: "white",
      fontSize: 20,
    },
    errorText: {
      color: "red",
      fontFamily: fonts.text,
      marginTop: 10,
      fontSize: 14,
    },
  });

const mobile = (window) =>
  StyleSheet.create({
    container: {
      paddingVertical: "15%",
      paddingHorizontal: "2.5%",
      flexGrow: 1,
    },
    topRow: {
      flexDirection: "column-reverse",
      justifyContent: "space-between",
      marginBottom: 25,
    },
    header: {
      fontFamily: fonts.semiBold,
      fontSize: 25,
      marginTop: 15,
    },
    descText: {
      fontFamily: fonts.text,
      fontSize: 16,
      color: "grey",
      marginBottom: 20,
    },
    pageSelector: {
      position: "relative",
      bottom: 20,
      alignSelf: "center",
      flexDirection: "row",
      backgroundColor: "white",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 11,
      },
      shadowOpacity: 0.25,
      shadowRadius: 14.78,
      elevation: 22,
      padding: 20,
      borderRadius: 10,
      justifyContent: "space-between",
    },
    pageButtonText: {
      fontFamily: fonts.bold,
      fontSize: 20,
      marginHorizontal: 20,
    },
    signaturePad: {
      backgroundColor: "grey",
      padding: 20,
      borderRadius: 10,
    },
    acceptButton: {
      backgroundColor: "green",
      paddingVertical: 10,
      paddingHorizontal: 40,
      borderRadius: 5,
      marginTop: 40,
      alignSelf: "flex-start",
    },
    acceptButtonText: {
      fontFamily: fonts.bold,
      color: "white",
      fontSize: 20,
    },
    errorText: {
      color: "red",
      fontFamily: fonts.text,
      marginTop: 10,
      fontSize: 14,
    },
  });

export { desktop, tablet, mobile };
