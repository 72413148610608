const io = require("socket.io-client");
import { Platform } from "react-native";

export const socket = io("https://cryptic-lake-17949.herokuapp.com", {
  transports: ["websocket"],
  allowUpgrades: false,
  reconnection: false,
});

// export const socket = io("http://localhost:3000", {
//   transports: ["websocket"],
//   allowUpgrades: false,
//   reconnection: false,
// });

/*let uri;
if (Platform.OS == "ios") {
  uri = "http://localhost:3000";
} else {
  uri = "http://10.0.2.2:3000";
}
export const socket = io(uri, {
  transports: ["websocket"],
  allowUpgrades: false,
  reconnection: false,
})*/

/*export const socket = io("http://e5ff7823e123.ngrok.io", {
  transports: ["websocket"],
  allowUpgrades: false,
  reconnection: false,
});*/
