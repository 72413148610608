import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  FlatList,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { Input } from "react-native-elements";
import { useMediaQuery } from "react-responsive";
import { Context as UserContext } from "../../context/UserContext";

const StreamerStats = () => {
  const {
    fetchStreamerIncome,
    state: { isUserInfoLoaded, streamerProfile, incomeGenerated },
  } = useContext(UserContext);
  //const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    const loadIncome = async () => {
      if (!incomeGenerated) {
        await fetchStreamerIncome();
      }
    };
    loadIncome();
  }, []);

  const poolsLaunched =
    streamerProfile.reactPools != undefined
      ? streamerProfile.reactPools.filter((pool) => pool.status == 2).length
      : 0;

  const poolsReadyToLaunch =
    streamerProfile.reactPools != undefined
      ? streamerProfile.reactPools.filter((pool) => pool.status == 1).length > 0
      : false;

  return (
    <>
      {poolsLaunched > 0 ? (
        <Text style={styles.linkTitle}>
          Sharable link:{" "}
          <TouchableOpacity
            onPress={() =>
              window.open(`https://buffd.gg/${streamerProfile.alias}`)
            }
          >
            <Text style={{ color: "white" }}>
              https://buffd.gg/{streamerProfile.alias}
            </Text>
          </TouchableOpacity>
        </Text>
      ) : (
        <Text style={styles.linkTitle}>
          Launch your first React to create your online store
        </Text>
      )}
      <View
        style={[
          styles.container,
          { flexDirection: isMobile ? "column" : "row" },
        ]}
      >
        <View style={styles.itemContainer}>
          <Text style={styles.label}>Collectibles Launched</Text>
          <Text style={styles.value}>{poolsLaunched}</Text>
        </View>
        <View style={styles.itemContainer}>
          <Text style={styles.label}>Income Generated</Text>
          {!incomeGenerated ? (
            <ActivityIndicator
              color="white"
              style={{ marginTop: 10, alignSelf: "flex-start" }}
            />
          ) : (
            <Text style={styles.value}>
              ${incomeGenerated.amountDue.toFixed(2)}
            </Text>
          )}
        </View>
        <View style={styles.itemContainer}>
          <Text style={styles.label}>Action Items</Text>
          {poolsReadyToLaunch ? (
            <Text style={[styles.value, { fontSize: 16, color: "red" }]}>
              React ready to launch
            </Text>
          ) : (
            <Text style={[styles.value, { fontSize: 16 }]}>
              Nothing to do 😃
            </Text>
          )}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  linkTitle: {
    marginTop: 5,
    marginBottom: 25,
    color: "grey",
    fontFamily: fonts.text,
    fontSize: 20,
  },
  container: {
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  itemContainer: {
    marginVertical: 10,
  },
  label: {
    fontFamily: fonts.expandedBold,
    color: "grey",
    marginBottom: 5,
  },
  value: {
    fontFamily: fonts.bold,
    color: "white",
    fontSize: 45,
  },
});

export default StreamerStats;
