import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import TopBar from "../../../components/TopBar";
import { useMediaQuery } from "react-responsive";
import fonts from "../../../res/fonts";
import LogoBlack from "../../../../assets/images/LogoBlack";
import { Input } from "react-native-elements";
import { toast } from "react-toastify";
import { Context as AuthContext } from "../../../context/AuthContext";
import * as EmailValidator from "email-validator";
import { Mixpanel } from "../../../api/mixPanel";

const SignUpScreen = ({ navigation }) => {
  //const [sentToMix, setSentToMix] = useState(false);
  const {
    signup,
    clearErrorMessage,
    state: { errorMessage },
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;
  const modalHorizontalPadding = isMobile ? 15 : 50;

  const regex = /[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]/g;

  const validateEntries = () => {
    if (alias == "" || email == "" || password == "" || confirmPassword == "") {
      toast.error("Please complete all fields.");
      return false;
    } else if (regex.test(alias)) {
      toast.error("The alias cannot contain special characters.");
      return false;
    } else if (alias.indexOf(" ") >= 0) {
      toast.error("The alias cannot contain spaces.");
      return false;
    } else if (password != confirmPassword) {
      toast.error("Passwords do not match.");
      return false;
    } else if (EmailValidator.validate(email) == false) {
      toast.error("Invalid email.");
      return false;
    } else {
      return true;
    }
  };

  const onSignUp = async () => {
    setIsLoading(true);
    if (validateEntries()) {
      await signup({ alias, email, password });
    } else {
      setIsLoading(false);
      Mixpanel.track("Sign Up Attempt", {
        attemptedAlias: alias,
        attemptedEmail: email,
      });
    }
  };

  useEffect(() => {
    console.log("Ending Loading");
    setIsLoading(false);
    clearErrorMessage();
    return () => {
      setIsLoading(false);
      clearErrorMessage();
    };
  }, [errorMessage]);

  return (
    <>
      <TopBar />
      <ScrollView contentContainerStyle={styles.container}>
        <View
          style={[
            styles.modalContainer,
            { width: modalWidth, paddingHorizontal: modalHorizontalPadding },
          ]}
        >
          <View style={styles.logoContainer}>
            <LogoBlack
              width={100}
              height={20}
              style={{
                alignSelf: "center",
                marginVertical: 20,
              }}
            />
            <Text style={styles.streamerDesc}>
              Are you a streamer?{" "}
              <TouchableOpacity
                onPress={() => navigation.navigate("StreamerInfo")}
              >
                <Text style={{ textDecorationLine: "underline" }}>
                  Enroll as Talent
                </Text>
              </TouchableOpacity>
            </Text>
            <Text style={styles.streamerDesc}>
              If you already enrolled as a streamer,{" "}
              <TouchableOpacity onPress={() => navigation.navigate("SignIn")}>
                <Text style={{ textDecorationLine: "underline" }}>Log In</Text>
              </TouchableOpacity>
            </Text>
          </View>
          <View style={{ alignSelf: "center" }}>
            <Text style={styles.descText}>Sign up to Buffd</Text>
            <Text style={styles.subDesc}>
              Already have an account?{" "}
              <TouchableOpacity onPress={() => navigation.navigate("SignIn")}>
                <Text style={{ textDecorationLine: "underline" }}>Log in</Text>
              </TouchableOpacity>
            </Text>
          </View>
          <View style={styles.divider} />
          <View style={styles.inputContainer}>
            <Input
              label="Alias"
              labelStyle={styles.labelStyle}
              placeholder={"This is what you'll go by"}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={alias}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newAlias) => setAlias(newAlias)}
            />
            <Input
              label="Email"
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={email}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newEmail) => setEmail(newEmail)}
            />
            <Input
              label="Password"
              secureTextEntry={true}
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={password}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newPassword) => setPassword(newPassword)}
            />
            <Input
              label="Confirm password"
              secureTextEntry={true}
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={confirmPassword}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newConfirmPassword) =>
                setConfirmPassword(newConfirmPassword)
              }
            />
            <TouchableOpacity
              style={styles.submitButton}
              onPress={() => onSignUp()}
            >
              {isLoading ? (
                <ActivityIndicator />
              ) : (
                <Text style={styles.submitButtonText}>Sign Up</Text>
              )}
            </TouchableOpacity>
            <Text style={styles.confirmText}>
              By signing up for Buffd, you acknowledge that you have read and
              agree to all applicable Terms of Service and our Privacy Policy
            </Text>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#f9f8f9",
    paddingHorizontal: "5%",
    paddingTop: 50,
    paddingBottom: 200,
  },
  header: {
    fontFamily: fonts.bold,
    fontSize: 45,
    color: "white",
  },
  modalContainer: {
    backgroundColor: "white",
    alignContent: "center",
    //justifyContent: "center",
    paddingVertical: 50,
    alignSelf: "center",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  logoContainer: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
    marginBottom: 30,
  },
  descText: {
    fontFamily: fonts.bold,
    color: colors.secondaryGreen,
    fontSize: 20,
    textAlign: "center",
  },
  subDesc: {
    fontFamily: fonts.semiBold,
    color: "grey",
    fontSize: 16,
    marginTop: 15,
    textAlign: "center",
  },
  streamerDesc: {
    fontFamily: fonts.semiBold,
    color: "black",
    fontSize: 16,
    textAlign: "center",
    marginBottom: 15,
  },
  divider: {
    borderTopWidth: 0.5,
    borderColor: "grey",
    marginTop: 20,
    opacity: 0.2,
  },
  inputContainer: {
    marginTop: 30,
    marginHorizontal: 0,
  },
  labelStyle: {
    fontFamily: fonts.text,
    fontSize: 14,
    marginBottom: 10,
    color: "grey",
  },
  inputContainerStyle: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    marginVertical: 5,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 25,
    paddingVertical: 5,
  },
  inputTextStyle: {
    fontFamily: fonts.text,
    color: "black",
    fontSize: 14,
    outlineWidth: 0,
  },
  submitButton: {
    backgroundColor: colors.green,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
    borderRadius: 8,
    width: "75%",
    alignSelf: "center",
    marginTop: 20,
  },
  submitButtonText: {
    fontFamily: fonts.bold,
    color: colors.secondaryGreen,
    fontSize: 14,
  },
  confirmText: {
    fontFamily: fonts.regular,
    color: "grey",
    fontSize: 10,
    marginTop: 20,
    textAlign: "center",
  },
});

export default SignUpScreen;
