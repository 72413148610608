import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { useMediaQuery } from "react-responsive";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  AntDesign,
} from "@expo/vector-icons";

const ColumnContainer = ({ styles }) => {
  return (
    <View>
      <Text style={styles.topText}>TENANTS</Text>
      <Text style={styles.header}>It pays to support.</Text>
      <Text style={styles.descText}>
        It's time to take your stream experience to the next level.
      </Text>
      <View style={styles.columnContainer}>
        <View style={styles.stepContainer}>
          <View>
            <View style={styles.headerRowTab}>
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons
                  name="compass"
                  size={35}
                  color="black"
                />
              </View>
              <Text style={styles.stepHeader}>Mine</Text>
            </View>
            <Text style={styles.stepDesc}>
              Buffd supports streamers of all sizes because too much talent goes
              unnoticed. Discover new talent before the paparazzi takes notice.
            </Text>
          </View>
          <View style={styles.bottomStepContainer}>
            <Text style={styles.bottomStepText}>/mine</Text>
            {/* <TouchableOpacity>
              <AntDesign name="arrowright" size={24} color={colors.green} />
            </TouchableOpacity> */}
          </View>
        </View>
        <View style={styles.stepContainer}>
          <View>
            <View style={styles.headerRowTab}>
              <View style={styles.iconContainer}>
                <MaterialIcons name="assistant" size={35} color="black" />
              </View>
              <Text style={styles.stepHeader}>Collect</Text>
            </View>
            <Text style={styles.stepDesc}>
              Build up your portfolio of epic on-stream moments from streamers
              you love and streamers you discover. Every dollar you spend gives
              back to the creators you love.
            </Text>
          </View>
          <View style={styles.bottomStepContainer}>
            <Text style={styles.bottomStepText}>/support</Text>
            {/* <TouchableOpacity>
              <AntDesign name="arrowright" size={24} color={colors.green} />
            </TouchableOpacity> */}
          </View>
        </View>
        <View style={styles.stepContainer}>
          <View>
            <View style={styles.headerRowTab}>
              <View style={styles.iconContainer}>
                <MaterialIcons
                  name="collections-bookmark"
                  size={35}
                  color="black"
                />
              </View>
              <Text style={styles.stepHeader}>Earn</Text>
            </View>
            <Text style={styles.stepDesc}>
              Earn money from your collectibles by posting them on the market.
            </Text>
          </View>
          <View style={styles.bottomStepContainer}>
            <Text style={styles.bottomStepText}>/collect</Text>
            {/* <TouchableOpacity>
              <AntDesign name="arrowright" size={24} color={colors.green} />
            </TouchableOpacity> */}
          </View>
        </View>
      </View>
    </View>
  );
};

export default ColumnContainer;
