import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  ImageBackground,
  Image,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import * as RootNavigation from "../../RootNavigation";
import { useMediaQuery } from "react-responsive";
import { AntDesign } from "@expo/vector-icons";

const StreamerHero = ({ styles }) => {
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });
  const windowDims = useWindowDimensions();

  const contentWidth = isTabletOrMobile ? 0.9 * windowDims.width : 1024;

  const streamerBox = (link, image, name, following) => (
    <TouchableOpacity
      style={{
        backgroundColor: "#F5F5F5",
        padding: 15,
        width: isMobile ? "100%" : 180,
        height: 70,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        marginVertical: 15,
      }}
      onPress={() => window.open(link)}
    >
      <Image
        style={{
          width: 35,
          height: 35,
          borderRadius: 100,
          marginRight: 10,
        }}
        source={image}
      />
      <View>
        <Text style={{ fontFamily: fonts.expandedBold, fontSize: 12 }}>
          {name}
        </Text>
        <Text
          style={{
            marginTop: 6,
            fontFamily: fonts.semiBold,
            color: "grey",
          }}
        >
          {following}
        </Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={[styles.outerContainer, { width: contentWidth }]}>
      <View
        style={{
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <View style={{ width: isMobile ? "95%" : "60%" }}>
          <Text style={styles.topText}>MEET BUFFD</Text>
          <Text style={styles.header}>Modern income for streamers</Text>
          <Text style={styles.body}>
            Buffd empowers streamers to monetize the content they already work
            so hard to create
          </Text>
          <TouchableOpacity
            style={styles.heroButton}
            onPress={() => RootNavigation.navigate("StreamerEnroll")}
          >
            <Text style={styles.heroButtonText}>
              Get started{" "}
              <Text style={{ fontFamily: fonts.text, opacity: 0.75 }}>
                — it's free
              </Text>
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{ width: isMobile ? "95%" : "30%" }}>
          <Image
            style={{
              width: "100%",
              height: isMobile ? "20vh" : "50vh",
            }}
            source={require("../../../assets/images/Majin_Streamer.jpeg")}
          />

          <Text
            style={{
              fontFamily: fonts.expandedBold,
              fontSize: 12,
              color: "#C8C8C8",
              marginTop: 15,
            }}
          >
            @MAJIN2UU
          </Text>
        </View>
      </View>
      <View
        style={{
          marginTop: 60,
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {streamerBox(
          "https://www.twitch.tv/lospollostv",
          require("../../../assets/images/streamerimages/lospollos.jpeg"),
          "LosPollosTV",
          "592K+"
        )}
        {streamerBox(
          "https://www.twitch.tv/broken1nglish",
          require("../../../assets/images/streamerimages/brokeninglish.png"),
          "Broken1nglish",
          "2.8K+"
        )}
        {streamerBox(
          "https://www.twitch.tv/aznboi",
          require("../../../assets/images/streamerimages/aznboi.png"),
          "AznBoi",
          "78.8K+"
        )}
        {streamerBox(
          "https://www.twitch.tv/veronika",
          require("../../../assets/images/streamerimages/veronika.png"),
          "veronika",
          "130K+"
        )}
      </View>
    </View>
  );
};

export default StreamerHero;
