import React, { useState } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  FlatList,
  Image,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import TopBar from "../../components/TopBar";
import LogoBlack from "../../../assets/images/LogoBlack";
import { useMediaQuery } from "react-responsive";
import fonts from "../../res/fonts";
import Footer from "../../components/Footer";
import StreamerHero from "./StreamerHero";
import StreamerExplainer from "./StreamerExplainer";
import {
  desktop as HeroDesktop,
  tablet as HeroTablet,
  mobile as HeroMobile,
} from "./Stylesheets/StreamerHeroStyle";
import {
  desktop as ExplainerDesktop,
  tablet as ExplainerTablet,
  mobile as ExplainerMobile,
} from "./Stylesheets/StreamerExplainerStyle";
import { FontAwesome5 } from "@expo/vector-icons";
import * as RootNavigation from "../../RootNavigation";

const StreamerInfoScreen = ({ navigation }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <>
      <ScrollView style={styles.container}>
        {isTabletOrMobile ? (
          <TopBar />
        ) : (
          <View style={styles.topBar}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                style={{ marginRight: 10 }}
                onPress={() => {
                  RootNavigation.navigate("Home");
                }}
              >
                <LogoBlack
                  width={130}
                  height={20}
                  style={{
                    alignSelf: "center",
                    marginVertical: 20,
                  }}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.socialButton}
                onPress={() => window.open("https://discord.gg/2MT3D2SHmq")}
              >
                <FontAwesome5 name="discord" size={24} color={"black"} />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.socialButton}
                onPress={() => window.open("https://twitter.com/BUFFDgg")}
              >
                <FontAwesome5 name="twitter" size={24} color={"black"} />
              </TouchableOpacity>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity
                onPress={() => {
                  RootNavigation.navigate("StreamerInfo");
                }}
                style={{ marginRight: 20 }}
              >
                <Text
                  style={{
                    fontFamily: fonts.expandedBold,
                    color: "white",
                  }}
                >
                  For Streamers
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  RootNavigation.navigate("SignIn");
                }}
                style={styles.outlineButton}
              >
                <Text style={styles.textLink}>LOG IN</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  RootNavigation.navigate("StreamerEnroll");
                }}
                style={[
                  styles.outlineButton,
                  { marginRight: 0, backgroundColor: colors.green },
                ]}
              >
                <Text style={styles.textLink}>STREAMER ENROLL</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
        <StreamerHero
          styles={
            isMobile ? HeroMobile : isTabletOrMobile ? HeroTablet : HeroDesktop
          }
        />
        <StreamerExplainer
          styles={
            isMobile
              ? ExplainerMobile
              : isTabletOrMobile
              ? ExplainerTablet
              : ExplainerDesktop
          }
        />
        <Footer />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  header: {
    color: "white",
    fontFamily: fonts.bold,
    fontSize: 40,
  },
  topBar: {
    position: "absolute",
    zIndex: 1000,
    top: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 50,
    width: "100%",
  },
  socialButton: {
    marginHorizontal: 10,
  },
  outlineButton: {
    borderRadius: 100,
    borderWidth: 2,
    borderColor: colors.green,
    backgroundColor: "#eefdec",
    paddingHorizontal: 35,
    paddingVertical: 15,
    marginHorizontal: 10,
  },
  textLink: {
    fontFamily: fonts.expandedBold,
    color: "black",
    fontSize: 12,
    letterSpacing: 4,
  },
});

export default StreamerInfoScreen;
