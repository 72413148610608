import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import TopBar from "../../components/TopBar";
import TowError from "../../../assets/images/TowError";
import SocialLinkRow from "./SocialLinkRow";
import CollectibleTab from "./CollectibleTab";
import StreamerFeed from "./StreamerFeed";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as StreamerContext } from "../../context/StreamerContext";
import { Ionicons } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../RootNavigation";
import { useIsFocused } from "@react-navigation/native";
import { Entypo } from "@expo/vector-icons";

const StreamerProfileScreen = ({ route }) => {
  const {
    state: { token },
  } = useContext(AuthContext);
  const {
    fetchStreamerInfo,
    checkClaim,
    clearNavigateStreamer,
    resetFetchedStreamer,
    state: { fetchedStreamer, isClaimEligible },
  } = useContext(StreamerContext);
  const [notFound, setNotFound] = useState(null);
  const [selectedCollectible, setSelectedCollectible] = useState(null);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isFocused = useIsFocused();
  const window = useWindowDimensions();
  const tag =
    route.params &&
    route.params.tag &&
    route.params.tag != undefined &&
    route.params.tag != ""
      ? route.params.tag
      : null;

  useEffect(() => {
    async function loadData() {
      console.log("******RUNNING LOAD DATA");
      if (!fetchedStreamer || `${fetchedStreamer.alias}` != `${tag}`)
        await fetchStreamerInfo(tag);
    }
    loadData();
  }, [tag]);

  useEffect(() => {
    return () => {
      resetFetchedStreamer();
    };
  }, [isFocused]);

  if (!fetchedStreamer) {
    return (
      <>
        <TopBar />
        <ScrollView
          contentContainerStyle={[styles.container, { width: window.width }]}
        >
          <View
            style={[
              styles.innerContent,
              {
                width:
                  isMobile || isTabletOrMobile ? 0.95 * window.width : 1024,
                paddingVertical: isMobile ? 20 : 50,
              },
            ]}
          >
            <View style={styles.container}>
              <View style={{ marginTop: 25 }}>
                <ActivityIndicator />
              </View>
            </View>
          </View>
        </ScrollView>
      </>
    );
  } else if (fetchedStreamer && fetchedStreamer.notFound) {
    return (
      <>
        <TopBar />
        <ScrollView
          contentContainerStyle={[styles.container, { width: window.width }]}
        >
          <View
            style={[
              styles.innerContent,
              {
                width:
                  isMobile || isTabletOrMobile ? 0.95 * window.width : 1024,
                paddingVertical: isMobile ? 20 : 50,
              },
            ]}
          >
            <View style={styles.errorContainer}>
              <TowError
                width={wp("50%")}
                height={hp("40%")}
                style={{
                  alignSelf: "center",
                }}
              />
              <Text style={styles.errorText}>"{tag}" not found</Text>
              <TouchableOpacity
                style={styles.homeButton}
                onPress={() => RootNavigation.navigate("Home")}
              >
                <Text
                  style={{ fontFamily: fonts.expandedBold, color: "white" }}
                >
                  GO HOME
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </>
    );
  } else {
    return (
      <>
        <TopBar />
        <ScrollView
          contentContainerStyle={[styles.container, { width: window.width }]}
        >
          <View
            style={[
              styles.innerContent,
              {
                width:
                  isMobile || isTabletOrMobile ? 0.95 * window.width : 1024,
                paddingVertical: isMobile ? 20 : 50,
              },
            ]}
          >
            {selectedCollectible != null ? (
              <>
                <View
                  style={[
                    styles.streamerContainer,
                    {
                      width:
                        isMobile || isTabletOrMobile
                          ? 0.95 * window.width
                          : 1024,
                    },
                  ]}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <TouchableOpacity
                      style={styles.backContainer}
                      onPress={() => setSelectedCollectible(null)}
                    >
                      <Entypo name="chevron-left" size={20} color="white" />
                      <Text style={styles.backText}>GO BACK</Text>
                    </TouchableOpacity>
                    <Text style={styles.breadcrumbs}>
                      / {fetchedStreamer.alias} /{" "}
                      {selectedCollectible.reactName
                        ? `${selectedCollectible.reactName}`
                        : "CARD"}
                    </Text>
                  </View>
                  <View style={styles.bioContainer}>
                    <Image
                      style={styles.imageContainer}
                      source={{ uri: fetchedStreamer.profilePic }}
                    />
                    <View style={styles.nameContainer}>
                      <SocialLinkRow data={fetchedStreamer} />
                      <Text style={styles.alias}>{tag}</Text>
                    </View>
                  </View>

                  <CollectibleTab
                    tag={tag}
                    fetchedStreamer={fetchedStreamer}
                    selectedCollectible={selectedCollectible}
                    setSelectedCollectible={setSelectedCollectible}
                  />
                </View>
              </>
            ) : (
              <>
                {isFocused ? (
                  <StreamerFeed
                    selectedCollectible={selectedCollectible}
                    setSelectedCollectible={setSelectedCollectible}
                  />
                ) : null}
              </>
            )}
          </View>
        </ScrollView>
      </>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "black",
  },
  innerContent: {
    alignSelf: "center",
  },
  backContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.backOverlay,
    paddingVertical: 6,
    paddingHorizontal: 15,
    borderRadius: 10,
    alignSelf: "flex-start",
  },
  backText: {
    color: "white",
    fontSize: 12,
    fontFamily: fonts.semiBold,
    marginLeft: 6,
  },
  breadcrumbs: {
    fontFamily: fonts.expandedRegular,
    color: "grey",
    fontSize: 10,
    marginLeft: 10,
  },
  streamerContainer: {
    flexGrow: 1,
    backgroundColor: "black",
    //backgroundColor: colors.backOverlay,
    // borderColor: "#40494e",
    // borderWidth: 1.5,
    // borderRadius: 10,
    width: 1024,
  },
  infoContainer: {
    padding: 25,
  },
  splitContainer: {
    width: "48%",
  },
  bioContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  imageContainer: {
    backgroundColor: colors.backOverlay,
    borderRadius: 10,
    width: 75,
    height: 75,
    marginRight: 16,
  },
  nameContainer: {},
  alias: {
    fontFamily: fonts.bold,
    fontSize: 30,
    color: "white",
  },
  divider: {
    borderBottomColor: "#A8A8A8",
    borderBottomWidth: 0.2,
  },
  bioLabel: {
    fontFamily: fonts.expandedBold,
    color: "grey",
    marginVertical: 10,
  },
  bioText: {
    marginVertical: 20,
    fontFamily: fonts.text,
    color: "#E0E0E0",
    lineHeight: 20,
    fontSize: 14,
  },
  dropList: {
    backgroundColor: colors.backOverlay,
    height: 150,
    width: "100%",
    flexDirection: "row",
    padding: 20,
    alignItems: "center",
  },
  dropContainer: {},
  dropHeader: {
    fontFamily: fonts.expandedBold,
  },
  errorContainer: {
    padding: 35,
    borderRadius: 10,
    justifyContent: "center",
    alignSelf: "center",
    marginTop: "5%",
    //backgroundColor: "white",
    alignItems: "center",
  },
  errorText: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
    marginTop: 50,
    marginBottom: 25,
    color: "white",
  },
  homeButton: {
    backgroundColor: colors.backOverlay,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 2,
  },
});

export default StreamerProfileScreen;
