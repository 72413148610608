import React, { useContext } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { FontAwesome, Entypo, Ionicons } from "@expo/vector-icons";
import TikTokLogo from "../../../assets/images/TikTokLogo";
import { Mixpanel } from "../../api/mixPanel";

const SocialLinkRow = ({ data }) => {
  const onIconPress = (link) => {
    Mixpanel.track("Streamer Link Click", { url: link });
    window.open(link);
  };

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 15,
      }}
    >
      {data.twitchLink && data.twitchLink != "" ? (
        <TouchableOpacity
          onPress={() => onIconPress(data.twitchLink)}
          style={styles.iconCont}
        >
          <FontAwesome
            name="twitch"
            size={14}
            style={{ marginHorizontal: 10 }}
            color="black"
          />
        </TouchableOpacity>
      ) : null}
      {data.instagramLink && data.instagramLink != "" ? (
        <TouchableOpacity
          onPress={() => onIconPress(data.instagramLink)}
          style={styles.iconCont}
        >
          <FontAwesome
            name="instagram"
            size={16}
            style={{ marginHorizontal: 10 }}
            color="black"
          />
        </TouchableOpacity>
      ) : null}
      {data.youTubeLink && data.youTubeLink != "" ? (
        <TouchableOpacity
          onPress={() => onIconPress(data.youTubeLink)}
          style={styles.iconCont}
        >
          <Entypo
            name="youtube"
            size={16}
            style={{ marginHorizontal: 10 }}
            color="black"
          />
        </TouchableOpacity>
      ) : null}
      {data.tikTokLink && data.tikTokLink != "" ? (
        <TouchableOpacity
          onPress={() => onIconPress(data.tikTokLink)}
          style={styles.iconCont}
        >
          <TikTokLogo
            width={14}
            height={14}
            style={{
              alignSelf: "center",
            }}
          />
        </TouchableOpacity>
      ) : null}
      {data.twitterLink && data.twitterLink != "" ? (
        <TouchableOpacity
          onPress={() => onIconPress(data.twitterLink)}
          style={styles.iconCont}
        >
          <FontAwesome
            name="twitter"
            size={16}
            style={{ marginHorizontal: 10 }}
            color="black"
          />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  iconCont: {
    marginRight: 15,
    width: 25,
    height: 25,
    backgroundColor: colors.green,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
});

export default SocialLinkRow;
