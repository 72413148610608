import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import TopBar from "../../../components/TopBar";
import { useMediaQuery } from "react-responsive";
import fonts from "../../../res/fonts";
import LogoBlack from "../../../../assets/images/LogoBlack";
import { Input } from "react-native-elements";
import { toast } from "react-toastify";
import { Context as AuthContext } from "../../../context/AuthContext";
import * as Linking from "expo-linking";
import { Mixpanel } from "../../../api/mixPanel";

const SignInScreen = ({ navigation }) => {
  //const [sentToMix, setSentToMix] = useState(false);
  const {
    signin,
    clearErrorMessage,
    state: { errorMessage },
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;
  const modalHorizontalPadding = isMobile ? 15 : 50;

  const onSignIn = async () => {
    setIsLoading(true);
    if (email == "" || password == "") {
      toast.error("Please enter an email and password.");
      setIsLoading(false);
    } else {
      await signin({ email, password });
    }
  };

  useEffect(() => {
    console.log("Ending Loading");
    Mixpanel.track("Sign In Attempt", { attemptedEmail: email });
    setIsLoading(false);
    clearErrorMessage();
    return () => {
      setIsLoading(false);
      clearErrorMessage();
    };
  }, [errorMessage]);

  return (
    <>
      <TopBar />
      <ScrollView contentContainerStyle={styles.container}>
        <View
          style={[
            styles.modalContainer,
            { width: modalWidth, paddingHorizontal: modalHorizontalPadding },
          ]}
        >
          <View style={styles.logoContainer}>
            <LogoBlack
              width={100}
              height={20}
              style={{
                alignSelf: "center",
                marginVertical: 20,
              }}
            />
          </View>
          <View style={{ alignSelf: "center" }}>
            <Text style={styles.descText}>Log in to Buffd</Text>
            <Text style={styles.subDesc}>
              Don't have an account?{" "}
              <TouchableOpacity onPress={() => navigation.navigate("SignUp")}>
                <Text style={{ textDecorationLine: "underline" }}>Sign up</Text>
              </TouchableOpacity>
            </Text>
          </View>
          <View style={styles.divider} />
          <View style={styles.inputContainer}>
            <Input
              label="Email"
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={email}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newEmail) => setEmail(newEmail)}
            />
            <Input
              label="Password"
              secureTextEntry={true}
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={password}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newPassword) => setPassword(newPassword)}
            />
            <View>
              <TouchableOpacity
                style={styles.submitButton}
                onPress={() => onSignIn()}
              >
                {isLoading ? (
                  <ActivityIndicator />
                ) : (
                  <Text style={styles.submitButtonText}>Log In</Text>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => Linking.openURL("mailto: support@buffd.gg")}
              >
                <Text style={styles.supportText}>
                  Email{" "}
                  <Text style={{ textDecorationLine: "underline" }}>
                    support@buffd.gg{" "}
                  </Text>
                  for help
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#f9f8f9",
    paddingHorizontal: "5%",
    paddingTop: 50,
  },
  header: {
    fontFamily: fonts.bold,
    fontSize: 45,
    color: "white",
  },
  modalContainer: {
    backgroundColor: "white",
    alignContent: "center",
    //justifyContent: "center",
    paddingVertical: 50,
    alignSelf: "center",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  logoContainer: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
    marginBottom: 30,
  },
  descText: {
    fontFamily: fonts.bold,
    color: colors.secondaryGreen,
    fontSize: 20,
    textAlign: "center",
  },
  subDesc: {
    fontFamily: fonts.semiBold,
    color: "grey",
    fontSize: 16,
    marginTop: 15,
    textAlign: "center",
  },
  divider: {
    borderTopWidth: 0.5,
    borderColor: "grey",
    marginTop: 20,
    opacity: 0.2,
  },
  inputContainer: {
    marginTop: 30,
    marginHorizontal: 0,
  },
  labelStyle: {
    fontFamily: fonts.text,
    fontSize: 14,
    marginBottom: 10,
    color: "grey",
  },
  inputContainerStyle: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    marginVertical: 5,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 25,
    paddingVertical: 5,
  },
  inputTextStyle: {
    fontFamily: fonts.text,
    color: "black",
    fontSize: 14,
    outlineWidth: 0,
  },
  submitButton: {
    backgroundColor: colors.green,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
    borderRadius: 8,
    width: "75%",
    alignSelf: "center",
    marginTop: 20,
  },
  submitButtonText: {
    fontFamily: fonts.bold,
    color: colors.secondaryGreen,
    fontSize: 14,
  },
  supportText: {
    fontFamily: fonts.regular,
    color: "grey",
    fontSize: 14,
    marginTop: 20,
    textAlign: "center",
  },
});

export default SignInScreen;
