import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  ImageBackground,
  Image,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { useMediaQuery } from "react-responsive";
import { AntDesign } from "@expo/vector-icons";
import * as RootNavigation from "../../RootNavigation";

const Hero = ({ scrollRef, styles }) => {
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });
  const window = useWindowDimensions();

  const contentWidth = isTabletOrMobile ? 0.9 * window.width : 1024;

  return (
    <ImageBackground
      source={require("../../../assets/images/StreamerBackground.png")}
      resizeMode="cover"
      style={{
        justifyContent: "center",
        height: "90vh",
        marginBottom: 100,
      }}
    >
      <View style={styles.background} />
      <View style={{ width: contentWidth, alignSelf: "center" }}>
        <View style={styles.heroContainer}>
          {/* <Text style={styles.topText}>YOU FOUND US</Text> */}
          <Text style={styles.header}>
            Welcome to the world of Twitch{" "}
            <Text style={{ color: colors.green }}>memorabilia</Text>
          </Text>
          <Text style={styles.body}>
            Collect the best on-stream moments as digital collectibles with
            blockchain technology
          </Text>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            <TouchableOpacity
              onPress={() => {
                RootNavigation.navigate("SignUp");
              }}
              style={[
                styles.heroButton,
                { backgroundColor: colors.green, marginRight: 20 },
              ]}
            >
              <Text style={[styles.heroButtonText, { color: "black" }]}>
                GET ACCESS
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.heroButton, { flexDirection: "row" }]}
              onPress={() => {
                scrollRef.current?.scrollTo({
                  x: 0,
                  y: hp("100%"),
                  animated: true,
                });
              }}
            >
              <AntDesign
                name="caretdown"
                size={12}
                color="white"
                style={{ marginRight: 15 }}
              />
              <Text style={styles.heroButtonText}>WAIT, WHAT?</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

export default Hero;
