import React, { useContext } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { Entypo } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../RootNavigation";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";
import { Mixpanel } from "../../api/mixPanel";

const onNavigate = (pageName) => {
  Mixpanel.track("Page View", { pageName: pageName });
  RootNavigation.navigate(pageName);
};

const MainNavSection = ({ selected, styles, iconSize }) => {
  const {
    state: { token },
  } = useContext(AuthContext);
  const {
    state: { streamerProfile },
  } = useContext(UserContext);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });

  const redIndicator = <View style={styles.redIndicatorStyle} />;

  return (
    <View style={styles.navSection}>
      {token != null ? (
        <>
          <TouchableOpacity
            style={[
              styles.navContainer,
              {
                borderBottomWidth: selected == 0 ? 2 : null,
                borderBottomColor: selected == 0 ? "white" : null,
              },
            ]}
            onPress={() => onNavigate("Home")}
          >
            <Entypo
              name="home"
              size={iconSize}
              color={selected == 0 ? "white" : "grey"}
            />

            <Text style={styles.navLink}>HOME</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.navContainer,
              {
                borderBottomWidth: selected == 1 ? 2 : null,
                borderBottomColor: selected == 1 ? "white" : null,
              },
            ]}
            onPress={() => onNavigate("Portfolio")}
          >
            <Entypo
              name="archive"
              size={iconSize}
              color={selected == 1 ? "white" : "grey"}
            />
            <Text style={styles.navLink}>PORTFOLIO</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.navContainer,
              {
                borderBottomWidth: selected == 2 ? 2 : null,
                borderBottomColor: selected == 2 ? "white" : null,
              },
            ]}
            onPress={() => onNavigate("About")}
          >
            <Entypo
              name="book"
              size={iconSize}
              color={selected == 2 ? "white" : "grey"}
            />
            <Text style={styles.navLink}>MANIFESTO</Text>
          </TouchableOpacity>
          {streamerProfile != null && streamerProfile._id ? (
            <TouchableOpacity
              style={[
                styles.navContainer,
                {
                  borderBottomWidth: selected == 3 ? 2 : null,
                  borderBottomColor: selected == 3 ? "white" : null,
                },
              ]}
              onPress={() => {
                Mixpanel.track("Page View", {
                  pageName: "StreamerProfile",
                  streamerAlias: streamerProfile.alias,
                });
                RootNavigation.navigate("Dashboard");
              }}
            >
              <View>
                <Entypo
                  name="v-card"
                  size={iconSize}
                  color={selected == 3 ? "white" : "grey"}
                />
                {streamerProfile != null && streamerProfile.status == 0
                  ? redIndicator
                  : null}
              </View>
              <Text style={styles.navLink}>PROFILE</Text>
            </TouchableOpacity>
          ) : null}
        </>
      ) : (
        <>
          {/* <TouchableOpacity
            style={styles.navContainer}
            onPress={() => onNavigate("Home")}
          >
            <Entypo
              name="home"
              size={iconSize}
              color={selected == 0 ? "white" : "grey"}
            />
            <Text style={styles.navLink}>HOME</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.navContainer}
            onPress={() => onNavigate("About")}
          >
            <Entypo
              name="bell"
              size={iconSize}
              color={selected == 3 ? "white" : "grey"}
            />
            <Text style={styles.navLink}>ABOUT</Text>
          </TouchableOpacity> */}
        </>
      )}
    </View>
  );
};

export default MainNavSection;
