import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { useMediaQuery } from "react-responsive";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  AntDesign,
} from "@expo/vector-icons";

const CollectibleContainer = () => {
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  let isMobile = useMediaQuery({ maxWidth: 600 });
  const window = useWindowDimensions();

  const containerWidth = isTabletOrMobile ? 0.95 * window.width : 1024;
  const columnWidth = isMobile
    ? 0.95 * window.width
    : isTabletOrMobile
    ? 500
    : 500;

  return (
    <View style={styles.container}>
      <Text style={styles.topText}>HOW IT WORKS</Text>
      <Text style={styles.header}>Digital Collectible Lifecycle</Text>
      <Text
        style={[
          styles.descText,
          { width: containerWidth - 50, fontSize: isMobile ? 16 : 20 },
        ]}
      >
        Buffd is working to support every single streamer. A select group of
        Buffd streamers are eligible for Buffd collectible drops, which are
        featured to the entire community of collectors.
      </Text>
      <View style={[styles.columnContainer, { width: containerWidth }]}>
        <View style={[styles.stepContainer, { width: columnWidth }]}>
          <View>
            <View style={styles.headerRowTab}>
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons
                  name="account-key"
                  size={35}
                  color="black"
                />
              </View>
              <Text style={styles.aboveHeader}>BOOTSTRAPPING</Text>
              <Text style={styles.stepHeader}>Sole Proprietor</Text>
            </View>
            <View style={styles.editionContainer}>
              <Text style={styles.editionLabel}>EDITION SIZE</Text>
              <Text style={styles.editionValue}>10</Text>
            </View>
            <Text style={styles.stepDesc}>
              All streamers can bootstrap and become a Buffd Sole Proprietor,
              giving early backers a chance to collect their on-stream moments
              while they grow. Early backers carry lifetime ultimate fandom
              status.
            </Text>
          </View>
        </View>
        <View style={[styles.stepContainer, { width: columnWidth }]}>
          <View>
            <View style={styles.headerRowTab}>
              <View style={styles.iconContainer}>
                <MaterialCommunityIcons
                  name="alarm-light"
                  size={35}
                  color="black"
                />
              </View>
              <Text style={styles.aboveHeader}>GOING PUBLIC</Text>
              <Text style={styles.stepHeader}>Buffd Drops</Text>
            </View>
            <View style={styles.editionContainer}>
              <Text style={styles.editionLabel}>EDITION SIZE</Text>
              <Text style={styles.editionValue}>1-25</Text>
            </View>
            <Text style={styles.stepDesc}>
              Once streamers rise the Twitch and community ranks, they can
              become eligible to 'go public' on Buffd. This exclusive group of
              streamers are featured in Buffd collectible drop events.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 150,
    alignSelf: "center",
    borderRadius: 10,
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    color: "grey",
    letterSpacing: 14,
    fontSize: 14,
    textAlign: "center",
  },
  header: {
    fontFamily: fonts.heavy,
    color: "white",
    marginTop: 25,
    fontSize: 60,
    textAlign: "center",
  },
  descText: {
    fontFamily: fonts.text,
    color: "grey",
    marginTop: 50,
    textAlign: "center",
    fontSize: 25,
    alignSelf: "center",
  },
  columnContainer: {
    marginTop: 80,
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  stepContainer: {
    padding: 40,
    //backgroundColor: colors.subBackground,
    backgroundColor: colors.backOverlay,
    borderRadius: 10,
    width: 500,
    minHeight: 500,
    justifyContent: "space-between",
    marginBottom: 25,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  iconContainer: {
    backgroundColor: colors.green,
    padding: 15,
    height: 75,
    width: 75,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
  },
  aboveHeader: {
    fontFamily: fonts.expandedBlack,
    color: "grey",
    fontSize: 10,
    letterSpacing: 5,
    marginBottom: 12,
  },
  stepHeader: {
    fontFamily: fonts.expandedBlack,
    fontSize: 35,
    marginBottom: 15,
    color: "white",
  },
  editionContainer: {
    marginVertical: 10,
  },
  editionLabel: {
    fontFamily: fonts.expandedBlack,
    color: "grey",
    fontSize: 10,
    letterSpacing: 5,
    marginBottom: 12,
  },
  editionValue: {
    fontFamily: fonts.expandedBlack,
    color: "white",
    fontSize: 25,
  },
  stepDesc: {
    fontFamily: fonts.text,
    color: "grey",
    fontSize: 20,
    flexWrap: "wrap",
    lineHeight: 28,
  },
});

export default CollectibleContainer;
