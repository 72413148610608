const colors = {
  green: "#7DFCB5",
  darkGreen: "#00CE5C",
  blueGrey: "#40494e",
  /*background: "#1C1C1C",
  //subBackground: "#111114",
  secondaryGreen: "#124429",
  //242424
  greyText: "#767676",
  lightGreyText: "#D0D0D0",
  text: "#BABABA",*/
  background: "white",
  subBackground: "#f9f8f9",
  backOverlay: "#111114",
  secondaryGreen: "#124429",
  //242424
  greyText: "#767676",
  lightGreyText: "#D0D0D0",
  text: "#BABABA",
  darkerGreen: "#28B48E",
};

export default colors;
