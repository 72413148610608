import React from "react";
import { StyleSheet, View } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../res/colors";
import fonts from "../res/fonts";
import { useMediaQuery } from "react-responsive";
import LogoSection from "./TopBarComps/LogoSection";
import MainNavSection from "./TopBarComps/MainNavSection";
import AuthSection from "./TopBarComps/AuthSection";
import MobileMenu from "./TopBarComps/MobileMenu";
import {
  desktop as MainNavDesktop,
  tabletOrMobile as MainNavTab,
} from "./TopBarComps/Stylesheets/MainNavStyle";

const TopBar = ({ selected }) => {
  let isMobile = useMediaQuery({ maxWidth: 600 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });

  if (isMobile) {
    return (
      <View
        style={[
          styles.container,
          {
            paddingTop: hp("1%"),
            paddingHorizontal: 25,
          },
        ]}
      >
        <LogoSection />
        <MobileMenu selected={selected} />
      </View>
    );
  } else if (isTabletOrMobile) {
    return (
      <View
        style={[
          styles.container,
          { paddingTop: hp("1%"), paddingHorizontal: "2.5%" },
        ]}
      >
        <LogoSection />
        <View />
        <View style={{ flexDirection: "row" }}>
          <MainNavSection
            selected={selected}
            styles={MainNavTab}
            iconSize={28}
          />
          <AuthSection nameShowing={false} />
        </View>
      </View>
    );
  } else {
    return (
      <View style={styles.outerContainer}>
        <View style={styles.fullContainer}>
          <LogoSection />
          <MainNavSection
            selected={selected}
            styles={MainNavDesktop}
            iconSize={28}
          />
          <AuthSection nameShowing={true} />
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    //paddingVertical: hp("1%"),
    backgroundColor: "#111114",
    zIndex: 10000,
  },
  outerContainer: {
    backgroundColor: "#111114",
    zIndex: 999,
  },
  fullContainer: {
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
    paddingTop: hp("1.5%"),
    width: "90%",
    alignSelf: "center",
    paddingHorizontal: 0,
    zIndex: 10000,
  },
});

export default TopBar;
