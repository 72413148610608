import AsyncStorage from "@react-native-async-storage/async-storage";
import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import { socket } from "../api/Socket";
import axios from "axios";
import * as Sentry from "sentry-expo";
import { toast } from "react-toastify";
import FormData from "form-data";
import { Mixpanel } from "../api/mixPanel";
import ReactPixel from "react-facebook-pixel";

const authReducer = (state, action) => {
  switch (action.type) {
    case "add_error":
      return { ...state, errorMessage: action.payload };
    case "signin":
      return { errorMessage: "", token: action.payload };
    case "clear_error_message":
      return { ...state, errorMessage: "" };
    case "signout":
      return { token: null, errorMessage: "" };
    case "username_exists":
      return { ...state, isUsernameAvailable: action.payload };
    case "validate_code":
      return { ...state, activationAuth: action.payload };
    default:
      return state;
  }
};

const tryLocalSignin = (dispatch) => async () => {
  const token = await AsyncStorage.getItem("token");
  const email = await AsyncStorage.getItem("email");
  if (token) {
    dispatch({ type: "signin", payload: token });
    ///Added
    if (!socket.connected) {
      await socket.connect();
    }
    await socket.emit("connection", "I'm connected");
    await socket.emit("login", email);
  }
};

const checkSignIn = (dispatch) => async () => {
  const token = await AsyncStorage.getItem("token");
  dispatch({ type: "signin", payload: token });
  if (token) {
    const email = await AsyncStorage.getItem("email");
    if (!socket.connected) {
      await socket.connect();
    }
    await socket.emit("connection", "I'm connected");
    await socket.emit("login", email);
  }
};

const clearErrorMessage = (dispatch) => () => {
  dispatch({ type: "clear_error_message" });
};

const resetAuth = (dispatch) => () => {
  dispatch({ type: "signout" });
};

const signup = (dispatch) => {
  return async ({ alias, email, password }) => {
    try {
      const response = await serverApi.post("/signup", {
        alias,
        email,
        password,
      });

      if (response.data == "0") {
        toast.error("An account with that email address already exists.");
        dispatch({
          type: "add_error",
          payload: "Something went wrong with sign up",
        });
        return;
      }
      if (response.data == "1") {
        toast.error(`The alias ${alias} is already taken.`);
        dispatch({
          type: "add_error",
          payload: "Something went wrong with sign up",
        });
        return;
      }
      ReactPixel.track("CompleteRegistration", { content_name: "SignUp" });
      Mixpanel.alias(response.data.userId);
      Mixpanel.identify(response.data.userId);
      Mixpanel.people.set({
        $name: `${alias}`,
        $email: email,
        ALIAS: `${alias}`,
        USER_ID: response.data.userId,
      });
      Mixpanel.track("Signed Up");
      await AsyncStorage.setItem("token", response.data.token);
      await AsyncStorage.setItem("email", email);
      dispatch({
        type: "signin",
        payload: response.data.token,
      });
      //navigate to main flow
      if (!socket.connected) {
        await socket.connect();
      }
      await socket.emit("connection", "I'm connected");
      await socket.emit("login", email);
    } catch (err) {
      console.log(err);
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "signup");
        scope.setExtra("alias", alias);
        scope.setExtra("email", email);
        Sentry.Browser.captureException(err);
      });
      dispatch({
        type: "add_error",
        payload: "Something went wrong with sign up",
      });
    }
  };
};

const streamerEnroll = (dispatch) => {
  return async ({
    alias,
    email,
    password,
    bio,
    paymentInfo,
    clipUrl,
    twitchLink,
    instagramLink,
    youTubeLink,
    tikTokLink,
    twitterLink,
  }) => {
    try {
      const response = await serverApi.post("/streamerenroll", {
        alias: alias,
        email: email,
        password: password,
        bio: bio,
        paymentInfo: paymentInfo,
        clipUrl: clipUrl,
        twitchLink: twitchLink,
        instagramLink: instagramLink,
        youTubeLink: youTubeLink,
        tikTokLink: tikTokLink,
        twitterLink: twitterLink,
      });

      if (response.data == "0") {
        toast.error("An account with that email address already exists.");
        dispatch({
          type: "add_error",
          payload: "Something went wrong with sign up",
        });
        return;
      }
      if (response.data == "1") {
        toast.error(`The alias ${alias} is already taken.`);
        dispatch({
          type: "add_error",
          payload: "Something went wrong with sign up",
        });
        return;
      }
      ReactPixel.track("CompleteRegistration", {
        content_name: "Streamer Enroll",
      });
      Mixpanel.alias(response.data.userId);
      Mixpanel.identify(response.data.userId);
      Mixpanel.people.set({
        $name: `${alias}`,
        $email: email,
        ALIAS: `${alias}`,
        USER_ID: response.data.userId,
      });
      Mixpanel.track("Signed Up");
      await AsyncStorage.setItem("token", response.data.token);
      await AsyncStorage.setItem("email", email);
      dispatch({
        type: "signin",
        payload: response.data.token,
      });
      //navigate to main flow
      if (!socket.connected) {
        await socket.connect();
      }
      await socket.emit("connection", "I'm connected");
      await socket.emit("login", email);
      toast.success("Application successful! The Buffd team will be in touch.");
    } catch (err) {
      console.log(err);
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "streamerEnroll");
        scope.setExtra("alias", alias);
        scope.setExtra("email", email);
        Sentry.Browser.captureException(err);
      });
      dispatch({
        type: "add_error",
        payload: "Something went wrong with sign up",
      });
    }
  };
};

const signin = (dispatch) => {
  return async ({ email, password }) => {
    try {
      const response = await serverApi.post("/signin", { email, password });
      await AsyncStorage.setItem("token", response.data.token);
      await AsyncStorage.setItem("email", email);

      console.log("SIGN IN TOKEN: " + response.data.token);
      dispatch({
        type: "signin",
        payload: response.data.token,
      });

      if (!socket.connected) {
        await socket.connect();
      }
      await socket.emit("connection", "I'm connected");
      await socket.emit("login", email);
    } catch (err) {
      console.log(err);
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "signin");
        scope.setExtra("email", email);
        Sentry.Browser.captureException(err);
      });
      dispatch({
        type: "add_error",
        payload: "Something went wrong with sign in",
      });
      toast.error("Your email or password is incorrect.");
    }
  };
};

const joinWaitList =
  (dispatch) =>
  async ({ email }) => {
    try {
      console.log("VALIDATING CODE");
      const response = await serverApi.post("/waitlist", { email });
      toast.success("Your email is on file");
    } catch (err) {
      console.log("joinWaitList error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "joinWaitList");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const validateCode =
  (dispatch) =>
  async ({ code }) => {
    try {
      console.log("VALIDATING CODE");
      const response = await serverApi.post("/validate-code", { code });
      dispatch({ type: "validate_code", payload: response.data });
      if (response.data.status == 0) {
        toast.success("Code validated successfully");
      } else {
        toast.error("Incorrect Code");
      }
    } catch (err) {
      console.log("validateCode error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "validateCode");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const useCodeAndSignup = (dispatch) => {
  return async ({ code, alias, email, password }) => {
    try {
      const response = await serverApi.post("/signup", {
        alias,
        email,
        password,
      });

      if (response.data == "0") {
        toast.error("An account with that email address already exists.");
        dispatch({
          type: "add_error",
          payload: "Something went wrong with sign up",
        });
        return;
      }
      if (response.data == "1") {
        toast.error(`The alias ${alias} is already taken.`);
        dispatch({
          type: "add_error",
          payload: "Something went wrong with sign up",
        });
        return;
      }
      ReactPixel.track("CompleteRegistration", { content_name: "SignUp" });
      Mixpanel.alias(response.data.userId);
      Mixpanel.identify(response.data.userId);
      Mixpanel.people.set({
        $name: `${alias}`,
        $email: email,
        ALIAS: `${alias}`,
        USER_ID: response.data.userId,
      });
      Mixpanel.track("Signed Up");
      await AsyncStorage.setItem("token", response.data.token);
      await AsyncStorage.setItem("email", email);
      dispatch({
        type: "signin",
        payload: response.data.token,
      });
      //navigate to main flow
      if (!socket.connected) {
        await socket.connect();
      }
      await socket.emit("connection", "I'm connected");
      await socket.emit("login", email);

      await serverApi.post("/use-code", {
        code: code,
        userId: response.data.userId,
      });
    } catch (err) {
      console.log(err);
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "signup");
        scope.setExtra("alias", alias);
        scope.setExtra("email", email);
        Sentry.Browser.captureException(err);
      });
      dispatch({
        type: "add_error",
        payload: "Something went wrong with sign up",
      });
    }
  };
};

//MANUALLY DISCONNECT SOCKET
const signout = (dispatch) => {
  return async () => {
    await AsyncStorage.removeItem("token");
    await AsyncStorage.removeItem("email");
    await AsyncStorage.removeItem("userId");
    await AsyncStorage.removeItem("userInfo");
    Mixpanel.track("Logged Out");
    Mixpanel.reset();
    dispatch({ type: "signout" });
    await socket.removeAllListeners();
    await socket.disconnect();
  };
};

export const { Provider, Context } = createDataContext(
  authReducer,
  //All Actions below
  {
    signin,
    resetAuth,
    signout,
    signup,
    clearErrorMessage,
    tryLocalSignin,
    streamerEnroll,
    checkSignIn,
    joinWaitList,
    validateCode,
    useCodeAndSignup,
  },
  {
    token: null,
    errorMessage: "",
    isUsernameAvailable: null,
    activationAuth: null,
  }
);
