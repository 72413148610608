import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { useMediaQuery } from "react-responsive";
import { MaterialIcons } from "@expo/vector-icons";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";
import { Context as TwitchContext } from "../../context/TwitchContext";
import { Context as StreamerContext } from "../../context/StreamerContext";
import { useIsFocused } from "@react-navigation/native";
import * as RootNavigation from "../../RootNavigation";
import { Mixpanel } from "../../api/mixPanel";
import { toast } from "react-toastify";

const onNavigate = (pageName) => {
  Mixpanel.track("Page View", { pageName: pageName });
  RootNavigation.navigate(pageName);
};

const AuthSection = ({ nameShowing }) => {
  const {
    state: { token },
    signout,
  } = useContext(AuthContext);
  const {
    resetUserInfo,
    state: { userInfo, streamerProfile },
  } = useContext(UserContext);
  const { clearState } = useContext(TwitchContext);
  const { reset } = useContext(StreamerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const isFocused = useIsFocused();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    return () => {
      if (!isFocused) setIsMenuVisible(false);
    };
  }, [isFocused]);

  const onMenuPress = () => {
    if (isMenuVisible) {
      setIsMenuVisible(false);
    } else {
      setIsMenuVisible(true);
    }
  };

  const onLogOut = async () => {
    setIsLoading(true);
    await RootNavigation.navigate("Home");
    await signout();
    await resetUserInfo();
    await clearState();
    await reset();
  };

  return (
    <>
      {token != null ? (
        <View
          style={[
            styles.navSection,
            {
              justifyContent: isTabletOrMobile ? null : "flex-end",
              width: isTabletOrMobile ? null : "33.33%",
            },
          ]}
        >
          {userInfo && nameShowing ? (
            <Text style={styles.accountName}>@{userInfo.alias}</Text>
          ) : null}
          <View style={styles.outerMenu}>
            <TouchableOpacity
              style={
                isMenuVisible ? styles.menuButtonSelected : styles.menuButton
              }
              onPress={() => onMenuPress()}
            >
              <MaterialIcons
                name={
                  isMenuVisible ? "keyboard-arrow-up" : "keyboard-arrow-down"
                }
                size={24}
                color={isMenuVisible ? "white" : "white"}
              />
            </TouchableOpacity>
            {isMenuVisible ? (
              <View style={styles.menuContainer}>
                <TouchableOpacity
                  style={styles.labelContainer}
                  onPress={() => onNavigate("Transaction")}
                >
                  <Text style={styles.menuLabel}>Transaction History</Text>
                  <MaterialIcons
                    name={"keyboard-arrow-right"}
                    size={24}
                    color={"white"}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.labelContainer}
                  onPress={() => onNavigate("Contact")}
                >
                  <Text style={styles.menuLabel}>Contact Us</Text>
                  <MaterialIcons
                    name={"keyboard-arrow-right"}
                    size={24}
                    color={"white"}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.labelContainer}
                  onPress={() => onLogOut()}
                >
                  {isLoading ? (
                    <ActivityIndicator />
                  ) : (
                    <>
                      <Text style={styles.menuLabel}>Log Out</Text>
                      <MaterialIcons
                        name={"keyboard-arrow-right"}
                        size={24}
                        color={"white"}
                      />
                    </>
                  )}
                </TouchableOpacity>
                {!streamerProfile && streamerProfile == null >= 0 ? (
                  <TouchableOpacity style={styles.labelContainer}>
                    <Text style={styles.menuLabel}>Enroll as Creator</Text>
                    <MaterialIcons
                      name={"keyboard-arrow-right"}
                      size={24}
                      color={"white"}
                    />
                  </TouchableOpacity>
                ) : null}
              </View>
            ) : null}
          </View>
        </View>
      ) : (
        <View
          style={[
            styles.navSection,
            {
              justifyContent: isTabletOrMobile ? null : "flex-end",
              width: isTabletOrMobile ? null : "33.33%",
            },
          ]}
        >
          <TouchableOpacity
            style={styles.loginButton}
            onPress={() => onNavigate("SignIn")}
          >
            <Text style={styles.loginText}>LOG IN</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.signupButton}
            onPress={() => onNavigate("SignUp")}
          >
            <Text style={styles.signupText}>SIGN UP</Text>
          </TouchableOpacity>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  navSection: {
    flexDirection: "row",
    alignItems: "center",
  },
  streamerInfoText: {
    fontFamily: fonts.expandedBold,
    color: colors.green,
    fontSize: 12,
    marginRight: 25,
  },
  comingSoonButton: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
    height: 40,
    backgroundColor: "#1C1C1C",
    borderRadius: 2,
    marginRight: 10,
    borderWidth: 2,
    borderColor: "#303030",
  },
  loginButton: {
    justifyContent: "center",
    alignItems: "center",
    width: 110,
    height: 35,
    backgroundColor: "black",
    borderRadius: 8,
    marginRight: 10,
    borderWidth: 2,
    borderColor: "#1C1C1C",
  },
  loginText: {
    fontFamily: fonts.expandedBold,
    fontSize: 11,
    color: "white",
  },
  signupButton: {
    justifyContent: "center",
    alignItems: "center",
    width: 110,
    height: 35,
    backgroundColor: colors.green,
    borderRadius: 8,
    marginLeft: 10,
  },
  signupText: {
    fontFamily: fonts.expandedBold,
    fontSize: 11,
    color: "black",
  },
  accountName: {
    fontFamily: fonts.bold,
    fontSize: 14,
    color: "grey",
  },
  menuButton: {
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    backgroundColor: "#1C1C1C",
    borderRadius: 2,
    borderWidth: 2,
    borderColor: "#303030",
    marginLeft: 20,
  },
  menuButtonSelected: {
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    backgroundColor: colors.green,
    borderRadius: 2,
    borderWidth: 2,
    borderColor: colors.darkerGreen,
    marginLeft: 20,
  },
  outerMenu: {},
  menuContainer: {
    backgroundColor: "black",
    borderColor: "#505050",
    borderWidth: 2,
    padding: 25,
    position: "absolute",
    borderRadius: 10,
    top: 45,
    right: 0,
    //marginLeft: 20,
    zIndex: 1000000000000,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.25,
    shadowRadius: 14.78,
    elevation: 22,
    width: 300,
  },
  labelContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginVertical: 10,
    backgroundColor: "#111114",
    borderRadius: 10,
    padding: 15,
  },
  menuLabel: {
    fontFamily: fonts.expandedBold,
    color: "white",
  },
});

export default AuthSection;
