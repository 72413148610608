import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Image,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import ReactListModal from "./Modals/ReactListModal";
import moment from "moment";
import { Context as UserContext } from "../../../context/UserContext";
import { Context as ReactContext } from "../../../context/ReactContext";
import { useMediaQuery } from "react-responsive";
import { Entypo } from "@expo/vector-icons";
import { ActivityIndicator } from "react-native";
import { range } from "lodash";
import { FontAwesome } from "@expo/vector-icons";
import * as RootNavigation from "../../../RootNavigation";

const ReactGridItem = ({ item, index, setIsIncomingBidsVisible }) => {
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const {
    state: { incomingBids },
  } = useContext(ReactContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [totalBids, setTotalBids] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    if (incomingBids != null && incomingBids.length > 0) {
      const availableBids = incomingBids.filter(
        (bidItem) =>
          `${bidItem.reactId._id}` == `${item._id}` &&
          `${bidItem.bidder._id}` != `${userInfo._id}`
      );
      setTotalBids(availableBids.length);
    }
  }, [incomingBids]);

  const totalListings = item.listings.filter((item) => item.status == 0).length;

  return (
    <TouchableOpacity
      style={styles.itemContainer}
      key={index}
      onPress={() =>
        RootNavigation.navigate("StreamerProfile", {
          tag: item.pool.streamerId.alias,
        })
      }
    >
      <ReactListModal
        item={item}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <Text style={styles.reactName}>{item.pool.reactName}</Text>
      <Text style={styles.streamerAlias}>{item.pool.streamerId.alias}</Text>
      <View style={styles.reactContainer}>
        <View style={styles.thumbnail}>
          <Image
            style={{ width: 180, height: 180, resizeMode: "contain" }}
            source={{
              uri: "https://res.cloudinary.com/buffd/image/upload/v1625059067/Product%20Images/RookieSeriesThumbnail_oommvt.png",
            }}
          />
        </View>
        <View style={styles.reactDetails}>
          <View style={styles.detailContainer}>
            <Text style={styles.label}>SERIAL</Text>
            <Text style={styles.value}>
              #{item.mintNumber}/{item.pool.total}
            </Text>
          </View>
          <View style={styles.detailContainer}>
            <Text style={styles.label}>OG PRICE</Text>
            <Text style={styles.value}>${item.price}</Text>
          </View>
          <View style={styles.detailContainer}>
            <Text style={styles.label}>DROP</Text>
            <Text style={styles.value}>
              {moment(item.pool.dropDate).format("l")}
            </Text>
          </View>
          <TouchableOpacity
            style={[styles.button, { backgroundColor: "black" }]}
            onPress={() =>
              RootNavigation.navigate("StreamerProfile", {
                tag: item.pool.streamerId.alias,
              })
            }
          >
            <FontAwesome name="external-link" size={14} color="grey" />
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ marginTop: 20, flexDirection: "row", flexWrap: "wrap" }}>
        {totalListings > 0 ? (
          <TouchableOpacity
            style={[styles.button, { backgroundColor: "black" }]}
            onPress={() => console.log("Already Listed")}
          >
            <Text style={[styles.buttonText, { color: "white" }]}>
              Listed on Market
            </Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={styles.button}
            onPress={() => setIsModalVisible(true)}
          >
            <Text style={styles.buttonText}>Sell React</Text>
          </TouchableOpacity>
        )}

        {totalBids > 0 ? (
          <TouchableOpacity
            style={[styles.button, { backgroundColor: "red" }]}
            onPress={() => setIsIncomingBidsVisible(true)}
          >
            <Text style={[styles.buttonText, { color: "white" }]}>
              {totalBids} Bid(s)
            </Text>
          </TouchableOpacity>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    width: 330,
    height: 380,
    backgroundColor: colors.backOverlay,
    borderRadius: 10,
    marginVertical: 20,
    padding: 20,
    marginRight: 20,
  },
  reactName: {
    fontFamily: fonts.semiBold,
    color: "white",
    fontSize: 22,
    marginBottom: 5,
    lineHeight: 35,
  },
  streamerAlias: {
    fontFamily: fonts.expandedBold,
    color: "grey",
    fontSize: 16,
    marginBottom: 20,
  },
  reactContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  thumbnail: {
    backgroundColor: "#E8E8E8",
    width: 180,
    height: 180,
    borderRadius: 10,
  },
  reactDetails: {
    width: 100,
  },
  detailContainer: {
    marginBottom: 10,
  },
  label: {
    fontFamily: fonts.semiBold,
    color: "grey",
    fontSize: 12,
  },
  value: {
    fontFamily: fonts.bold,
    color: "white",
    fontSize: 18,
  },
  button: {
    backgroundColor: colors.green,
    paddingVertical: 8,
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    marginVertical: 10,
    alignSelf: "flex-start",
    marginRight: 10,
  },
  buttonText: {
    fontFamily: fonts.semiBold,
  },
});

export default ReactGridItem;
