import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { LinearGradient } from "expo-linear-gradient";
import { useMediaQuery } from "react-responsive";
import { Context as TwitchContext } from "../../context/TwitchContext";

const VideoPlayer = () => {
  const {
    state: { currentStream },
  } = useContext(TwitchContext);
  let isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const window = useWindowDimensions();

  return (
    <View
      style={
        isTabletOrMobile
          ? {
              width: 0.92 * window.width + 15,
              height: (1 / (16 / 9)) * (0.92 * window.width) + 15,
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 40,
            }
          : {
              width: 0.6 * window.width + 50,
              height: (1 / (16 / 9)) * (0.6 * window.width) + 50,
              justifyContent: "center",
              alignItems: "center",
            }
      }
    >
      <LinearGradient
        // Background Linear Gradient
        colors={["#7dfcb5", "#7dfce0"]}
        style={
          isTabletOrMobile
            ? {
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                width: 0.92 * window.width + 15,
                height: (1 / (16 / 9)) * (0.92 * window.width) + 15,
                zIndex: -1,
              }
            : {
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                width: 0.6 * window.width + 50,
                height: (1 / (16 / 9)) * (0.6 * window.width) + 50,
                zIndex: -1,
              }
        }
        start={{ x: 0.5, y: 0.5 }}
      />

      <iframe
        src={`https://player.twitch.tv/?channel=${currentStream.user_login}&parent=buffd.gg&parent=www.buffd.gg`}
        width={isTabletOrMobile ? 0.92 * window.width : 0.6 * window.width}
        height={
          isTabletOrMobile
            ? (1 / (16 / 9)) * (0.92 * window.width)
            : (1 / (16 / 9)) * (0.6 * window.width)
        }
        allowFullScreen="<allowFullScreen>"
        frameBorder="0"
      ></iframe>
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundGrad: {},
});

export default VideoPlayer;
