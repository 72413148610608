import React, { useContext, useState, useMemo } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { Context as UserContext } from "../../context/UserContext";
import { Context as StripeContext } from "../../context/StripeContext";
import { useMediaQuery } from "react-responsive";

const PayoutInfoRequired = ({ navigation }) => {
  //const [sentToMix, setSentToMix] = useState(false);
  const { triggerConnectAuthContinue } = useContext(StripeContext);
  const [isLoading, setIsLoading] = useState(false);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  //const isDesktop = useMediaQuery({ maxWidth: 1024 });

  const onEnroll = async () => {
    setIsLoading(true);
    await triggerConnectAuthContinue();
    setIsLoading(false);
  };

  return (
    <View style={{ marginTop: 20 }}>
      <TouchableOpacity
        style={[
          styles.button,
          {
            backgroundColor: "red",
          },
        ]}
        onPress={() => {
          if (!isLoading) onEnroll();
        }}
      >
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <Text style={styles.buttonText}>Additional Info Required</Text>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  stripePayoutContainer: {
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    padding: 25,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
    width: 1025,
    marginTop: 40,
  },
  button: {
    backgroundColor: colors.green,
    paddingHorizontal: 30,
    paddingVertical: 8,
    borderRadius: 8,
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  buttonText: {
    fontFamily: fonts.semiBold,
    fontSize: 16,
    color: "white",
  },
});

export default PayoutInfoRequired;
