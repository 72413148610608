import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { socket } from "../api/Socket";
import * as Linking from "expo-linking";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import ReactPixel from "react-facebook-pixel";

const userReducer = (state, action) => {
  switch (action.type) {
    case "fetch_stripe_verify":
      return {
        ...state,
        isStreamerAuthorized: action.payload,
      };
    case "fetch_stripe_balance":
      return {
        ...state,
        balance: action.payload,
      };
    case "reset_stripe_info":
      return {
        isStreamerAuthorized: null,
      };
    default:
      return state;
  }
};

const purchaseStreamerCard = (dispatch) => async (streamerId, tag, price) => {
  try {
    toast.error("Purchases not available at this time");
    // ReactPixel.track("InitiateCheckout", {
    //   currency: "USD",
    //   value: price,
    //   content_ids: [streamerId],
    //   content_name: tag,
    //   content_type: "product",
    //   contents: [{ id: streamerId, quantity: 1 }],
    // });
    // const stripePromise = loadStripe(
    //   "pk_live_51IY33tEHZWPb26FdB3C1CJiEun2i35keEX6t5Lo9R6PLKXCGCXk9FeRlB0XsbZ7SA8LKBb5ZbX7vPEegK1vVcAqZ00pMNioPYZ"
    // );
    // const stripe = await stripePromise;
    // const response = await serverApi.post("/create-checkout-session", {
    //   streamerId: streamerId,
    //   tag: tag,
    //   price: price,
    // });
    // const result = await stripe.redirectToCheckout({
    //   sessionId: response.data.id,
    // });
    // if (result.error) {
    //   console.log(result.error);
    //   toast.error("Something went wrong. Refresh the page and try again.");
    // }
  } catch (err) {
    console.log(err);
    console.log("purchaseStreamerCard error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "triggerStreamerAuth");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const purchasePack = (dispatch) => async (packId, packName, price) => {
  try {
    toast.error("Purchases not available at this time");
    // const stripePromise = loadStripe(
    //   "pk_live_51IY33tEHZWPb26FdB3C1CJiEun2i35keEX6t5Lo9R6PLKXCGCXk9FeRlB0XsbZ7SA8LKBb5ZbX7vPEegK1vVcAqZ00pMNioPYZ"
    // );
    // const stripe = await stripePromise;
    // const response = await serverApi.post("/pack-checkout-session", {
    //   packId,
    //   packName,
    //   price,
    // });
    // const result = await stripe.redirectToCheckout({
    //   sessionId: response.data.id,
    // });
    // if (result.error) {
    //   console.log(result.error);
    //   toast.error("Something went wrong. Refresh the page and try again.");
    // }
  } catch (err) {
    console.log(err);
    console.log("purchaseStreamerCard error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "triggerStreamerAuth");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const triggerConnectAuth =
  (dispatch) => async (country, firstName, lastName) => {
    try {
      console.log("TRIGGERING STREAMER AUTH");
      const response = await serverApi.post("/stripeauth", {
        country,
        firstName,
        lastName,
      });
      Linking.openURL(response.data);
    } catch (err) {
      console.log("triggerConnectAuth error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "triggerConnectAuth");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const triggerConnectAuthContinue = (dispatch) => async () => {
  try {
    console.log("TRIGGERING STREAMER AUTH");
    const response = await serverApi.post("/stripeauth");
    Linking.openURL(response.data);
  } catch (err) {
    console.log("triggerConnectAuthContinue error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "triggerConnectAuthContinue");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const triggerConnectEdit = (dispatch) => async () => {
  try {
    console.log("TRIGGERING STREAMER EDIT");
    const response = await serverApi.get("/stripeupdate");
    Linking.openURL(response.data);
  } catch (err) {
    console.log("triggerConnectEdit error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "triggerConnectEdit");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const fetchStripeBalance = (dispatch) => async () => {
  try {
    console.log("FETCHING STRIPE BALANCE");
    const response = await serverApi.get("/stripebalance");
    dispatch({ type: "fetch_stripe_balance", payload: response.data });
  } catch (err) {
    console.log("fetchStripeBalance error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchStripeBalance");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const fetchStripeVerify = (dispatch) => async (streamerProfile) => {
  if (
    streamerProfile.stripeAccountId == null ||
    streamerProfile.stripeAccountId === undefined ||
    streamerProfile.stripeAccountId == ""
  ) {
    return false;
  } else {
    try {
      console.log("CHECKING STRIPE VERIFICATION");
      const response = await serverApi.get("/stripeverify");
      dispatch({ type: "fetch_stripe_verify", payload: response.data });
    } catch (err) {
      console.log("fetchStripeVerify error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "fetchUserInfo");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  }
};

const resetStripeInfo = (dispatch) => async () => {
  dispatch({ type: "reset_stripe_info" });
};

export const { Provider, Context } = createDataContext(
  userReducer,
  {
    triggerConnectAuth,
    triggerConnectAuthContinue,
    triggerConnectEdit,
    fetchStripeVerify,
    resetStripeInfo,
    purchaseStreamerCard,
    purchasePack,
    fetchStripeBalance,
  },
  {
    isStreamerAuthorized: null,
    balance: null,
  }
);
