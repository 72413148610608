import { StyleSheet } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";

const desktop = StyleSheet.create({
  navSection: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "33.33%",
    zIndex: 10,
  },
  navContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 0,
    paddingBottom: hp("1.5%"),
    width: 125,
  },
  navLink: {
    fontFamily: fonts.bold,
    fontSize: 8,
    paddingTop: 5,
    color: colors.greyText,
  },
  redIndicatorStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "red",
    width: 8,
    height: 8,
    borderRadius: 8,
  },
});

const tabletOrMobile = StyleSheet.create({
  navSection: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  navContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 0,
    paddingBottom: hp("1%"),
    width: 100,
  },
  navLink: {
    fontFamily: fonts.bold,
    fontSize: 8,
    paddingTop: 5,
    color: colors.greyText,
  },
  redIndicatorStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "red",
    width: 8,
    height: 8,
    borderRadius: 8,
  },
});

export { desktop, tabletOrMobile };
