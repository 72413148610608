import mixpanel from "mixpanel-browser";
mixpanel.init("9af5198ca2b6f06bd85e992715bf75ee");

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  reset: () => {
    mixpanel.reset();
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
