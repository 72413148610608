import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import TopBar from "../../components/TopBar";
import { useMediaQuery } from "react-responsive";
import { Context as EmailContext } from "../../context/EmailContext";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";
import fonts from "../../res/fonts";
import { Input } from "react-native-elements";
import { toast } from "react-toastify";

const ContactScreen = ({ navigation }) => {
  const {
    state: { token },
  } = useContext(AuthContext);
  const {
    state: { userInfo },
  } = useContext(UserContext);
  const { sendFeedback } = useContext(EmailContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailText, setEmailText] = useState("");
  const [height, setHeight] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const onMessageSend = async () => {
    setIsLoading(true);
    if (name == "" || email == "" || emailText == "") {
      toast.error("Please complete all form fields.");
      setIsLoading(false);
    } else {
      try {
        let id = "";
        let alias = "";
        if (token) {
          id = userInfo._id;
          alias = userInfo.alias;
        }
        await sendFeedback({
          name: name,
          email: email,
          text: emailText,
          id: id,
          alias: alias,
        });
        setName("");
        setEmail("");
        setEmailText("");
        setHeight("");
        setIsLoading(false);
        toast("Message sent successfully!");
      } catch (err) {
        toast.error("Something went wrong... Try again later.");
      }
    }
  };

  return (
    <View style={styles.container}>
      <TopBar />
      <ScrollView
        contentContainerStyle={{
          paddingTop: hp("8%"),
        }}
      >
        <View
          style={{
            paddingHorizontal: "8%",
            justifyContent: "center",
          }}
        >
          <Text
            style={[
              styles.header,
              { fontSize: isMobile ? 35 : isTabletOrMobile ? 45 : 60 },
            ]}
          >
            Contact Us
          </Text>
          <Text
            style={[
              styles.subHeader,
              { fontSize: isMobile ? 12 : isTabletOrMobile ? 15 : 20 },
            ]}
          >
            We're working hard to create a community of viewers to support
            up-and-coming streamers. We're always looking for feedback and
            ideas!
          </Text>
        </View>
        <View style={styles.formContainer}>
          <Input
            label="NAME"
            labelStyle={{
              fontFamily: fonts.bold,
              fontSize: 14,
              color: "grey",
            }}
            containerStyle={{
              backgroundColor: "white",
              paddingHorizontal: 20,
              marginVertical: 15,
            }}
            placeholder={"John Doe"}
            placeholderTextColor="grey"
            inputContainerStyle={{
              borderColor: colors.lightGreyText,
              borderWidth: 1,
              borderBottomWidth: 1,
              borderRadius: 10,
              marginTop: 20,
              paddingHorizontal: 25,
              paddingVertical: 5,
            }}
            value={name}
            multiline={false}
            inputStyle={{
              fontFamily: fonts.text,
              color: "black",
              fontSize: 20,
              outlineWidth: 0,
            }}
            onChangeText={(newText) => setName(newText)}
          />
          <Input
            label="EMAIL"
            labelStyle={{
              fontFamily: fonts.bold,
              fontSize: 14,
              color: "grey",
            }}
            containerStyle={{
              backgroundColor: "white",
              paddingHorizontal: 20,
              marginVertical: 15,
            }}
            placeholder={"jdoe@gmail.com"}
            placeholderTextColor="grey"
            inputContainerStyle={{
              borderColor: colors.lightGreyText,
              borderWidth: 1,
              borderBottomWidth: 1,
              borderRadius: 10,
              marginTop: 20,
              paddingHorizontal: 25,
              paddingVertical: 5,
            }}
            value={email}
            multiline={false}
            inputStyle={{
              fontFamily: fonts.text,
              color: "black",
              fontSize: 20,
              outlineWidth: 0,
            }}
            onChangeText={(newText) => setEmail(newText)}
          />
          <Input
            label="FEEDBACK"
            labelStyle={{
              fontFamily: fonts.bold,
              fontSize: 14,
              color: "grey",
            }}
            style={{ height: Math.max(35, height) }}
            containerStyle={{
              backgroundColor: "white",
              paddingHorizontal: 20,
              marginVertical: 15,
            }}
            placeholder={"I love Buffd so much and here's why..."}
            placeholderTextColor="grey"
            onContentSizeChange={(event) => {
              setHeight(event.nativeEvent.contentSize.height);
            }}
            inputContainerStyle={{
              borderColor: colors.lightGreyText,
              borderWidth: 1,
              borderBottomWidth: 1,
              borderRadius: 10,
              marginTop: 20,
              padding: 25,
            }}
            value={emailText}
            multiline={true}
            inputStyle={{
              fontFamily: fonts.text,
              color: "black",
              fontSize: 20,
              outlineWidth: 0,
            }}
            onChangeText={(newText) => setEmailText(newText)}
          />
          <TouchableOpacity
            style={styles.contactButton}
            onPress={() => {
              onMessageSend();
            }}
          >
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              <Text
                style={[
                  styles.buttonText,
                  { fontSize: isMobile ? 15 : isTabletOrMobile ? 15 : 20 },
                ]}
              >
                Send Message
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  contentContainer: {
    height: "100%",
    paddingTop: "2%",
    borderWidth: 2,
  },
  header: {
    fontFamily: fonts.expandedBlack,
    color: "black",
    marginBottom: 20,
    textAlign: "center",
  },
  subHeader: {
    fontFamily: fonts.medium,
    color: "grey",
    textAlign: "center",
    paddingHorizontal: "5%",
  },
  formContainer: {
    paddingBottom: hp("20%"),
    paddingTop: hp("8%"),
    backgroundColor: "white",
    paddingHorizontal: "12%",
  },
  contactButton: {
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 8,
    backgroundColor: colors.green,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
  },
  buttonText: {
    fontFamily: fonts.semiBold,
    color: "#008463",
  },
});

export default ContactScreen;
