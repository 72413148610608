import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { Context as UserContext } from "../../../context/UserContext";
import ReactGrid from "./ReactGrid";
import EmptyPortfolio from "../../../../assets/images/EmptyPortfolio";
import ReactIncomingBidsModal from "./Modals/ReactIncomingBidsModal";
import ReactOutgoingBidsModal from "./Modals/ReactOutgoingBidsModal";
import ReactListingsModal from "./Modals/ReactListingsModal";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { Ionicons } from "@expo/vector-icons";
import * as RootNavigation from "../../../RootNavigation";

const ReactView = () => {
  const {
    state: { isUserInfoLoaded, cardPortfolio },
  } = useContext(UserContext);
  const [isIncomingBidsVisible, setIsIncomingBidsVisible] = useState(false);
  const [isOutgoingBidsVisible, setIsOutgoingBidsVisible] = useState(false);
  const [isListingsVisible, setIsListingsVisible] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <View style={styles.outerContainer}>
      <ReactIncomingBidsModal
        isModalVisible={isIncomingBidsVisible}
        setIsModalVisible={setIsIncomingBidsVisible}
      />
      <ReactOutgoingBidsModal
        isModalVisible={isOutgoingBidsVisible}
        setIsModalVisible={setIsOutgoingBidsVisible}
      />
      <ReactListingsModal
        isModalVisible={isListingsVisible}
        setIsModalVisible={setIsListingsVisible}
      />
      <View style={styles.heroContainer}>
        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
          <TouchableOpacity
            style={styles.heroButton}
            onPress={() => setIsIncomingBidsVisible(true)}
          >
            <Text style={{ fontSize: 15 }}>📩</Text>
            <Text style={styles.heroButtonText}>Incoming Bids</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.heroButton}
            onPress={() => setIsOutgoingBidsVisible(true)}
          >
            <Text style={{ fontSize: 15 }}>📬</Text>
            <Text style={styles.heroButtonText}>Your Outgoing Bids</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.heroButton}
            onPress={() => setIsListingsVisible(true)}
          >
            <Text style={{ fontSize: 15 }}>🏛️</Text>
            <Text style={styles.heroButtonText}>Your Marketplace Listings</Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.heroButton}
            onPress={() => RootNavigation.navigate("Transaction")}
          >
            <Text style={{ fontSize: 15 }}>📝</Text>
            <Text style={styles.heroButtonText}>Balance History</Text>
          </TouchableOpacity>
        </View>
        <Text style={styles.header}>
          {cardPortfolio.reactsAcquired
            ? cardPortfolio.reactsAcquired.length
            : 0}{" "}
          React(s) in Total
        </Text>
      </View>

      {cardPortfolio.reactsAcquired &&
      cardPortfolio.reactsAcquired.length > 0 ? (
        <ReactGrid setIsIncomingBidsVisible={setIsIncomingBidsVisible} />
      ) : (
        <View>
          <EmptyPortfolio
            width={400}
            height={400}
            style={{
              alignSelf: "center",
              marginVertical: 20,
            }}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    backgroundColor: "black",
    //marginHorizontal: 10,
  },
  heroButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderRadius: 50,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: "#303030",
    backgroundColor: colors.backOverlay,
    alignSelf: "flex-start",
    marginRight: 20,
    marginBottom: 20,
  },
  heroButtonText: {
    fontFamily: fonts.semiBold,
    fontSize: 16,
    color: "white",
    marginLeft: 15,
    marginRight: 8,
  },
  header: {
    fontFamily: fonts.expandedBlack,
    fontSize: 20,
    color: "white",
    marginTop: 20,
    marginBottom: 20,
  },
});

export default ReactView;
