import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import PayoutAuth from "./PayoutAuth";
import PayoutInfoRequired from "./PayoutInfoRequired";
import { Context as UserContext } from "../../context/UserContext";
import { Context as ReactContext } from "../../context/ReactContext";
import { Context as StripeContext } from "../../context/StripeContext";
import { useMediaQuery } from "react-responsive";

const PayoutInfo = ({ navigation }) => {
  const {
    state: { cardPortfolio },
  } = useContext(UserContext);
  const {
    fetchBalance,
    state: { balance },
  } = useContext(ReactContext);
  // const {
  //   triggerConnectEdit,
  //   fetchStripeBalance,
  //   state: { balance },
  // } = useContext(StripeContext);
  const [isLoading, setIsLoading] = useState(false);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  //const isDesktop = useMediaQuery({ maxWidth: 1024 });

  const viewWidth = isMobile || isTabletOrMobile ? "95%" : 1025;

  // const onEdit = async () => {
  //   setIsLoading(true);
  //   await triggerConnectEdit();
  //   setIsLoading(false);
  // };

  useEffect(() => {
    const loadBalance = async () => {
      // if (
      //   false &&
      //   cardPortfolio.stripeConnectId &&
      //   cardPortfolio.stripeConnectId != undefined &&
      //   cardPortfolio.stripeVerified == true &&
      //   !balance
      // ) {
      //   await fetchStripeBalance();
      // }
      if (!balance) {
        await fetchBalance();
      }
    };
    loadBalance();
  }, []);

  if (true) {
    return (
      <View style={[styles.stripePayoutContainer, { width: viewWidth }]}>
        <Text style={styles.header}>
          Payouts: <Text style={{ color: "red" }}>Coming Soon</Text>
        </Text>

        <Text style={styles.desc}>
          The Buffd team is working around the clock with our partnered payment
          processor to make the payout process as easy possible for you. Please
          contact support@buffd.gg with questions.
        </Text>

        <View style={{ marginVertical: 20 }}>
          <Text style={styles.labelDesc}>Balance due for Payouts</Text>
          {!balance ? (
            <ActivityIndicator
              style={{ marginTop: 10, alignSelf: "flex-start" }}
            />
          ) : (
            <Text style={styles.payoutValue}>
              ${balance.amountDue.toFixed(2)}
            </Text>
          )}
        </View>
      </View>
    );
  } else {
    return (
      <>
        <View style={[styles.stripePayoutContainer, { width: viewWidth }]}>
          {!cardPortfolio.stripeConnectId ||
          cardPortfolio.stripeConnectId === undefined ||
          !cardPortfolio.stripeVerified ? (
            <Text style={styles.header}>
              Payouts: <Text style={{ color: "red" }}>Action Required</Text>
            </Text>
          ) : (
            <Text style={styles.header}>Payouts</Text>
          )}

          {!cardPortfolio.stripeConnectId ||
          cardPortfolio.stripeConnectId === undefined ||
          !cardPortfolio.stripeVerified ? (
            <Text style={styles.desc}>
              In order to list items for auction and receive payouts, we need
              some more information for legal and tax purposes
            </Text>
          ) : null}
          {!cardPortfolio.stripeConnectId ||
          cardPortfolio.stripeConnectId === undefined ? (
            <PayoutAuth />
          ) : !cardPortfolio.stripeVerified > 0 ? (
            <PayoutInfoRequired />
          ) : (
            <>
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginVertical: 20,
                }}
              >
                <View style={{ marginHorizontal: 20 }}>
                  <Text style={styles.labelDesc}>
                    Current Balance for Automated Payouts
                  </Text>
                  {!balance ? (
                    <ActivityIndicator
                      style={{ marginTop: 10, alignSelf: "flex-start" }}
                    />
                  ) : (
                    <Text style={styles.payoutValue}>
                      ${balance.balanceAvailable}
                    </Text>
                  )}
                </View>

                <View style={{ marginHorizontal: 20 }}>
                  <Text style={styles.labelDesc}>Pending Balance</Text>
                  {!balance ? (
                    <ActivityIndicator
                      style={{ marginTop: 10, alignSelf: "flex-start" }}
                    />
                  ) : (
                    <Text style={styles.payoutValue}>
                      ${balance.balancePending}
                    </Text>
                  )}
                </View>
              </View>
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    backgroundColor: colors.green,
                  },
                ]}
                onPress={() => {
                  if (!isLoading) onEdit();
                }}
              >
                {isLoading ? (
                  <ActivityIndicator />
                ) : (
                  <Text style={styles.buttonText}>
                    Manage Payouts & View History
                  </Text>
                )}
              </TouchableOpacity>
            </>
          )}
        </View>
      </>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  innerContainer: {
    width: 1025,
    alignSelf: "center",
  },
  stripePayoutContainer: {
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    padding: 25,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
    width: 1025,
    marginTop: 40,
  },
  headerContainer: {
    marginVertical: 50,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
  },
  desc: {
    fontFamily: fonts.text,
    fontSize: 14,
    marginTop: 20,
  },
  labelDesc: {
    fontFamily: fonts.expandedBold,
    color: "grey",
    fontSize: 15,
  },
  payoutValue: {
    fontFamily: fonts.expandedBold,
    color: "black",
    fontSize: 40,
    marginTop: 10,
  },
  button: {
    backgroundColor: colors.green,
    paddingHorizontal: 30,
    paddingVertical: 8,
    borderRadius: 8,
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  buttonText: {
    fontFamily: fonts.semiBold,
    fontSize: 16,
  },
});

export default PayoutInfo;
