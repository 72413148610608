import React, { useContext, useState, useMemo } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  useWindowDimensions,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { Context as UserContext } from "../../context/UserContext";
import { Context as StripeContext } from "../../context/StripeContext";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import countryList from "react-select-country-list";
import { Input } from "react-native-elements";

const stripeCountries = [
  "US",
  "AT",
  "AR",
  "AU",
  "BE",
  "BG",
  "BO",
  "CA",
  "CH",
  "CR",
  "CY",
  "CZ",
  "DE",
  "DK",
  "DO",
  "EE",
  "ES",
  "FI",
  "FR",
  "GB",
  "GR",
  "HK",
  "HR",
  "HU",
  "ID",
  "IE",
  "IL",
  "IS",
  "IT",
  "LI",
  "LT",
  "LU",
  "LV",
  "MT",
  "MX",
  "NL",
  "NO",
  "NZ",
  "PE",
  "PL",
  "PT",
  "RO",
  "SE",
  "SG",
  "SI",
  "SK",
  "TH",
  "TT",
  "UY",
];

const PayoutAuth = ({ navigation }) => {
  //const [sentToMix, setSentToMix] = useState(false);
  const {
    state: { streamerProfile },
  } = useContext(UserContext);
  const { triggerConnectAuth } = useContext(StripeContext);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  //const isDesktop = useMediaQuery({ maxWidth: 1024 });

  const onEnroll = async () => {
    setIsLoading(true);
    await triggerConnectAuth(value.value, first, last);
    setIsLoading(false);
  };

  const changeHandler = (value) => {
    setValue(value);
    if (!stripeCountries.includes(value.value)) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  return (
    <View style={{ marginTop: 20 }}>
      <Input
        label="Legal First Name"
        labelStyle={{
          fontFamily: fonts.bold,
          fontSize: 14,
          color: "grey",
        }}
        containerStyle={{
          backgroundColor: "white",
          paddingHorizontal: 20,
          marginVertical: 5,
          marginHorizontal: 0,
        }}
        placeholder={"John"}
        placeholderTextColor="grey"
        inputContainerStyle={{
          borderColor: colors.lightGreyText,
          borderWidth: 1,
          borderBottomWidth: 1,
          borderRadius: 10,
          marginTop: 20,
          paddingHorizontal: 15,
        }}
        value={first}
        multiline={false}
        inputStyle={{
          fontFamily: fonts.text,
          color: "black",
          fontSize: 12,
          outlineWidth: 0,
        }}
        onChangeText={(newText) => setFirst(newText)}
      />
      <Input
        label="Legal Last Name"
        labelStyle={{
          fontFamily: fonts.bold,
          fontSize: 14,
          color: "grey",
        }}
        containerStyle={{
          backgroundColor: "white",
          paddingHorizontal: 20,
          marginVertical: 5,
        }}
        placeholder={"Doe"}
        placeholderTextColor="grey"
        inputContainerStyle={{
          borderColor: colors.lightGreyText,
          borderWidth: 1,
          borderBottomWidth: 1,
          borderRadius: 10,
          marginTop: 20,
          paddingHorizontal: 15,
        }}
        value={last}
        multiline={false}
        inputStyle={{
          fontFamily: fonts.text,
          color: "black",
          fontSize: 12,
          outlineWidth: 0,
        }}
        onChangeText={(newText) => setLast(newText)}
      />
      <View style={{ marginVertical: 10, paddingHorizontal: 20 }}>
        <Select
          options={options}
          placeholder={"Select your Country"}
          value={value}
          onChange={changeHandler}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            marginVertical: 10,
          }}
        >
          <TouchableOpacity
            style={styles.countryPickerButton}
            onPress={() =>
              changeHandler({ value: "US", label: "United States" })
            }
          >
            <Text style={styles.countryText}>🇺🇸 US</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.countryPickerButton}>
            <Text
              style={styles.countryText}
              onPress={() => changeHandler({ value: "CA", label: "Canada" })}
            >
              🇨🇦 CA
            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.countryPickerButton}>
            <Text
              style={styles.countryText}
              onPress={() =>
                changeHandler({ value: "GB", label: "United Kingdom" })
              }
            >
              🇬🇧 GB
            </Text>
          </TouchableOpacity>
        </View>

        {isValid ? (
          <TouchableOpacity
            style={[
              styles.button,
              {
                backgroundColor:
                  value == "" || first == "" || last == ""
                    ? "red"
                    : colors.green,
              },
            ]}
            onPress={() => {
              if (!isLoading) onEnroll();
            }}
          >
            {isLoading ? (
              <ActivityIndicator />
            ) : value == "" || first == "" || last == "" ? (
              <Text style={[styles.buttonText, { color: "white" }]}>
                Fill in the info above
              </Text>
            ) : (
              <Text style={styles.buttonText}>Continue</Text>
            )}
          </TouchableOpacity>
        ) : (
          <Text style={[styles.buttonText, { marginTop: 20 }]}>
            We do not currently support {value.value} for automated payouts.
            Please contact support@buffd.gg
          </Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  stripePayoutContainer: {
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    padding: 25,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
    width: 1025,
    marginTop: 40,
  },
  countryPickerButton: {
    marginHorizontal: 10,
    backgroundColor: "white",
    borderColor: "#E8E8E8",
    borderWidth: 2,
    borderRadius: 1,
    paddingHorizontal: 15,
    paddingVertical: 4,
    borderRadius: 10,
  },
  countryText: {
    fontFamily: fonts.text,
  },
  button: {
    backgroundColor: colors.green,
    paddingHorizontal: 30,
    paddingVertical: 8,
    borderRadius: 8,
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  buttonText: {
    fontFamily: fonts.semiBold,
    fontSize: 16,
  },
});

export default PayoutAuth;
