import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  useWindowDimensions,
  Modal,
} from "react-native";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { useMediaQuery } from "react-responsive";
import { useIsFocused } from "@react-navigation/native";
import { MaterialIcons } from "@expo/vector-icons";
import { Context as UserContext } from "../../../context/UserContext";
import { Mixpanel } from "../../../api/mixPanel";

const PackOpenModal = ({ data, isModalVisible, setIsModalVisible }) => {
  const { initiatePackOpen } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const isFocused = useIsFocused();
  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;

  const openPack = async () => {
    try {
      setIsLoading(true);
      Mixpanel.track("Open Pack", { packName: data.packName });
      await initiatePackOpen(data);
    } catch (e) {
      if (isFocused) {
        setIsLoading(false);
        setIsModalVisible(false);
      }
    }
  };

  return (
    <Modal animationType="fade" transparent={true} visible={isModalVisible}>
      <TouchableWithoutFeedback
        onPress={() => {
          Mixpanel.track("Claim Pack Close", { streamerAlias: data.alias });
          setIsModalVisible(!isModalVisible);
        }}
      >
        <View
          style={{
            position: "absolute",
            height: window.height,
            width: window.width,
            zIndex: 100000000,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        />
      </TouchableWithoutFeedback>

      <View
        style={{
          position: "absolute",
          zIndex: 100000002,
          paddingTop: "10%",
          alignSelf: "center",
        }}
      >
        <View style={[styles.container, { width: modalWidth }]}>
          <View style={styles.topRow}>
            <Text style={styles.header}>Open pack</Text>
            <TouchableOpacity
              onPress={() => {
                setIsModalVisible(!isModalVisible);
              }}
            >
              <MaterialIcons name="cancel" size={24} color="grey" />
            </TouchableOpacity>
          </View>

          <Text style={styles.explainerText}>
            Once your pack is opened, it cannot be undone. Are you sure you want
            to open it?
          </Text>
          <TouchableOpacity
            style={[styles.authButton, { backgroundColor: colors.green }]}
            onPress={() => openPack()}
          >
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              <Text style={[styles.authText, { color: "black" }]}>
                OPEN PACK
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    alignContent: "center",
    //justifyContent: "center",
    padding: 30,
    alignSelf: "center",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
  },
  inputLabel: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
    marginLeft: 20,
    marginBottom: 10,
  },
  inputContainer: {
    marginTop: 30,
    marginHorizontal: 0,
  },
  labelStyle: {
    fontFamily: fonts.text,
    fontSize: 14,
    marginBottom: 10,
    color: "grey",
  },
  inputContainerStyle: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    marginVertical: 5,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 25,
    paddingVertical: 5,
  },
  inputTextStyle: {
    fontFamily: fonts.text,
    color: "black",
    fontSize: 14,
    outlineWidth: 0,
  },
  commonAmountRow: {
    flexDirection: "row",
    marginVertical: 20,
    justifyContent: "space-between",
  },
  amountButton: {
    width: "30%",
    backgroundColor: colors.green,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  amountText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
  },
  purchaseButton: {
    backgroundColor: "black",
    marginTop: 25,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  purchaseText: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 14,
  },
  explainerText: {
    fontFamily: fonts.text,
    color: colors.greyText,
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
  },
  authButton: {
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    paddingVertical: 14,
    marginVertical: 15,
  },
  authText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
    color: "white",
  },
});

export default PackOpenModal;
