import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import RenderReactGrid from "./RenderReactGrid";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import SocialLinkRow from "./SocialLinkRow";
import PullReactModal from "./Modals/PullReactModal";
import { Context as StreamerContext } from "../../context/StreamerContext";
import { useMediaQuery } from "react-responsive";
import StickyBox from "react-sticky-box";
import { Entypo } from "@expo/vector-icons";

const StreamerFeed = ({ setSelectedCollectible }) => {
  const {
    state: { fetchedStreamer },
  } = useContext(StreamerContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const window = useWindowDimensions();

  const allReacts = fetchedStreamer.reactPools.filter(
    (item) => item.status == 2
  );
  const collectibleList =
    fetchedStreamer.cardDetails.cardDesign &&
    fetchedStreamer.cardDetails.cardDesign != ""
      ? [...allReacts, { _id: "card", status: 3 }]
      : allReacts;

  const availableReacts = allReacts.filter((item) => item.claimed < item.total);
  const reactsReserve =
    availableReacts.length > 0 ? availableReacts[0].reserve : null;

  const seriesCount =
    (fetchedStreamer.cardDetails.cardDesign &&
    fetchedStreamer.cardDetails.cardDesign != ""
      ? 1
      : 0) + (fetchedStreamer.reactPools.length > 0 ? 1 : 0);

  const streamerInfo = (
    <View
      style={{
        width: 320,
        height: 500,
      }}
    >
      <View style={styles.bioContainer}>
        <Image
          style={styles.imageContainer}
          source={{ uri: fetchedStreamer.profilePic }}
        />
        <View style={styles.nameContainer}>
          <SocialLinkRow data={fetchedStreamer} />
          <Text style={styles.name}>{fetchedStreamer.alias}</Text>
        </View>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ marginVertical: 20, marginRight: 35 }}>
          <Text style={styles.label}>SERIES</Text>
          <Text style={styles.value}>{seriesCount}</Text>
        </View>
        <View style={{ marginVertical: 20 }}>
          <Text style={styles.label}>ITEMS</Text>
          <Text style={styles.value}>{collectibleList.length}</Text>
        </View>
      </View>
      <Text style={styles.bio}>{fetchedStreamer.info.bio}</Text>
      <TouchableOpacity
        style={[
          styles.purchaseButton,
          {
            backgroundColor: availableReacts.length > 0 ? colors.green : "red",
          },
        ]}
        onPress={() => {
          if (availableReacts.length > 0) {
            setIsModalVisible(true);
          }
        }}
      >
        <Text
          style={[
            styles.purchaseText,
            { color: availableReacts.length > 0 ? "black" : "white" },
          ]}
        >
          {availableReacts.length > 0
            ? `Purchase a ${fetchedStreamer.alias} React`
            : "Unavailable"}
        </Text>
      </TouchableOpacity>
      <Text style={styles.purchaseDesc}>
        Pull one of the available {fetchedStreamer.alias} Reacts
      </Text>
    </View>
  );

  return (
    <View
      style={{
        flexDirection: isMobile ? "column" : "row",
        alignItems: isMobile ? "center" : "flex-start",
        justifyContent: "space-between",
      }}
    >
      {isMobile ? (
        <>{streamerInfo}</>
      ) : (
        <StickyBox offsetTop={50} offsetBottom={20}>
          {streamerInfo}
        </StickyBox>
      )}
      <View
        style={{
          minHeight: 1000,
          width: !isTabletOrMobile ? 660 : 320,
        }}
      >
        <View>
          <Text
            style={{
              fontFamily: fonts.semiBold,
              letterSpacing: 4,
              color: "white",
              fontSize: 14,
              textAlign: "center",
              marginBottom: 8,
            }}
          >
            COLLECTIBLES
          </Text>
          <View
            style={{
              borderColor: "white",
              borderBottomWidth: StyleSheet.hairlineWidth,
              marginBottom: 25,
            }}
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {collectibleList.map((item) => {
            if (item.status < 3) {
              return (
                <View key={`${item._id}`}>
                  <View style={styles.cardContainer}>
                    <RenderReactGrid item={item} />
                  </View>
                  <TouchableOpacity
                    style={styles.viewButton}
                    onPress={() => setSelectedCollectible(item)}
                  >
                    <Text style={styles.viewText}>VIEW MORE</Text>
                    <Entypo
                      name="chevron-right"
                      size={12}
                      color="black"
                      style={{ marginLeft: 5 }}
                    />
                  </TouchableOpacity>
                </View>
              );
            } else {
              return (
                <View key={`${item._id}`}>
                  <Image
                    resizeMode="cover"
                    style={styles.cardContainer}
                    source={{ uri: fetchedStreamer.cardDetails.cardDesign }}
                  />
                  <TouchableOpacity
                    style={styles.viewButton}
                    onPress={() => setSelectedCollectible(item)}
                  >
                    <Text style={styles.viewText}>VIEW MORE</Text>
                    <Entypo
                      name="chevron-right"
                      size={12}
                      color="black"
                      style={{ marginLeft: 5 }}
                    />
                  </TouchableOpacity>
                </View>
              );
            }
          })}
        </View>
        <Text
          style={{
            marginTop: 40,
            alignSelf: "center",
            textAlign: "center",
            fontFamily: fonts.expandedBold,
            color: "grey",
            fontSize: 12,
          }}
        >
          Looks like we're all out
        </Text>
      </View>
      {availableReacts.length > 0 ? (
        <PullReactModal
          data={{ ...fetchedStreamer, reserve: reactsReserve }}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "black",
  },
  bioContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  imageContainer: {
    backgroundColor: colors.backOverlay,
    borderRadius: 10,
    width: 75,
    height: 75,
    marginRight: 16,
  },
  name: {
    fontFamily: fonts.semiBold,
    color: "white",
    fontSize: 30,
  },
  label: {
    fontFamily: fonts.semiBold,
    fontSize: 12,
    color: "#484848",
    letterSpacing: 4,
    marginBottom: 5,
  },
  value: {
    fontFamily: fonts.expandedBlack,
    color: "white",
    fontSize: 40,
  },
  bio: {
    marginTop: 5,
    fontFamily: fonts.text,
    color: "grey",
    fontSize: 14,
    lineHeight: 22,
  },
  purchaseButton: {
    backgroundColor: colors.green,
    marginTop: 30,
    marginBottom: 15,
    paddingVertical: 14,
    justifyContent: "center",
    alignItems: "center",
  },
  purchaseText: {
    fontFamily: fonts.semiBold,
  },
  purchaseDesc: {
    color: "white",
    fontFamily: fonts.text,
    fontSize: 12,
  },
  cardContainer: {
    alignSelf: "flex-start",
    alignItems: "flex-start",
    height: 410,
    width: 315,
    marginHorizontal: 5,
    marginTop: 10,
    borderColor: "grey",
    borderWidth: StyleSheet.hairlineWidth,
  },
  viewButton: {
    marginHorizontal: 5,
    backgroundColor: "white",
    paddingVertical: 12,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  viewText: {
    fontFamily: fonts.semiBold,
    fontSize: 10,
    letterSpacing: 2,
    color: "black",
  },
});

export default StreamerFeed;
