import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { socket } from "../api/Socket";
import { toast } from "react-toastify";

const packReducer = (state, action) => {
  switch (action.type) {
    case "fetch_pack":
      return {
        ...state,
        fetchedPack: action.payload,
      };
    case "update_pack_info":
      return {
        ...state,
        fetchedPack:
          state.fetchedPack != null &&
          `${action.payload._id}` == `${state.fetchedPack._id}`
            ? action.payload
            : state.fetchedPack,
      };
    case "check_claim":
      return {
        ...state,
        isClaimEligible: { bool: action.payload },
      };
    case "navigate_pack": {
      return {
        ...state,
        navigatedPack: true,
      };
    }
    case "clear_navigate_pack": {
      return {
        ...state,
        navigatedPack: false,
      };
    }
    case "reset_fetched_pack": {
      return {
        ...state,
        fetchedPack: null,
      };
    }
    case "reset":
      return {
        fetchedPack: null,
      };
    default:
      return state;
  }
};

const makePackConnection = (dispatch) => async () => {
  try {
    socket.on("packClaimed", (data) => {
      console.log("--GLOBAL SOCKET PACK CLAIMED");
      dispatch({ type: "update_pack_info", payload: data.body.packPool });
      toast("Community Pack Claimed");
    });
  } catch (err) {
    console.log("makePackConnection error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "makePackConnection");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const fetchPackInfo = (dispatch) => async () => {
  try {
    console.log("FETCHING PACK INFO");
    const response = await serverApi.post("/packprofile");
    dispatch({ type: "fetch_pack", payload: response.data });
  } catch (err) {
    console.log("fetchPackInfo error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchPackInfo");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const navigatePack = (dispatch) => async () => {
  dispatch({ type: "navigate_pack" });
};

const clearNavigatePack = (dispatch) => async () => {
  dispatch({ type: "clear_navigate_pack" });
};

const resetFetchedPack = (dispatch) => async () => {
  dispatch({ type: "reset_fetched_pack" });
};

const reset = (dispatch) => async () => {
  dispatch({ type: "reset" });
};

export const { Provider, Context } = createDataContext(
  packReducer,
  {
    makePackConnection,
    fetchPackInfo,
    navigatePack,
    clearNavigatePack,
    resetFetchedPack,
    reset,
  },
  {
    fetchedPack: null,
    navigatedPack: false,
  }
);
