import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Image,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import ClipModal from "../HomeScreen/FeedView/ClipModal";
import moment from "moment";
import { FontAwesome } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../RootNavigation";

const ReactPurchaseButton = ({ item }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isScouting, setIsScouting] = useState(
    item.dropDate ? moment().isBefore(moment(item.dropDate)) : true
  );
  const [timeRemaining, setTimeRemaining] = useState(" -- : -- : -- ");
  const [isLoading, setIsLoading] = useState(false);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (isScouting == false) clearInterval(timeInterval);
      const now = moment();
      const duration = moment.duration(moment(item.dropDate).diff(now));
      if (duration <= 0) {
        clearInterval(timeInterval);
        setTimeRemaining("00:00:00");
        setIsScouting(false);
      } else {
        setTimeRemaining(
          moment.utc(duration.asMilliseconds()).format("HH:mm:ss")
        );
      }
    }, 1000);
    return () => clearInterval(timeInterval);
  }, []);

  const availableListings = item.listings.filter((item) => item.status == 0);
  const type =
    item.claimed.length < item.total ? 0 : availableListings.length > 0 ? 1 : 2;
  //0 direct purchase, 1 market listings, 2 place bid

  return (
    <>
      <Text style={styles.soldOutText}>
        {isScouting
          ? "SCOUTING"
          : type == 0
          ? `${item.total - item.claimed.length} REMAINING`
          : "SOLD OUT"}
      </Text>
      <TouchableOpacity
        style={styles.claimButton}
        onPress={() => {
          if (!isScouting) setIsModalVisible(true);
        }}
      >
        <Text style={styles.claimText}>
          {isScouting
            ? `SCOUTING: ${timeRemaining}`
            : type == 0
            ? "BUY REACT"
            : type == 1
            ? "MARKETPLACE LISTINGS"
            : "PLACE BID"}
        </Text>
      </TouchableOpacity>
      <Text style={styles.descText}>
        Every purchase supports {item.streamerId.alias} directly
      </Text>

      {!isScouting ? (
        <ClipModal
          data={item}
          type={type}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  soldOutText: {
    marginTop: 20,
    marginBottom: 10,
    fontFamily: fonts.expandedBold,
    color: "red",
    fontSize: 12,
  },
  claimButton: {
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2.5,
    paddingVertical: 14,
  },
  claimText: {
    fontFamily: fonts.expandedBold,
    fontSize: 11,
  },
  descText: {
    marginTop: 15,
    fontFamily: fonts.medium,
    color: "grey",
    fontSize: 12,
  },
});

export default ReactPurchaseButton;
