import React, { useContext, useState, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  StatusBar,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  useWindowDimensions,
  Modal,
} from "react-native";
import colors from "../res/colors";
import fonts from "../res/fonts";
import { useMediaQuery } from "react-responsive";
import { useIsFocused } from "@react-navigation/native";
import { MaterialIcons } from "@expo/vector-icons";
import { Input, CheckBox } from "react-native-elements";
import SignaturePad from "react-signature-pad-wrapper";
import { Context as UserContext } from "../context/UserContext";
import { Mixpanel } from "../api/mixPanel";

const ContractModal = ({ isModalVisible, setIsModalVisible, styles }) => {
  const {
    submitIntakeForm,
    submitSignature,
    state: { streamerProfile },
  } = useContext(UserContext);
  const [avgViewers, setAvgViewers] = useState("");
  const [currentRevenue, setCurrentRevenue] = useState("");
  const [gamePlayed, setGamePlayed] = useState("");
  const [motivation, setMotivation] = useState("");
  const [fullName, setFullName] = useState("");
  const [isMinor, setIsMinor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const signaturePad = useRef(null);
  const isFocused = useIsFocused();
  const windowSize = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const onAgree = async () => {
    if (signaturePad != null) {
      if (
        avgViewers == "" ||
        currentRevenue == "" ||
        gamePlayed == "" ||
        motivation == ""
      ) {
        setError("Streamer Info fields blank");
      } else if (signaturePad.current.isEmpty()) {
        setError("Signature field blank");
      } else if (fullName == "") {
        setError("Enter your full name");
      } else {
        setIsLoading(true);
        await submitIntakeForm({
          streamerId: streamerProfile._id,
          avgViewers: avgViewers,
          currentRevenue: currentRevenue,
          gamePlayed: gamePlayed,
          motivation: motivation,
        });
        const signaturePng = signaturePad.current.toDataURL();
        await submitSignature(signaturePng, fullName, isMinor);
        setIsModalVisible(false);
      }
    } else {
      setError("Signature field blank");
    }
  };

  return (
    <Modal animationType="fade" transparent={true} visible={isModalVisible}>
      <View
        style={{
          position: "absolute",
          backgroundColor: "white",
          zIndex: 100000000,
          height: windowSize.height,
          width: windowSize.width,
          flexGrow: 1,
        }}
      >
        <ScrollView contentContainerStyle={styles.container}>
          <View style={styles.topRow}>
            <Text style={styles.header}>Streamer Agreement</Text>
            {streamerProfile.status == 0 ? (
              <TouchableOpacity
                onPress={() => {
                  setIsModalVisible(!isModalVisible);
                }}
              >
                <MaterialIcons name="cancel" size={24} color="grey" />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity></TouchableOpacity>
            )}
          </View>
          {streamerProfile.status == 0 ? (
            <Text style={styles.descText}>
              <TouchableOpacity
                onPress={() =>
                  window.open(
                    "https://drive.google.com/file/d/19kT0Ynclw5JRM8abgs9TEtPYIyn7dinS/view?usp=sharing"
                  )
                }
              >
                <Text
                  style={{ color: "blue", textDecorationLine: "underline" }}
                >
                  Click here to view the Streamer Agreement as a PDF.
                </Text>
              </TouchableOpacity>{" "}
              Email support@buffd.gg for help.
            </Text>
          ) : (
            <Text style={styles.descText}>
              In order to continue being a streamer on Buffd, we need your
              permission to keep using your name on the platform. Read the
              summary page below and scroll down to agree. {"\n\n"} Contact
              support@buffd.gg with any questions or if you choose to be removed
              as a streamer.
            </Text>
          )}

          <Text style={{ marginBottom: 10 }}>
            This COLLECTIBLE RELEASE (“Release”) governs the use of any artwork,
            images and/or video content provided to Buffd, Inc. for use under
            this Release (“Collectibles”).{"\n\n"}In consideration of publicity
            and exposure occasioned by its exposure of the Collectibles, the
            receipt and sufficiency of which is hereby acknowledged, I hereby
            grant Buffd, Inc. and its subsidiaries, licensees, agents,
            representatives, successors and assigns (“Buffd”) the right to use,
            record, broadcast, transmit, sell, publicly perform, display,
            publish, create derivative works of, and reproduce the Collectibles,
            in perpetuity, throughout the world, in any media now known or later
            developed, for any advertising, promotional, or other commercial
            purpose in connection with Buffd’s business activities. In
            connection with the use of the Collectibles, Buffd may use my name,
            image, likeness, trademarks, service marks, biographical details,
            logos, videos, streams, and all other indicia of identity specified
            and/or associated in connection with the same. Buffd is not required
            to exercise any of the rights in this Release or to otherwise use
            the Collectibles in any manner.{"\n\n"}I hereby waive any right that
            I may have to inspect and/or approve any uses of the Collectibles or
            other exercise by Buffd of the rights granted herein. This Release
            is irrevocable and shall be binding upon my heirs, executors,
            administrator, successors and assigns. For a period of five (5)
            years, I agree to not grant to any other persons or entities the
            right to use the Collectibles who are in the primary business of
            selling digital collectibles. I hereby release, discharge and agree
            to hold harmless Buffd entities and their officers and directors
            from any liability by virtue of any use granted herein. I am
            responsible for any taxes that I may be required to pay based on
            sales of any Collectibles. I acknowledge that Buffd will receive a
            25% commission on primary sales of my Collectibles and split equally
            a 10% marketplace commission on any secondary sales.{"\n\n"}Buffd
            reserves the right to modify this Agreement at any time; provided,
            Buffd shall provide you written notice (email is sufficient) prior
            to any such change going into effect. Streamer’s failure to
            terminate this Agreement upon written notice to Buffd (email is
            sufficient) following receipt of the Modification Notice shall
            constitute Streamer’s acceptance of all changes set forth therein.
            {"\n\n"}This Release shall be governed by the laws of the State of
            Illinois and any disputes related hereto will be exclusively settled
            in the state or federal courts in Cook County, Illinois and I agree
            to such jurisdiction and venue.{"\n\n"}
          </Text>

          <View style={{ borderWidth: 1, borderColor: "grey", padding: 25 }}>
            <Text style={{ fontFamily: fonts.semiBold, fontSize: 25 }}>
              Streamer Info
            </Text>
            <Text
              style={{
                fontFamily: fonts.text,
                fontSize: 16,
                marginTop: 15,
                marginBottom: 30,
              }}
            >
              This helps us customize the experience to your needs
            </Text>
            <Input
              label="Average Concurrent Viewers"
              placeholder="How many concurrent viewers do you average?"
              value={avgViewers}
              onChangeText={(newValue) =>
                setAvgViewers(newValue.replace(/[^0-9]/g, ""))
              }
            />
            <View style={{ marginVertical: 15 }} />
            <Input
              label="Current Monthly Streaming Revenue ($USD)"
              placeholder="How much you currently make through streaming monthly?"
              value={currentRevenue}
              onChangeText={(newValue) =>
                setCurrentRevenue(newValue.replace(/[^0-9]/g, ""))
              }
            />
            <View style={{ marginVertical: 15 }} />
            <Input
              label="Games You Play"
              placeholder="What game(s) do you play?"
              value={gamePlayed}
              onChangeText={(newValue) => setGamePlayed(newValue)}
            />
            <View style={{ marginVertical: 15 }} />
            <Input
              label="How Will You Use Buffd?"
              placeholder="How do you plan to use Buffd for your stream? Why do you want to use Buffd?"
              value={motivation}
              onChangeText={(newValue) => setMotivation(newValue)}
            />
          </View>

          <View style={{ marginTop: 40, marginBottom: 10 }}>
            <Text style={styles.descText}>
              Confirm your agreement below by entering your full name and
              drawing your signature into the box below:
            </Text>
          </View>
          <Input
            placeholder="Full Name"
            value={fullName}
            onChangeText={(newName) => setFullName(newName)}
          />

          <View
            style={{
              backgroundColor: "white",
              height: 240,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 11,
              },
              shadowOpacity: 0.25,
              shadowRadius: 14.78,
              elevation: 22,
              padding: 20,
              borderRadius: 10,
              marginTop: 10,
              marginBottom: 25,
            }}
          >
            <Text style={{ fontFamily: fonts.bold, fontSize: 16 }}>
              E-Signature
            </Text>
            <SignaturePad ref={signaturePad} height={180} />
            <View style={{ borderBottomColor: "grey", borderBottomWidth: 2 }} />
          </View>
          <CheckBox
            title="Yes, I am 18 years of age or older"
            checked={!isMinor}
            onPress={() => setIsMinor(!isMinor)}
          />
          <TouchableOpacity
            style={styles.acceptButton}
            onPress={() => onAgree()}
          >
            {isLoading ? (
              <ActivityIndicator color="#fff" />
            ) : (
              <Text style={styles.acceptButtonText}>Accept Terms</Text>
            )}
          </TouchableOpacity>
          {error != "" ? <Text style={styles.errorText}>{error}</Text> : null}
        </ScrollView>
      </View>
    </Modal>
  );
};

export default ContractModal;
