import React from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import colors from "../res/colors";
import fonts from "../res/fonts";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { useMediaQuery } from "react-responsive";
import Logo from "../../assets/images/Logo";

const TopBar = () => {
  let isMobile = useMediaQuery({ maxWidth: 600 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });

  if (isMobile) {
    return (
      <View style={[styles.container, { paddingHorizontal: 25 }]}>
        <Logo
          width={80}
          height={16}
          style={{
            alignSelf: "center",
            marginVertical: 20,
          }}
        />
        <View />
      </View>
    );
  } else if (isTabletOrMobile) {
    return (
      <View style={styles.container}>
        <Logo
          width={80}
          height={16}
          style={{
            alignSelf: "center",
            marginVertical: 20,
          }}
        />
        <View />
        <View style={{ flexDirection: "row" }}></View>
      </View>
    );
  } else {
    return (
      <View style={{ backgroundColor: "#111114" }}>
        <View style={{ width: "90%", alignSelf: "center" }}>
          <View style={[styles.container, { paddingHorizontal: 0 }]}>
            <Logo
              width={80}
              height={16}
              style={{
                alignSelf: "center",
                marginVertical: 20,
              }}
            />
            <TouchableOpacity
              style={styles.loadingButton}
              onPress={() => console.log("Pressed while loading")}
            >
              <ActivityIndicator />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: hp("1%"),
    backgroundColor: "#111114",
    zIndex: 10000,
    paddingHorizontal: wp("2.5%"),
  },
  loadingButton: {
    justifyContent: "center",
    alignItems: "center",
    width: 110,
    height: 35,
    backgroundColor: colors.green,
    borderRadius: 8,
    marginLeft: 10,
    borderWidth: 2,
    borderColor: colors.green,
  },
});

export default TopBar;
