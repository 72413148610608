import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import moment from "moment";
import RenderClip from "./RenderClip";
import SoldOutTab from "./SoldOutTab";
import { Context as ReactContext } from "../../../context/ReactContext";
import { useMediaQuery } from "react-responsive";
import { InView, useInView } from "react-intersection-observer";
import { range } from "lodash";
import loading_messages from "../../../../assets/loading_messages";
import {
  desktop as ClipDesktopStyle,
  tablet as ClipTabletStyle,
  mobile as ClipMobileStyle,
} from "../Stylesheets/RenderClipStyle";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { useIsFocused } from "@react-navigation/native";

const FeedView = () => {
  const {
    fetchReactFeed,
    state: { feedList, currentPage, totalPages, isFeedLoaded },
  } = useContext(ReactContext);
  const [isLoading, setIsLoading] = useState(false);
  const [playLock, setPlayLock] = useState(-1);
  const windowSize = useWindowDimensions();
  const isFocused = useIsFocused();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  //const isDesktop = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    async function loadFeed() {
      if (!isFeedLoaded && isFocused) {
        await fetchReactFeed({ page: currentPage });
      }
    }
    loadFeed();
  }, []);

  const handleViewChange = async (inView, entry, index) => {
    //if (!inView || !index % 2 == 0 || currentPage > totalPages) return;
    if (inView && !feedList[index + 1]) {
      setIsLoading(true);
      await fetchReactFeed({ page: currentPage + 1 });
      setIsLoading(false);
    }
  };

  if (!isFeedLoaded || !feedList || feedList.length == 0) {
    return (
      <View>
        <ActivityIndicator color="white" style={{ alignSelf: "center" }} />
        <Text
          style={{
            marginTop: 20,
            color: "grey",
            fontFamily: fonts.text,
            fontSize: 16,
            alignSelf: "center",
            textAlign: "center",
          }}
        >
          {
            loading_messages[
              Math.floor(Math.random() * loading_messages.length)
            ]
          }
        </Text>
      </View>
    );
  } else {
    const available = feedList.filter(
      (item) => item.claimed.length < item.total
    );
    const soldOut = feedList.filter(
      (item) => item.claimed.length >= item.total
    );

    return (
      <View>
        {available.length == 0 ? (
          <View
            style={{
              backgroundColor: colors.backOverlay,
              padding: 40,
              marginBottom: 80,
            }}
          >
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View>
                <Text
                  style={{
                    fontFamily: fonts.bold,
                    fontSize: 12,
                    color: "white",
                    letterSpacing: 5,
                  }}
                >
                  BUFFD EXCHANGE
                </Text>
                <Text
                  style={{
                    marginTop: 10,
                    fontFamily: fonts.text,
                    fontSize: 12,
                    color: "grey",
                    letterSpacing: 2,
                  }}
                >
                  Exclusive Drop
                </Text>
              </View>
              <FontAwesome name="bolt" size={20} color="white" />
            </View>
            <Text
              style={{
                marginTop: 40,
                fontFamily: fonts.semiBold,
                fontSize: 35,
                color: "white",
              }}
            >
              Next Buffd drop in{"  "}
              <Text style={{ color: "grey", fontFamily: fonts.text }}>
                -- hours -- minutes -- seconds
              </Text>
            </Text>
            <Text
              style={{
                fontFamily: fonts.text,
                fontSize: 16,
                lineHeight: 26,
                color: "#C0C0C0",
                marginTop: 25,
              }}
            >
              Partnered streamers are eligible to 'go public' on Buffd and join
              the exclusive group of streamers featured in Buffd drop events.
              Streamers on the Buffd Exchange release in extremely limited
              quantities.
            </Text>
            <TouchableOpacity
              style={styles.discordButton}
              onPress={() => window.open("https://discord.gg/2MT3D2SHmq")}
            >
              <FontAwesome5
                name="discord"
                size={20}
                color={colors.green}
                style={{ marginRight: 10 }}
              />
              <Text style={styles.buttonText}>
                Join the Community on Discord
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
        {available.length > 0 ? (
          <Text style={styles.labelHeader}>Fresh Off the Press 🔥</Text>
        ) : null}
        {available.map((item, index) => {
          return (
            <InView
              threshold={0.4}
              root={document.body}
              onChange={(inView, entry) =>
                handleViewChange(inView, entry, index)
              }
              triggerOnce={true}
              key={item._id}
            >
              <RenderClip
                item={item}
                index={index}
                playLock={playLock}
                setPlayLock={setPlayLock}
                styles={
                  isMobile
                    ? ClipMobileStyle(windowSize)
                    : isTabletOrMobile
                    ? ClipTabletStyle(windowSize)
                    : ClipDesktopStyle(windowSize)
                }
              />
            </InView>
          );
        })}

        {soldOut.length > 0 ? (
          <Text style={styles.labelHeader}>
            Sold Out<Text> </Text> ❌
          </Text>
        ) : null}
        <SoldOutTab />
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "black",
  },
  labelHeader: {
    fontFamily: fonts.bold,
    fontSize: 30,
    color: "white",
    marginBottom: 25,
  },
  discordButton: {
    alignSelf: "flex-start",
    backgroundColor: "black",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderRadius: 50,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: "#303030",
  },
  buttonText: {
    fontFamily: fonts.semiBold,
    color: "white",
  },
});

export default React.memo(FeedView);
