import React, { useContext, useState, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { Context as AuthContext } from "../../context/AuthContext";
import * as EmailValidator from "email-validator";
import { useMediaQuery } from "react-responsive";
import { useIsFocused } from "@react-navigation/native";
import { Input } from "react-native-elements";
import { toast } from "react-toastify";
import { Mixpanel } from "../../api/mixPanel";

const ActivationScreen = ({ navigation }) => {
  const {
    validateCode,
    useCodeAndSignup,
    joinWaitList,
    state: { activationAuth },
  } = useContext(AuthContext);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [alias, setAlias] = useState("");
  const [signUpEmail, setSignUpEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);
  const window = useWindowDimensions();
  const isFocused = useIsFocused();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;
  const modalHorizontalPadding = isMobile ? 15 : 50;

  const onSubmit = async () => {
    if (!isCodeLoading) {
      setIsCodeLoading(true);
      if (code == "") {
        toast.error("Please enter a code");
        setIsCodeLoading(false);
      } else {
        await validateCode({ code });
        setIsCodeLoading(false);
      }
    }
  };

  const regex = /[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]/g;

  const validateEntries = () => {
    if (
      alias == "" ||
      signUpEmail == "" ||
      password == "" ||
      confirmPassword == ""
    ) {
      toast.error("Please complete all fields.");
      return false;
    } else if (regex.test(alias)) {
      toast.error("The alias cannot contain special characters.");
      return false;
    } else if (alias.indexOf(" ") >= 0) {
      toast.error("The alias cannot contain spaces.");
      return false;
    } else if (password != confirmPassword) {
      toast.error("Passwords do not match.");
      return false;
    } else if (EmailValidator.validate(signUpEmail) == false) {
      toast.error("Invalid email.");
      return false;
    } else {
      return true;
    }
  };

  const onSignUp = async () => {
    if (!isSignUpLoading) {
      setIsSignUpLoading(true);
      if (validateEntries()) {
        await useCodeAndSignup({
          code: activationAuth.key,
          alias: alias,
          email: signUpEmail,
          password: password,
        });
      } else {
        setIsSignUpLoading(false);
        Mixpanel.track("Sign Up Attempt", {
          attemptedAlias: alias,
          attemptedEmail: signUpEmail,
        });
      }
    }
  };

  const onEmailSubmit = async () => {
    if (!isEmailLoading) {
      setIsEmailLoading(true);
      if (email == "") {
        toast.error("Please enter an email");
        setIsEmailLoading(false);
      } else {
        await joinWaitList({ email: email });
        setIsEmailLoading(false);
      }
    }
  };

  console.log(activationAuth);

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
        backgroundColor: "black",
        paddingHorizontal: "5%",
        paddingTop: 50,
      }}
    >
      <View
        style={[
          styles.container,
          { width: modalWidth, paddingHorizontal: modalHorizontalPadding },
        ]}
      >
        {activationAuth && activationAuth.status == 0 ? (
          <View>
            <Text style={styles.subHeader}>
              Your code was validated successfully. This is a one-time use code.
            </Text>
            <Input
              label="Email"
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={signUpEmail}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newSignUpEmail) => setSignUpEmail(newSignUpEmail)}
            />
            <Input
              label="Alias"
              labelStyle={styles.labelStyle}
              placeholder={"This is what you'll go by"}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={alias}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newAlias) => setAlias(newAlias)}
            />
            <Input
              label="Password"
              secureTextEntry={true}
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={password}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newPassword) => setPassword(newPassword)}
            />
            <Input
              label="Confirm password"
              secureTextEntry={true}
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={confirmPassword}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newConfirmPassword) =>
                setConfirmPassword(newConfirmPassword)
              }
            />
            <TouchableOpacity style={styles.button} onPress={() => onSignUp()}>
              {isSignUpLoading ? (
                <ActivityIndicator />
              ) : (
                <Text style={styles.buttonText}>Sign Up</Text>
              )}
            </TouchableOpacity>
          </View>
        ) : (
          <>
            <TouchableOpacity
              style={{ marginBottom: 20 }}
              onPress={() => navigation.navigate("Home")}
            >
              <Text style={{ fontFamily: fonts.semiBold, color: "grey" }}>
                Go Back
              </Text>
            </TouchableOpacity>
            <Text style={styles.subHeader}>Enter your activation code</Text>
            <Input
              label="Code"
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={code}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newCode) => setCode(newCode)}
            />
            <TouchableOpacity style={styles.button} onPress={() => onSubmit()}>
              {isCodeLoading ? (
                <ActivityIndicator />
              ) : (
                <Text style={styles.buttonText}>Let me in</Text>
              )}
            </TouchableOpacity>
            <View style={styles.divider} />
            <Text style={[styles.subHeader, { fontSize: 16 }]}>
              No code? Join the wait list
            </Text>
            <Input
              label="Email"
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainerStyle}
              placeholderTextColor="grey"
              inputContainerStyle={styles.inputStyle}
              value={email}
              inputStyle={styles.inputTextStyle}
              onChangeText={(newEmail) => setEmail(newEmail)}
            />
            <TouchableOpacity
              style={styles.button}
              onPress={() => onEmailSubmit()}
            >
              {isEmailLoading ? (
                <ActivityIndicator />
              ) : (
                <Text style={styles.buttonText}>Join Wait List</Text>
              )}
            </TouchableOpacity>
          </>
        )}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 30,
    backgroundColor: colors.backOverlay,
    alignContent: "center",
    //justifyContent: "center",
    paddingVertical: 50,
    alignSelf: "center",
    borderTopColor: colors.green,
    borderTopWidth: 5,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 11,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  subHeader: {
    fontFamily: fonts.text,
    fontSize: 24,
    lineHeight: 35,
    color: "white",
    marginBottom: 40,
  },
  labelStyle: {
    fontFamily: fonts.text,
    fontSize: 14,
    marginBottom: 10,
    color: "grey",
  },
  inputContainerStyle: {
    backgroundColor: colors.backOverlay,
    paddingHorizontal: 20,
    marginVertical: 5,
  },
  inputStyle: {
    borderColor: "grey",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 25,
    paddingVertical: 5,
  },
  inputTextStyle: {
    fontFamily: fonts.text,
    color: "white",
    fontSize: 14,
    outlineWidth: 0,
  },
  button: {
    alignSelf: "flex-start",
    backgroundColor: colors.green,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 35,
    paddingVertical: 12,
    borderRadius: 5,
    marginTop: 10,
    marginLeft: 25,
  },
  buttonText: {
    fontFamily: fonts.text,
    color: "black",
    fontSize: 18,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "grey",
    marginTop: 40,
    marginVertical: 25,
  },
});

export default ActivationScreen;
