import React, { useEffect, useRef } from "react";
//import { unstable_createElement } from "react-native-web";
import { InView, useInView } from "react-intersection-observer";
import Hls from "hls.js";
//const Video = (props) => unstable_createElement("video", props);

export default function VideoPlayer({
  _id,
  playLock,
  setPlayLock,
  feed,
  playbackId,
}) {
  const videoRef = useRef(null);
  const src = `https://stream.mux.com/${playbackId}.m3u8`;

  useEffect(() => {
    let hls;
    if (videoRef.current) {
      const video = videoRef.current;

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef]);

  const handleViewChange = (inView, entry) => {
    if (videoRef.current) {
      const videoElem = document.querySelector(`#react-video-${_id}`);
      if (!inView) {
        videoElem.pause();
      }
    }
  };

  return (
    <>
      {feed ? (
        <InView
          threshold={0.9}
          root={document.body}
          onChange={(inView, entry) => handleViewChange(inView, entry)}
        >
          <video
            id={`react-video-${_id}`}
            controls
            ref={videoRef}
            disablePictureInPicture={true}
            controlsList="nofullscreen nodownload noremoteplayback"
            loop={true}
            style={{ width: 150, height: 150 }}
          />
        </InView>
      ) : (
        <video
          id={`react-video-${_id}`}
          controls
          ref={videoRef}
          disablePictureInPicture={true}
          controlsList="nofullscreen nodownload noremoteplayback"
          loop={true}
          style={{ width: 150, height: 150 }}
        />
      )}
    </>
  );
}
