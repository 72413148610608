import { StyleSheet } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";

const desktop = StyleSheet.create({
  outerContainer: {
    flexGrow: 1,
    backgroundColor: "white",
    paddingHorizontal: "5%",
  },
  middleSection: {
    flexGrow: 1,
    marginVertical: hp("10%"),
    backgroundColor: "black",
    paddingHorizontal: "5%",
  },
  heroContainer: {
    marginVertical: "8%",
    width: "90%",
    alignSelf: "center",
  },
  header: {
    marginBottom: 25,
    fontFamily: fonts.bold,
    fontSize: 80,
    color: "#3b3b3b",
  },
  columnContainer: {
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardContainer: {
    width: "48%",
    padding: 30,
    backgroundColor: "#f6f6f6",
  },
  cardHeader: {
    fontFamily: fonts.bold,
    color: "#3b3b3b",
    marginBottom: 20,
    fontSize: 25,
  },
  cardBody: {
    fontFamily: fonts.text,
    color: "#3b3b3b",
    fontSize: 18,
    lineHeight: 25,
  },
  signUpContainer: {
    padding: 50,
    backgroundColor: "#f6f6f6",
    borderRadius: 10,
    marginBottom: hp("15%"),
  },
  signUpButton: {
    alignSelf: "flex-start",
    backgroundColor: "black",
    paddingVertical: 22,
    paddingHorizontal: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    marginTop: 40,
  },
  signUpText: {
    fontFamily: fonts.expandedBold,
    color: "white",
  },
});

const tablet = StyleSheet.create({
  outerContainer: {
    flexGrow: 1,
    backgroundColor: "white",
    paddingHorizontal: "5%",
  },
  middleSection: {
    flexGrow: 1,
    marginVertical: hp("10%"),
    backgroundColor: "black",
    paddingHorizontal: "5%",
  },
  heroContainer: {
    marginVertical: "8%",
    width: "90%",
    alignSelf: "center",
  },
  header: {
    marginBottom: 25,
    fontFamily: fonts.bold,
    fontSize: 70,
    color: "#3b3b3b",
  },
  columnContainer: {
    marginVertical: 50,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardContainer: {
    width: "48%",
    padding: 30,
    backgroundColor: "#f6f6f6",
  },
  cardHeader: {
    fontFamily: fonts.bold,
    color: "#3b3b3b",
    marginBottom: 20,
    fontSize: 25,
  },
  cardBody: {
    fontFamily: fonts.text,
    color: "#3b3b3b",
    fontSize: 18,
    lineHeight: 25,
  },
  signUpContainer: {
    padding: 50,
    backgroundColor: "#f6f6f6",
    borderRadius: 10,
    marginBottom: hp("15%"),
  },
  signUpButton: {
    backgroundColor: "black",
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    marginTop: 40,
  },
  signUpText: {
    fontFamily: fonts.expandedBold,
    color: "white",
  },
});

const mobile = StyleSheet.create({
  outerContainer: {
    flexGrow: 1,
    backgroundColor: "white",
    paddingHorizontal: "5%",
  },
  middleSection: {
    flexGrow: 1,
    marginVertical: hp("10%"),
    paddingTop: 50,
    backgroundColor: "black",
    paddingHorizontal: "5%",
  },
  heroContainer: {
    marginVertical: "8%",
    width: "90%",
    alignSelf: "center",
  },
  header: {
    marginBottom: 25,
    fontFamily: fonts.bold,
    fontSize: 45,
    color: "#3b3b3b",
  },
  columnContainer: {
    marginVertical: 50,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardContainer: {
    width: "90%",
    padding: 30,
    backgroundColor: "#f6f6f6",
    marginBottom: 30,
  },
  cardHeader: {
    fontFamily: fonts.bold,
    color: "#3b3b3b",
    marginBottom: 20,
    fontSize: 25,
  },
  cardBody: {
    fontFamily: fonts.text,
    color: "#3b3b3b",
    fontSize: 18,
    lineHeight: 25,
  },
  signUpContainer: {
    padding: 50,
    backgroundColor: "#f6f6f6",
    borderRadius: 10,
    marginBottom: hp("15%"),
  },
  signUpButton: {
    backgroundColor: "black",
    paddingVertical: 12,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    marginTop: 40,
  },
  signUpText: {
    fontFamily: fonts.expandedBold,
    color: "white",
  },
});

export { desktop, tablet, mobile };
