import React, { useState, useRef } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Dimensions,
  Image,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import moment from "moment";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import ordinal from "ordinal";
import StreamerCard from "../../../components/StreamerCard";
import StreamerGifCard from "../../../components/StreamerGifCard";
import IBOCard from "../../../components/IBOCard";
import PackOpenModal from "./PackOpenModal";
import ListCardModal from "./ListCardModal";
import ReactCardFlip from "react-card-flip";
import { useMediaQuery } from "react-responsive";

const RenderCard = ({ item, index, styles, numColumns, queued }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isListModalVisible, setIsListModalVisible] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const imageWidth = isMobile
    ? 0.65 * window.width
    : (0.9 * window.width) / (numColumns + 1);
  const imageHeight = isMobile
    ? (0.65 * window.width) / (360 / 504)
    : (0.9 * window.width) / (numColumns + 1) / (360 / 504);

  if (item.flipped) {
    return (
      <View
        style={
          index % numColumns == 0
            ? styles.firstStreamerContainer
            : styles.streamerContainer
        }
      >
        <ReactCardFlip
          isFlipped={isFlipped}
          flipDirection="horizontal"
          flipSpeedFrontToBack={10}
          flipSpeedBackToFront={10}
        >
          <TouchableOpacity onPress={() => setIsFlipped(!isFlipped)}>
            {item.type == 3 ? (
              <Image
                style={{
                  height: imageHeight,
                  width: imageWidth,
                  resizeMode: "contain",
                }}
                source={{
                  uri: "https://res.cloudinary.com/buffd/image/upload/v1620981587/BUFFD-Unknown-Card-Template-Limited_1_fymn7a.jpg",
                }}
              />
            ) : (
              <Image
                style={{
                  height: imageHeight,
                  width: imageWidth,
                  resizeMode: "contain",
                }}
                source={{
                  uri: "https://res.cloudinary.com/buffd/image/upload/v1620981603/BUFFD-Unknown-Card-Template-Basic_1_dog5jk.jpg",
                }}
              />
            )}
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setIsFlipped(!isFlipped)}>
            <>
              {item.type == 3 ? (
                <StreamerGifCard
                  cardDesign={item.cardDesign}
                  gifLink={item.gifLink}
                  width={imageWidth}
                  height={imageHeight}
                  mintNumber={item.mintNumber}
                />
              ) : item.type == 4 ? (
                <IBOCard
                  backgroundUri={item.cardDesign}
                  alias={item.streamerAlias}
                  width={imageWidth}
                  height={imageHeight}
                  mintNumber={item.mintNumber}
                  totalCards={item.totalCards}
                  claimDate={moment(item.createdAt).format("MM/DD/YYYY")}
                />
              ) : (
                <StreamerCard
                  uri={item.cardDesign}
                  width={imageWidth}
                  height={imageHeight}
                  mintNumber={item.mintNumber}
                  totalCards={item.totalCards}
                  claimDate={moment(item.createdAt).format("MM/DD/YYYY")}
                />
              )}
              {/* <Text style={styles.streamerName}>Streamer Name</Text> */}
              <Text style={styles.descText}>Founder's Edition</Text>
              <Text style={styles.descText}>No. {item.mintNumber}</Text>
            </>
          </TouchableOpacity>
        </ReactCardFlip>
      </View>
    );
  } else if (!item.packPrice) {
    return (
      <View
        style={
          index % numColumns == 0
            ? styles.firstStreamerContainer
            : styles.streamerContainer
        }
      >
        <ListCardModal
          data={item}
          isModalVisible={isListModalVisible}
          setIsModalVisible={setIsListModalVisible}
        />
        {item.type == 3 ? (
          <StreamerGifCard
            cardDesign={item.cardDesign}
            gifLink={item.gifLink}
            width={imageWidth}
            height={imageHeight}
            mintNumber={item.mintNumber}
          />
        ) : item.type == 4 ? (
          <IBOCard
            backgroundUri={item.cardDesign}
            alias={item.streamerAlias}
            width={imageWidth}
            height={imageHeight}
            mintNumber={item.mintNumber}
            totalCards={item.totalCards}
            claimDate={moment(item.createdAt).format("MM/DD/YYYY")}
          />
        ) : (
          <StreamerCard
            uri={item.cardDesign}
            width={imageWidth}
            height={imageHeight}
            mintNumber={item.mintNumber}
            totalCards={item.totalCards}
            claimDate={moment(item.createdAt).format("MM/DD/YYYY")}
          />
        )}

        {/* <Text style={styles.streamerName}>Streamer Name</Text> */}
        <View style={styles.underCardContainer}>
          <View>
            <Text style={styles.descText}>
              {item.type == 3 ? "Founder's" : ordinal(item.editionNumber)}{" "}
              Edition
            </Text>
            <Text style={styles.descText}>No. {item.mintNumber}</Text>
          </View>
          {!queued ? (
            <TouchableOpacity
              style={styles.listButton}
              onPress={() => setIsListModalVisible(true)}
            >
              <Text style={styles.listText}>SELL CARD</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={[styles.listButton, { backgroundColor: "#111114" }]}
            >
              <Text style={[styles.listText, { color: "white" }]}>QUEUED</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  } else {
    return (
      <View
        style={
          index % numColumns == 0
            ? styles.firstStreamerContainer
            : styles.streamerContainer
        }
      >
        <PackOpenModal
          data={item}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
        <TouchableOpacity onPress={() => setIsModalVisible(true)}>
          <Text style={[styles.descText, { alignSelf: "center" }]}>
            Press Pack to Reveal
          </Text>
          <Image
            style={{
              height: imageHeight,
              width: imageWidth,
              resizeMode: "contain",
            }}
            source={{
              uri: item.packDesign,
            }}
          />
        </TouchableOpacity>
        <Text style={styles.descText}>Community Pack</Text>
        <Text style={styles.descText}>No. {item.mintNumber}</Text>
      </View>
    );
  }
};

export default RenderCard;
