import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import StreamerCard from "../../components/StreamerCard";
import StreamerGifCard from "../../components/StreamerGifCard";
import IBOCard from "../../components/IBOCard";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import { range } from "lodash";

const DropGrid = ({
  fetchedStreamer,
  collectibleList,
  selectedCollectible,
  setSelectedCollectible,
}) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  console.log(selectedCollectible);

  return (
    <View>
      <View style={styles.headerContainer}>
        <Text style={styles.header}>ALL DROPS</Text>
      </View>
      <View style={styles.gridContainer}>
        {collectibleList.map((item, index) => {
          const contStyle =
            selectedCollectible == index
              ? [
                  styles.dropContainer,
                  { borderWidth: 1, borderColor: colors.green },
                ]
              : styles.dropContainer;

          if (item.status < 3) {
            //REACT
            return (
              <TouchableOpacity
                style={contStyle}
                key={index}
                onPress={() => setSelectedCollectible(index)}
              >
                <Image
                  style={{ width: 300, height: 300, borderRadius: 10 }}
                  source={{
                    uri: "https://res.cloudinary.com/buffd/image/upload/v1625058923/Product%20Images/RookieSeriesCover_mdntip.png",
                  }}
                />
              </TouchableOpacity>
            );
          } else {
            //CARD
            return (
              <TouchableOpacity
                style={contStyle}
                key={index}
                onPress={() => setSelectedCollectible(index)}
              >
                <Image
                  style={{ width: 300, height: 300, borderRadius: 10 }}
                  source={{
                    uri: fetchedStreamer.cardDetails.cardDesign,
                  }}
                />
              </TouchableOpacity>
            );
          }
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: colors.backOverlay,
    paddingVertical: 25,
    justifyContent: "center",
    paddingHorizontal: 25,
    borderRadius: 2.5,
    marginBottom: 20,
  },
  header: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 25,
  },
  gridContainer: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  dropContainer: {
    backgroundColor: colors.backOverlay,
    borderRadius: 10,
    marginVertical: 20,
    marginRight: 20,
  },
});

export default DropGrid;
