import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Image,
  ScrollView,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { Context as ReactContext } from "../../../context/ReactContext";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import * as RootNavigation from "../../../RootNavigation";
import { AntDesign } from "@expo/vector-icons";

const SoldOutGrid = () => {
  const {
    fetchReactFeed,
    state: { feedList, currentPage, totalPages, isFeedLoaded },
  } = useContext(ReactContext);
  const [isLoading, setIsLoading] = useState(false);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const loadMore = async () => {
    setIsLoading(true);
    await fetchReactFeed({ page: currentPage + 1 });
    setIsLoading(false);
  };

  const soldOut = feedList.filter((item) => item.claimed.length >= item.total);

  return (
    <View>
      <View style={styles.gridContainer}>
        {soldOut.map((item, index) => {
          return (
            <TouchableOpacity
              style={styles.itemContainer}
              key={index}
              onPress={() =>
                RootNavigation.navigate("StreamerProfile", {
                  tag: item.streamerId.alias,
                })
              }
            >
              <View style={styles.thumbnail}>
                <Image
                  style={{ width: 280, height: 220, resizeMode: "contain" }}
                  source={{
                    uri: "https://res.cloudinary.com/buffd/image/upload/v1625059067/Product%20Images/RookieSeriesThumbnail_oommvt.png",
                  }}
                />
              </View>
              <View style={{ marginTop: 25, marginBottom: 20 }}>
                <Text style={styles.reactName}>{item.reactName}</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Image
                    style={{
                      width: 40,
                      height: 40,
                      resizeMode: "cover",
                      borderRadius: 100,
                      marginRight: 15,
                    }}
                    source={{ uri: item.streamerId.profilePic }}
                  />
                  <Text style={styles.streamerAlias}>
                    {item.streamerId.alias}
                  </Text>
                </View>
              </View>
              <View style={styles.reactContainer}>
                <View style={styles.detailContainer}>
                  <Text style={styles.label}>TOTAL</Text>
                  <Text style={styles.value}>
                    {item.total}/{item.total}
                  </Text>
                </View>
                <View style={styles.detailContainer}>
                  <Text style={styles.label}>OG PRICE</Text>
                  <Text style={styles.value}>${item.reserve.toFixed(2)}</Text>
                </View>
                <View style={styles.detailContainer}>
                  <Text style={styles.label}>DROP</Text>
                  <Text style={styles.value}>
                    {moment(item.dropDate).format("l")}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
      <TouchableOpacity
        style={{ alignSelf: "center", marginTop: 20 }}
        onPress={() => loadMore()}
      >
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            <Text style={{ fontFamily: fonts.expandedBold, color: "white" }}>
              <AntDesign
                name="reload1"
                size={16}
                color="white"
                style={{ marginRight: 10 }}
              />{" "}
              LOAD MORE
            </Text>
          </>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  gridContainer: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    //justifyContent: "space-between",
  },
  itemContainer: {
    width: 320,
    height: 440,
    backgroundColor: colors.backOverlay,
    borderRadius: 10,
    marginVertical: 20,
    padding: 20,
    marginRight: 20,
  },
  reactName: {
    fontFamily: fonts.semiBold,
    color: "white",
    fontSize: 22,
    marginBottom: 5,
    lineHeight: 35,
  },
  streamerAlias: {
    fontFamily: fonts.expandedRegular,
    color: "grey",
    fontSize: 16,
    marginBottom: 20,
  },
  reactContainer: {
    flexDirection: "row",
  },
  thumbnail: {
    // backgroundColor: "#E8E8E8",
    width: 280,
    height: 220,
    borderRadius: 10,
  },
  reactDetails: {
    width: 100,
  },
  detailContainer: {
    marginBottom: 10,
    marginRight: 20,
  },
  label: {
    fontFamily: fonts.semiBold,
    color: "grey",
    fontSize: 10,
  },
  value: {
    fontFamily: fonts.bold,
    color: "white",
    fontSize: 14,
  },
  button: {
    backgroundColor: colors.green,
    paddingVertical: 8,
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    marginVertical: 10,
    alignSelf: "flex-start",
    marginRight: 10,
  },
  buttonText: {
    fontFamily: fonts.semiBold,
  },
});

export default SoldOutGrid;
