import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  FlatList,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import ContractModal from "../../components/ContractModal";
import { useMediaQuery } from "react-responsive";
import { Context as UserContext } from "../../context/UserContext";
import {
  desktop as ContractDesktop,
  tablet as ContractTablet,
  mobile as ContractMobile,
} from "../../components/ContractModalStyle";

const StreamerToDo = () => {
  const {
    state: { isUserInfoLoaded, streamerProfile },
  } = useContext(UserContext);
  const [isContractOpen, setIsContractOpen] = useState(false);
  const windowSize = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const showLegalTask =
    streamerProfile.legalStatus == undefined ||
    streamerProfile.legalStatus == 0;
  const showBioTask =
    streamerProfile.info.bio == "" ||
    (streamerProfile.twitchLink == "" &&
      streamerProfile.tikTokLink == "" &&
      streamerProfile.instagramLink == "" &&
      streamerProfile.youTubeLink == "" &&
      streamerProfile.twitterLink == "");
  const showReactTask =
    streamerProfile.reactRequests == undefined ||
    streamerProfile.reactRequests.length == 0;
  const isPending =
    !showLegalTask &&
    !showBioTask &&
    !showReactTask &&
    streamerProfile.status == 0;

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <View style={{ flexShrink: 1 }}>
          <Text style={styles.header}>You're off to a great start</Text>
          <Text style={styles.subHeader}>
            In order to complete your registration, please complete the items
            below.
          </Text>
        </View>
        <View
          style={[
            styles.statusBox,
            {
              backgroundColor:
                streamerProfile.status == 1
                  ? "green"
                  : isPending
                  ? "yellow"
                  : "red",
            },
          ]}
        >
          <Text
            style={[
              styles.statusText,
              { color: isPending ? "black" : "white" },
            ]}
          >
            {isPending ? "PENDING" : "INCOMPLETE"}
          </Text>
        </View>
      </View>
      <View style={styles.divider} />

      <View style={styles.listItem}>
        <Text
          style={[
            styles.listTitle,
            {
              textDecorationLine: showLegalTask ? null : "line-through",
              textDecorationStyle: showLegalTask ? null : "solid",
            },
          ]}
        >
          • <Text> </Text>Accept the Buffd Streamer Use Policy
        </Text>
        {showLegalTask ? (
          <TouchableOpacity
            style={styles.listButton}
            onPress={() => setIsContractOpen(true)}
          >
            <Text style={styles.listButtonText}>Complete Agreement</Text>
          </TouchableOpacity>
        ) : null}
      </View>

      <View style={styles.listItem}>
        <Text
          style={[
            styles.listTitle,
            {
              textDecorationLine: showReactTask ? null : "line-through",
              textDecorationStyle: showReactTask ? null : "solid",
            },
          ]}
        >
          • <Text> </Text>Submit your first Buffd React clip below (Reacts Tab)
        </Text>
      </View>

      <View style={styles.listItem}>
        <Text
          style={[
            styles.listTitle,
            {
              textDecorationLine: showBioTask ? null : "line-through",
              textDecorationStyle: showBioTask ? null : "solid",
            },
          ]}
        >
          • <Text> </Text>Complete your streamer bio and social media links
          below (Profile Tab)
        </Text>
      </View>

      <View
        style={{
          marginVertical: 10,
          borderColor: "grey",
          padding: 20,
          borderWidth: 1,
        }}
      >
        <Text style={{ color: "white", fontSize: 14 }}>
          <Text style={{ textDecorationLine: "underline" }}>
            Note Buffd's Revised Pricing Model:
          </Text>{" "}
          Rather than charging every streamer an upfront cost, Buffd will cover
          all development, hosting, creation, and minting costs associated with
          your collectibles. The net sales revenues from all primary purchases
          will be split 75% to you and 25% to us. When any of your collectibles
          are resold in the peer-to-peer marketplace, a 10% commission from the
          sale will be split 50% to you and 50% to us.
        </Text>
      </View>

      {isPending ? (
        <Text style={styles.listTitle}>
          The team is processing your React and developing your personalized
          page
        </Text>
      ) : null}
      <ContractModal
        isModalVisible={isContractOpen}
        setIsModalVisible={setIsContractOpen}
        styles={
          isMobile
            ? ContractMobile(windowSize)
            : isTabletOrMobile
            ? ContractTablet(windowSize)
            : ContractDesktop(windowSize)
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.backOverlay,
    padding: 25,
    flexShrink: 1,
  },
  header: {
    fontFamily: fonts.text,
    fontSize: 25,
    color: "white",
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: fonts.text,
    fontSize: 18,
    color: "#A9A9A9",
    marginBottom: 15,
  },
  statusBox: {
    paddingHorizontal: 25,
    paddingVertical: 14,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  statusText: {
    fontFamily: fonts.expandedBold,
    fontSize: 12,
    color: "white",
  },
  divider: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: "#181818",
    marginBottom: 30,
  },
  listItem: {
    marginVertical: 20,
  },
  listTitle: {
    fontFamily: fonts.text,
    fontSize: 18,
    color: "white",
  },
  listButton: {
    marginTop: 20,
    backgroundColor: colors.green,
    paddingHorizontal: 20,
    paddingVertical: 10,
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    marginHorizontal: 20,
  },
  listButtonText: {
    fontFamily: fonts.expandedBold,
    fontSize: 12,
  },
});

export default StreamerToDo;
