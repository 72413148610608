import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import _ from "lodash";

const twitchReducer = (state, action) => {
  switch (action.type) {
    case "fetch_streams":
      return {
        ...state,
        streams: action.payload,
        streamsLeft: action.payload,
        isStreamsLoaded: true,
      };
    case "select_next_stream":
      return {
        ...state,
        currentStream: action.payload,
      };
    case "update_remaining_streams":
      return {
        ...state,
        remainingStreams: action.payload,
      };
    case "clear_state":
      return {
        isStreamsLoaded: false,
        streams: null,
        remainingStreams: null,
        currentStream: null,
      };
    default:
      return state;
  }
};

const fetchNextStream = (dispatch) => async (allStreams) => {
  console.log("FIND NEXT STREAM");
  if (allStreams && allStreams.length > 0) {
    const nextStream = allStreams[0];
    const remStreams = _.drop(allStreams);
    dispatch({ type: "select_next_stream", payload: nextStream });
    console.log(nextStream);
    dispatch({ type: "update_remaining_streams", payload: remStreams });
  }
  return;
};

const fetchStreams = (dispatch) => async () => {
  try {
    console.log("FETCHING STREAMS");
    const response = await serverApi.get("/streams");
    const shuffledArr = _.shuffle(response.data);
    dispatch({ type: "fetch_streams", payload: shuffledArr });

    const nextStream = shuffledArr[0];
    const remStreams = _.drop(shuffledArr);
    dispatch({ type: "select_next_stream", payload: nextStream });
    console.log(nextStream);
    dispatch({ type: "update_remaining_streams", payload: remStreams });
    return;
  } catch (err) {
    console.log("fetchStreams Error");
  }
};

const clearState = (dispatch) => async () => {
  try {
    dispatch({ type: "clear_state" });
  } catch (err) {
    console.log("clearState Error");
  }
};

export const { Provider, Context } = createDataContext(
  twitchReducer,
  {
    fetchStreams,
    fetchNextStream,
    clearState,
  },
  {
    isStreamsLoaded: false,
    streams: null,
    remainingStreams: null,
    currentStream: null,
  }
);
