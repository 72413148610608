import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";
import { Context as AuthContext } from "../../../context/AuthContext";
import { Context as UserContext } from "../../../context/UserContext";
import { Context as ReactContext } from "../../../context/ReactContext";
import CurrencyInput from "react-native-currency-input";
import Popup from "reactjs-popup";
import "./ClipStyle.css";
import { MaterialIcons } from "@expo/vector-icons";
import { useMediaQuery } from "react-responsive";
import { useIsFocused } from "@react-navigation/native";
import { Mixpanel } from "../../../api/mixPanel";
import moment from "moment";
import { range } from "lodash";
import * as RootNavigation from "../../../RootNavigation";

const ClipModal = ({ data, type, isModalVisible, setIsModalVisible }) => {
  const {
    state: { token },
  } = useContext(AuthContext);
  const {
    state: { userInfo, cardPortfolio },
  } = useContext(UserContext);
  const { purchaseStreamerReact, placeReactBid, purchaseReactListing } =
    useContext(ReactContext);
  const [price, setPrice] = useState(data.reserve);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () => setIsModalVisible(false);

  const isFocused = useIsFocused();
  const window = useWindowDimensions();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const modalWidth = isMobile ? 0.9 * window.width : 500;

  const reactsClaimedToday =
    token != null && cardPortfolio.reactsAcquired
      ? cardPortfolio.reactsAcquired.filter((reactItem) => {
          if (reactItem.pool && reactItem.pool.dropDate) {
            return moment
              .utc(reactItem.pool.dropDate)
              .isSame(moment.utc(), "day");
          } else {
            return false;
          }
        }).length
      : 0;

  const purchaseClip = async () => {
    if (!isLoading) {
      setIsLoading(true);
      if (
        price &&
        price > 0.0 &&
        price >= data.reserve &&
        reactsClaimedToday < 3
      ) {
        Mixpanel.track("Initiate Clip Purchase", {
          poolId: data._id,
          streamerAlias: data.streamerId.alias,
        });
        await purchaseStreamerReact({ poolId: data._id, price: price });
      } else if (reactsClaimedToday >= 3) {
        setError(`You have claimed your maximum of 3 Reacts today.`);
      } else {
        setError(
          `Price must be greater than the reserve ($${data.reserve.toFixed(
            2
          )}).`
        );
      }
      if (isFocused) {
        setIsLoading(false);
      }
    }
  };

  const bidClip = async () => {
    if (!isLoading) {
      setIsLoading(true);
      if (price && price > 2.0) {
        Mixpanel.track("Initiate Clip Bid", {
          poolId: data._id,
          streamerAlias: data.streamerId.alias,
        });
        await placeReactBid({ poolId: data._id, price: price });
      } else {
        setError("Price must be greater than $2.00.");
      }
      if (isFocused) {
        setIsLoading(false);
      }
    }
  };

  const purchaseListing = async ({ listItem }) => {
    console.log(listItem);
    if (!isLoading) {
      setIsLoading(true);
      Mixpanel.track("Initiate Clip Listing Purchase", {
        listingId: listItem._id,
        poolId: data._id,
        streamerAlias: data.streamerId.alias,
      });
      await purchaseReactListing({
        listingId: listItem._id,
        poolId: data._id,
        price: listItem.price,
      });
      if (isFocused) {
        setIsLoading(false);
      }
    }
  };

  const onAuth = async (type) => {
    if (type == 0) {
      setIsModalVisible(false);
      Mixpanel.track("Page View", { pageName: "SignIn" });
      RootNavigation.navigate("SignIn");
    } else {
      setIsModalVisible(false);
      Mixpanel.track("Page View", { pageName: "SignUp" });
      RootNavigation.navigate("SignUp");
    }
  };

  const innerContent = () => {
    if (type == 0) {
      return (
        <>
          <Text style={styles.inputLabel}>REACT TITLE</Text>
          <Text style={styles.clipName}>{data.reactName}</Text>
          <Text style={styles.inputLabel}>PURCHASE AMOUNT $</Text>
          <CurrencyInput
            value={price}
            onChangeValue={setPrice}
            unit="$"
            delimiter=","
            separator="."
            precision={2}
            ignoreNegative={true}
            onChangeText={(formattedValue) => {
              console.log(formattedValue); // $2,310.46
            }}
            style={styles.inputStyle}
            keyboardType={"numeric"}
            placeholder={"$0.00"}
          />
          <View style={styles.commonAmountRow}>
            <TouchableOpacity
              style={styles.amountButton}
              onPress={() => setPrice(price + 5.0)}
            >
              <Text style={styles.amountText}>+$5.00</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.amountButton}
              onPress={() => setPrice(price + 25.0)}
            >
              <Text style={styles.amountText}>+$25.00</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.amountButton}
              onPress={() => setPrice(price + 50.0)}
            >
              <Text style={styles.amountText}>+$50.00</Text>
            </TouchableOpacity>
          </View>
          <Text
            style={[
              styles.noteText,
              {
                color: "black",
                fontSize: 14,
                fontFamily: fonts.medium,
                lineHeight: 24,
              },
            ]}
          >
            {`This clip costs $${data.reserve.toFixed(
              2
            )}, but all net proceeds and any additional amount will go directly to ${
              data.streamerId.alias
            }`}
          </Text>

          {error && error != "" ? (
            <Text style={styles.errorText}>{error}</Text>
          ) : null}
          <TouchableOpacity
            style={styles.purchaseButton}
            onPress={() => purchaseClip()}
          >
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              <Text style={styles.purchaseText}>
                Purchase ({price ? `$${price.toFixed(2)}` : null})
              </Text>
            )}
          </TouchableOpacity>
        </>
      );
    } else if (type == 1) {
      const availableListings = data.listings.filter(
        (item) => item.status == 0 && `${item.ownerId._id}` != `${userInfo._id}`
      );

      return (
        <>
          <Text style={styles.inputLabel}>REACT TITLE</Text>
          <Text style={styles.clipName}>{data.reactName}</Text>
          <Text style={styles.inputLabel}>
            MARKET LISTINGS ({availableListings.length})
          </Text>
          <ScrollView
            style={{ borderRadius: 10, maxHeight: 300 }}
            contentContainerStyle={styles.listingsContainer}
          >
            {availableListings.length == 0 ? (
              <Text style={{ fontFamily: fonts.semiBold, marginVertical: 10 }}>
                No available listings at this time
              </Text>
            ) : null}
            {isLoading ? (
              <ActivityIndicator style={{ alignSelf: "center" }} />
            ) : null}
            {!isLoading
              ? availableListings.map((listItem) => {
                  return (
                    <View key={listItem._id}>
                      <View style={styles.listItemContainer}>
                        <View style={{ flexDirection: "row" }}>
                          <Text style={styles.listLabel}>
                            <Text style={{ fontSize: 16 }}>
                              #{listItem.reactId.mintNumber}
                            </Text>
                            /{data.total}
                          </Text>
                          <Text
                            style={[
                              styles.listLabel,
                              { marginLeft: isMobile ? 10 : 25, fontSize: 16 },
                            ]}
                          >
                            ${listItem.price.toFixed(2)}
                          </Text>
                        </View>
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <Text
                            style={[
                              styles.listLabel,
                              {
                                width: isMobile ? 75 : 125,
                                marginRight: 10,
                                color: "grey",
                                fontSize: 12,
                              },
                            ]}
                          >
                            @{listItem.ownerId.alias}
                          </Text>
                          <TouchableOpacity
                            style={styles.buyButton}
                            onPress={() => purchaseListing({ listItem })}
                          >
                            <Text style={styles.buyText}>Buy</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={styles.listDivider} />
                    </View>
                  );
                })
              : null}
          </ScrollView>

          <Text style={styles.noteText}>
            {`Every purchase benefits ${data.streamerId.alias}`}
          </Text>
        </>
      );
    } else {
      return (
        <>
          <Text style={styles.inputLabel}>REACT TITLE</Text>
          <Text style={styles.clipName}>{data.reactName}</Text>
          <Text
            style={[
              styles.noteText,
              { color: "black", fontSize: 22, marginBottom: 20 },
            ]}
          >
            This clip is <Text style={{ color: "red" }}>sold out</Text>, but you
            can send a bid to all clip owners below.
          </Text>
          <Text style={styles.inputLabel}>BID AMOUNT $</Text>
          <CurrencyInput
            value={price}
            onChangeValue={setPrice}
            unit="$"
            delimiter=","
            separator="."
            precision={2}
            ignoreNegative={true}
            onChangeText={(formattedValue) => {
              console.log(formattedValue); // $2,310.46
            }}
            style={styles.inputStyle}
            keyboardType={"numeric"}
            placeholder={"$0.00"}
          />
          <View style={styles.commonAmountRow}>
            <TouchableOpacity
              style={styles.amountButton}
              onPress={() => setPrice(price + 5.0)}
            >
              <Text style={styles.amountText}>+$5.00</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.amountButton}
              onPress={() => setPrice(price + 25.0)}
            >
              <Text style={styles.amountText}>+$25.00</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.amountButton}
              onPress={() => setPrice(price + 50.0)}
            >
              <Text style={styles.amountText}>+$50.00</Text>
            </TouchableOpacity>
          </View>
          <Text style={styles.noteText}>
            {`Note: Your bid can be accepted by any clip owner. Your bid may appear as a charge on your credit card statement at first while the amount is being held, but is only processed if someone accepts your bid.`}
          </Text>
          {error && error != "" ? (
            <Text style={styles.errorText}>{error}</Text>
          ) : null}
          <TouchableOpacity
            style={styles.purchaseButton}
            onPress={() => bidClip()}
          >
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              <Text style={styles.purchaseText}>
                Bid ({price ? `$${price.toFixed(2)}` : null})
              </Text>
            )}
          </TouchableOpacity>
        </>
      );
    }
  };

  return (
    <Popup
      open={isModalVisible}
      closeOnDocumentClick
      onClose={closeModal}
      position="center center"
      contentStyle={{
        borderTopColor: colors.green,
        borderTopWidth: 5,
        borderRadius: 10,
        width: modalWidth,
      }}
    >
      <View style={{ padding: isMobile ? 10 : 30 }}>
        <View style={styles.topRow}>
          <Text style={styles.header}>
            {!token ? "Log In" : "Purchase Clip"}
          </Text>
          <TouchableOpacity
            onPress={() => {
              Mixpanel.track("Claim Clip Close", {
                streamerAlias: data.streamerId.alias,
              });
              setIsModalVisible(!isModalVisible);
            }}
          >
            <MaterialIcons name="cancel" size={24} color="grey" />
          </TouchableOpacity>
        </View>

        {!token ? (
          <>
            <Text style={styles.explainerText}>
              You must be signed in to make a purchase. Choose an option below.
            </Text>
            <TouchableOpacity
              style={[styles.authButton, { backgroundColor: colors.green }]}
              onPress={() => onAuth(0)}
            >
              <Text style={[styles.authText, { color: "black" }]}>LOG IN</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.authButton}
              onPress={() => onAuth(1)}
            >
              <Text style={styles.authText}>SIGN UP</Text>
            </TouchableOpacity>
          </>
        ) : (
          <>{innerContent()}</>
        )}
      </View>
    </Popup>
  );
};

const styles = StyleSheet.create({
  container: {},
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
  },
  header: {
    fontFamily: fonts.expandedBold,
    fontSize: 30,
  },
  inputLabel: {
    fontFamily: fonts.expandedBold,
    fontSize: 10,
    marginBottom: 10,
  },
  clipName: {
    fontFamily: fonts.semiBold,
    fontSize: 22,
    marginBottom: 30,
  },
  inputStyle: {
    borderColor: "#bcd4c8",
    borderWidth: 1.5,
    borderBottomWidth: 1.5,
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    fontFamily: fonts.expandedRegular,
    color: "black",
    fontSize: 25,
    outlineWidth: 0,
  },
  commonAmountRow: {
    flexDirection: "row",
    marginVertical: 20,
    justifyContent: "space-between",
  },
  amountButton: {
    width: "30%",
    backgroundColor: colors.green,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 15,
  },
  amountText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
  },
  purchaseButton: {
    backgroundColor: "black",
    marginTop: 25,
    borderRadius: 6,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  purchaseText: {
    fontFamily: fonts.expandedBold,
    color: "white",
    fontSize: 14,
  },
  explainerText: {
    fontFamily: fonts.text,
    color: colors.greyText,
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 20,
  },
  authButton: {
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    paddingVertical: 14,
    marginVertical: 15,
  },
  authText: {
    fontFamily: fonts.expandedBold,
    fontSize: 14,
    color: "white",
  },
  noteText: {
    fontFamily: fonts.text,
    color: "grey",
    marginVertical: 10,
    fontSize: 14,
  },
  errorText: {
    fontFamily: fonts.text,
    color: "red",
    marginBottom: 5,
    fontSize: 14,
  },
  listingsContainer: {
    backgroundColor: "#E8E8E8",
    padding: 15,
  },
  listItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listLabel: {
    fontFamily: fonts.semiBold,
    color: "black",
  },
  listDivider: {
    borderBottomColor: "#D0D0D0",
    borderBottomWidth: 0.5,
    marginTop: 8,
    marginBottom: 25,
  },
  buyButton: {
    backgroundColor: "black",
    paddingHorizontal: 30,
    paddingVertical: 8,
    borderRadius: 4,
  },
  buyText: {
    fontFamily: fonts.bold,
    color: "white",
  },
});

export default ClipModal;
