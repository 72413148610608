import React, { useContext, useState, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../res/colors";
import fonts from "../../res/fonts";
import { Context as AuthContext } from "../../context/AuthContext";
import { useMediaQuery } from "react-responsive";

const ManifestoScreen = ({ navigation }) => {
  const {
    state: { token },
  } = useContext(AuthContext);
  const [showing, setShowing] = useState(0);
  const window = useWindowDimensions();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const containerWidth = isMobile
    ? 0.9 * window.width
    : isTabletOrMobile
    ? 600
    : 800;

  const containerPadding = isMobile ? 25 : isTabletOrMobile ? 40 : 60;

  return (
    <ScrollView
      style={{ backgroundColor: "black", padding: 40, paddingBottom: 200 }}
      contentContainerStyle={[
        styles.container,
        { width: containerWidth, padding: containerPadding },
      ]}
    >
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text style={styles.title}>Hey, welcome to Buffd</Text>
        {token ? (
          <TouchableOpacity onPress={() => navigation.navigate("Home")}>
            <Text style={styles.logInText}>Back Home</Text>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={() => navigation.navigate("SignIn")}>
            <Text style={styles.logInText}>Already have an account?</Text>
          </TouchableOpacity>
        )}
      </View>
      <Text style={styles.subHeader}>
        We want to bring as much value as humanly possible to the streaming
        world through digital collectibles. Read on to see what we're about.
      </Text>
      <Text style={styles.body}>
        Countless NFT projects have come and gone over the past few months.
        We've loved participating in them.
      </Text>
      <Text style={styles.body}>
        But we started seeing NFT projects that looked more like cash grabs,
        hype cycles, and more than anything else... gambling.
      </Text>
      <Text style={styles.body}>
        Now NFT projects feel like a race to get there first rather than a new
        way to enjoy collecting. Something you don’t wanna miss out on.
        Something you buy to make a quick buck.{" "}
      </Text>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginVertical: 25,
        }}
      >
        <View
          style={{
            height: 40,
            borderLeftColor: "white",
            borderLeftWidth: 1,
            marginRight: 15,
          }}
        />
        <Text style={[styles.body, { fontSize: 25 }]}>
          We want to do this differently.
        </Text>
      </View>
      <Text style={styles.body}>
        We don’t do pack drops. We don’t randomly assign rarities. We don’t just
        work with the biggest creators. This should excite you.
      </Text>
      <Text style={styles.body}>
        We’re holding onto that feeling you had collecting baseball cards:
        discovering new players, trading with friends and building communities
        around real memories.
      </Text>
      <Text style={styles.body}>
        Streamers get 100% of upfront sales. They will forever receive
        commissions on resales.
      </Text>

      <Text style={styles.body}>There's real emotion here.</Text>

      <Text style={styles.body}>
        It’s an opportunity to collect things that are meaningful to you. To
        discover new creators before they blow up. To be a true collector.
      </Text>

      <Text style={styles.body}>
        We believe that real value comes from collecting the things you love.
        The moments you remember. The clips that make you laugh. The ones that
        make your jaw drop. The ones that mean something to you.
      </Text>

      <Text style={styles.body}>
        Buffd is all that, and more. We hope you enjoy.
      </Text>

      <Text style={styles.body}>- The Buffd Team 🚀</Text>
      {token ? (
        <TouchableOpacity
          style={[styles.button, { marginBottom: 20 }]}
          onPress={() => navigation.navigate("Home")}
        >
          <Text style={styles.buttonText}>I'm all in. Take me back home.</Text>
        </TouchableOpacity>
      ) : (
        <>
          <TouchableOpacity
            style={styles.button}
            onPress={() => navigation.navigate("Activate")}
          >
            <Text style={styles.buttonText}>
              I'm all in. Let's make something great.
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ marginVertical: 20 }}
            onPress={() => navigation.navigate("SignIn")}
          >
            <Text style={styles.logInText}>Already have an account?</Text>
          </TouchableOpacity>
        </>
      )}
      <Text style={{ fontFamily: fonts.text, color: "grey" }}>
        support@buffd.gg
      </Text>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    alignSelf: "center",
    backgroundColor: colors.backOverlay,
    borderRadius: 10,
  },
  title: {
    fontFamily: fonts.semiBold,
    fontSize: 30,
    marginBottom: 25,
    color: "white",
  },
  subHeader: {
    fontFamily: fonts.text,
    fontSize: 24,
    lineHeight: 35,
    color: "#D8D8D8",
    marginBottom: 40,
  },
  body: {
    fontFamily: fonts.text,
    fontSize: 18,
    lineHeight: 28,
    color: "#D8D8D8",
    marginVertical: 20,
  },
  button: {
    alignSelf: "flex-start",
    backgroundColor: colors.green,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 35,
    paddingVertical: 12,
    borderRadius: 5,
    marginTop: 25,
  },
  buttonText: {
    fontFamily: fonts.text,
    color: "black",
    fontSize: 18,
  },
  logInText: {
    fontFamily: fonts.text,
    color: colors.green,
  },
});

export default ManifestoScreen;
