import { StyleSheet } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../res/colors";
import fonts from "../res/fonts";

const desktop = StyleSheet.create({
  container: {
    marginTop: "4%",
    backgroundColor: "#111114",
    padding: "5%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  header: {
    fontFamily: fonts.expandedBold,
    color: "white",
    marginBottom: 25,
  },
  licenseText: {
    fontFamily: fonts.expandedRegular,
    color: "grey",
    marginVertical: 5,
  },
  talentButton: {
    backgroundColor: colors.green,
    paddingVertical: 12,
    paddingHorizontal: 24,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  talentText: {
    color: "black",
    fontFamily: fonts.expandedBold,
    fontSize: 12,
  },
});

const tablet = StyleSheet.create({
  container: {
    marginTop: "4%",
    backgroundColor: "#111114",
    padding: "5%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  header: {
    fontFamily: fonts.expandedBold,
    color: "white",
    marginBottom: 25,
  },
  licenseText: {
    fontFamily: fonts.expandedRegular,
    color: "grey",
    marginVertical: 5,
  },
  talentButton: {
    backgroundColor: colors.green,
    paddingVertical: 12,
    paddingHorizontal: 24,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  talentText: {
    color: "black",
    fontFamily: fonts.expandedBold,
    fontSize: 12,
  },
  column: {
    marginVertical: 20,
  },
});

const mobile = StyleSheet.create({
  container: {
    marginTop: "4%",
    backgroundColor: "#111114",
    padding: "5%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  header: {
    fontFamily: fonts.expandedBold,
    color: "white",
    marginBottom: 25,
  },
  licenseText: {
    fontFamily: fonts.expandedRegular,
    color: "grey",
    marginVertical: 5,
  },
  talentButton: {
    backgroundColor: colors.green,
    paddingVertical: 12,
    paddingHorizontal: 24,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  talentText: {
    color: "black",
    fontFamily: fonts.expandedBold,
    fontSize: 12,
  },
  column: {
    marginVertical: 20,
  },
});

export { desktop, tablet, mobile };
