import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { socket } from "../api/Socket";
import { toast } from "react-toastify";

const streamerReducer = (state, action) => {
  switch (action.type) {
    case "fetch_streamer_list":
      return {
        ...state,
        dailyStreamerList: [
          {
            date:
              action.payload.todayStreamers && action.payload.todayStreamers[0]
                ? action.payload.todayStreamers[0].cardDetails.dropDate
                : null,
            list: action.payload.todayStreamers,
          },
        ],
        paginationDate:
          action.payload.todayStreamers && action.payload.todayStreamers[0]
            ? action.payload.todayStreamers[0].cardDetails.dropDate
            : null,
        soldOutList: action.payload.soldOut,
        otherStreamers: action.payload.otherStreamers,
        allStreamers: action.payload.allStreamers,
        alphabetStreamers: action.payload.alphabetStreamers,
        isStreamerListLoaded: true,
      };
    case "fetch_more_streamer_list":
      return {
        ...state,
        dailyStreamerList:
          action.payload.length > 0
            ? [
                ...state.dailyStreamerList,
                {
                  date: action.payload[0].cardDetails.dropDate,
                  list: action.payload,
                },
              ]
            : state.dailyStreamerList,
        paginationDate:
          action.payload.length > 0
            ? action.payload[0].cardDetails.dropDate
            : state.paginationDate,
      };
    case "fetch_streamer":
      return {
        ...state,
        fetchedStreamer: action.payload,
      };
    case "update_streamer_info":
      return {
        ...state,
        fetchedStreamer:
          state.fetchedStreamer != null &&
          `${action.payload.streamerId}` == `${state.fetchedStreamer._id}`
            ? action.payload.streamerProfile
            : state.fetchedStreamer,
      };
    case "check_claim":
      return {
        ...state,
        isClaimEligible: { bool: action.payload },
      };
    case "navigate_streamer": {
      return {
        ...state,
        navigatedStreamer: action.payload,
      };
    }
    case "clear_navigate_streamer": {
      return {
        ...state,
        navigatedStreamer: null,
      };
    }
    case "reset_fetched_streamer": {
      return {
        ...state,
        fetchedStreamer: null,
      };
    }
    case "reset_daily": {
      return {
        ...state,
        dailyStreamerList: state.dailyStreamerList.slice(0, 1),
        paginationDate: state.dailyStreamerList[0].date,
      };
    }
    case "update_pool": {
      return {
        ...state,
        fetchedStreamer:
          state.fetchedStreamer != null
            ? {
                ...state.fetchedStreamer,
                reactPools: _.map(
                  state.fetchedStreamer.reactPools,
                  function (poolItem) {
                    if (`${poolItem._id}` == `${action.payload.poolId}`) {
                      if (
                        action.payload.pool.claimed[0] &&
                        action.payload.pool.claimed[0].mintNumber
                      ) {
                        return {
                          ...action.payload.pool,
                        };
                      } else {
                        return {
                          ...action.payload.pool,
                          claimed: poolItem.claimed,
                        };
                      }
                    } else {
                      return poolItem;
                    }
                  }
                ),
              }
            : null,
      };
    }
    case "reset":
      return {
        isStreamerListLoaded: false,
        streamerList: null,
        fetchedStreamer: null,
      };
    default:
      return state;
  }
};

const makeStreamerConnection = (dispatch) => async () => {
  try {
    socket.on("cardClaimed", (data) => {
      console.log(
        "--GLOBAL SOCKET STREAMER CARD PURCHASED FOR " + data.body.streamerId
      );
      dispatch({ type: "update_streamer_info", payload: data.body });
    });
    socket.on("updatePool", (data) => {
      console.log("--GLOBAL SOCKET POOL UPDATE " + data.body.poolId);
      dispatch({ type: "update_pool", payload: data.body });
    });
  } catch (err) {
    console.log("makeStreamerConnection error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "makeStreamerConnection");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const fetchStreamerList = (dispatch) => async () => {
  try {
    console.log("FETCHING STREAMER LIST");
    const response = await serverApi.get("/streamerlist");
    dispatch({ type: "fetch_streamer_list", payload: response.data });
  } catch (err) {
    console.log("fetchStreamerList error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchStreamerList");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const fetchMoreDrops = (dispatch) => async (date) => {
  try {
    console.log("FETCHING MORE STREAMERS");
    console.log(date);
    const response = await serverApi.post("/streamerlist-day", { date });
    dispatch({ type: "fetch_more_streamer_list", payload: response.data });
  } catch (err) {
    console.log("fetchMoreDrops error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchMoreDrops");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const fetchStreamerInfo = (dispatch) => async (streamerTag) => {
  try {
    console.log("FETCHING STREAMER INFO");
    const response = await serverApi.post("/streamerprofile", { streamerTag });
    dispatch({ type: "fetch_streamer", payload: response.data });
  } catch (err) {
    console.log("fetchStreamerInfo error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchStreamerInfo");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const selectStreamer = (dispatch) => async (streamerProfile) => {
  try {
    console.log("SELECTED STREAMER");
    dispatch({ type: "fetch_streamer", payload: streamerProfile });
  } catch (err) {
    console.log("selectStreamer error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "selectStreamer");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const checkClaim = (dispatch) => async (userId, streamerId) => {
  try {
    console.log("CHECKING CLAIM ELIGIBILITY");
    const response = await serverApi.post("/checkclaim", {
      userId,
      streamerId,
    });
    dispatch({ type: "check_claim", payload: response.data });
  } catch (err) {
    console.log("checkClaim error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "checkClaim");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const navigateStreamer = (dispatch) => async (tag) => {
  dispatch({ type: "navigate_streamer", payload: tag });
};

const clearNavigateStreamer = (dispatch) => async () => {
  dispatch({ type: "clear_navigate_streamer" });
};

const resetFetchedStreamer = (dispatch) => async () => {
  dispatch({ type: "reset_fetched_streamer" });
};

const resetDaily = (dispatch) => async () => {
  console.log("RESETTING DAILY");
  dispatch({ type: "reset_daily" });
};

const reset = (dispatch) => async () => {
  dispatch({ type: "reset" });
};

export const { Provider, Context } = createDataContext(
  streamerReducer,
  {
    makeStreamerConnection,
    fetchStreamerList,
    fetchMoreDrops,
    fetchStreamerInfo,
    selectStreamer,
    checkClaim,
    navigateStreamer,
    clearNavigateStreamer,
    resetFetchedStreamer,
    resetDaily,
    reset,
  },
  {
    isStreamerListLoaded: false,
    dailyStreamerList: null,
    paginationDate: null,
    soldOutList: null,
    otherStreamers: null,
    allStreamers: null,
    alphabetStreamers: null,
    fetchedStreamer: null,
    isClaimEligible: null,
    navigatedStreamer: null,
  }
);
