import createDataContext from "./createDataContext";
import serverApi from "../api/server";
import * as RootNavigation from "../RootNavigation";
import * as Sentry from "sentry-expo";
import _ from "lodash";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { socket } from "../api/Socket";
import { toast } from "react-toastify";
import FormData from "form-data";
import confetti from "canvas-confetti";

const userReducer = (state, action) => {
  switch (action.type) {
    case "fetch_user":
      return {
        ...state,
        userInfo: action.payload.userInfo,
        cardPortfolio: action.payload.cardPortfolio,
        streamerProfile: action.payload.streamerProfile,
        isUserInfoLoaded: true,
      };
    case "update_portfolio":
      return {
        ...state,
        cardPortfolio: action.payload,
      };
    case "update_pool":
      return {
        ...state,
        cardPortfolio: {
          ...state.cardPortfolio,
          reactsAcquired: _.map(
            state.cardPortfolio.reactsAcquired,
            function (reactItem) {
              if (`${reactItem.pool._id}` == `${action.payload.poolId}`) {
                return {
                  ...reactItem,
                  listings: action.payload.pool.listings.filter(
                    (item) => `${item.reactId}` == `${reactItem._id}`
                  ),
                };
              } else {
                return reactItem;
              }
            }
          ),
        },
      };
    case "fetch_income":
      return {
        ...state,
        incomeGenerated: action.payload,
      };
    case "fetch_react_requests":
      return {
        ...state,
        reactRequests: action.payload,
      };
    case "update_streamer_profile":
      return {
        ...state,
        streamerProfile: action.payload,
      };
    case "update_card_portfolio":
      return {
        ...state,
        cardPortfolio: action.payload,
      };
    case "load_card_details":
      return {
        ...state,
        cardLoaded: action.payload,
      };
    case "clear_card_details":
      return {
        ...state,
        cardLoaded: null,
      };
    case "set_stripe_auth":
      return {
        ...state,
        streamerProfile: {
          ...state.streamerProfile,
          stripeStatus: action.payload,
        },
      };
    case "reset_user_info":
      return {
        isUserInfoLoaded: false,
        userInfo: null,
        cardPortfolio: null,
        streamerProfile: null,
      };
    default:
      return state;
  }
};

const makePortfolioConnection = (dispatch) => async () => {
  try {
    socket.on("purchaseCard", (data) => {
      console.log("CARD PURCHASE SOCKET UPDATE");
      dispatch({ type: "update_portfolio", payload: data.body });
      toast("Your portfolio was updated!");
    });
    console.log("PurchaseCard socket on");
    socket.on("receivedPurchase", (data) => {
      console.log("RECEIVED PURCHASE SOCKET UPDATE");
      dispatch({ type: "update_streamer_profile", payload: data.body });
      toast("Your card was just purchased!");
    });
    socket.on("receivedBuyNow", (data) => {
      console.log("RECEIVED BUY NOW UPDATE");
      dispatch({ type: "update_portfolio", payload: data.body });
      toast(
        "Your card for auction was sold for the Buy Now price! Check the transactions page for details"
      );
    });
    socket.on("wonAuction", (data) => {
      console.log("RECEIVED WON AUCTION UPDATE");
      dispatch({ type: "update_portfolio", payload: data.body });
      toast("You won the auction! Check the transactions page for details");
    });
    socket.on("receivedBid", (data) => {
      console.log("RECEIVED BID AUCTION UPDATE");
      toast("Your auction received a new bid!");
    });
    socket.on("auctionEnded", (data) => {
      console.log("RECEIVED AUCTION ENDED UPDATE");
      toast(
        "Your auction ended! Check the transactions page to see if it sold. If not, it will be placed back in the auction queue."
      );
    });
    socket.on("receivedBuyReactListing", (data) => {
      console.log("RECEIVED BUY REACT LISTING UPDATE");
      dispatch({ type: "update_portfolio", payload: data.body });
      toast("Your React listing was purchased!");
    });
    socket.on("boughtReactListing", (data) => {
      console.log("BOUGHT REACT LISTING UPDATE");
      dispatch({ type: "update_portfolio", payload: data.body });
      toast("You purchased a React successfully! Check your portfolio");
    });
    socket.on("purchaseClip", (data) => {
      console.log("BOUGHT REACT PRIMARY UPDATE");
      dispatch({ type: "update_portfolio", payload: data.body });
      toast("You purchased a React successfully! Check your portfolio");
    });
    socket.on("updatePortfolio", (data) => {
      console.log("LISTED REACT FOR SALE");
      dispatch({ type: "update_portfolio", payload: data.body });
    });
    socket.on("clipPurchased", (data) => {
      console.log("SOMEONE BOUGHT YOUR CLIP");
      toast("Your clip was purchased!");
    });
    socket.on("updatePool", (data) => {
      console.log("--GLOBAL SOCKET POOL UPDATE " + data.body.poolId);
      dispatch({ type: "update_pool", payload: data.body });
    });
  } catch (err) {
    console.log("makePortfolioConnection error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "makePortfolioConnection");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
  }
};

const fetchUserInfo = (dispatch) => async () => {
  try {
    console.log("FETCHING USER INFO");
    const response = await serverApi.get("/portfolio");
    dispatch({ type: "fetch_user", payload: response.data });
    await AsyncStorage.setItem("userId", response.data.userInfo._id);
  } catch (err) {
    console.log("fetchUserInfo error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchUserInfo");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const initiatePackOpen = (dispatch) => async (pack) => {
  try {
    const cardsToReveal = pack.cardsReceived;
    const response = await serverApi.post("/openpack", {
      claimedPackId: pack._id,
    });
    let newPortfolio = { ...response.data.cardPortfolio };
    newPortfolio.cardsAcquired = newPortfolio.cardsAcquired.map((item) =>
      cardsToReveal.includes(`${item._id}`) ? { ...item, flipped: true } : item
    );
    newPortfolio.cardsAcquired.reverse();
    dispatch({ type: "update_card_portfolio", payload: newPortfolio });
    var duration = 3 * 1000;
    var end = Date.now() + duration;

    (function frame() {
      confetti({
        particleCount: 7,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
      });
      confetti({
        particleCount: 7,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  } catch (err) {
    console.log("initiatePackOpen error");
    console.log(err);
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "initiatePackOpen");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const setStripeAuth = (dispatch) => async (status) => {
  dispatch({ type: "set_stripe_auth", payload: status });
};

const resetUserInfo = (dispatch) => async () => {
  dispatch({ type: "reset_user_info" });
};

const streamerConvert = (dispatch) => {
  return async ({
    bio,
    paymentInfo,
    clipUrl,
    twitchLink,
    instagramLink,
    youTubeLink,
    tikTokLink,
    twitterLink,
  }) => {
    try {
      const response = await serverApi.post("/streamerconvert", {
        bio: bio,
        paymentInfo: paymentInfo,
        clipUrl: clipUrl,
        twitchLink: twitchLink,
        instagramLink: instagramLink,
        youTubeLink: youTubeLink,
        tikTokLink: tikTokLink,
        twitterLink: twitterLink,
      });
      dispatch({ type: "update_streamer_profile", payload: response.data });
      RootNavigation.navigate("Home");
    } catch (err) {
      console.log(err);
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "streamerConvert");
        Sentry.Browser.captureException(err);
      });
      dispatch({
        type: "add_error",
        payload: "Something went wrong with sign up",
      });
    }
  };
};

const fetchStreamerIncome = (dispatch) => async () => {
  try {
    console.log("FETCHING STREAMER INCOME");
    const response = await serverApi.get("/streamer-income");
    dispatch({ type: "fetch_income", payload: response.data });
  } catch (err) {
    console.log("fetchStreamerIncome error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchStreamerIncome");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const fetchReactRequests = (dispatch) => async () => {
  try {
    console.log("FETCHING REACT REQUESTS");
    const response = await serverApi.get("/list-react-requests");
    dispatch({ type: "fetch_react_requests", payload: response.data });
  } catch (err) {
    console.log("fetchReactRequests error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchReactRequests");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const updateStreamerProfile =
  (dispatch) =>
  async ({
    bio,
    twitchLink,
    instagramLink,
    youTubeLink,
    tikTokLink,
    twitterLink,
    streamerId,
  }) => {
    try {
      console.log("UPDATING STREAMER INFO");
      const response = await serverApi.post("/update-streamer-profile", {
        bio,
        twitchLink,
        instagramLink,
        youTubeLink,
        tikTokLink,
        twitterLink,
        streamerId,
      });
      dispatch({ type: "update_streamer_profile", payload: response.data });
    } catch (err) {
      console.log("updateStreamerProfile error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "updateStreamerProfile");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const submitReactRequest =
  (dispatch) =>
  async ({ streamerId, clipLinks }) => {
    try {
      console.log("SUBMITTING REACT REQUEST");
      const response = await serverApi.post("/create-react-request", {
        streamerId,
        clipLinks,
      });
      dispatch({ type: "update_streamer_profile", payload: response.data });
    } catch (err) {
      console.log("submitReactRequest error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "submitReactRequest");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const publishPool =
  (dispatch) =>
  async ({ poolId }) => {
    try {
      console.log("PUBLISHING POOL");
      const response = await serverApi.post("/publish-pool", {
        poolId,
      });
      dispatch({ type: "update_streamer_profile", payload: response.data[0] });
    } catch (err) {
      console.log("publishPool error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "publishPool");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const submitIntakeForm =
  (dispatch) =>
  async ({
    streamerId,
    avgViewers,
    currentRevenue,
    gamePlayed,
    motivation,
  }) => {
    try {
      console.log("SUBMITTING INTAKE FORM");
      const response = await serverApi.post("/streamer-intake", {
        streamerId,
        avgViewers,
        currentRevenue,
        gamePlayed,
        motivation,
      });
    } catch (err) {
      console.log("publishPool error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "publishPool");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const b64toBlob = (dataURI) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
};

const submitSignature =
  (dispatch) => async (signatureImage, fullName, isMinor) => {
    try {
      console.log("SUBMITTING SIGNATURE");
      const data = new FormData();
      const blob = b64toBlob(signatureImage);
      const fileType = blob.type.split("/")[1];
      const file = new File([blob], "photo." + fileType);
      data.append("image", file);
      data.append("fullName", fullName);
      const mappedMinor = isMinor == true ? 1 : 0;
      data.append("isMinor", mappedMinor);
      const response = await serverApi.post("/streamersignature", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({ type: "update_streamer_profile", payload: response.data });
    } catch (err) {
      console.log("submitSignature error");
      console.log(err);
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "submitSignature");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const submitCardListing =
  (dispatch) => async (cardId, reservePrice, buyNowPrice) => {
    try {
      console.log("SUBMITTING AUCTION LISTING");
      const response = await serverApi.post("/submit-market-listing", {
        cardId,
        reservePrice,
        buyNowPrice,
      });
      dispatch({ type: "update_portfolio", payload: response.data });
    } catch (err) {
      console.log(err);
      console.log("submitCardListing error");
      const userIdentification = await AsyncStorage.getItem("userId");
      Sentry.Browser.configureScope(function (scope) {
        scope.setExtra("function_name", "submitCardListing");
        scope.setExtra("id", userIdentification);
        Sentry.Browser.captureException(err);
      });
      toast.error(
        "Having trouble connecting to the server... Try again in a few."
      );
    }
  };

const fetchCardDetails = (dispatch) => async (cardId) => {
  try {
    console.log("FETCHING CARD DETAILS");
    const response = await serverApi.post("/card-details", {
      cardId,
    });
    dispatch({ type: "load_card_details", payload: response.data });
  } catch (err) {
    console.log(err);
    console.log("fetchCardDetails error");
    const userIdentification = await AsyncStorage.getItem("userId");
    Sentry.Browser.configureScope(function (scope) {
      scope.setExtra("function_name", "fetchCardDetails");
      scope.setExtra("id", userIdentification);
      Sentry.Browser.captureException(err);
    });
    toast.error(
      "Having trouble connecting to the server... Try again in a few."
    );
  }
};

const clearCardDetails = (dispatch) => async () => {
  dispatch({ type: "clear_card_details" });
};

export const { Provider, Context } = createDataContext(
  userReducer,
  {
    makePortfolioConnection,
    fetchUserInfo,
    initiatePackOpen,
    setStripeAuth,
    resetUserInfo,
    streamerConvert,
    fetchStreamerIncome,
    fetchReactRequests,
    updateStreamerProfile,
    submitReactRequest,
    publishPool,
    submitIntakeForm,
    submitSignature,
    submitCardListing,
    fetchCardDetails,
    clearCardDetails,
  },
  {
    isUserInfoLoaded: false,
    userInfo: null,
    cardPortfolio: null,
    streamerProfile: null,
    cardLoaded: null,
    incomeGenerated: null,
    reactRequests: null,
  }
);
