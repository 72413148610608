import { StyleSheet } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import colors from "../../../res/colors";
import fonts from "../../../res/fonts";

const desktop = StyleSheet.create({
  container: {
    marginTop: "4%",
    alignSelf: "center",
    borderRadius: 10,
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    color: "grey",
    letterSpacing: 14,
    fontSize: 14,
    textAlign: "center",
  },
  header: {
    fontFamily: fonts.heavy,
    color: "white",
    marginTop: 25,
    fontSize: 80,
    textAlign: "center",
  },
  descText: {
    fontFamily: fonts.text,
    color: "grey",
    marginTop: 50,
    textAlign: "center",
    fontSize: 25,
  },
  columnContainer: {
    marginTop: 80,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  stepContainer: {
    padding: 40,
    //backgroundColor: colors.subBackground,
    backgroundColor: "white",
    borderRadius: 10,
    borderColor: "#F0F0F0",
    borderWidth: 1,
    width: "28%",
    minHeight: 500,
    justifyContent: "space-between",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
  },
  iconContainer: {
    backgroundColor: colors.green,
    padding: 15,
    height: 75,
    width: 75,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
  },
  stepHeader: {
    fontFamily: fonts.expandedBlack,
    fontSize: 35,
    marginBottom: 15,
  },
  stepDesc: {
    fontFamily: fonts.text,
    color: "#404040",
    fontSize: 20,
    flexWrap: "wrap",
    lineHeight: 28,
  },
  bottomStepContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bottomStepText: {
    fontFamily: fonts.expandedRegular,
    fontSize: 16,
  },
});

const tablet = StyleSheet.create({
  container: {
    marginTop: "4%",
    alignSelf: "center",
    borderRadius: 10,
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    color: "grey",
    letterSpacing: 14,
    fontSize: 14,
    textAlign: "center",
  },
  header: {
    fontFamily: fonts.heavy,
    color: "white",
    marginTop: 25,
    fontSize: 60,
    textAlign: "center",
  },
  descText: {
    fontFamily: fonts.text,
    color: "grey",
    marginTop: 50,
    textAlign: "center",
    fontSize: 20,
  },
  columnContainer: {
    marginTop: 80,
    flexDirection: "column",
    alignItems: "center",
  },
  headerRowTab: {
    flexDirection: "row",
    alignItems: "center",
  },
  stepContainer: {
    padding: 40,
    //backgroundColor: colors.subBackground,
    backgroundColor: "white",
    borderRadius: 10,
    borderColor: "#F0F0F0",
    borderWidth: 1,
    width: "70%",
    justifyContent: "space-between",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
    marginBottom: 25,
  },
  iconContainer: {
    backgroundColor: colors.green,
    padding: 15,
    height: 75,
    width: 75,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
    marginRight: 20,
  },
  stepHeader: {
    fontFamily: fonts.expandedBlack,
    fontSize: 35,
    marginBottom: 15,
  },
  stepDesc: {
    fontFamily: fonts.text,
    color: "#404040",
    fontSize: 20,
    flexWrap: "wrap",
    lineHeight: 28,
    marginBottom: 40,
  },
  bottomStepContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bottomStepText: {
    fontFamily: fonts.expandedRegular,
    fontSize: 16,
  },
});

const mobile = StyleSheet.create({
  container: {
    marginTop: "4%",
    alignSelf: "center",
    borderRadius: 10,
  },
  topText: {
    fontFamily: fonts.expandedBlack,
    color: "grey",
    letterSpacing: 14,
    fontSize: 14,
    textAlign: "center",
  },
  header: {
    fontFamily: fonts.heavy,
    color: "white",
    marginTop: 25,
    fontSize: 60,
    textAlign: "center",
  },
  descText: {
    fontFamily: fonts.text,
    color: "grey",
    marginTop: 50,
    textAlign: "center",
    fontSize: 20,
  },
  columnContainer: {
    marginTop: 80,
    flexDirection: "column",
    alignItems: "center",
  },
  headerRowTab: {
    flexDirection: "row",
    alignItems: "center",
  },
  stepContainer: {
    padding: 40,
    //backgroundColor: colors.subBackground,
    backgroundColor: "white",
    borderRadius: 10,
    borderColor: "#F0F0F0",
    borderWidth: 1,
    width: "85%",
    justifyContent: "space-between",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.05,
    shadowRadius: 14.78,
    elevation: 22,
    marginBottom: 25,
  },
  iconContainer: {
    backgroundColor: colors.green,
    padding: 15,
    height: 50,
    width: 50,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 25,
    marginRight: 20,
  },
  stepHeader: {
    fontFamily: fonts.expandedBlack,
    fontSize: 25,
    marginBottom: 15,
  },
  stepDesc: {
    fontFamily: fonts.text,
    color: "#404040",
    fontSize: 20,
    flexWrap: "wrap",
    lineHeight: 28,
    marginBottom: 40,
  },
  bottomStepContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bottomStepText: {
    fontFamily: fonts.expandedRegular,
    fontSize: 16,
  },
});

export { desktop, tablet, mobile };
